import React, { useCallback, useEffect, useState, useRef, useContext } from 'react';
import { Modal, Typography, notification, Button, Table, Checkbox, Col, Row, Form, Select } from 'antd';
import { useNavigate } from 'react-router-dom';
import locale from 'antd/es/locale/pt_BR';
import Icon from '@ant-design/icons';
import { FaCheckCircle, FaExclamationCircle, FaBan } from 'react-icons/fa';
// import moment from 'moment';

import { colModalChecks } from './GridStyle';

// import { getToken } from './../../services/LStorage/token';
import { VerificaRota } from './../../services/VerificaSePodeAcessar';
import LayoutDashboard from './../../layout/LayoutAdmin';
import { urlMunicipioStatusConsolidadoDataFiltro, urlMunicipioStatusMunicipios, urlMunicipioStatusMunicipiosXlsx } from './../../services/urls';
import { api } from './../../services/apiAxios';
import { EmitirErro } from '../../services/EmitirErro';
import { SairDoSistema } from '../../services/LStorage/SairSistema';
import { getToken } from './../../services/LStorage/token';

import { convertMoedaBRDoJSComExtamente2CasasDecimais, convertMoedaBRParaUS, poeValorComPontosEmMil } from './../../services/Conversores';

import { useGetColumnSearchProps } from './../../hooks/FilterTable/string';
import { Loading } from '../../components/Loading';
import { useGetColumnSearchPropsObj } from '../../hooks/FilterTable/objString';
import { GlobalContext } from '../../contexts/globalContext';
import { useGetColumnSearchPropsObjDDD } from '../../hooks/FilterTable/objDDD';
import { FormModal } from './components/FormModal';
import { removeAcento } from '../../utils/RemoveAcentos';
import { useGetColumnSearchRegiaoAdministrativa } from './components/FilterTableHook/regiaoAdministrativa';

import './style.css';
const { Text } = Typography;


const IconFaCheckCircle = (props: any) => <Icon {...props} component={FaCheckCircle} ></Icon>
const IconFaExclamationCircle = (props: any) => <Icon {...props} component={FaExclamationCircle} ></Icon>
const IconFaBan = (props: any) => <Icon {...props} component={FaBan} ></Icon>

// const largura = window.innerWidth;
// const altura = window.innerHeight;
type interfFiltroPopulacao = 'todos' | 'municipiosAteVinteMil' | 'municipiosEntreVinteECinquenta' | 'municipiosEntreCinquentaECem' | 'municipiosAcimaDeCem';

interface interfMunicipios {
    "id": string,
    "createdAt": string,
    "updatedAt": string,
    "limiteLrf"?: string | number | null,
    "limitePotencial"?: string | number | null,
    "cdp": boolean,
    "statusCertidoes": boolean,
    "cdpAtualizadoEm"?: string | null,
    "despesaPessoalLegislativo"?: number | string | null,
    "despesaPessoalLegislativoAtualizadoEm"?: string | null,
    "despesaPessoalLegislativoPeriodo"?: string | null,
    "despesaPessoalExecutivo": 41.3,
    "despesaPessoalExecutivoAtualizadoEm"?: string | null,
    "despesaPessoalExecutivoPeriodo"?: string | null,
    "municipioAtendido": boolean,
    "pendentesRegularizacaoConsultaJuridica": boolean,
    "pendentesRegularizacaoConsultaJuridicaAtualizadoEm"?: string | null,
    "homologacaoRreo": boolean,
    "homologacaoRreoAtualizadoEm"?: string | null,
    "homologacaoRreoPeriodo"?: string | null,
    "homologacaoRreoEm"?: string | null,
    "retificacaoRreoEm"?: string | null,
    "homologacaoRgf": boolean,
    "homologacaoRgfAtualizadoEm"?: string | null,
    "homologacaoRgfEm"?: string | null,
    "retificacaoRgfEm"?: string | null,
    "homologacaoRgfPeriodo"?: string | null,
    "municipioIdIbge": number,
    "populacao": number,
    "anoPopulacao"?: string | null,
    "valorComprometido"?: number | string,
    "municipio": {
        "idIbge": number,
        "nome": string,
        "ddd": string | null,
        "populacao": number | null,
        "valorComprometido": number | null,
        "municipioAtendido": boolean,
        "anoPopulacao"?: string | null,
        "municipioAtendidoAtualizadoEm"?: string | null,
        "createdAt"?: string | null,
        "updatedAt"?: string | null,
        "regiaoAdministrativa": {
            "createdAt": string | null,
            "id": string,
            "nome": string | null,
            "updatedAt": string | null,
        }
    },
    "artigo167aStatusValor"?: number | null,
    "habilitado": boolean,
    "populacaoFormatada"?: string | null,
    "limiteLrfString"?: string | null,
    "item_3_1_1": "Comprovado" | "Desativado" | "A comprovar",
    "item_3_1_2": "Comprovado" | "Desativado" | "A comprovar",
    "item_3_2_1": "Comprovado" | "Desativado" | "A comprovar",
    "item_3_2_2": "Comprovado" | "Desativado" | "A comprovar",
    "item_3_2_3": "Comprovado" | "Desativado" | "A comprovar",
    "item_3_2_4": "Comprovado" | "Desativado" | "A comprovar",
    "item_3_3": "Comprovado" | "Desativado" | "A comprovar",
    "item_3_4_1": "Comprovado" | "Desativado" | "A comprovar",
    "item_3_4_2": "Comprovado" | "Desativado" | "A comprovar",
    "item_3_5": "Comprovado" | "Desativado" | "A comprovar",
    "despesaExecutivoAcimaDoLimite"?: string | null,
    "despesaLegislativoAcimaDoLimite"?: string | null,
    "despesaPessoalExecutivoString"?: string | null,
    "despesaPessoalLegislativoString"?: string | null
    "certidao_1"?: string | null
    "certidao_2"?: string | null
    "certidao_5"?: string | null
    "certidao_7"?: string | null
    "certidao_9"?: string | null
}
interface interfDadosMunicios {
    "municipiosAteVinteMil": Array<interfMunicipios>,
    "municipiosEntreVinteECinquenta": Array<interfMunicipios>,
    "municipiosEntreCinquentaECem": Array<interfMunicipios>,
    "municipiosAcimaDeCem": Array<interfMunicipios>
}

const ListagemMunicipios: React.FC = () => {

    const navigate = useNavigate();

    const refAno = useRef<any>();
    const refMes = useRef<any>();

    const formRef = useRef<any>(null);
    const refContagemDoFiltro = React.useRef<Array<any>>([]);
    const refDataRequestAllCityState = React.useRef<Array<any>>([]);
    const refValorDaPopulacaoFiltro = React.useRef<interfFiltroPopulacao>('todos');

    const { theme } = useContext(GlobalContext);

    const refDataRequestAllCity = useRef<interfDadosMunicios | null>(null);

    const [dadosTabelaState, setDadosTabelaState] = useState<Array<interfMunicipios>>([]);
    const [columnsState, setColumnsState] = useState<Array<any>>([]);
    const [visibleModal, setVisibleModal] = useState<boolean>(false);
    const [visibleModalFiltros, setVisibleModalFiltros] = useState<boolean>(false);
    const [loadingPage, setLoadingPage] = useState(false);
    const [handleSubmitModal, setHandleSubmitModal] = useState<any>(null);

    const [valSelectMesFiltros, setValSelectMesFiltros] = useState<number>();
    const [valSelectAnoFiltros, setValSelectAnoFiltros] = useState<number>();
    const [meses, setMeses] = useState<Array<{ mes: number, nome: string }>>([]);
    const [anos, setAnos] = useState<Array<{ ano: number, meses: Array<{ mes: number }> }>>([]);

    const mesesString = [
        {
            mes: 0,
            nome: 'Janeiro'
        },
        {
            mes: 1,
            nome: 'Fevereiro'
        },
        {
            mes: 2,
            nome: 'Março'
        },
        {
            mes: 3,
            nome: 'Abril'
        },
        {
            mes: 4,
            nome: 'Maio'
        },
        {
            mes: 5,
            nome: 'Junho'
        },
        {
            mes: 6,
            nome: 'Julho'
        },
        {
            mes: 7,
            nome: 'Agosto'
        },
        {
            mes: 8,
            nome: 'Setembro'
        },
        {
            mes: 9,
            nome: 'Outubro'
        },
        {
            mes: 10,
            nome: 'Novembro'
        },
        {
            mes: 11,
            nome: 'Dezembro'
        }
    ]

    const arrayAttColunas = [
        // { id: "id", nome: 'id', value: false },
        { id: "nome", nome: 'Nome do Município', value: true },
        { id: "ddd", nome: 'DDD do Município', value: false },
        { id: "regiaoAdministrativa", nome: 'Região Administrativa', value: false },
        { id: "populacao", nome: 'População', value: true },
        { id: "cdp", nome: 'CDP', value: true },
        { id: "despesaPessoalLegislativo", nome: 'Despesa Pessoal Legislativo (% RCL Ajustada)', value: true },
        { id: "despesaPessoalExecutivo", nome: 'Despesa Pessoal Executivo (% RCL Ajustada)', value: true },
        { id: "homologacaoRreo", nome: 'Homologação RREO', value: true },
        { id: "homologacaoRgf", nome: 'Homologação RGF', value: true },
        { id: "artigo167aResultado", nome: "Artigo 167-A CF (%) - Atual", value: false },
        { id: "artigo167aAnteriorResultado", nome: "Artigo 167-A CF (%) - Anterior", value: false },
        { id: "pendentesRegularizacaoConsultaJuridica", nome: 'Pendentes de regularização / Em consulta jurídica', value: true },
        { id: "habilitado", nome: 'Resultado', value: true },
        { id: "limiteLrf", nome: 'Limite LRF', value: true },
        { id: "limitePotencial", nome: 'Limite Potencial', value: false },
        { id: "valorComprometido", nome: 'Valor comprometido', value: false },
        { id: "municipioAtendido", nome: 'Município atendido', value: true },
        { id: "statusCertidoes", nome: 'Status Certidões', value: false },
        { id: "item_3_1_1", nome: "Item 3_1_1 CAUC", value: false },
        { id: "item_3_1_2", nome: "Item 3_1_2 CAUC", value: false },
        { id: "item_3_2_1", nome: "Item 3_2_1 CAUC", value: false },
        { id: "item_3_2_2", nome: "Item 3_2_2 CAUC", value: false },
        { id: "item_3_2_3", nome: "Item 3_2_3 CAUC", value: false },
        { id: "item_3_2_4", nome: "Item 3_2_4 CAUC", value: false },
        { id: "item_3_3", nome: "Item 3_3 CAUC", value: false },
        { id: "item_3_4_1", nome: "Item 3_4_1 CAUC", value: false },
        { id: "item_3_4_2", nome: "Item 3_4_2 CAUC", value: false },
        { id: "item_3_5", nome: "Item 3_5 CAUC", value: false },
        { id: "certidao_1", nome: "Receita Federal – (Financiamento e Fundo Perdido)", value: false },
        { id: "certidao_2", nome: "FGTS", value: false },
        { id: "certidao_5", nome: "Tribunal Superior do Trabalho - TST", value: false },
        { id: "certidao_7", nome: "CADIN", value: false },
        { id: "certidao_9", nome: "Certificado de Regularidade Previdenciária - CRP", value: false },
        // { id: "anoPopulacao", nome: 'Ano população', value: false },
        // { id: "createdAt", nome: 'Criado em', value: false },
        // { id: "updatedAt", nome: 'Atualizado em', value: false },
        // { id: "cdpAtualizadoEm", nome: 'CDP atualizado em', value: false },
        // { id: "despesaPessoalLegislativoAtualizadoEm", nome: 'Despesa pessoal legislativo atualizado em', value: false },
        // { id: "despesaPessoalLegislativoPeriodo", nome: 'Despesa pessoal legislativo periodo', value: false },
        // { id: "despesaPessoalExecutivoAtualizadoEm", nome: 'Despesa pessoal executivo atualizado em', value: false },
        // { id: "despesaPessoalExecutivoPeriodo", nome: 'Despesa pessoal executivo periodo', value: false },
        // { id: "pendentesRegularizacaoConsultaJuridicaAtualizadoEm", nome: 'Pendentes regularizacao consulta juridica atualizado em', value: false },
        // { id: "homologacaoRreoAtualizadoEm", nome: 'Homologacao RREO atualizado em', value: false },
        // { id: "homologacaoRreoPeriodo", nome: 'Homologacao RREO periodo', value: false },
        // { id: "homologacaoRreoEm", nome: 'Homologacao RREO em', value: false },
        // { id: "retificacaoRreoEm", nome: 'Retificação RREO em', value: false },
        // { id: "homologacaoRgfAtualizadoEm", nome: 'Homologacao RGF atualizado em', value: false },
        // { id: "homologacaoRgfEm", nome: 'Homologacao RGF em', value: false },
        // { id: "retificacaoRgfEm", nome: 'Retificação RGF em', value: false },
        // { id: "homologacaoRgfPeriodo", nome: 'Homologacao RGF periodo', value: false },
        // { id: "municipioIdIbge", nome: 'Municipio id', value: false },
        // { id: "populacaoFormatada", nome: 'População formatada', value: false },
        // { id: "limiteLrfString", nome: 'Limite LRF String', value: false },
        // { id: "despesaExecutivoAcimaDoLimite", nome: 'Despesa executivo acima do limite', value: false },
        // { id: "despesaLegislativoAcimaDoLimite", nome: 'Despesa legislativo acima do limite', value: false },
        // { id: "despesaPessoalExecutivoString", nome: 'Despesa pessoal executivo String', value: false },
        // { id: "despesaPessoalLegislativoString", nome: 'Despesa pessoal legislativo String', value: false }
    ]

    // pesquisa "reset filter table antd"
    // Reset filters table example
    // https://github.com/ant-design/ant-design/blob/master/components/table/demo/reset-filter.md
    // or ou
    // https://programming.vip/docs/antd-how-to-clear-the-filter-items-after-the-table-component-data-is-re-rendered.html
    const colunasDinamicas: any = {
        nome: {
            title: 'Município',
            key: 'municicio',
            dataIndex: 'municipio',
            // ...useGetColumnSearchProps('municipio'),
            ...useGetColumnSearchPropsObj('municipio'),
            width: '10%',
            sorter: (a: any, b: any) => a.municipio.nome.localeCompare(b.municipio.nome),
            showSorterTooltip: false,
            render: (municipio: any) => {

                return municipio.nome
            },
        },
        ddd: {
            title: 'DDD',
            key: 'ddd',
            dataIndex: 'municipio',
            // ...useGetColumnSearchProps('municipio'),
            ...useGetColumnSearchPropsObjDDD('municipio'),
            width: '10%',
            sorter: (a: any, b: any) => a.municipio.ddd.localeCompare(b.municipio.ddd),
            showSorterTooltip: false,
            render: (municipio: any) => {

                return (
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                        <Text
                        // style={{
                        //     color: objCompleto.importante ? '#EB3C54' : undefined
                        // }}
                        // strong={objCompleto.importante}
                        >
                            {municipio.ddd}
                        </Text>
                    </div>
                )
            },
        },
        regiaoAdministrativa: {
            title: 'Região Administrativa',
            key: 'regiaoAdministrativa',
            dataIndex: 'municipio',
            // ...useGetColumnSearchProps('municipio'),
            ...useGetColumnSearchRegiaoAdministrativa('municipio'),
            width: '10%',
            sorter: (a: any, b: any) => a.municipio.regiaoAdministrativa.nome.localeCompare(b.municipio.regiaoAdministrativa.nome),
            showSorterTooltip: false,
            render: (municipio: any) => {

                return (
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        textAlign: 'center'
                    }}>
                        <Text
                        // style={{
                        //     color: objCompleto.importante ? '#EB3C54' : undefined
                        // }}
                        // strong={objCompleto.importante}
                        >
                            {municipio.regiaoAdministrativa.nome}
                        </Text>
                    </div>
                )
            },
        },
        cdp: {
            title: 'CDP',
            dataIndex: 'cdp',
            // ...useGetColumnSearchProps('cdp'),
            width: '10%',
            filters: [
                {
                    text: 'Regular',
                    value: 'true',
                },
                {
                    text: 'Irregular',
                    value: 'false',
                },
            ],
            onFilter: (value: any, record: any) => {
                return record.cdp.toString().indexOf(value) === 0;
            },
            showSorterTooltip: false,
            render: (cpd: any) => {

                return (
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}>

                        <Text
                        // style={{
                        //     color: objCompleto.importante ? '#EB3C54' : undefined
                        // }}
                        // strong={objCompleto.importante}
                        >
                            {
                                cpd ?
                                    'Regular'
                                    : 'Irregular'
                            }
                        </Text>
                    </div>

                )
            },
        },
        statusCertidoes: {
            title: 'Status certidões',
            dataIndex: 'statusCertidoes',
            // ...useGetColumnSearchProps('statusCertidoes'),
            width: '10%',
            filters: [
                {
                    text: 'Válida',
                    value: 'false',
                },
                {
                    text: 'Inválida',
                    value: 'true',
                },
            ],
            onFilter: (value: any, record: any) => {
                return record.statusCertidoes.toString().indexOf(value) === 0;
            },
            showSorterTooltip: false,
            render: (statusCertidoes: any) => {

                return (
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}>

                        <Text
                        // style={{
                        //     color: objCompleto.importante ? '#EB3C54' : undefined
                        // }}
                        // strong={objCompleto.importante}
                        >
                            {
                                statusCertidoes ?
                                    'Inválida'
                                    : 'Válida'
                            }
                        </Text>
                    </div>

                )
            },
        },
        pendentesRegularizacaoConsultaJuridica: {
            title: `Pendentes de \n Regularização`,
            dataIndex: 'pendentesRegularizacaoConsultaJuridica',
            // ...useGetColumnSearchProps('cdp'),
            width: '10%',
            filters: [
                {
                    text: 'SIM',
                    value: 'true',
                },
                {
                    text: 'NÃO',
                    value: 'false',
                },
            ],
            onFilter: (value: any, record: any) => {
                return record.pendentesRegularizacaoConsultaJuridica.toString().indexOf(value) === 0;
            },
            showSorterTooltip: false,
            render: (cpd: any) => {

                return (
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}>


                        <Text
                        // style={{
                        //     color: objCompleto.importante ? '#EB3C54' : undefined
                        // }}
                        // strong={objCompleto.importante}
                        >
                            {
                                cpd ?
                                    'SIM'
                                    : 'NÃO'
                            }
                        </Text>
                    </div>

                )
            },
        },
        despesaPessoalLegislativo: {
            title: `Despesa Pessoal Legislativo (%)`,
            dataIndex: 'despesaPessoalLegislativo',
            ...useGetColumnSearchProps('despesaPessoalLegislativo'),
            width: '10%',
            sorter: (a: any, b: any) => a.despesaPessoalLegislativo - b.despesaPessoalLegislativo,
            showSorterTooltip: false,
            render: (despesaPessoalLegislativo: any) => {

                return (
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                        <Text
                        // style={{
                        //     color: objCompleto.importante ? '#EB3C54' : undefined
                        // }}
                        // strong={objCompleto.importante}
                        >
                            {
                                despesaPessoalLegislativo
                            }
                        </Text>
                    </div>

                )
            },
        },
        despesaPessoalExecutivo: {
            title: `Despesa Pessoal Executivo (%)`,
            dataIndex: 'despesaPessoalExecutivo',
            ...useGetColumnSearchProps('despesaPessoalExecutivo'),
            width: '10%',
            sorter: (a: any, b: any) => a.despesaPessoalExecutivo - b.despesaPessoalExecutivo,
            showSorterTooltip: false,
            render: (despesaPessoalExecutivo: any) => {

                return (
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                        <Text
                        // style={{
                        //     color: objCompleto.importante ? '#EB3C54' : undefined
                        // }}
                        // strong={objCompleto.importante}
                        >
                            {
                                despesaPessoalExecutivo
                            }
                        </Text>
                    </div>

                )
            },
        },
        homologacaoRreo: {
            title: `Homologação RREO`,
            dataIndex: 'homologacaoRreo',
            // ...useGetColumnSearchProps('cdp'),
            width: '10%',
            filters: [
                {
                    text: 'SIM',
                    value: 'true',
                },
                {
                    text: 'NÃO',
                    value: 'false',
                },
            ],
            onFilter: (value: any, record: any) => {
                return record.homologacaoRreo.toString().indexOf(value) === 0;
            },
            showSorterTooltip: false,
            render: (homologacaoRreo: any) => {
                return (
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                        <Text
                        // style={{
                        //     color: objCompleto.importante ? '#EB3C54' : undefined
                        // }}
                        // strong={objCompleto.importante}
                        >
                            {
                                homologacaoRreo ?
                                    'SIM'
                                    : 'NÃO'
                            }
                        </Text>
                    </div>

                )
            },
        },
        homologacaoRgf: {
            title: `Homologação RGF`,
            dataIndex: 'homologacaoRgf',
            // ...useGetColumnSearchProps('cdp'),
            width: '10%',
            filters: [
                {
                    text: 'SIM',
                    value: 'true',
                },
                {
                    text: 'NÃO',
                    value: 'false',
                },
            ],
            onFilter: (value: any, record: any) => {
                return record.homologacaoRgf.toString().indexOf(value) === 0;
            },
            showSorterTooltip: false,
            render: (homologacaoRgf: any) => {
                return (
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                        <Text
                        // style={{
                        //     color: objCompleto.importante ? '#EB3C54' : undefined
                        // }}
                        // strong={objCompleto.importante}
                        >
                            {
                                homologacaoRgf ?
                                    'SIM'
                                    : 'NÃO'
                            }
                        </Text>
                    </div>

                )
            },
        },
        artigo167aResultado: {
            title: `Artigo 167-A CF (%) - Atual`,
            dataIndex: 'artigo167aResultado',
            ...useGetColumnSearchProps('artigo167aResultado'),
            width: '10%',
            sorter: (a: any, b: any) => a?.artigo167aResultado - b?.artigo167aResultado,
            showSorterTooltip: false,
            render: (artigo167aResultado: number | null | undefined) => {

                return (
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                        <Text
                        >
                            {
                                artigo167aResultado === undefined || artigo167aResultado === null
                                    ?
                                    0 : artigo167aResultado
                            }
                        </Text>
                    </div>

                )
            },
        },
        artigo167aAnteriorResultado: {
            title: `Artigo 167-A CF (%) - Anterior`,
            dataIndex: 'artigo167aAnteriorResultado',
            ...useGetColumnSearchProps('artigo167aAnteriorResultado'),
            width: '10%',
            sorter: (a: any, b: any) => a?.artigo167aAnteriorResultado - b?.artigo167aAnteriorResultado,
            showSorterTooltip: false,
            render: (artigo167aAnteriorResultado: number | null | undefined) => {

                return (
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                        <Text
                        >
                            {
                                artigo167aAnteriorResultado === undefined || artigo167aAnteriorResultado === null
                                    ?
                                    0 : artigo167aAnteriorResultado
                            }
                        </Text>
                    </div>

                )
            },
        },
        municipioAtendido: {
            title: `Município Atendido`,
            dataIndex: 'municipioAtendido',
            // ...useGetColumnSearchProps('cdp'),
            width: '10%',
            filters: [
                {
                    text: 'SIM',
                    value: 'true',
                },
                {
                    text: 'NÃO',
                    value: 'false',
                },
            ],
            onFilter: (value: any, record: any) => {
                return record.municipioAtendido.toString().indexOf(value) === 0;
            },
            showSorterTooltip: false,
            render: (municipioAtendido: any) => {
                return (
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                        <Text
                        // style={{
                        //     color: objCompleto.importante ? '#EB3C54' : undefined
                        // }}
                        // strong={objCompleto.importante}
                        >
                            {
                                municipioAtendido ?
                                    'SIM'
                                    : 'NÃO'
                            }
                        </Text>
                    </div>

                )
            },
        },
        limiteLrf: {
            title: 'Limite LRF (R$)',
            dataIndex: 'limiteLrf',
            // ...useGetColumnSearchProps('cdp'),
            width: '10%',
            sorter: (a: any, b: any) => a.limiteLrf - b.limiteLrf,
            showSorterTooltip: false,
            render: (limiteLrf: any) => {

                return (
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                        <Text
                        // style={{
                        //     color: objCompleto.importante ? '#EB3C54' : undefined
                        // }}
                        // strong={objCompleto.importante}
                        >
                            {
                                convertMoedaBRDoJSComExtamente2CasasDecimais(limiteLrf)
                            }
                        </Text>
                    </div>

                )
            },
        },
        limitePotencial: {
            title: 'Limite Potencial',
            dataIndex: 'limitePotencial',
            // ...useGetColumnSearchProps('cdp'),
            width: '10%',
            sorter: (a: any, b: any) => a.limitePotencial - b.limitePotencial,
            showSorterTooltip: false,
            render: (limitePotencial: any) => {

                return (
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                        <Text
                        // style={{
                        //     color: objCompleto.importante ? '#EB3C54' : undefined
                        // }}
                        // strong={objCompleto.importante}
                        >
                            {
                                convertMoedaBRDoJSComExtamente2CasasDecimais(limitePotencial)
                            }
                        </Text>
                    </div>

                )
            },
        },
        habilitado: {
            title: `Resultado`,
            dataIndex: 'habilitado',
            // ...useGetColumnSearchProps('cdp'),
            width: '10%',
            filters: [
                {
                    text: 'Habilitado',
                    value: 'true',
                },
                {
                    text: 'Não Habilitado',
                    value: 'false',
                },
            ],
            onFilter: (value: any, record: any) => {
                return record.habilitado.toString().indexOf(value) === 0;
            },
            showSorterTooltip: false,
            render: (habilitado: any) => {
                return (
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        textAlign: 'center'
                    }}>
                        <Text
                            style={{
                                //     color: objCompleto.importante ? '#EB3C54' : undefined
                            }}
                        // strong={objCompleto.importante}
                        >
                            {
                                habilitado ?
                                    'Habilitado'
                                    : 'Não Habilitado'
                            }
                        </Text>
                    </div>

                )
            },
        },
        populacao: {
            title: 'População',
            dataIndex: 'populacao',
            // ...useGetColumnSearchProps('cdp'),
            width: '10%',
            sorter: (a: any, b: any) => a.populacao - b.populacao,
            showSorterTooltip: false,
            render: (populacao: any) => {

                return (
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                        <Text
                        // style={{
                        //     color: objCompleto.importante ? '#EB3C54' : undefined
                        // }}
                        // strong={objCompleto.importante}
                        >
                            {
                                poeValorComPontosEmMil(populacao)
                            }
                        </Text>
                    </div>

                )
            },
        },
        valorComprometido: {
            title: 'Valor comprometido (R$)',
            dataIndex: 'valorComprometido',
            // ...useGetColumnSearchProps('cdp'),
            width: '10%',
            sorter: (a: any, b: any) => a.valorComprometido - b.valorComprometido,
            showSorterTooltip: false,
            render: (valorComprometido: any) => {

                return (
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                        <Text
                        // style={{
                        //     color: objCompleto.importante ? '#EB3C54' : undefined
                        // }}
                        // strong={objCompleto.importante}
                        >
                            {
                                convertMoedaBRDoJSComExtamente2CasasDecimais(valorComprometido)
                            }
                        </Text>
                    </div>

                )
            },
        },
        item_3_1_1: {
            title: 'Item 3_1_1 CAUC',
            dataIndex: 'item_3_1_1',
            ...useGetColumnSearchProps('item_3_1_1'),
            width: '8%',
            sorter: (a: any, b: any) => a.item_3_1_1.localeCompare(b.item_3_1_1),
            showSorterTooltip: false,
            render: (criado: any) => {
                return (
                    <div
                        style={{
                            display: 'flex',
                            // justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                    >
                        {
                            criado === 'Comprovado' ?
                                <IconFaCheckCircle style={{ color: '#4CA750', fontSize: 26, marginRight: 10, padding: 0, backgroundColor: '#fff', borderRadius: '100%' }} />
                                : criado === 'A comprovar' ?
                                    <IconFaExclamationCircle style={{ color: '#df9e36', fontSize: 26, marginRight: 10, padding: 0, backgroundColor: '#fff', borderRadius: '100%' }} />
                                    : criado === 'Desativado' ?
                                        <IconFaBan style={{ color: '#ccc', fontSize: 26, marginRight: 10, padding: 0 }} />
                                        :
                                        <IconFaCheckCircle style={{ color: '#4CA750', fontSize: 26, marginRight: 10, padding: 0 }} />
                        }
                        <Text>
                            {
                                criado
                            }
                        </Text>
                    </div>
                )
            },
        },
        item_3_1_2: {
            title: 'Item 3_1_2 CAUC',
            dataIndex: 'item_3_1_2',
            ...useGetColumnSearchProps('item_3_1_2'),
            width: '8%',
            sorter: (a: any, b: any) => a.item_3_1_2.localeCompare(b.item_3_1_2),
            showSorterTooltip: false,
            render: (criado: any) => {
                return (
                    <div
                        style={{
                            display: 'flex',
                            // justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                    >
                        {
                            criado === 'Comprovado' ?
                                <IconFaCheckCircle style={{ color: '#4CA750', fontSize: 26, marginRight: 10, padding: 0, backgroundColor: '#fff', borderRadius: '100%' }} />
                                : criado === 'A comprovar' ?
                                    <IconFaExclamationCircle style={{ color: '#df9e36', fontSize: 26, marginRight: 10, padding: 0, backgroundColor: '#fff', borderRadius: '100%' }} />
                                    : criado === 'Desativado' ?
                                        <IconFaBan style={{ color: '#ccc', fontSize: 26, marginRight: 10, padding: 0 }} />
                                        :
                                        <IconFaCheckCircle style={{ color: '#4CA750', fontSize: 26, marginRight: 10, padding: 0 }} />
                        }
                        <Text>
                            {
                                criado
                            }
                        </Text>
                    </div>
                )
            },
        },
        item_3_2_1: {
            title: 'Item 3_2_1 CAUC',
            dataIndex: 'item_3_2_1',
            ...useGetColumnSearchProps('item_3_2_1'),
            width: '8%',
            sorter: (a: any, b: any) => a.item_3_2_1.localeCompare(b.item_3_2_1),
            showSorterTooltip: false,
            render: (criado: any) => {
                return (
                    <div
                        style={{
                            display: 'flex',
                            // justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                    >
                        {
                            criado === 'Comprovado' ?
                                <IconFaCheckCircle style={{ color: '#4CA750', fontSize: 26, marginRight: 10, padding: 0, backgroundColor: '#fff', borderRadius: '100%' }} />
                                : criado === 'A comprovar' ?
                                    <IconFaExclamationCircle style={{ color: '#df9e36', fontSize: 26, marginRight: 10, padding: 0, backgroundColor: '#fff', borderRadius: '100%' }} />
                                    : criado === 'Desativado' ?
                                        <IconFaBan style={{ color: '#ccc', fontSize: 26, marginRight: 10, padding: 0 }} />
                                        :
                                        <IconFaCheckCircle style={{ color: '#4CA750', fontSize: 26, marginRight: 10, padding: 0 }} />
                        }
                        <Text>
                            {
                                criado
                            }
                        </Text>
                    </div>
                )
            },
        },
        item_3_2_2: {
            title: 'Item 3_2_2 CAUC',
            dataIndex: 'item_3_2_2',
            ...useGetColumnSearchProps('item_3_2_2'),
            width: '8%',
            sorter: (a: any, b: any) => a.item_3_2_2.localeCompare(b.item_3_2_2),
            showSorterTooltip: false,
            render: (criado: any) => {
                return (
                    <div
                        style={{
                            display: 'flex',
                            // justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                    >
                        {
                            criado === 'Comprovado' ?
                                <IconFaCheckCircle style={{ color: '#4CA750', fontSize: 26, marginRight: 10, padding: 0, backgroundColor: '#fff', borderRadius: '100%' }} />
                                : criado === 'A comprovar' ?
                                    <IconFaExclamationCircle style={{ color: '#df9e36', fontSize: 26, marginRight: 10, padding: 0, backgroundColor: '#fff', borderRadius: '100%' }} />
                                    : criado === 'Desativado' ?
                                        <IconFaBan style={{ color: '#ccc', fontSize: 26, marginRight: 10, padding: 0 }} />
                                        :
                                        <IconFaCheckCircle style={{ color: '#4CA750', fontSize: 26, marginRight: 10, padding: 0 }} />
                        }
                        <Text>
                            {
                                criado
                            }
                        </Text>
                    </div>
                )
            },
        },
        item_3_2_3: {
            title: 'Item 3_2_3 CAUC',
            dataIndex: 'item_3_2_3',
            ...useGetColumnSearchProps('item_3_2_3'),
            width: '8%',
            sorter: (a: any, b: any) => a.item_3_2_3.localeCompare(b.item_3_2_3),
            showSorterTooltip: false,
            render: (criado: any) => {
                return (
                    <div
                        style={{
                            display: 'flex',
                            // justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                    >
                        {
                            criado === 'Comprovado' ?
                                <IconFaCheckCircle style={{ color: '#4CA750', fontSize: 26, marginRight: 10, padding: 0, backgroundColor: '#fff', borderRadius: '100%' }} />
                                : criado === 'A comprovar' ?
                                    <IconFaExclamationCircle style={{ color: '#df9e36', fontSize: 26, marginRight: 10, padding: 0, backgroundColor: '#fff', borderRadius: '100%' }} />
                                    : criado === 'Desativado' ?
                                        <IconFaBan style={{ color: '#ccc', fontSize: 26, marginRight: 10, padding: 0 }} />
                                        :
                                        <IconFaCheckCircle style={{ color: '#4CA750', fontSize: 26, marginRight: 10, padding: 0 }} />
                        }
                        <Text>
                            {
                                criado
                            }
                        </Text>
                    </div>
                )
            },
        },
        item_3_2_4: {
            title: 'Item 3_2_4 CAUC',
            dataIndex: 'item_3_2_4',
            ...useGetColumnSearchProps('item_3_2_4'),
            width: '8%',
            sorter: (a: any, b: any) => a.item_3_2_4.localeCompare(b.item_3_2_4),
            showSorterTooltip: false,
            render: (criado: any) => {
                return (
                    <div
                        style={{
                            display: 'flex',
                            // justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                    >
                        {
                            criado === 'Comprovado' ?
                                <IconFaCheckCircle style={{ color: '#4CA750', fontSize: 26, marginRight: 10, padding: 0, backgroundColor: '#fff', borderRadius: '100%' }} />
                                : criado === 'A comprovar' ?
                                    <IconFaExclamationCircle style={{ color: '#df9e36', fontSize: 26, marginRight: 10, padding: 0, backgroundColor: '#fff', borderRadius: '100%' }} />
                                    : criado === 'Desativado' ?
                                        <IconFaBan style={{ color: '#ccc', fontSize: 26, marginRight: 10, padding: 0 }} />
                                        :
                                        <IconFaCheckCircle style={{ color: '#4CA750', fontSize: 26, marginRight: 10, padding: 0 }} />
                        }
                        <Text>
                            {
                                criado
                            }
                        </Text>
                    </div>
                )
            },
        },
        item_3_3: {
            title: 'Item 3_3 CAUC',
            dataIndex: 'item_3_3',
            ...useGetColumnSearchProps('item_3_3'),
            width: '8%',
            sorter: (a: any, b: any) => a.item_3_3.localeCompare(b.item_3_3),
            showSorterTooltip: false,
            render: (criado: any) => {
                return (
                    <div
                        style={{
                            display: 'flex',
                            // justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                    >
                        {
                            criado === 'Comprovado' ?
                                <IconFaCheckCircle style={{ color: '#4CA750', fontSize: 26, marginRight: 10, padding: 0, backgroundColor: '#fff', borderRadius: '100%' }} />
                                : criado === 'A comprovar' ?
                                    <IconFaExclamationCircle style={{ color: '#df9e36', fontSize: 26, marginRight: 10, padding: 0, backgroundColor: '#fff', borderRadius: '100%' }} />
                                    : criado === 'Desativado' ?
                                        <IconFaBan style={{ color: '#ccc', fontSize: 26, marginRight: 10, padding: 0 }} />
                                        :
                                        <IconFaCheckCircle style={{ color: '#4CA750', fontSize: 26, marginRight: 10, padding: 0 }} />
                        }
                        <Text>
                            {
                                criado
                            }
                        </Text>
                    </div>
                )
            },
        },
        item_3_4_1: {
            title: 'Item 3_4_1 CAUC',
            dataIndex: 'item_3_4_1',
            ...useGetColumnSearchProps('item_3_4_1'),
            width: '8%',
            sorter: (a: any, b: any) => a.item_3_4_1.localeCompare(b.item_3_4_1),
            showSorterTooltip: false,
            render: (criado: any) => {
                return (
                    <div
                        style={{
                            display: 'flex',
                            // justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                    >
                        {
                            criado === 'Comprovado' ?
                                <IconFaCheckCircle style={{ color: '#4CA750', fontSize: 26, marginRight: 10, padding: 0, backgroundColor: '#fff', borderRadius: '100%' }} />
                                : criado === 'A comprovar' ?
                                    <IconFaExclamationCircle style={{ color: '#df9e36', fontSize: 26, marginRight: 10, padding: 0, backgroundColor: '#fff', borderRadius: '100%' }} />
                                    : criado === 'Desativado' ?
                                        <IconFaBan style={{ color: '#ccc', fontSize: 26, marginRight: 10, padding: 0 }} />
                                        :
                                        <IconFaCheckCircle style={{ color: '#4CA750', fontSize: 26, marginRight: 10, padding: 0 }} />
                        }
                        <Text>
                            {
                                criado
                            }
                        </Text>
                    </div>
                )
            },
        },
        item_3_4_2: {
            title: 'Item 3_4_2 CAUC',
            dataIndex: 'item_3_4_2',
            ...useGetColumnSearchProps('item_3_4_2'),
            width: '8%',
            sorter: (a: any, b: any) => a.item_3_4_2.localeCompare(b.item_3_4_2),
            showSorterTooltip: false,
            render: (criado: any) => {
                return (
                    <div
                        style={{
                            display: 'flex',
                            // justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                    >
                        {
                            criado === 'Comprovado' ?
                                <IconFaCheckCircle style={{ color: '#4CA750', fontSize: 26, marginRight: 10, padding: 0, backgroundColor: '#fff', borderRadius: '100%' }} />
                                : criado === 'A comprovar' ?
                                    <IconFaExclamationCircle style={{ color: '#df9e36', fontSize: 26, marginRight: 10, padding: 0, backgroundColor: '#fff', borderRadius: '100%' }} />
                                    : criado === 'Desativado' ?
                                        <IconFaBan style={{ color: '#ccc', fontSize: 26, marginRight: 10, padding: 0 }} />
                                        :
                                        <IconFaCheckCircle style={{ color: '#4CA750', fontSize: 26, marginRight: 10, padding: 0 }} />
                        }
                        <Text>
                            {
                                criado
                            }
                        </Text>
                    </div>
                )
            },
        },
        item_3_5: {
            title: 'Item 3_5 CAUC',
            dataIndex: 'item_3_5',
            ...useGetColumnSearchProps('item_3_5'),
            width: '8%',
            sorter: (a: any, b: any) => a.item_3_5.localeCompare(b.item_3_5),
            showSorterTooltip: false,
            render: (criado: any) => {
                return (
                    <div
                        style={{
                            display: 'flex',
                            // justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                    >
                        {
                            criado === 'Comprovado' ?
                                <IconFaCheckCircle style={{ color: '#4CA750', fontSize: 26, marginRight: 10, padding: 0, backgroundColor: '#fff', borderRadius: '100%' }} />
                                : criado === 'A comprovar' ?
                                    <IconFaExclamationCircle style={{ color: '#df9e36', fontSize: 26, marginRight: 10, padding: 0, backgroundColor: '#fff', borderRadius: '100%' }} />
                                    : criado === 'Desativado' ?
                                        <IconFaBan style={{ color: '#ccc', fontSize: 26, marginRight: 10, padding: 0 }} />
                                        :
                                        <IconFaCheckCircle style={{ color: '#4CA750', fontSize: 26, marginRight: 10, padding: 0 }} />
                        }
                        <Text>
                            {
                                criado
                            }
                        </Text>
                    </div>
                )
            },
        },
        certidao_1: {
            title: 'Receita Federal – (Financiamento e Fundo Perdido)',
            dataIndex: 'certidao_1',
            ...useGetColumnSearchProps('certidao_1'),
            width: '8%',
            sorter: (a: any, b: any) => a.certidao_1.localeCompare(b.certidao_1),
            showSorterTooltip: false,
            render: (criado: any) => {
                return (
                    <div
                        style={{
                            display: 'flex',
                            // justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                    >
                        <Text>
                            {
                                criado
                            }
                        </Text>
                    </div>
                )
            },
        },
        certidao_2: {
            title: 'FGTS',
            dataIndex: 'certidao_2',
            ...useGetColumnSearchProps('certidao_2'),
            width: '8%',
            sorter: (a: any, b: any) => a.certidao_2.localeCompare(b.certidao_2),
            showSorterTooltip: false,
            render: (criado: any) => {
                return (
                    <div
                        style={{
                            display: 'flex',
                            // justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                    >
                        <Text>
                            {
                                criado
                            }
                        </Text>
                    </div>
                )
            },
        },
        certidao_5: {
            title: 'Tribunal Superior do Trabalho - TST',
            dataIndex: 'certidao_5',
            ...useGetColumnSearchProps('certidao_5'),
            width: '8%',
            sorter: (a: any, b: any) => a.certidao_5.localeCompare(b.certidao_5),
            showSorterTooltip: false,
            render: (criado: any) => {
                return (
                    <div
                        style={{
                            display: 'flex',
                            // justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                    >
                        <Text>
                            {
                                criado
                            }
                        </Text>
                    </div>
                )
            },
        },
        certidao_7: {
            title: 'CADIN',
            dataIndex: 'certidao_7',
            ...useGetColumnSearchProps('certidao_7'),
            width: '8%',
            sorter: (a: any, b: any) => a.certidao_7.localeCompare(b.certidao_7),
            showSorterTooltip: false,
            render: (criado: any) => {
                return (
                    <div
                        style={{
                            display: 'flex',
                            // justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                    >
                        <Text>
                            {
                                criado
                            }
                        </Text>
                    </div>
                )
            },
        },
        certidao_9: {
            title: 'Certificado de Regularidade Previdenciária - CRP',
            dataIndex: 'certidao_9',
            ...useGetColumnSearchProps('certidao_9'),
            width: '8%',
            sorter: (a: any, b: any) => a.certidao_9.localeCompare(b.certidao_9),
            showSorterTooltip: false,
            render: (criado: any) => {
                return (
                    <div
                        style={{
                            display: 'flex',
                            // justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                    >
                        <Text>
                            {
                                criado
                            }
                        </Text>
                    </div>
                )
            },
        },
    }

    //setando dados das cidades no selected
    const buscaDadosESetDadosNaTabela = useCallback((estaInicio?: boolean) => {

        const buscaCiaddes = async () => {
            try {
                setLoadingPage(true);
                let result = await api.get(urlMunicipioStatusMunicipios + '/' + refAno.current + '/' + refMes.current,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        }
                    });

                refDataRequestAllCity.current = result.data;

                const { municipiosAcimaDeCem, municipiosAteVinteMil, municipiosEntreCinquentaECem, municipiosEntreVinteECinquenta }: interfDadosMunicios = result.data

                let arrayAllCities: Array<interfMunicipios> = [
                    ...municipiosAteVinteMil,
                    ...municipiosEntreVinteECinquenta,
                    ...municipiosEntreCinquentaECem,
                    ...municipiosAcimaDeCem,
                ];

                refDataRequestAllCityState.current = arrayAllCities;

                if (refValorDaPopulacaoFiltro.current === 'todos') {

                    setDadosTabelaState(arrayAllCities);
                } else {

                    setDadosTabelaState(result.data[refValorDaPopulacaoFiltro.current]);
                }

                if (estaInicio) {

                    setColunasTabela();
                }



                setLoadingPage(false);

            } catch (error) {
                let msgErro: any = (error as Error);
                setLoadingPage(false);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'municipios_dkwdfaiweyi')
                }


            }
        }

        if (refAno.current !== null && refAno.current !== undefined &&
            refMes.current !== null && refMes.current !== undefined) {

            buscaCiaddes();
        }

    }, []);

    //setando dados das cidades no selected
    const buscarDadosAnoEMes = useCallback(() => {

        const buscaCiaddes = async () => {
            try {
                setLoadingPage(true)
                let result = await api.get(urlMunicipioStatusConsolidadoDataFiltro,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        }
                    });


                const arrayDeAnos = result.data.anos.sort((a: any, b: any) => {
                    return b.ano - a.ano;
                });

                setAnos(arrayDeAnos);

                refAno.current = arrayDeAnos[0].ano

                setValSelectAnoFiltros(arrayDeAnos[0].ano)

                const arrayDeMeses: Array<{ mes: number }> = arrayDeAnos[0].meses.sort((a: any, b: any) => {
                    return a.ano - b.ano;
                });

                let mesesObjCompleto: Array<{ mes: number, nome: string }> = []

                mesesString.forEach((element, index) => {

                    let isEqual = false
                    arrayDeMeses.forEach((elementMesReq, index) => {
                        if (element.mes === elementMesReq.mes) {
                            isEqual = true
                        }
                    })

                    if (isEqual) {
                        mesesObjCompleto.push(element)
                    }
                })

                setMeses(mesesObjCompleto)
                setValSelectMesFiltros(mesesObjCompleto[(mesesObjCompleto.length - 1)].mes)
                refMes.current = mesesObjCompleto[(mesesObjCompleto.length - 1)].mes


                buscaDadosESetDadosNaTabela(true);

                // setLoadingPage(false)

            } catch (error) {

                setLoadingPage(false)
                let msgErro: any = (error as Error);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'relatorioconsolidado_dkwdfaiweyi')
                }


            }
        }

        buscaCiaddes();


    }, []);

    const setColunasTabela = useCallback((dados?: any) => {

        if (dados) {
            const arrayDadosColuns: Array<any> = [];
            Object.keys(dados).forEach((key, index) => {

                if (dados[key]) {
                    arrayDadosColuns.push(colunasDinamicas[key]);
                }
                // arrayDados.push({nome: key, value: dados[key]})
            });
            setColumnsState(arrayDadosColuns)
        } else {

            const arrayDadosColuns: Array<any> = [];
            arrayAttColunas.forEach((key, index) => {

                if (key.value) {
                    arrayDadosColuns.push(colunasDinamicas[key.id]);
                }

            });
            setColumnsState(arrayDadosColuns)
        }
    }, []);

    useEffect(() => {

        let arrayUrl = window.location.pathname.split("/");
        VerificaRota(arrayUrl, navigate);

        buscarDadosAnoEMes();


    }, []);

    const clickCancelModal = useCallback(() => {
        setVisibleModal(false);
    }, []);

    const clickCancelModalFiltros = useCallback(() => {
        setHandleSubmitModal(null);
        setVisibleModalFiltros(false);
    }, []);

    const onSubmit = useCallback((): void => {

        formRef.current?.validateFields()
            .then((values: any) => {

                setColunasTabela(values);
                clickCancelModal();

            })
            .catch(() => {
                // .catch((errorInfo: any) => {

                notification.error({
                    message: 'Erro',
                    description:
                        'Preencha os campos obrigatorios!',
                });
            });


    }, []);

    const percorreValoresFuncaoFiltrosTable = useCallback((copyDadosSelecionados: Array<any>, valuesForm: any): any => {

        let temLegislativoInicio = false;
        let temLegislativoFim = false;

        if (valuesForm.legislativoInicial !== undefined && valuesForm.legislativoInicial !== '') {
            temLegislativoInicio = true;
        }

        if (valuesForm.legislativoFinal !== undefined && valuesForm.legislativoFinal !== '') {
            temLegislativoFim = true;
        }

        let arrayDadosAllForSetTable: any = [];

        let legislativoArrayDados: any = [];
        let executivoArrayDados: any = [];
        let limiteLRFArrayDados: any = [];

        if (temLegislativoInicio && temLegislativoFim) {

            copyDadosSelecionados.forEach((element: interfMunicipios) => {
                if (typeof element.despesaPessoalLegislativo === 'number') {
                    if (
                        element.despesaPessoalLegislativo >= Number(valuesForm.legislativoInicial) &&
                        element.despesaPessoalLegislativo <= Number(valuesForm.legislativoFinal)
                    ) {
                        legislativoArrayDados.push(element);
                    }
                }
            });

        }
        if (temLegislativoInicio && !temLegislativoFim) {

            copyDadosSelecionados.forEach((element: interfMunicipios) => {
                if (typeof element.despesaPessoalLegislativo === 'number') {
                    if (
                        element.despesaPessoalLegislativo >= Number(valuesForm.legislativoInicial)
                    ) {
                        legislativoArrayDados.push(element);
                    }
                }
            });

        }
        if (!temLegislativoInicio && temLegislativoFim) {

            copyDadosSelecionados.forEach((element: interfMunicipios) => {
                if (typeof element.despesaPessoalLegislativo === 'number') {
                    if (
                        element.despesaPessoalLegislativo <= Number(valuesForm.legislativoFinal)
                    ) {
                        legislativoArrayDados.push(element);
                    }
                }
            });

        }
        if (!temLegislativoInicio && !temLegislativoFim) {

            legislativoArrayDados = [...copyDadosSelecionados];

        }

        let temExecutivoInicio = false;
        let temExecutivoFim = false;

        if (valuesForm.executivoInicial !== undefined && valuesForm.executivoInicial !== '') {
            temExecutivoInicio = true;
        }

        if (valuesForm.executivoFinal !== undefined && valuesForm.executivoFinal !== '') {
            temExecutivoFim = true;
        }

        if (temExecutivoInicio && temExecutivoFim) {

            legislativoArrayDados.forEach((element: interfMunicipios) => {
                if (typeof element.despesaPessoalExecutivo === 'number') {
                    if (
                        element.despesaPessoalExecutivo >= Number(valuesForm.executivoInicial) &&
                        element.despesaPessoalExecutivo <= Number(valuesForm.executivoFinal)
                    ) {
                        executivoArrayDados.push(element);
                    }
                }
            });

        }
        if (temExecutivoInicio && !temExecutivoFim) {

            legislativoArrayDados.forEach((element: interfMunicipios) => {
                if (typeof element.despesaPessoalExecutivo === 'number') {
                    if (
                        element.despesaPessoalExecutivo >= Number(valuesForm.executivoInicial)
                    ) {
                        executivoArrayDados.push(element);
                    }
                }
            });

        }
        if (!temExecutivoInicio && temExecutivoFim) {

            legislativoArrayDados.forEach((element: interfMunicipios) => {
                if (typeof element.despesaPessoalExecutivo === 'number') {
                    if (
                        element.despesaPessoalExecutivo <= Number(valuesForm.executivoFinal)
                    ) {
                        executivoArrayDados.push(element);
                    }
                }
            });

        }
        if (!temExecutivoInicio && !temExecutivoFim) {

            executivoArrayDados = [...legislativoArrayDados];

        }

        let temLimiteLRFInicio = false;
        let temLimiteLRFFim = false;

        if (valuesForm.limiteLRFInicial !== undefined && valuesForm.limiteLRFInicial !== '') {
            temLimiteLRFInicio = true;
        }

        if (valuesForm.limiteLRFFinal !== undefined && valuesForm.limiteLRFFinal !== '') {
            temLimiteLRFFim = true;
        }

        if (temLimiteLRFInicio && temLimiteLRFFim) {

            executivoArrayDados.forEach((element: interfMunicipios) => {
                if (typeof element.limiteLrf === 'number') {
                    if (
                        element.limiteLrf >= Number(valuesForm.limiteLRFInicial) &&
                        element.limiteLrf <= Number(valuesForm.limiteLRFFinal)
                    ) {
                        limiteLRFArrayDados.push(element);
                    }
                }
            });

        }
        if (temLimiteLRFInicio && !temLimiteLRFFim) {

            executivoArrayDados.forEach((element: interfMunicipios) => {
                if (typeof element.limiteLrf === 'number') {
                    if (
                        element.limiteLrf >= Number(valuesForm.limiteLRFInicial)
                    ) {
                        limiteLRFArrayDados.push(element);
                    }
                }
            });

        }
        if (!temLimiteLRFInicio && temLimiteLRFFim) {

            executivoArrayDados.forEach((element: interfMunicipios) => {
                if (typeof element.limiteLrf === 'number') {
                    if (
                        element.limiteLrf <= Number(valuesForm.limiteLRFFinal)
                    ) {
                        limiteLRFArrayDados.push(element);
                    }
                }
            });

        }
        if (!temLimiteLRFInicio && !temLimiteLRFFim) {

            limiteLRFArrayDados = [...executivoArrayDados];

        }

        arrayDadosAllForSetTable = [...limiteLRFArrayDados];

        return arrayDadosAllForSetTable;

    }, []);


    const handleClickSalvarModalFiltros = useCallback((form: any): void => {

        const setDados = async (values: any) => {
            try {

                const { populacao }: { populacao: interfFiltroPopulacao } = values

                refValorDaPopulacaoFiltro.current = populacao;

                if (populacao === 'todos') {

                    const copyDadosSelecionados: any = [...refDataRequestAllCityState.current];

                    const resultDadosTable = percorreValoresFuncaoFiltrosTable(copyDadosSelecionados, values);

                    setDadosTabelaState(resultDadosTable);

                } else {

                    const copyDadosSelecionados: any = [...refDataRequestAllCity.current![populacao]];

                    const resultDadosTable = percorreValoresFuncaoFiltrosTable(copyDadosSelecionados, values);
                    setDadosTabelaState(resultDadosTable);

                    // setDadosTabelaState(copyDadosSelecionados);
                }

                clickCancelModalFiltros();
            } catch (error) {
                let msgErro: any = (error as Error);
                setLoadingPage(false);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'municipios_dkwdfaiweyi')
                }


            }
        }


        form?.validateFields()
            .then((values: any) => {

                values.limiteLRFInicial = convertMoedaBRParaUS(values.limiteLRFInicial)
                values.limiteLRFFinal = convertMoedaBRParaUS(values.limiteLRFFinal)

                setDados(values)


            })
            .catch(() => {
                // .catch((errorInfo: any) => {

                notification.error({
                    message: 'Erro',
                    description:
                        'Preencha os campos obrigatorios!',
                });
            });

    }, []);

    const clickAnoFiltros = useCallback((selectedIndex: any, obj: any) => {
        let keyNumber = Number(obj.key)
        refAno.current = selectedIndex;
        setValSelectAnoFiltros(selectedIndex)

        let mesesObjCompleto: Array<{ mes: number, nome: string }> = []

        mesesString.forEach((element, index) => {

            let isEqual = false
            anos[keyNumber].meses.forEach((elementMesReq, index) => {
                if (element.mes === elementMesReq.mes) {
                    isEqual = true
                }
            })

            if (isEqual) {
                mesesObjCompleto.push(element)
            }
        })
        setMeses(mesesObjCompleto)
        setValSelectMesFiltros(mesesObjCompleto[(mesesObjCompleto.length - 1)].mes)
        refMes.current = mesesObjCompleto[(mesesObjCompleto.length - 1)].mes

        buscaDadosESetDadosNaTabela();

    }, [anos]);

    const onChangeSelectCidades = useCallback((value: any, obj: any): void => {

        // let keyNumber = Number(obj.key)

        refMes.current = value
        setValSelectMesFiltros(value)

        buscaDadosESetDadosNaTabela();

    }, []);

    const handleCLickGenerateXLSX = useCallback((): void => {

        const gerarXLSX = async () => {

            try {
                setLoadingPage(true)
                const result = await api.get(urlMunicipioStatusMunicipiosXlsx + '/' + refAno.current + '/' + refMes.current,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        }
                    });

                const resultArquivo = await api.get(urlMunicipioStatusMunicipiosXlsx + '/' + result.data.filename,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        },
                        responseType: 'blob',
                    });

                const url = window.URL.createObjectURL(new Blob([resultArquivo.data]));
                const link = document.createElement('a');
                link.href = url;

                link.setAttribute('download', result.data.filename);
                document.body.appendChild(link);
                link.click();

                setLoadingPage(false)
            } catch (error) {

            }


        }

        gerarXLSX();

    }, []);


    return (
        <LayoutDashboard>
            <Loading
                loading={loadingPage}
            >
                <div
                    style={{ marginBottom: 5 }}
                >
                    <Text style={{ marginRight: 5 }} >{'Selecione o ano e o mês:'}</Text>
                </div>
                <div
                    style={{
                        display: 'flex'
                    }}
                >
                    <div
                        style={{
                            marginRight: 5,
                            marginBottom: 5
                        }}
                    >
                        <Select
                            showSearch
                            optionFilterProp="children"
                            onChange={clickAnoFiltros}
                            value={valSelectAnoFiltros}
                            // style={{
                            //     width: '100%',
                            //     marginBottom: 5,
                            //     marginRight: 5,
                            // }}
                            filterOption={(input: any, option: any) => {
                                let textDigit = removeAcento(input)
                                let listCidade = removeAcento(option?.children);
                                return listCidade.indexOf(textDigit) >= 0
                            }}
                        >
                            {
                                anos.map((element, index) => (
                                    <Select.Option
                                        key={index}
                                        value={element.ano}
                                    >
                                        {element.ano}
                                    </Select.Option>
                                ))
                            }
                        </Select>
                    </div>

                    <div
                        style={{
                            marginRight: 5,
                            marginBottom: 5
                        }}
                    >
                        <Select
                            showSearch
                            placeholder="Selecione o mês"
                            optionFilterProp="children"
                            // className="botaoListVendedorUser"
                            onChange={onChangeSelectCidades}
                            value={valSelectMesFiltros}
                            // disabled={!hasSelectedAdd}
                            // defaultValue='Usuários'
                            style={{
                                width: '130%'
                            }}
                            filterOption={(input: any, option: any) => {
                                let textDigit = removeAcento(input)
                                let listCidade = removeAcento(option?.children);
                                return listCidade.indexOf(textDigit) >= 0
                            }}
                        >
                            {
                                meses.map((element: any, index: number) => {
                                    return (
                                        <Select.Option
                                            key={index}
                                            value={element.mes}
                                        >
                                            {element.nome}
                                        </Select.Option>
                                    )
                                })
                            }
                        </Select>
                    </div>
                </div>

                <div
                    style={{
                        display: 'flex',
                        // justifyContent: 'flex-end',
                        // justifyContent: 'flex-end'
                        marginBottom: 15
                    }}
                    className="botoesGerarXlsxEoutros_ksjhvfgh"
                >
                    <div
                        style={{
                            display: 'flex',
                        }}
                    >
                        <Button
                            type="primary"
                            // danger
                            className="botaoVerdeBaixarTodas"
                            style={{ marginBottom: 2, marginRight: 4 }}
                            // disabled={!hasSelectedAdd}
                            onClick={() => { handleCLickGenerateXLSX() }}
                        >
                            Gerar XLSX
                        </Button>
                        <Button
                            type="primary"
                            // className="botaoVerdeBaixarTodas"
                            style={{ marginBottom: 2, marginRight: 4 }}
                            // disabled={!hasSelectedAdd}
                            onClick={() => { setVisibleModal(true) }}
                        >
                            Colunas
                        </Button>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            // justifyContent: 'flex-end',
                            // justifyContent: 'flex-end'
                            // marginBottom: 15
                        }}
                    >
                        <Button
                            type="primary"
                            // className="botaoVerdeBaixarTodas"
                            style={{ marginBottom: 2, marginRight: 4 }}
                            // disabled={!hasSelectedAdd}
                            onClick={() => { setVisibleModalFiltros(true) }}
                        >
                            Filtros
                        </Button>
                        <Button
                            type="primary"
                            danger
                            // className="botaoVerdeBaixarTodas"
                            style={{ marginBottom: 2 }}
                            // disabled={!hasSelectedAdd}
                            onClick={() => { window.location.reload(); }}
                        >
                            Resetar Listagem
                        </Button>
                    </div>
                </div>
                <Table
                    style={{
                        // marginTop: 50
                    }}
                    className='marginTopTables'
                    rowKey={(record) => record.id}
                    // loading={loadingTable}
                    // onRow={(record, rowIndex) => {
                    //     return {
                    //         onClick: event => { onclickTable(record, rowIndex) }, // click row
                    //         onDoubleClick: event => { }, // double click row
                    //         onContextMenu: event => { }, // right button click row
                    //         onMouseEnter: event => { }, // mouse enter row
                    //         onMouseLeave: event => { }, // mouse leave row
                    //     };
                    // }}
                    // rowSelection={rowSelection}
                    columns={columnsState} dataSource={dadosTabelaState}
                    scroll={{ x: 1000 }}
                    onChange={
                        (pagination, filters, sorter, extra) => {
                            refContagemDoFiltro.current = extra.currentDataSource
                        }
                    }
                    title={(registros) => {

                        return (
                            <div
                                style={{
                                    backgroundColor: theme === 'light' ? '#FAFAFA' : '#1D1D1D',
                                    padding: 10,
                                    margin: -8,
                                    marginBottom: 0.5
                                }}
                            >
                                <Text>
                                    {/* {`Total: ${dadosTabelaState.length} registros.`} */}
                                    {`Mostrando: ${registros.length} de ${refContagemDoFiltro.current.length === 0 ?
                                        dadosTabelaState.length :
                                        refContagemDoFiltro.current.length} num total de ${dadosTabelaState.length} `}
                                </Text>
                            </div>
                        )
                    }}
                    footer={(registros) => {
                        return (
                            <div
                                style={{
                                    textAlign: 'end'
                                }}
                            >
                                <Text>
                                    {/* {`Total: ${dadosTabelaState.length} registros.`} */}
                                    {`Mostrando: ${registros.length} de ${refContagemDoFiltro.current.length === 0 ?
                                        dadosTabelaState.length :
                                        refContagemDoFiltro.current.length} num total de ${dadosTabelaState.length} `}
                                </Text>
                            </div>
                        )
                    }}
                    size="small"
                    pagination={{
                        locale: { items_per_page: '' },
                        showSizeChanger: true,
                        defaultPageSize: 645,
                        // position: ['topRight', 'bottomRight'],
                        position: [],
                        pageSizeOptions: ['10', '20', '50', '100', '645']
                    }}
                    locale={locale.Table}
                />
            </Loading>
            <Modal
                title="Selecione as colunas da tabela"
                visible={visibleModal}
                // onOk={() => clickBtnSubmitUploadArquivo(arquivoObjeto)}
                onCancel={() => clickCancelModal()}
                style={{
                    top: 20
                }}
                // className="modalMunicipios_uhrgbfars"
                className="modalMunicipiosFiltros_fiudefvbiawe"
                footer={[
                    <Button
                        key="back"
                        onClick={clickCancelModal}
                    // loading={loading}
                    >
                        Cancelar
                    </Button>,
                    <Button
                        key="submit"
                        type="primary"
                        // loading={loading}
                        onClick={() => onSubmit()}
                    >
                        Salvar
                    </Button>
                ]}
                key="modalListagemMunicipios"
            >
                <Loading
                // loading={loading}
                >
                    <Form
                        name="formColunasTable"
                        ref={formRef}
                        // initialValues={{
                        //     remember: true,
                        // }}
                        style={{
                            width: '100%'
                        }}

                        onFinish={onSubmit}
                    // onFinishFailed={onFinishFailed}
                    >
                        <Row>
                            {
                                arrayAttColunas.map((value, index) => {
                                    return (
                                        <Col key={value.id} {...colModalChecks}>
                                            <Form.Item
                                                name={value.id}
                                                style={{
                                                    margin: 5
                                                }}
                                                valuePropName={'checked'}
                                                initialValue={value.value}
                                            >
                                                <Checkbox
                                                >{value.nome}</Checkbox>
                                            </Form.Item>
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                    </Form>
                </Loading>
            </Modal>
            <Modal
                title="Filtros da tabela"
                visible={visibleModalFiltros}
                // visible={true}
                // onOk={() => clickBtnSubmitUploadArquivo(arquivoObjeto)}
                onCancel={() => clickCancelModalFiltros()}
                style={{
                    top: 20
                }}
                className="modalMunicipiosFiltros_fiudefvbiawe"
                footer={[
                    <Button
                        key="back"
                        onClick={clickCancelModalFiltros}
                    // loading={loading}
                    >
                        Cancelar
                    </Button>,
                    <Button
                        key="submit"
                        type="primary"
                        // loading={loading}
                        onClick={() => setHandleSubmitModal(Math.round(new Date().getTime() / 1000))}
                    >
                        Salvar
                    </Button>
                ]}
                key="modalListagemMunicipiosFiltros"
            >
                <FormModal
                    submitModal={handleSubmitModal}
                    clickSalvarModalFiltros={handleClickSalvarModalFiltros}
                />
            </Modal>
        </LayoutDashboard >
    );
}

export default ListagemMunicipios;
