import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Row, Col, Select, Tabs, Typography, notification, Spin } from 'antd';
import { useNavigate } from 'react-router-dom';

import { colSelect } from './GridStyle';

// import { getToken } from './../../services/LStorage/token';
import { VerificaRota, validacaoPermissao } from './../../services/VerificaSePodeAcessar';
import LayoutDashboard from './../../layout/LayoutAdmin';
import { urlUsuariosPermissao, urlUsuariosRegra, urlMunicipioMunicipios } from './../../services/urls';
import { api } from './../../services/apiAxios';
import { EmitirErro } from '../../services/EmitirErro';
import { SairDoSistema } from '../../services/LStorage/SairSistema';
import { getToken } from './../../services/LStorage/token';
import { DadosMunicipio } from './components/DadosMunicipio';
import { Certidoes } from './components/Certidoes';
import { Prefeito } from './components/Prefeito';
import { Congelamento } from './components/Congelamento';

import './style.css';

import {
    removeAcento
} from './../../utils/RemoveAcentos';
import { DadosDoPrefeito } from './components/DadosDoPrefeito';
import { CAUCItem3 } from './components/CAUCItem3';
import { Saneamento } from './components/Saneamento';

const { TabPane } = Tabs;
const { Text } = Typography;


// const largura = window.innerWidth;
// const altura = window.innerHeight;

interface DadosCidadesAll {
    "idIbge": number,
    "nome": string,
}

const Municipios: React.FC = () => {

    const navigate = useNavigate();

    const refDataListCidades = useRef<Array<DadosCidadesAll>>([]);

    const [dataListCidades, setDataListCidades] = useState<Array<DadosCidadesAll>>([]);
    const [loadingListCidades, setLoadingListCidades] = useState(false);
    const [municipioSelecionado, setMunicipioSelecionado] = useState<DadosCidadesAll>();

    const selectMunicipioPorId = useCallback((id: number): DadosCidadesAll | null => {
        let objCidade = null;
        refDataListCidades.current.forEach(val => {
            if (val.idIbge === id) {
                objCidade = val
            }
        });
        return objCidade;

    }, []);

    //setando dados das cidades no selected
    const buscaCidades = useCallback(() => {


        const buscaCiaddes = async () => {
            try {
                setLoadingListCidades(true);
                let resultCidade = await api.get(urlMunicipioMunicipios,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        }
                    });

                refDataListCidades.current = resultCidade.data
                setDataListCidades(resultCidade.data);

                setLoadingListCidades(false);

            } catch (error) {
                let msgErro: any = (error as Error);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'municipios_dkwdfaiweyi')
                }


            }
        }

        buscaCiaddes();

    }, []);

    useEffect(() => {

        let arrayUrl = window.location.pathname.split("/");
        VerificaRota(arrayUrl, navigate);

        buscaCidades();


    }, []);

    const onChangeSelectCidades = useCallback((value: any, obj: any): void => {

        const objSelecionado = selectMunicipioPorId(value)

        if (objSelecionado) setMunicipioSelecionado(objSelecionado);

    }, []);

    return (
        <LayoutDashboard>
            <Row>
                <Col {...colSelect}>
                    <div
                        style={{ marginBottom: 5 }}
                    >
                        <Text style={{ margin: 1 }} >{'Selecione o município:'}</Text>
                    </div>
                    <Select
                        showSearch
                        placeholder="Digite o nome de um município"
                        optionFilterProp="children"
                        // className="botaoListVendedorUser"
                        onChange={onChangeSelectCidades}
                        loading={loadingListCidades}
                        notFoundContent={loadingListCidades ? <Spin size="small" /> : null}
                        // disabled={!hasSelectedAdd}
                        // defaultValue='Usuários'
                        style={{
                            width: '100%',
                            marginBottom: 5,
                            marginRight: 5,
                        }}
                        filterOption={(input: any, option: any) => {
                            let textDigit = removeAcento(input)
                            let listCidade = removeAcento(option?.children);
                            return listCidade.indexOf(textDigit) >= 0
                        }}
                    >
                        {
                            dataListCidades.map((item) => {
                                return (
                                    <Select.Option
                                        value={item.idIbge}
                                        key={item.idIbge.toString()}
                                    >
                                        {item.nome}
                                    </Select.Option>
                                )
                            })
                        }
                    </Select>
                </Col>
            </Row>

            {municipioSelecionado &&
                <Row>
                    <Col span={24}>
                        <Tabs
                            type="card"
                            defaultActiveKey="1"
                            style={{
                                marginTop: 15,
                            }}
                            className='tab-municipios'

                        >
                            <TabPane
                                tab="Dados do Município"
                                key="1"
                            >
                                <DadosMunicipio municipioSelecionado={municipioSelecionado} />
                            </TabPane>
                            <TabPane
                                tab="CAUC - Item 3"
                                key="2CaucItem3"
                            >
                                <CAUCItem3 municipioSelecionado={municipioSelecionado} />
                            </TabPane>
                            <TabPane
                                tab="Certidões"
                                key="2"
                            >
                                <Certidoes municipioSelecionado={municipioSelecionado} />
                            </TabPane>
                            <TabPane
                                tab="Congelamento"
                                key="5Congelamento"
                            >
                                <Congelamento municipioSelecionado={municipioSelecionado} />
                            </TabPane>
                            <TabPane
                                tab="Saneamento Básico - TCE"
                                key="6Saneamento"
                            >
                                <Saneamento municipioSelecionado={municipioSelecionado} />
                            </TabPane>
                            <TabPane
                                tab="Prefeito"
                                key="3"

                            >
                                <Prefeito municipioSelecionado={municipioSelecionado} />
                            </TabPane>
                            {
                                validacaoPermissao(['view_mayor_data']) &&
                                <TabPane
                                    tab="Dados do Prefeito"
                                    key="4"
                                >
                                    <DadosDoPrefeito municipioSelecionado={municipioSelecionado} />
                                </TabPane>
                            }
                        </Tabs>
                    </Col>
                </Row>
            }



        </LayoutDashboard>
    );
}

export default Municipios;
