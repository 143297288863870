import { Col, Typography, Row } from 'antd';

// import { useNavigate } from 'react-router-dom';

// import { getToken } from './../../services/LStorage/token';
// import { VerificaRota } from './../../../../../services/VerificaSePodeAcessar';
// import { urlUsuariosRegra } from './../../../../../services/urls';
// import { api } from './../../../../../services/apiAxios';
// import { EmitirErro } from '../../../../../services/EmitirErro';
// import { SairDoSistema } from '../../../../../services/LStorage/SairSistema';
// import { getToken } from './../../../../../services/LStorage/token';
import { CardSituacao } from './../CardSituacao';
import './style.css';

// import {
//     removeAcento
// } from './../../../../../utils/RemoveAcentos';
// import { CardLimites } from './CardLimites';
// import { TodasInformacoes } from './TodasInformacoes';

const { Text, Title } = Typography;

interface intProps {
    urlImg?: string;
    nome?: string;
    cargo?: string;
    cnpj?: string;
    card1Title?: string;
    card1Desc?: string;
    card2Title?: string;
    card2Desc?: string;
    card1CorFundo?: string;
    card2CorFundo?: string;
    statusCard?: string | null;
}

export const CardPrefeito = ({
    urlImg = '',
    nome = '',
    cargo = '',
    cnpj,
    card1Title = '',
    card1Desc = '',
    card2Title = '',
    card2Desc = '',
    card1CorFundo = '#4CA750',
    card2CorFundo = '#4CA750',
    statusCard = 'Em mandato',
}: intProps) => {

    // const navigate = useNavigate();

    return (
        <div className='Municipios_DivPrincipalPreifeito_fdijkbsi'>
            {
                urlImg && urlImg !== '' &&
                <img
                    className='Municipios_ImgPrefeito_oiufbgwefo'
                    style={{
                        // width: 130,
                        // margin: 10,
                        marginRight: 12,
                        filter: statusCard !== 'Em mandato' ? 'opacity(0.4) grayscale(1)' : undefined,
                        // height
                        // padding: '0px 4px',
                        // line- height: 1.42857143;
                        // border: '1px solid #ddd',
                        borderRadius: 4,
                    }}
                    src={
                        process.env.NODE_ENV === 'development' ?
                            cargo == 'Prefeito' ? "https://divulgacandcontas.tse.jus.br/candidaturas/oficial/2022/BR/BR/544/candidatos/893498/foto.jpg" : 'https://divulgacandcontas.tse.jus.br/candidaturas/oficial/2022/BR/BR/544/candidatos/908966/foto.jpeg'
                            :
                            urlImg
                    }
                // src="https://divulgacandcontas.tse.jus.br/candidaturas/oficial/2018/BR/BR/2022802018/280000625869/foto_1534363925445.jpg"
                />
            }
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                }}
                className='divtextsCards_CardPrefeito_fewohbfwo'
            >
                <Text style={{ fontWeight: 'bold', fontSize: '1.8rem' }} type={statusCard === 'Em mandato' ? undefined : 'secondary'} >{nome}</Text>
                <Text style={{ fontSize: '1.1rem' }}>{cargo}</Text>
                {/* <Text style={{ fontSize: '1.2rem' }}>{cnpj ? `CNPJ - ${cnpj}` : ''}</Text> */}
                <div
                    className='Municipios_DivCardSituacao_fceowuyvbg'
                >
                    {statusCard === 'Em mandato'
                        ?
                        <>
                            <CardSituacao
                                title={card1Title}
                                descricao={card1Desc}
                                corFundo={card1CorFundo}
                                style={{
                                    marginRight: 5,
                                    marginBottom: window.innerWidth <= 576 ? 5 : 0
                                }}
                            />
                            <CardSituacao
                                title={card2Title}
                                descricao={card2Desc}
                                corFundo={card2CorFundo}
                            />
                        </>
                        :
                        <CardSituacao
                            title={statusCard ? statusCard : ''}
                            descricao={'Status'}
                            corFundo={"#ccc"}
                            style={{
                                marginRight: 5,
                                marginBottom: window.innerWidth <= 576 ? 5 : 0
                            }}
                        />
                    }
                </div>
            </div>
        </div>
    );
}
