import React, { useCallback, useEffect, useState, useContext } from 'react';
import {
    Row, Col, Typography, Button, notification,
    Table, Divider, Popconfirm
} from 'antd';
import Icon, { AreaChartOutlined, DeleteOutlined } from '@ant-design/icons';
import { FaCog } from 'react-icons/fa';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import locale from 'antd/es/locale/pt_BR';

import { GlobalContext } from '../../../../contexts/globalContext';
import { convertDataUSParaBrasilEHora } from '../../../../services/Conversores';
// import { getToken } from './../../../../services/LStorage/token';
import { validacaoPermissao, VerificaRota } from './../../../../services/VerificaSePodeAcessar';
import LayoutDashboard from './../../../../layout/LayoutAdmin';
import { urlLimiteOperacoesProduto } from './../../../../services/urls';
import { api } from './../../../../services/apiAxios';
import { EmitirErro } from '../../../../services/EmitirErro';
import { SairDoSistema } from '../../../../services/LStorage/SairSistema';
import { getToken } from './../../../../services/LStorage/token';
import { useGetColumnSearchProps } from './../../../../hooks/FilterTable/string';
import { useGetColumnSearchPropsData } from './../../../../hooks/FilterTable/date';

import './../style.css';

const { Text, Title } = Typography;

const IconCogs = (props: any) => (<Icon {...props} component={FaCog} ></Icon>);
// const largura = window.innerWidth;
// const altura = window.innerHeight;


interface IntefDadosTabelaList {
    "id": string,
    "createdAt": string | null,
    "updatedAt": string | null,
    "nome": string | null,
    "indexadorId": string,
    "meses_carencia": number | null,
    "meses_prazo": number | null,
    "taxa_juros": number | null,
}

const Produto: React.FC = () => {

    const navigate = useNavigate();

    const { theme } = useContext(GlobalContext);

    const refContagemDoFiltro = React.useRef<Array<any>>([]);

    const [dadosTabelaState, setDadosTabelaState] = useState<Array<IntefDadosTabelaList>>([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState<Array<string | number>>([]);
    const [hasSelectedEdit, setHasSelectedEdit] = useState(false);

    const [loadingScreen, setLoadingScreen] = useState(false);

    //setando dados das cidades no selected
    const reqbuscarDadosTable = useCallback(() => {


        const funcBusca = async () => {
            try {
                setLoadingScreen(true);
                let result = await api.get(urlLimiteOperacoesProduto,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        }
                    });

                setDadosTabelaState(result.data);

                setLoadingScreen(false);

            } catch (error) {
                setLoadingScreen(false);
                let msgErro: any = (error as Error);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'indexador_dkwdfaiweyi')
                }


            }
        }

        funcBusca();

    }, []);

    useEffect(() => {

        let arrayUrl = window.location.pathname.split("/");
        // VerificaRota(arrayUrl, navigate, true, validacaoPermissao(['manage_user']));
        VerificaRota(arrayUrl, navigate, true);

        reqbuscarDadosTable();


    }, []);

    // click na linha da tabela de listagem de usuario
    const onclickTable = useCallback((record: any, row: any): void => {

        if (selectedRowKeys.includes(record.id)) {
            let filter = selectedRowKeys.filter((val) => {
                return val != record.id
            })
            if (filter.length === 1) {
                setSelectedRowKeys(filter);
                setHasSelectedEdit(true);
            } else if (filter.length === 0) {
                setSelectedRowKeys(filter);
                setHasSelectedEdit(false);
            } else {
                setSelectedRowKeys(filter);
            }

        } else {
            if (selectedRowKeys.length == 0) {
                setSelectedRowKeys([record.id]);
                setHasSelectedEdit(true);
            } else {
                let teste = selectedRowKeys.concat(record.id);
                setSelectedRowKeys(teste);
                setHasSelectedEdit(false);
            }
        }
    }, [selectedRowKeys]);

    //checkbox da tabela de listagem
    // const onSelectChange = useCallback((selectedRowKeys: any): void => {
    const onSelectChange = useCallback((selectedRowKeys: Array<string | number>, selectedRows: Array<any>): void => {
        if (selectedRowKeys.length > 1) {
            setSelectedRowKeys(selectedRowKeys);
            setHasSelectedEdit(false);
        } else if (selectedRowKeys.length === 0) {
            setSelectedRowKeys(selectedRowKeys);
            setHasSelectedEdit(false);
        } else {
            setSelectedRowKeys(selectedRowKeys);
            setHasSelectedEdit(true);
        }
    }, [selectedRowKeys]);

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        columnWidth: '1%'
    };

    const columns = [
        {
            title: 'Nome',
            dataIndex: 'nome',
            ...useGetColumnSearchProps('nome'),
            width: '10%',
            sorter: (a: any, b: any) => a.nome.localeCompare(b.nome),
            showSorterTooltip: false,
        },
        {
            title: 'Taxa de juros (%)',
            dataIndex: 'taxa_juros',
            // ...useGetColumnSearchProps('email'),
            width: '3%',
            // sorter: (a: any, b: any) => a.email.localeCompare(b.email),
            showSorterTooltip: false,
            render: (taxa_juros: any) => (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    {
                        taxa_juros &&
                        <Text>
                            {
                                taxa_juros
                            }
                        </Text>
                    }
                </div>
            ),
        },
        {
            title: 'Meses de carência',
            dataIndex: 'meses_carencia',
            // ...useGetColumnSearchProps('email'),
            width: '3%',
            // sorter: (a: any, b: any) => a.email.localeCompare(b.email),
            showSorterTooltip: false,
            render: (meses_carencia: any) => (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    {
                        meses_carencia &&
                        <Text>
                            {
                                meses_carencia
                            }
                        </Text>
                    }
                </div>
            ),
        },
        {
            title: 'Meses de prazo',
            dataIndex: 'meses_prazo',
            // ...useGetColumnSearchProps('email'),
            width: '3%',
            // sorter: (a: any, b: any) => a.email.localeCompare(b.email),
            showSorterTooltip: false,
            render: (meses_prazo: any) => (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    {
                        meses_prazo &&
                        <Text>
                            {
                                meses_prazo
                            }
                        </Text>
                    }
                </div>
            ),
        },
        // {
        //     title: 'Criado em',
        //     dataIndex: 'createdAt',
        //     ...useGetColumnSearchPropsData('createdAt'),
        //     width: '3%',
        //     sorter: (a: any, b: any) => {

        //         let aMonMen: any = null
        //         let bMonMen: any = null
        //         if (a.createdAt) {
        //             aMonMen = moment(a.createdAt).unix()
        //         } else {
        //             aMonMen = 0
        //         }
        //         if (b.createdAt) {
        //             bMonMen = moment(b.createdAt).unix()
        //         } else {
        //             bMonMen = 0
        //         }

        //         return aMonMen - bMonMen
        //     },
        //     showSorterTooltip: false,
        //     // sorter: (a: any, b: any) => a.createdAt.localeCompare(b.createdAt),
        //     render: (criado: any) => (
        //         <div
        //             style={{
        //                 display: 'flex',
        //                 alignItems: 'center'
        //             }}
        //         >
        //             <Text>
        //                 {
        //                     criado ? convertDataUSParaBrasilEHora(criado) : '-'
        //                 }
        //             </Text>
        //         </div>
        //     ),
        // },
        {
            title: 'Atualizado em',
            dataIndex: 'updatedAt',
            ...useGetColumnSearchPropsData('updatedAt'),
            width: '3%',
            sorter: (a: any, b: any) => {

                let aMonMen: any = null
                let bMonMen: any = null
                if (a.updatedAt) {
                    aMonMen = moment(a.updatedAt).unix()
                } else {
                    aMonMen = 0
                }
                if (b.updatedAt) {
                    bMonMen = moment(b.updatedAt).unix()
                } else {
                    bMonMen = 0
                }

                return aMonMen - bMonMen
            },
            showSorterTooltip: false,
            // sorter: (a: any, b: any) => a.updatedAt.localeCompare(b.updatedAt),
            render: (atualizado: any) => (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <Text>
                        {
                            atualizado ? convertDataUSParaBrasilEHora(atualizado) : '-'
                        }
                    </Text>
                </div>
            ),
        },
    ];

    const clickBotaoEditar = useCallback((): void => {
        if (selectedRowKeys.length == 1) {

            let objSelecionado: any = {}
            dadosTabelaState.forEach((dados): void => {
                if (dados.id === selectedRowKeys[0]) {
                    objSelecionado = dados
                }
            })

            localStorage.setItem('@DesenvolveSP:configuracoesEditar', JSON.stringify(objSelecionado));

            navigate(`/configuracoes/produto/${selectedRowKeys[0]}`)


        }
    }, [selectedRowKeys, dadosTabelaState]);


    const clickBotaoDeletarDados = useCallback((): void => {

        if (selectedRowKeys.length == 1) {
            let obj: Array<any> = [];
            dadosTabelaState.forEach(({ id }): void => {
                if (id === selectedRowKeys[0]) {
                    obj.push({ id })
                }
            })

            const excluirDadosModal = async () => {
                try {
                    setLoadingScreen(true);
                    await api.delete(urlLimiteOperacoesProduto + '/' + obj[0].id, {
                        // data: obj[0],
                        headers: { 'Authorization': 'Bearer ' + getToken() }
                    })
                    notification.success({
                        message: 'Sucesso',
                        description:
                            'Seus dados foram excluídos com sucesso!',
                    });

                    setSelectedRowKeys([]);
                    setHasSelectedEdit(false);

                    setLoadingScreen(false);
                    reqbuscarDadosTable();

                } catch (error) {

                    let msgErro: any = (error as Error);

                    setLoadingScreen(false);
                    notification.error({
                        message: 'Erro',
                        description:
                            msgErro?.response?.data?.message ? msgErro?.response?.data?.message : 'Não foi possivel deletar, entre em contato com suporte!',
                    });
                }
            }

            excluirDadosModal();

        }

    }, [selectedRowKeys, dadosTabelaState]);

    return (
        <LayoutDashboard>
            <Row>
                <Col span={24}>
                    <Title level={4}>
                        <IconCogs style={{ fontSize: 23, marginRight: 10 }} />
                        {"Produtos"}
                    </Title>
                    <Divider />
                </Col>
            </Row>
            <div
                style={{
                    display: 'flex',
                    // justifyContent: 'flex-end'
                    // marginBottom: 5
                }}
            >
                <Button
                    type="primary"
                    className="Configuracoes_BotaoVerde_edfrgjkfbrefg"
                    style={{ margin: 2 }}
                    // disabled={!hasSelectedAdd}
                    onClick={() => {
                        let dadosLocalStorage: string | null = localStorage.getItem('@DesenvolveSP:configuracoesEditar');
                        let localUserData: any = null;
                        if (typeof dadosLocalStorage == 'string') {
                            localUserData = JSON.parse(dadosLocalStorage);
                        }

                        if (localUserData) {
                            localStorage.removeItem('@DesenvolveSP:configuracoesEditar');
                        }

                        navigate('/configuracoes/produto/criar')
                    }}
                >
                    Adicionar
                </Button>
                <Button
                    type="default"
                    style={{ margin: 2 }}
                    className="Configuracoes_BotaoAmarelo_dslkcjbsdcpi"
                    onClick={clickBotaoEditar}
                    disabled={!hasSelectedEdit}
                >
                    Editar
                </Button>
                <Popconfirm
                    title="Deseja excluir?"
                    onConfirm={clickBotaoDeletarDados}
                    onCancel={() => { }}
                    okText="Sim"
                    cancelText="Não"
                >
                    <Button className="botaoDeleteAssociacao"
                        type="primary"
                        danger

                        style={{ margin: 2 }}
                        // disabled={!hasSelectedDel}
                        disabled={!hasSelectedEdit}
                    >
                        <DeleteOutlined />
                    </Button>
                </Popconfirm>
            </div>
            <Table
                style={{
                    // marginTop: 50
                }}
                className='marginTopTables'
                rowKey={(record) => record.id}
                loading={loadingScreen}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: event => { onclickTable(record, rowIndex) }, // click row
                        onDoubleClick: event => { }, // double click row
                        onContextMenu: event => { }, // right button click row
                        onMouseEnter: event => { }, // mouse enter row
                        onMouseLeave: event => { }, // mouse leave row
                    };
                }}
                rowSelection={rowSelection}
                columns={columns} dataSource={dadosTabelaState}
                scroll={{ x: 850 }}
                onChange={
                    (pagination, filters, sorter, extra) => {
                        refContagemDoFiltro.current = extra.currentDataSource
                    }
                }
                title={(registros) => {

                    return (
                        <div
                            style={{
                                backgroundColor: theme === 'light' ? '#FAFAFA' : '#1D1D1D',
                                padding: 10,
                                margin: -8,
                                marginBottom: 0.5
                            }}
                        >
                            <Text>
                                {/* {`Total: ${dadosTabelaState.length} registros.`} */}
                                {`Mostrando: ${registros.length} de ${refContagemDoFiltro.current.length === 0 ?
                                    dadosTabelaState.length :
                                    refContagemDoFiltro.current.length} num total de ${dadosTabelaState.length} `}
                            </Text>
                        </div>
                    )
                }}
                footer={(registros) => {
                    return (
                        <div
                            style={{
                                textAlign: 'end'
                            }}
                        >
                            <Text>
                                {/* {`Total: ${dadosTabelaState.length} registros.`} */}
                                {`Mostrando: ${registros.length} de ${refContagemDoFiltro.current.length === 0 ?
                                    dadosTabelaState.length :
                                    refContagemDoFiltro.current.length} num total de ${dadosTabelaState.length} `}
                            </Text>
                        </div>
                    )
                }}
                size="small"
                pagination={{
                    locale: { items_per_page: '' },
                    showSizeChanger: true,
                    defaultPageSize: 100,
                    position: ['topRight', 'bottomRight'],
                    pageSizeOptions: ['10', '20', '50', '100']
                }}
                locale={locale.Table}
            />

        </LayoutDashboard >
    );
}

export default Produto;
