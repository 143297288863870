import jwt_decode from 'jwt-decode';
interface usuarioLogado {
    primeiro_acesso?: boolean,
    email: string | null,
    nome: string
}

export const getUsuario = (): usuarioLogado | undefined => {
    let dadosLocalStorage: string | null = localStorage.getItem('@DesenvolveSP:usuario');
    let localUserData: any = null;
    if (typeof dadosLocalStorage == 'string') {
        localUserData = JSON.parse(dadosLocalStorage);
        return localUserData.usuario
    } else {
        return undefined
    }
}

export const getIdUsuario = (): string | undefined => {
    let dadosLocalStorage: string | null = localStorage.getItem('@DesenvolveSP:rftoken');
    let localUserData: any = null;
    if (typeof dadosLocalStorage == 'string') {
        localUserData = JSON.parse(dadosLocalStorage);

        if (localUserData.refresh_token) {
            let decodedToken: any = jwt_decode(localUserData.refresh_token);

            if (decodedToken && decodedToken != null && decodedToken?.sub) {
                //retorna o id do usuario
                return decodedToken?.sub
            }
        }
        return undefined
    } else {
        return undefined
    }
}

export const getRefreshToken = (): string | undefined => {
    let dadosLocalStorage: string | null = localStorage.getItem('@DesenvolveSP:rftoken');
    let localUserData: any = null;
    if (typeof dadosLocalStorage == 'string') {
        localUserData = JSON.parse(dadosLocalStorage);
        return localUserData.refresh_token
    } else {
        return undefined
    }
}

export const removeRefreshToken = (): void => {
    let dadosLocalStorage: string | null = localStorage.getItem('@DesenvolveSP:rftoken');
    let localUserData: any = null;
    if (typeof dadosLocalStorage == 'string') {
        localUserData = JSON.parse(dadosLocalStorage);
    }

    if (localUserData) {
        localStorage.removeItem('@DesenvolveSP:rftoken');
    }
}

export const removeUsuario = (): void => {
    let dadosLocalStorage: string | null = localStorage.getItem('@DesenvolveSP:usuario');
    let localUserData: any = null;
    if (typeof dadosLocalStorage == 'string') {
        localUserData = JSON.parse(dadosLocalStorage);
    }

    if (localUserData) {
        localStorage.removeItem('@DesenvolveSP:usuario');
    }
}
