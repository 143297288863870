
/**
 * Conversor de data retorna mesEAnoEscrito
 * 23 julho 2020
 */
export const convertDataUSParaBRReturnMesEAnoEscrito = (data?: Date): string => {
    if (data) {

        const mesesAbre = ['Janeiro',
            'Fevereiro',
            'Março',
            'Abril',
            'Maio',
            'Junho',
            'Julho',
            'Agosto',
            'Setembro',
            'Outubro',
            'Novembro',
            'Dezembro'];
        // let currentDate = new Date(data + 'T14:00:00');
        let result = mesesAbre[data.getMonth()] + '/' + data.getFullYear()

        // console.log("result")
        // console.log(result)
        return result;

    }
    return ''
};

/**
 * Conversor de data retorna mesEAnoEscrito
 * mes_12_ano_2023
 */
export const convertDataUSParaBRReturnMesEAno = (data?: Date): string => {
    if (data) {

        //mes começa em 1 e termina em 12
        // let result = 'mes_' + (data.getMonth() + 1) + '_ano_' + data.getFullYear()

        //mes começa em 0 e termina em 11
        let result = 'mes_' + data.getMonth() + '_ano_' + data.getFullYear()

        return result;

    }
    return ''
};
