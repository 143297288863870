import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Table, Typography, notification, Button, Row, Col, Select, Spin, Modal } from 'antd';
import Icon, { LoadingOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { io } from 'socket.io-client';

import { useNavigate } from 'react-router-dom';
import locale from 'antd/es/locale/pt_BR';
import { FaDownload } from 'react-icons/fa';
import { urlCongelamentoMunicipio, urlCongelamento, urlCongelamentos, urlCongelamentoCompleto, urlHttpGovSocketIo } from './../../../../services/urls';
import { EmitirErro } from '../../../../services/EmitirErro';
import { SairDoSistema } from '../../../../services/LStorage/SairSistema';
import { api } from '../../../../services/apiAxios';
import { getToken } from './../../../../services/LStorage/token';
import { useGetColumnSearchProps } from './../../../../hooks/FilterTable/string';
import { GlobalContext } from '../../../../contexts/globalContext';
import { colSelect } from './GridStyle';

import { differenceInSeconds } from 'date-fns';
import './style.css';

// @ts-ignore
import ImgUpLike from './../../../../assets/imagens/upLike.png';
// @ts-ignore
import ImgDownLike from './../../../../assets/imagens/downLike.png';
import { removeAcento } from '../../../../utils/RemoveAcentos';
import { convertDataUSParaBrasilEHora, convertDataUSParaBrasilEHoraSegundos } from '../../../../services/Conversores';

const IconDownload = (props: any) => <Icon {...props} component={FaDownload} ></Icon>
// const antIconLoading = () => ;

const { Text } = Typography;

// const largura = window.innerWidth;
// const altura = window.innerHeight;

interface interfaArquivoCongelamento {
    arquivo: string
    id: string
    status: boolean
}

interface DadosListCongelamneto {
    createdAt: string,
    id: string,
    idIbge: number,
    status: string,
    usuario: {
        ativo: boolean,
        createdAt: string,
        email: string,
        id: string,
        lastAccess: string,
        nome: string,
        updatedAt: string,
    },
    updatedAt: string,
    usuarioId: string,
}

interface interfDadosMunicipioSelecionado {
    municipioSelecionado?: {
        "idIbge": number,
        "nome": string,
    }
}

export const Congelamento = ({
    municipioSelecionado
}: interfDadosMunicipioSelecionado) => {

    const { theme } = useContext(GlobalContext);
    const navigate = useNavigate();

    const refsocket = React.useRef<any>(null);

    const refContagemDoFiltro = React.useRef<Array<any>>([]);
    const refIdCongelamento = React.useRef<any>(null);
    const refDataCongelamento = React.useRef<DadosListCongelamneto | null>(null);
    const refDiferencaDeCongelamneto = React.useRef<string | null>(null);
    const refIdIbge = React.useRef<any>();
    const refDataListCongelamento = React.useRef<Array<DadosListCongelamneto>>([]);

    const [dadosTabelaState, setDadosTabelaState] = useState<Array<interfaArquivoCongelamento>>([]);

    const [modalViewAtencaoCongelamento, setModalViewAtencaoCongelamento] = useState(false);
    const [loading, setLoading] = useState(false);


    const [valueSelectCongelamnetos, setValueSelectCongelamnetos] = useState();
    const [loadingListCongelamento, setLoadingListCongelamento] = useState(false);
    const [dataListCongelamento, setDataListCongelamento] = useState<Array<DadosListCongelamneto>>([]);

    const setDiferencaDeTempo = useCallback((zerar?: boolean): void => {
        if (zerar) {
            refDiferencaDeCongelamneto.current = null
            return
        }
        if (
            refDataCongelamento.current &&
            refDataCongelamento.current?.createdAt &&
            refDataCongelamento.current?.updatedAt
        ) {
            let given_seconds = differenceInSeconds(
                new Date(refDataCongelamento.current?.updatedAt),
                new Date(refDataCongelamento.current?.createdAt),
                // { roundingMethod: 'floor' }
            )

            let hours = Math.floor(given_seconds / 3600)
            let minutes: string | number = Math.floor((given_seconds - (hours * 3600)) / 60);
            let seconds: string | number = given_seconds - (hours * 3600) - (minutes * 60);

            if (minutes.toString().length === 1) minutes = '0' + minutes
            if (seconds.toString().length === 1) seconds = '0' + seconds
            refDiferencaDeCongelamneto.current = minutes + 'm:' + seconds + 's'

        }
    }, [])

    const desconectarSocker = useCallback((): void => {
        //desconectar socket
        if (refsocket.current) {

            refsocket.current.close();
            refsocket.current = null

        }
    }, [])

    const socketConnection = useCallback((idCongelamento: any): void => {

        const socket = io(urlHttpGovSocketIo);


        refsocket.current = socket
        socket.on('connect', () => {
            socket.emit('congelamento', { congelamento: idCongelamento })

            socket.on('congelamento', data => {

                if (typeof data === 'object') {

                    setDadosTabelaState(data)
                }

                if (typeof data === 'string') {

                    desconectarSocker();

                    buscaCongelamentoPelaCidade(refIdIbge.current)
                }

            })

        });

        // socket.on('disconnect', () => {

        //     console.log('Fechoou :D')

        // });

    }, []);

    //setando dados das cidades no selected
    const buscaArquivosPorCongelamento = useCallback((idCongelamento: string) => {

        const request = async () => {
            try {
                setLoading(true);
                let result = await api.get(urlCongelamento + '/' + idCongelamento,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        }
                    });

                setDadosTabelaState(result.data);

                setLoading(false);

            } catch (error) {

                let msgErro: any = (error as Error);
                setLoading(false);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'municipios_dkwdfaiweyi')
                }


            }
        }

        request();

    }, []);

    //setando dados das cidades no selected
    const buscaCongelamentoPelaCidade = useCallback((idIbge: number) => {


        const request = async () => {
            try {
                setLoadingListCongelamento(true);
                let result = await api.get(urlCongelamentoMunicipio + '/' + idIbge,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        }
                    });

                refDataListCongelamento.current = result.data
                setDataListCongelamento(result.data);

                if (result.data.length > 0) {

                    // const totalTamanho = (result.data.length - 1)
                    const totalTamanho = 0

                    refIdCongelamento.current = result.data[totalTamanho].id
                    refDataCongelamento.current = result.data[totalTamanho]
                    setValueSelectCongelamnetos(result.data[totalTamanho].id)
                    buscaArquivosPorCongelamento(result.data[totalTamanho].id)

                    if (result.data[totalTamanho]?.status === 'em andamento') {

                        socketConnection(result.data[totalTamanho].id)
                    }


                    setDiferencaDeTempo();
                }

                setLoadingListCongelamento(false);

            } catch (error) {

                let msgErro: any = (error as Error);
                setLoadingListCongelamento(false);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'municipios_dkwdfaiweyi')
                }


            }
        }

        request();

    }, []);


    useEffect(() => {

        if (municipioSelecionado) buscaCongelamentoPelaCidade(municipioSelecionado.idIbge);

        refIdIbge.current = municipioSelecionado?.idIbge
        setValueSelectCongelamnetos(undefined)
        setDataListCongelamento([])
        refDataListCongelamento.current = []
        refIdCongelamento.current = null
        refDataCongelamento.current = null
        setDadosTabelaState([])

        desconectarSocker();

    }, [municipioSelecionado]);

    const selectCongelamentoPorID = useCallback((id: any): DadosListCongelamneto | null => {
        let dadosCongelamento = null;
        refDataListCongelamento.current.forEach(val => {
            if (val.id == id) {
                dadosCongelamento = val
            }
        });
        return dadosCongelamento;

    }, []);

    const onChangeSelectCongelamento = useCallback((value: any): void => {

        desconectarSocker();

        setValueSelectCongelamnetos(value)
        refIdCongelamento.current = value
        buscaArquivosPorCongelamento(value)

        let congelamentoSelecionado = selectCongelamentoPorID(value)
        refDataCongelamento.current = congelamentoSelecionado
        if (congelamentoSelecionado?.status === 'em andamento') {

            socketConnection(value)
        }

        setDiferencaDeTempo();

    }, []);

    const handleGerarNovoCongelamento = useCallback(() => {

        const request = async () => {
            try {
                setLoading(true);
                let result = await api.post(urlCongelamento, {
                    "idIbge": refIdIbge.current
                },
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        }
                    });


                buscaCongelamentoPelaCidade(refIdIbge.current)
                // setDadosTabelaState(result.data);

                setLoading(false);

            } catch (error) {

                let msgErro: any = (error as Error);
                setLoading(false);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'municipios_dkwdfaiweyi')
                }


            }
        }

        request();

    }, []);

    const clickBtnGerarZipCongelamento = useCallback(() => {

        const buscaPdf = async () => {
            try {
                if (typeof refDataCongelamento.current?.id === 'string') {

                    setLoading(true)
                    let result = await api.get(urlCongelamentoCompleto + '/' + refDataCongelamento.current?.id,
                        {
                            headers: {
                                'Authorization': 'Bearer ' + getToken()
                            },
                            responseType: 'blob',
                        });

                    const dataInicioCongtelamento = new Date(refDataCongelamento.current.createdAt)

                    const dataFormatada = `${dataInicioCongtelamento.getUTCDate()}-${(dataInicioCongtelamento.getUTCMonth()+1)}-${dataInicioCongtelamento.getUTCFullYear()}_${dataInicioCongtelamento.getHours()}-${dataInicioCongtelamento.getMinutes()}`;

                    const nomeArquivo = `${refDataCongelamento.current.idIbge}-${dataFormatada}.zip`

                    const url = window.URL.createObjectURL(new Blob([result.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', nomeArquivo);
                    document.body.appendChild(link);
                    link.click();


                    setLoading(false)
                }

            } catch (error) {
                setLoading(false)
                let msgErro: any = (error as Error);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'congelamento_cwerfgbowafbpi')
                }


            }
        }

        buscaPdf();

    }, []);

    const columns = [
        {
            title: 'Nome',
            dataIndex: 'arquivo',
            ...useGetColumnSearchProps('arquivo'),
            width: '10%',
            sorter: (a: any, b: any) => a.arquivo.localeCompare(b.arquivo),
            showSorterTooltip: false,
        },
        // {
        //     title: 'Status',
        //     dataIndex: 'status',
        //     width: '2%',
        //     render: (criado: any, record: any) => {
        //         return (
        //             <div
        //                 style={{
        //                     display: 'flex',
        //                     justifyContent: 'space-between',
        //                     alignItems: 'center'
        //                 }}
        //             >

        //                 <img style={{
        //                     width: 20,
        //                     marginLeft: 5
        //                 }} src={record.status ? ImgUpLike : ImgDownLike} />
        //             </div>
        //         )
        //     },
        // },
        {
            title: 'Baixar',
            dataIndex: 'arquivo',
            width: '2%',
            showSorterTooltip: false,
            render: (arquivo: any, record: any) => {
                return (
                    <div className='naoElinha'>
                        {
                            record.status ?
                                <a
                                    // https://desenvolveapi.govfacilbrasil.com.br/congelamentos/3ce10aaa-5741-4a83-afc0-7a4a694224f5/CAUC.pdf
                                    // href={urlCongelamentos + '/' + record.id + '/' + arquivo}
                                    href={urlCongelamentos + '/' + refIdCongelamento.current + '/' + arquivo}
                                    className='ant-btn ant-btn-primary botaoNaolinha'
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {'Baixar '}<IconDownload />
                                </a>
                                :
                                refDataCongelamento.current?.status === 'em andamento' ?
                                    <Spin
                                        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                                    />
                                    :
                                    <Text
                                        style={{
                                            fontWeight: 'bold',
                                            color: 'red'
                                            // color: record.status ? 'red' : 'green'
                                        }}
                                    >
                                        {'Não foi possível baixar!'}
                                    </Text>
                        }

                    </div>
                )
            },
        },
    ];

    return (
        <>
            {
                dataListCongelamento.length === 0 ?
                    <>
                        <Button
                            type="primary"
                            className="botaoVerdeScreenCongelamento"
                            style={{ marginBottom: 10 }}
                            // disabled={!hasSelectedAdd}
                            onClick={() => { handleGerarNovoCongelamento() }}
                        >
                            Novo Congelamento
                        </Button>
                    </>
                    :
                    <Row style={{ marginBottom: 0 }}>
                        <Col {...colSelect}>
                            <div
                                style={{ marginBottom: 5 }}
                            >
                                <Text style={{ margin: 1 }} >{'Selecione o congelamento:'}</Text>
                            </div>
                            <div
                                className="Congelamento_divSelectButton_cewlhfbweu"
                            >
                                <Select
                                    showSearch
                                    placeholder="Digite o ibge do municipio"
                                    optionFilterProp="children"
                                    // className="botaoListVendedorUser"
                                    onChange={onChangeSelectCongelamento}
                                    loading={loadingListCongelamento}
                                    notFoundContent={loadingListCongelamento ? <Spin size="small" /> : null}
                                    value={valueSelectCongelamnetos}
                                    style={{
                                        width: '100%',
                                        marginBottom: 10,
                                        marginRight: 5,
                                    }}
                                    filterOption={(input: any, option: any) => {
                                        let textDigit = removeAcento(input)
                                        let listCidade = removeAcento(option?.children);
                                        return listCidade.indexOf(textDigit) >= 0
                                    }}
                                >
                                    {
                                        dataListCongelamento.map((item, index) => {
                                            return (
                                                <Select.Option
                                                    value={item.id}
                                                    key={item.id}
                                                >
                                                    {(index + 1) + ' - ' + convertDataUSParaBrasilEHora(item.createdAt) + ' - ' + item.status + ' - ' + item.usuario.nome}
                                                </Select.Option>
                                            )
                                        })
                                    }
                                </Select>
                                <Button
                                    type="primary"
                                    className="botaoVerdeScreenCongelamento"
                                    style={{ marginBottom: 10 }}
                                    // disabled={!hasSelectedAdd}
                                    onClick={() => { handleGerarNovoCongelamento() }}
                                >
                                    Novo Congelamento
                                </Button>
                            </div>
                        </Col>
                    </Row>
            }

            {refDataCongelamento.current &&
                <div
                    className='Congelamento_TextosStatusEDatas_asdfyukgvoe'
                >
                    <div
                        style={{
                            display: 'flex',
                            margin: 7
                        }}
                    >

                        <Text>Status:</Text>
                        <Text style={{ marginLeft: 3 }} strong>{refDataCongelamento.current?.status}</Text>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            margin: 7
                        }}
                    >

                        <Text>Data Início:</Text>
                        {
                            refDataCongelamento.current?.createdAt &&
                            <Text style={{ marginLeft: 3 }} strong>{convertDataUSParaBrasilEHoraSegundos(refDataCongelamento.current?.createdAt)}</Text>
                        }
                    </div>
                    {
                        refDataCongelamento.current?.status && refDataCongelamento.current?.status != 'em andamento' &&
                        <div
                            style={{
                                display: 'flex',
                                margin: 7
                            }}
                        >

                            <Text>Data Fim:</Text>
                            {
                                refDataCongelamento.current?.updatedAt &&
                                <Text style={{ marginLeft: 3 }} strong>{convertDataUSParaBrasilEHoraSegundos(refDataCongelamento.current?.updatedAt)}</Text>
                            }
                        </div>
                    }
                    {
                        refDataCongelamento.current?.status && refDataCongelamento.current?.status != 'em andamento' &&
                        <div
                            style={{
                                display: 'flex',
                                margin: 7
                            }}
                        >
                            <Text>Tempo Total:</Text>
                            {/* {
                            refDataCongelamento.current?.tempoTotal &&
                            <Text style={{ marginLeft: 3 }} strong>{refDataCongelamento.current?.tempoTotal}</Text>
                        } */}
                            {
                                refDataCongelamento.current?.updatedAt && refDataCongelamento.current?.createdAt &&
                                <Text style={{ marginLeft: 3 }} strong>{
                                    refDiferencaDeCongelamneto.current
                                }</Text>
                            }

                        </div>
                    }
                </div>
            }
            <div
                className='divBotaoAtencaoEBaixarTodas_congelamento_sdfgjrebgopib'
            >
                <div
                    style={{
                        display: 'flex',
                        // justifyContent: 'flex-end',
                        // justifyContent: 'flex-end',
                        alignItems: 'center',
                        marginBottom: 15,
                        marginLeft: 7
                    }}
                >
                    <Text strong style={{
                        fontWeight: 'bold',
                        color: 'red',
                        marginRight: 5
                    }}>{'Atenção'}</Text>
                    <Button
                        type="link"
                        // className="BotaoLi"
                        style={{
                            margin: 2, padding: 0, fontSize: 16
                        }}
                        // disabled={!hasSelectedAdd}
                        onClick={() => { setModalViewAtencaoCongelamento(true) }}
                    >
                        <InfoCircleOutlined style={{
                            // color: theme === 'light' ? '#262626' : '#fff',
                            color: 'red'
                        }} />
                    </Button>
                </div>
                {
                    refDataCongelamento.current?.status && refDataCongelamento.current?.status != 'em andamento' &&
                    <div
                        style={{
                            // display: 'flex',
                            // justifyContent: 'flex-end',
                            marginBottom: 15
                        }}
                    >
                        <Button
                            className="botaoViewUsuarioUser"
                            type="primary"
                            style={{ margin: 2 }}
                            onClick={() => clickBtnGerarZipCongelamento()}
                        // disabled={!hasSelectedView}
                        >
                            Baixar todos
                        </Button>
                    </div>
                }
            </div>

            <Table
                style={{
                    // marginTop: 50
                }}
                className='marginTopTables'
                loading={loading}
                rowKey={(record) => record.id}
                // loading={loadingTable}
                // onRow={(record, rowIndex) => {
                //     return {
                //         onClick: event => { onclickTable(record, rowIndex) }, // click row
                //         onDoubleClick: event => { }, // double click row
                //         onContextMenu: event => { }, // right button click row
                //         onMouseEnter: event => { }, // mouse enter row
                //         onMouseLeave: event => { }, // mouse leave row
                //     };
                // }}
                // rowSelection={rowSelection}
                columns={columns} dataSource={dadosTabelaState}
                scroll={{ x: 550 }}
                onChange={
                    (pagination, filters, sorter, extra) => {
                        refContagemDoFiltro.current = extra.currentDataSource
                    }
                }
                // title={(registros) => {

                //     return (
                //         <div
                //             style={{
                //                 backgroundColor: theme === 'light' ? '#FAFAFA' : '#1D1D1D',
                //                 padding: 10,
                //                 margin: -8,
                //                 marginBottom: 0.5
                //             }}
                //         >
                //             <Text>
                //                 {/* {`Total: ${dadosTabelaState.length} registros.`} */}
                //                 {`Mostrando: ${registros.length} de ${refContagemDoFiltro.current.length === 0 ?
                //                     dadosTabelaState.length :
                //                     refContagemDoFiltro.current.length} num total de ${dadosTabelaState.length} `}
                //             </Text>
                //         </div>
                //     )
                // }}
                // footer={(registros) => {
                //     return (
                //         <div
                //             style={{
                //                 textAlign: 'end'
                //             }}
                //         >
                //             <Text>
                //                 {/* {`Total: ${dadosTabelaState.length} registros.`} */}
                //                 {`Mostrando: ${registros.length} de ${refContagemDoFiltro.current.length === 0 ?
                //                     dadosTabelaState.length :
                //                     refContagemDoFiltro.current.length} num total de ${dadosTabelaState.length} `}
                //             </Text>
                //         </div>
                //     )
                // }}
                size="small"
                pagination={{
                    locale: { items_per_page: '' },
                    showSizeChanger: false,
                    defaultPageSize: 100,
                    // position: ['topRight', 'bottomRight'],
                    position: [],
                    pageSizeOptions: ['10', '20', '50', '100']
                }}
                locale={locale.Table}
            />
            <Modal
                title="Atenção!"
                visible={modalViewAtencaoCongelamento}
                // className="classmodalDadosContatoInatendimento"
                onOk={() => setModalViewAtencaoCongelamento(false)}
                onCancel={() => setModalViewAtencaoCongelamento(false)}
                footer={[
                    <Button key="back" onClick={() => setModalViewAtencaoCongelamento(false)}>
                        Fechar
                    </Button>,
                ]}
            >
                <Row style={{ marginBottom: 25 }}>
                    <Col span={24} style={{ marginBottom: 5 }}>
                        <Text
                            strong={false}
                        >
                            <Text strong style={{ marginRight: 5 }}>* CAUC: </Text>
                            {'A partir das 09:00h (Antes desse horário é provável que os dados apresentem inconsistências no sistema do STN).'}
                        </Text>
                    </Col>
                </Row>
                <Row>
                    <Col span={24} style={{ marginBottom: 5 }}>
                        <Text
                            strong={false}
                        >
                            <Text strong style={{ marginRight: 5 }}>* SAHEM: </Text>
                            {'Em dias de semana o site só permite consultas das 10:00h até as 23:59h. Finais de semana e feriados o site não permite consultas.'}
                        </Text>
                    </Col>
                </Row>
            </Modal>
        </>
    );
}
