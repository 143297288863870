import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Col, Typography, Row, notification, Button, Alert, Tabs } from 'antd';

import { useNavigate } from 'react-router-dom';

import { urlMunicipioEleicao, urlPoliticosFiles, urlPoliticosPolitico } from './../../../../services/urls';
import { api } from './../../../../services/apiAxios';
import { EmitirErro } from '../../../../services/EmitirErro';
import { SairDoSistema } from '../../../../services/LStorage/SairSistema';
import { getToken } from './../../../../services/LStorage/token';
import { cardPrefeito } from './GridStyle';
import { CardPrefeito } from './components/CardPrefeito';
import { Loading } from '../../../../components/Loading';
import { Eleicoes } from './components/Eleicoes';
import { Noticias } from './components/Noticias';
import { ProcessosTJSP } from './components/ProcessosTJSP';
import { DadosPessoais } from './components/DadosPessoais';
import { UltimaEleicao } from './components/UltimaEleicao';
import { convertDataUSParaBrasil, convertDataUSParaBrasilEHora } from '../../../../services/Conversores';
import './style.css'

// import {
//     removeAcento
// } from './../../../../utils/RemoveAcentos';
// import { CardLimites } from './CardLimites';
// import { TodasInformacoes } from './TodasInformacoes';

const { TabPane } = Tabs;
const { Text } = Typography;

// const largura = window.innerWidth;
// const altura = window.innerHeight;

interface interfDadosPessoais {
    coligacao: string | null,
    composicao_coligacao: string | null,
    cor_raca: string | null,
    cpf: string | null,
    data_nascimento: string | null,
    estado_civil: string | null,
    grau_de_instrucao: string | null,
    nacionalidade_naturalidade: string | null,
    nome: string,
    ocupacao: string | null,
    _id: string,
    genero: string | null,//daqui pra baixo não esta vindo na rota
    sites: Array<string>,
    limite_legal_de_gastos_1t: number | null,
    limite_legal_de_gastos_2t: number | null,
    atualizado_em: string | null,
    dados_atualizado_em: string | null,
}

interface interfDadosPolitico {
    cpf: string,
    nome: string,
    parceiro: string,
    cargo: string,
    status: string,
    img: string,
}

interface interfDadosPrefeito {
    "id": string,
    "createdAt": string,
    "updatedAt": string,
    "ano": number,
    cpfPrefeito: string,
    cpfVicePrefeito: string,
    "prefeito": string | null,
    "vicePrefeito": string | null,
    "fotoPrefeito": string | null,
    "fotoVicePrefeito": string | null,
    "observacao": string | null,
    "tipoEleicao": string | null,
    "turno": number | null,
    "situacaoPrefeito": string | null,
    "situacaoVicePrefeito": string | null,
    "statusPrefeito": string | null,
    "statusVicePrefeito": string | null,
    "situacaoPartidoPrefeito": string | null,
    "situacaoPartidoVicePrefeito": string | null,
    "fonte": string | null,
    "municipioIdIbge": number
}

interface interfDadosMunicipioSelecionado {
    municipioSelecionado?: {
        "idIbge": number,
        "nome": string,
    }
}

export const DadosDoPrefeito = ({
    municipioSelecionado
}: interfDadosMunicipioSelecionado) => {

    const navigate = useNavigate();

    const refDadosPoliticoRequest = useRef<interfDadosPrefeito>();

    const [dadosPolitico, setDadosPolitico] = useState<interfDadosPolitico>();
    // const [dadosPoliticoRequest, setDadosPoliticoRequest] = useState<interfDadosPrefeito>();
    const [loading, setLoading] = useState(false);
    const [politicoSelecionadoIsPrefeito, setPoliticoSelecionadoIsPrefeito] = useState<boolean>(true);
    const [dadosInAbaDadosPessoais, setDadosInAbaDadosPessoais] = useState<interfDadosPessoais | null>(null);

    //setando dados das cidades no selected
    const buscaDadosPessoais = useCallback((cpfPolitico: string) => {

        const buscaDaods = async () => {
            try {
                setLoading(true);
                let result = await api.get(urlPoliticosPolitico + '/' + cpfPolitico,
                    // let result = await api.get(urlPoliticosProcesso + '/' + '312321321312',
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        }
                    });

                setDadosInAbaDadosPessoais(result.data);


                setLoading(false);

            } catch (error) {

                let msgErro: any = (error as Error);
                setLoading(false);

                setDadosInAbaDadosPessoais(null);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {

                    EmitirErro(error, true, 'municipios_dkwdfaiweyi')
                }


            }
        }

        buscaDaods();

    }, []);

    //setando dados das cidades no selected
    const buscaCidades = useCallback((idIbge: number) => {

        const buscaCiaddes = async () => {
            try {
                setLoading(true);
                let resultCidade = await api.get(urlMunicipioEleicao + '/' + idIbge,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        }
                    });

                refDadosPoliticoRequest.current = resultCidade.data[0];

                const { cpfPrefeito, fotoPrefeito, prefeito, statusPrefeito, vicePrefeito }: interfDadosPrefeito = resultCidade.data[0];
                setDadosPolitico({
                    nome: typeof prefeito === 'string' ? prefeito : '',
                    cargo: 'Prefeito',
                    cpf: cpfPrefeito,
                    img: typeof fotoPrefeito === 'string' ? fotoPrefeito : '',
                    parceiro: typeof vicePrefeito === 'string' ? vicePrefeito : '',
                    status: typeof statusPrefeito === 'string' ? statusPrefeito : ''
                });
                buscaDadosPessoais(cpfPrefeito)
                setPoliticoSelecionadoIsPrefeito(true);

                setLoading(false);

            } catch (error) {

                let msgErro: any = (error as Error);
                setLoading(false);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'municipios_dkwdfaiweyi')
                }


            }
        }

        buscaCiaddes();

    }, []);

    useEffect(() => {

        // let arrayUrl = window.location.pathname.split("/");
        // VerificaRota(arrayUrl, navigate);

        if (municipioSelecionado) buscaCidades(municipioSelecionado.idIbge);

    }, [municipioSelecionado]);

    const handleClcikLinkPrefeitoOrVice = useCallback((setar: boolean) => {

        if (refDadosPoliticoRequest.current) {

            if (setar) {
                buscaDadosPessoais(refDadosPoliticoRequest.current.cpfPrefeito)
                setDadosPolitico({
                    nome: typeof refDadosPoliticoRequest.current.prefeito === 'string' ? refDadosPoliticoRequest.current.prefeito : '',
                    cargo: 'Prefeito',
                    cpf: refDadosPoliticoRequest.current.cpfPrefeito,
                    img: typeof refDadosPoliticoRequest.current.fotoPrefeito === 'string' ? refDadosPoliticoRequest.current.fotoPrefeito : '',
                    parceiro: typeof refDadosPoliticoRequest.current.vicePrefeito === 'string' ? refDadosPoliticoRequest.current.vicePrefeito : '',
                    status: typeof refDadosPoliticoRequest.current.statusPrefeito === 'string' ? refDadosPoliticoRequest.current.statusPrefeito : ''
                });
            } else {
                buscaDadosPessoais(refDadosPoliticoRequest.current.cpfVicePrefeito)
                setDadosPolitico({
                    nome: typeof refDadosPoliticoRequest.current.vicePrefeito === 'string' ? refDadosPoliticoRequest.current.vicePrefeito : '',
                    cargo: 'Vice-Prefeito',
                    cpf: refDadosPoliticoRequest.current.cpfVicePrefeito,
                    img: typeof refDadosPoliticoRequest.current.fotoVicePrefeito === 'string' ? refDadosPoliticoRequest.current.fotoVicePrefeito : '',
                    parceiro: typeof refDadosPoliticoRequest.current.prefeito === 'string' ? refDadosPoliticoRequest.current.prefeito : '',
                    status: typeof refDadosPoliticoRequest.current.statusVicePrefeito === 'string' ? refDadosPoliticoRequest.current.statusVicePrefeito : ''
                });
            }
        }

        setPoliticoSelecionadoIsPrefeito(setar)

    }, []);

    return (
        <>
            <Loading
                loading={loading}
            >

                {
                    dadosPolitico?.nome && dadosPolitico.nome === 'Sem dados' ?
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                marginBottom: 25,
                            }}
                        >
                            <Alert
                                message="Sem Dados"
                                description={
                                    <Text style={{ marginRight: 5, fontSize: '1rem' }} >
                                        {'Esse município não apresenta dados de Prefeito e Vice-Prefeito pois o resultado de suas eleições Ordinárias foi anulado pelo TSE. Novas eleições Suplementares ainda não foram feitas.'}
                                    </Text>
                                }
                                type="error"
                                showIcon
                            />
                        </div>
                        :
                        <>
                            <Row
                                style={{
                                    marginBottom: 15,
                                }}
                            >
                                <Col
                                    {...cardPrefeito}
                                    style={{
                                        marginTop: 20,
                                        marginBottom: 0,
                                        marginRight: 5,
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}
                                >
                                    <CardPrefeito
                                        urlImg={dadosPolitico?.img ? urlPoliticosFiles + '/' + dadosPolitico?.img : ''}
                                        nome={dadosPolitico?.nome ? dadosPolitico.nome : ''}
                                        render={() => {
                                            return (
                                                <Text>
                                                    <Text
                                                        style={{ fontSize: '1.1rem', fontWeight: 'normal' }}
                                                    >
                                                        {!politicoSelecionadoIsPrefeito ? 'Prefeito: ' : 'Vice-Prefeito: '}
                                                    </Text>
                                                    <Button
                                                        style={{
                                                            padding: 0, margin: 0,
                                                            fontWeight: 'bold',
                                                            fontSize: '1.2rem'
                                                        }}
                                                        type='link'
                                                        onClick={() => handleClcikLinkPrefeitoOrVice(!politicoSelecionadoIsPrefeito)}
                                                    >
                                                        {dadosPolitico?.parceiro ? dadosPolitico.parceiro : ''}
                                                    </Button>
                                                </Text>
                                            )
                                        }}
                                        cargo={dadosPolitico?.cargo ? dadosPolitico.cargo : ''}
                                        statusCard={dadosPolitico?.status}
                                    />
                                </Col>
                            </Row>
                            <div
                                style={{
                                    marginBottom: 15
                                }}
                                className="DadosPrefeito_divsDataHora_olbrqeoiugfyb"
                            >
                                <Text type='secondary' style={{ marginRight: 5, fontSize: '1rem' }} >{'Dados coletados no TSE em:'}</Text>
                                <Text type='secondary' style={{ fontWeight: 'bold', fontSize: '1rem' }}>
                                    {dadosInAbaDadosPessoais?.atualizado_em ? convertDataUSParaBrasilEHora(dadosInAbaDadosPessoais.atualizado_em) : ''}
                                </Text>
                            </div>
                        </>
                }
                <Row>
                    <Col span={24}>
                        <Tabs
                            type="card"
                            defaultActiveKey="1"
                            style={{
                                marginTop: 15,
                            }}
                            className='tab-municipios'

                        >
                            <TabPane
                                tab="Eleições"
                                key="eleicoes1"
                            >
                                <Eleicoes dadosPoliticoSelecionado={dadosPolitico} />
                            </TabPane>
                            <TabPane
                                tab="Notícias"
                                key="noticias1"
                            >
                                <Noticias dadosPoliticoSelecionado={dadosPolitico} />
                            </TabPane>
                            <TabPane
                                tab="Processos TJSP"
                                key="processosTJSP1"
                            >
                                <ProcessosTJSP dadosPoliticoSelecionado={dadosPolitico} />
                            </TabPane>
                            <TabPane
                                tab="Dados Pessoais"
                                key="dadosPessoais1"
                            >
                                <DadosPessoais
                                    dadosPoliticoSelecionado={dadosPolitico}
                                    dadosInAbaDadosPessoais={dadosInAbaDadosPessoais}
                                />
                            </TabPane>
                            {/* <TabPane
                                tab="Última Eleição"
                                key="ultimaEleicao1"
                            >
                                <UltimaEleicao
                                    dadosPoliticoSelecionado={dadosPolitico}
                                    municipioSelecionado={municipioSelecionado}
                                />
                            </TabPane> */}
                        </Tabs>
                    </Col>
                </Row>

            </Loading>
        </>
    );
}
