import React, { ReactNode, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Col, Typography, Row, notification, Button, Divider, Alert, Select } from 'antd';

import { useNavigate } from 'react-router-dom';

// import { colSelect } from './GridStyle';

// import { getToken } from './../../services/LStorage/token';
import { VerificaRota } from './../../../../services/VerificaSePodeAcessar';
import { urlMunicipioEleicao, urlPoliticosFiles } from './../../../../services/urls';
import { api } from './../../../../services/apiAxios';
import { EmitirErro } from '../../../../services/EmitirErro';
import { SairDoSistema } from '../../../../services/LStorage/SairSistema';
import { getToken } from './../../../../services/LStorage/token';
import { CardSituacao } from './CardSituacao';
import { cardPrefeito } from './GridStyle';
import { CardPrefeito } from './CardPrefeito';
import { Loading } from '../../../../components/Loading';
import { GlobalContext } from '../../../../contexts/globalContext';
import { removeAcento } from '../../../../utils/RemoveAcentos';

// import {
//     removeAcento
// } from './../../../../utils/RemoveAcentos';
// import { CardLimites } from './CardLimites';
// import { TodasInformacoes } from './TodasInformacoes';

const { Text, Title } = Typography;

// const largura = window.innerWidth;
// const altura = window.innerHeight;

interface interfDadosPrefeito {
    "id": string,
    "createdAt": string,
    "updatedAt": string,
    "ano": number,
    "prefeito": string | null,
    "vicePrefeito": string | null,
    "fotoPrefeito": string | null,
    "fotoVicePrefeito": string | null,
    "observacao": string | null,
    "tipoEleicao": string | null,
    "turno": number | null,
    "situacaoPrefeito": string | null,
    "situacaoVicePrefeito": string | null,
    "statusPrefeito": string | null,
    "statusVicePrefeito": string | null,
    "situacaoPartidoPrefeito": string | null,
    "situacaoPartidoVicePrefeito": string | null,
    "fonte": string | null,
    "municipioIdIbge": number
}
interface interfDadosMunicipioSelecionado {
    municipioSelecionado?: {
        "idIbge": number,
        "nome": string,
    }
}

export const Prefeito = ({
    municipioSelecionado
}: interfDadosMunicipioSelecionado) => {

    const navigate = useNavigate();
    const { theme } = useContext(GlobalContext);

    const refInputObservacao = useRef<any>();
    const refInputObservacaoValue = useRef<any>();

    const [dadosPrefeitoAnoAnterior, setDadosPrefeitoAnoAnterior] = useState<interfDadosPrefeito>();
    const [dadosPrefeito, setDadosPrefeito] = useState<interfDadosPrefeito>();
    const [loading, setLoading] = useState(false);
    const [podeSalvarObs, setPodeSalvarObs] = useState(false);
    const [valSelectStatusPrefeito, setValSelectStatusPrefeito] = useState<string>('Em mandato');
    const [valSelectStatusVicePrefeito, setValSelectStatusVicePrefeito] = useState<string>('Em mandato');

    //setando dados das cidades no selected
    const buscaCidades = useCallback((idIbge: number) => {


        const buscaCiaddes = async () => {
            try {
                setLoading(true);
                let resultCidade = await api.get(urlMunicipioEleicao + '/' + idIbge,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        }
                    });


                setDadosPrefeitoAnoAnterior(resultCidade.data[(resultCidade.data.length - 1)]);

                refInputObservacao.current.value = resultCidade.data[0]?.observacao !== null &&
                    resultCidade.data[0]?.observacao !== undefined ?
                    resultCidade.data[0].observacao : ''


                setValSelectStatusPrefeito(resultCidade.data[0].statusPrefeito)
                setValSelectStatusVicePrefeito(resultCidade.data[0].statusVicePrefeito)
                setDadosPrefeito(resultCidade.data[0]);

                setLoading(false);

            } catch (error) {

                let msgErro: any = (error as Error);
                setLoading(false);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'municipios_dkwdfaiweyi')
                }


            }
        }

        buscaCiaddes();

    }, []);

    useEffect(() => {

        // let arrayUrl = window.location.pathname.split("/");
        // VerificaRota(arrayUrl, navigate);
        setPodeSalvarObs(false)
        if (municipioSelecionado) buscaCidades(municipioSelecionado.idIbge);

    }, [municipioSelecionado]);


    const onChangeInputObservacao = useCallback((e: any) => {

        refInputObservacaoValue.current = e.target.value;

        const obsev = dadosPrefeito?.observacao !== null && dadosPrefeito?.observacao !== undefined
            ? dadosPrefeito.observacao : ''

        if (obsev === e.target.value) {
            setPodeSalvarObs(false)
        } else {
            setPodeSalvarObs(true)
        }

    }, [dadosPrefeito])

    const onClickSalvarObservacao = useCallback((eleicao?: interfDadosPrefeito) => {

        const buscaCiaddes = async () => {
            try {

                if (eleicao) {

                    setLoading(true);
                    let result = await api.patch(urlMunicipioEleicao + '/' + eleicao.id,
                        { observacao: refInputObservacaoValue.current },
                        {
                            headers: {
                                'Authorization': 'Bearer ' + getToken()
                            }
                        });

                    notification.success({
                        message: 'Sucesso',
                        description:
                            'Suas observações foram salvas!',
                    });

                    setDadosPrefeito(result.data)
                    setPodeSalvarObs(false)
                    setLoading(false);
                }

            } catch (error) {

                let msgErro: any = (error as Error);
                setLoading(false);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'municipios_dkwdfaiweyi')
                }


            }
        }

        buscaCiaddes();

    }, []);

    const clickUpdateStatusPrefeito = useCallback((value: any, option: any, dadosPrefeito?: interfDadosPrefeito) => {

        const requisicao = async () => {
            try {

                if (dadosPrefeito) {

                    setLoading(true);
                    let result = await api.patch(urlMunicipioEleicao + '/statusPrefeito/' + dadosPrefeito.id,
                        { statusPrefeito: value },
                        {
                            headers: {
                                'Authorization': 'Bearer ' + getToken()
                            }
                        });

                    notification.success({
                        message: 'Sucesso',
                        description:
                            'Status alterado com sucesso!',
                    });

                    setValSelectStatusPrefeito(result.data.statusPrefeito)
                    setValSelectStatusVicePrefeito(result.data.statusVicePrefeito)
                    setDadosPrefeito(result.data);

                    setPodeSalvarObs(false)
                    setLoading(false);
                }

            } catch (error) {

                let msgErro: any = (error as Error);
                setLoading(false);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'municipios_dkwdfaiweyi')
                }


            }
        }

        requisicao();

    }, []);

    const clickUpdateStatusVicePrefeito = useCallback((value: any, option: any, dadosPrefeito?: interfDadosPrefeito) => {

        setValSelectStatusVicePrefeito(value);

        const requisicao = async () => {
            try {

                if (dadosPrefeito) {

                    setLoading(true);
                    let result = await api.patch(urlMunicipioEleicao + '/statusVicePrefeito/' + dadosPrefeito.id,
                        { statusVicePrefeito: value },
                        {
                            headers: {
                                'Authorization': 'Bearer ' + getToken()
                            }
                        });

                    notification.success({
                        message: 'Sucesso',
                        description:
                            'Status alterado com sucesso!',
                    });

                    setValSelectStatusPrefeito(result.data.statusPrefeito)
                    setValSelectStatusVicePrefeito(result.data.statusVicePrefeito)
                    setDadosPrefeito(result.data);

                    setPodeSalvarObs(false)
                    setLoading(false);
                }

            } catch (error) {

                let msgErro: any = (error as Error);
                setLoading(false);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'municipios_dkwdfaiweyi')
                }


            }
        }

        requisicao();

    }, []);

    return (
        <>
            <Loading
                loading={loading}
            >
                {
                    dadosPrefeitoAnoAnterior?.prefeito && dadosPrefeitoAnoAnterior.prefeito === 'Sem dados' ?
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                marginBottom: 25,
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    marginBottom: 10,
                                }}
                            >
                                <Text style={{ marginRight: 5, fontSize: '1rem' }} >{'Eleições municipais:'}</Text>
                                <Text style={{ fontWeight: 'bold', marginRight: 5, fontSize: '1rem' }}>{dadosPrefeitoAnoAnterior?.ano ? dadosPrefeitoAnoAnterior.ano : ''}</Text>
                            </div>
                            <Alert
                                message="Sem Dados"
                                description={
                                    <Text style={{ marginRight: 5, fontSize: '1rem' }} >
                                        {'Esse município não apresenta dados de Prefeito e Vice-Prefeito pois o resultado de suas eleições Ordinárias foi anulado pelo TSE. Novas eleições Suplementares ainda não foram feitas.'}
                                    </Text>
                                }
                                type="error"
                                showIcon
                            />
                        </div>
                        :
                        <>
                            <div
                                style={{
                                    display: 'flex',
                                    marginBottom: 5
                                }}
                            >
                                <Text style={{ marginRight: 5, fontSize: '1rem' }} >{'Eleições municipais:'}</Text>
                                <Text style={{ fontWeight: 'bold', fontSize: '1rem' }}>{dadosPrefeitoAnoAnterior?.ano ? dadosPrefeitoAnoAnterior.ano : ''}</Text>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    marginBottom: 5
                                }}
                            >
                                <Text style={{ marginRight: 5, fontSize: '1rem' }} >{'Prefeito Eleito:'}</Text>
                                <Text style={{ fontWeight: 'bold', fontSize: '1rem' }}>{dadosPrefeitoAnoAnterior?.prefeito ? dadosPrefeitoAnoAnterior.prefeito : ''}</Text>
                            </div>
                        </>
                }

                <Divider
                    style={{ margin: '20px 0', backgroundColor: theme === 'light' ? '#e1e1e1' : '#434343' }}
                />

                {
                    dadosPrefeito?.prefeito && dadosPrefeito.prefeito === 'Sem dados' ?
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                marginBottom: 25,
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    marginBottom: 10,
                                }}
                            >
                                <Text style={{ marginRight: 5, fontSize: '1rem' }} >{'Eleições municipais:'}</Text>
                                <Text style={{ fontWeight: 'bold', marginRight: 5, fontSize: '1rem' }}>{dadosPrefeito?.ano ? dadosPrefeito.ano : ''}</Text>
                            </div>
                            <Alert
                                message="Sem Dados"
                                description={
                                    <Text style={{ marginRight: 5, fontSize: '1rem' }} >
                                        {'Esse município não apresenta dados de Prefeito e Vice-Prefeito pois o resultado de suas eleições Ordinárias foi anulado pelo TSE. Novas eleições Suplementares ainda não foram feitas.'}
                                    </Text>
                                }
                                type="error"
                                showIcon
                            />
                        </div>
                        :
                        <>
                            <div
                                style={{
                                    display: 'flex'
                                }}
                            >
                                <Text style={{ marginRight: 5, fontSize: '1rem' }} >{'Eleições municipais:'}</Text>
                                <Text style={{ fontWeight: 'bold', marginRight: 5, fontSize: '1rem' }}>{dadosPrefeito?.ano ? dadosPrefeito.ano : ''}</Text>
                                <Text style={{ fontWeight: 'bold', fontSize: '1rem' }}>{dadosPrefeito?.turno ? ' - ' + dadosPrefeito.turno + 'º turno' : ''}</Text>
                            </div>

                            <Row
                                style={{
                                    marginBottom: 25,
                                }}
                            >
                                <Col
                                    {...cardPrefeito}
                                    style={{
                                        marginTop: 20,
                                        marginBottom: 0,
                                        marginRight: 5,
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}
                                >
                                    <CardPrefeito
                                        urlImg={dadosPrefeito?.fotoPrefeito ? urlPoliticosFiles + '/' + dadosPrefeito.fotoPrefeito : ''}
                                        nome={dadosPrefeito?.prefeito ? dadosPrefeito.prefeito : ''}
                                        cargo='Prefeito'
                                        cnpj=''
                                        card1Title={dadosPrefeito?.situacaoPrefeito ? dadosPrefeito.situacaoPrefeito : ''}
                                        card1Desc='Situação Candidatura'
                                        card1CorFundo='#4CA750'
                                        card2Title={dadosPrefeito?.situacaoPartidoPrefeito ? dadosPrefeito.situacaoPartidoPrefeito : ''}
                                        card2Desc='Situação Partido/Coligação'
                                        statusCard={dadosPrefeito?.statusPrefeito}
                                    // card2CorFundo='#4CA750'
                                    />
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        marginTop: 10
                                    }}>
                                        <div
                                            style={{ marginBottom: 5 }}
                                        >
                                            <Text style={{ marginRight: 5, fontWeight: 'bold' }} >{'Status:'}</Text>
                                        </div>
                                        <div
                                            style={{
                                                marginRight: 5,
                                                marginBottom: 5
                                            }}
                                        >
                                            <Select
                                                showSearch
                                                optionFilterProp="children"
                                                onChange={(value, option) => { clickUpdateStatusPrefeito(value, option, dadosPrefeito) }}
                                                value={valSelectStatusPrefeito}
                                                style={{
                                                    width: '125px',
                                                }}
                                                filterOption={(input: any, option: any) => {
                                                    let textDigit = removeAcento(input)
                                                    let listCidade = removeAcento(option?.children);
                                                    return listCidade.indexOf(textDigit) >= 0
                                                }}
                                            >
                                                <Select.Option
                                                    value="Cassado"
                                                >
                                                    {'Cassado'}
                                                </Select.Option>
                                                <Select.Option
                                                    value="Em mandato"
                                                >
                                                    {'Em mandato'}
                                                </Select.Option>
                                                <Select.Option
                                                    value="Falecido"
                                                >
                                                    {'Falecido'}
                                                </Select.Option>
                                                <Select.Option
                                                    value="Licenciado"
                                                >
                                                    {'Licenciado'}
                                                </Select.Option>
                                            </Select>
                                        </div>
                                    </div>
                                </Col>
                                <Col
                                    {...cardPrefeito}
                                    style={{
                                        marginTop: 20,
                                        marginBottom: 0,
                                        marginRight: -5,
                                        display: 'flex',
                                        flexDirection: 'column'

                                    }}
                                >

                                    <CardPrefeito
                                        urlImg={dadosPrefeito?.fotoVicePrefeito ? urlPoliticosFiles + '/' + dadosPrefeito.fotoVicePrefeito : ''}
                                        nome={dadosPrefeito?.vicePrefeito ? dadosPrefeito.vicePrefeito : ''}
                                        cargo='Vice-Prefeito'
                                        cnpj=''
                                        card1Title={dadosPrefeito?.situacaoVicePrefeito ? dadosPrefeito.situacaoVicePrefeito : ''}
                                        card1Desc='Situação Candidatura'
                                        card1CorFundo='#4CA750'
                                        card2Title={dadosPrefeito?.situacaoPartidoVicePrefeito ? dadosPrefeito.situacaoPartidoVicePrefeito : ''}
                                        card2Desc='Situação Partido/Coligação'
                                        statusCard={dadosPrefeito?.statusVicePrefeito}
                                    // card2CorFundo='#4CA750'
                                    />

                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        marginTop: 10
                                    }}>
                                        <div
                                            style={{ marginBottom: 5 }}
                                        >
                                            <Text style={{ marginRight: 5, fontWeight: 'bold' }} >{'Status:'}</Text>
                                        </div>
                                        <div
                                            style={{
                                                marginRight: 5,
                                                marginBottom: 5
                                            }}
                                        >
                                            <Select
                                                showSearch
                                                optionFilterProp="children"
                                                onChange={(value, option) => { clickUpdateStatusVicePrefeito(value, option, dadosPrefeito) }}
                                                value={valSelectStatusVicePrefeito}
                                                style={{
                                                    width: '125px',
                                                }}
                                                filterOption={(input: any, option: any) => {
                                                    let textDigit = removeAcento(input)
                                                    let listCidade = removeAcento(option?.children);
                                                    return listCidade.indexOf(textDigit) >= 0
                                                }}
                                            >
                                                <Select.Option
                                                    value="Cassado"
                                                >
                                                    {'Cassado'}
                                                </Select.Option>
                                                <Select.Option
                                                    value="Em mandato"
                                                >
                                                    {'Em mandato'}
                                                </Select.Option>
                                                <Select.Option
                                                    value="Falecido"
                                                >
                                                    {'Falecido'}
                                                </Select.Option>
                                                <Select.Option
                                                    value="Licenciado"
                                                >
                                                    {'Licenciado'}
                                                </Select.Option>
                                            </Select>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </>
                }
                <div
                    style={{
                        display: 'flex',
                        marginBottom: 25
                    }}
                >
                    <Text style={{ marginRight: 5, fontSize: '1rem' }} >{'Dados Públicos:'}</Text>
                    <Text style={{ fontWeight: 'bold', fontSize: '1rem' }}>
                        {dadosPrefeito?.fonte ? 'Fonte ' + dadosPrefeito.fonte : ''}
                    </Text>
                </div>
                <Col
                    span={24}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginBottom: 5
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            marginBottom: 5,
                            alignItems: 'center'
                        }}
                    >
                        <Text style={{ fontSize: '1rem' }} >{'Observações:'}</Text>
                        <Button
                            type="primary"
                            style={{ marginLeft: 10 }}
                            onClick={() => onClickSalvarObservacao(dadosPrefeito)}
                            disabled={!podeSalvarObs}
                        >
                            Salvar
                        </Button>
                    </div>

                    <textarea
                        className="ant-input"
                        ref={refInputObservacao}
                        style={{ maxHeight: 200, height: 100 }}
                        onChange={(e) => {
                            onChangeInputObservacao(e)
                        }}
                    />
                </Col>


            </Loading>
        </>
    );
}
