import { useCallback, useEffect, useState } from 'react';
import { Tabs, Typography, notification } from 'antd';

import { useNavigate } from 'react-router-dom';

// import { colSelect } from './GridStyle';

import {
    convertMoedaBRDoJSComExtamente2CasasDecimais,
    poeValorComPontosEmMil
} from '../../../../services/Conversores';
import { urlMunicipioStatus } from './../../../../services/urls';
import { api } from './../../../../services/apiAxios';
import { EmitirErro } from '../../../../services/EmitirErro';
import { SairDoSistema } from '../../../../services/LStorage/SairSistema';
import { getToken } from './../../../../services/LStorage/token';

// import {
//     removeAcento
// } from './../../../../utils/RemoveAcentos';
import { CardLimites } from './CardLimites';
import { TodasInformacoes } from './TodasInformacoes';
import { Loading } from '../../../../components/Loading';
import './style.css';

const { TabPane } = Tabs;
const { Text, Title } = Typography;

// const largura = window.innerWidth;
// const altura = window.innerHeight;

interface InterfMunicipio {
    "limiteLrf"?: number | null,
    "cdp": boolean,
    "cdpAtualizadoEm": string | null,
    "despesaPessoalLegislativo"?: number | null,
    "despesaPessoalLegislativoAtualizadoEm": string | null,
    "despesaPessoalLegislativoPeriodo": string | null,
    "despesaPessoalExecutivo"?: number | null,
    "despesaPessoalExecutivoAtualizadoEm": string | null,
    "despesaPessoalExecutivoPeriodo": string | null,
    "pendentesRegularizacaoConsultaJuridica": boolean,
    "pendentesRegularizacaoConsultaJuridicaAtualizadoEm": string | null,
    "retificacaoRgfEm": string | null,
    "retificacaoRreoEm": string | null,
    "homologacaoRgf": boolean,
    "homologacaoRgfEm": string | null,
    "homologacaoRgfPeriodo": string | null,
    "homologacaoRgfAtualizadoEm": string | null,
    "homologacaoRreoAtualizadoEm": string | null,
    "homologacaoRreoEm": string | null,
    "homologacaoRreoPeriodo": string | null,
    "homologacaoRreo": boolean,
    "municipioIdIbge": number,
    "municipio": {
        "idIbge": number,
        "anoPopulacao"?: string | null,
        "nome"?: string | null,
        "ddd"?: string | null,
        "populacao"?: number | null,
        "valorComprometido"?: number | null,
        "municipioAtendido": boolean,
        "municipioAtendidoAtualizadoEm": string | null,
        "regiaoAdministrativa"?: {
            "id"?: string | null,
            "createdAt": string,
            "updatedAt": string,
            "nome"?: string | null
        },
        "createdAt": string,
        "updatedAt": string
    },
    "artigo167aGeradoEm": string | null,
    "artigo167aPeriodo": string | null,
    "artigo167aAnteriorGeradoEm": string | null,
    "artigo167aAnteriorPeriodo": string | null,
    "artigo167aStatus": boolean,
    "artigo167aAtualizadoEm": string | null,
    "artigo167aResultado": number | null,
    "artigo167aAnteriorResultado": number | null,
    "limitePotencial"?: number | null,
    "habilitado": boolean
}

interface interfDadosMunicipioSelecionado {
    municipioSelecionado?: {
        "idIbge": number,
        "nome": string,
    }
}

export const DadosMunicipio = ({
    municipioSelecionado
}: interfDadosMunicipioSelecionado) => {

    const navigate = useNavigate();

    const [dadosMunicipio, setDadosMunicipio] = useState<InterfMunicipio>();
    const [loading, setLoading] = useState(false);

    //setando dados das cidades no selected
    const buscaCidades = useCallback((idIbge: number) => {


        const buscaCiaddes = async () => {
            try {
                setLoading(true);
                let resultCidade = await api.get(urlMunicipioStatus + '/' + idIbge,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        }
                    });

                setDadosMunicipio(resultCidade.data);

                setLoading(false);

            } catch (error) {

                let msgErro: any = (error as Error);
                setLoading(false);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'municipios_dkwdfaiweyi')
                }


            }
        }

        buscaCiaddes();

    }, []);

    useEffect(() => {

        // let arrayUrl = window.location.pathname.split("/");
        // VerificaRota(arrayUrl, navigate);

        if (municipioSelecionado) buscaCidades(municipioSelecionado.idIbge);

    }, [municipioSelecionado]);


    return (
        <>
            <Loading
                loading={loading}
            >
                <div
                    style={{
                        display: 'flex',
                        marginBottom: 5
                    }}
                >

                    <Text strong style={{ marginRight: 5 }} >{`População em ${dadosMunicipio &&
                        dadosMunicipio.municipio?.anoPopulacao ?
                        dadosMunicipio.municipio.anoPopulacao
                        : ''
                        } (SEADE):`}</Text>
                    <Text>{dadosMunicipio && dadosMunicipio.municipio?.populacao ? poeValorComPontosEmMil(dadosMunicipio.municipio.populacao) : ''}</Text>
                </div>
                <div
                    style={{
                        marginBottom: 5
                    }}
                >
                    <Title style={{ margin: 0, padding: 0 }} level={3}>LIMITES</Title>
                </div>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'flex-end',
                        flexWrap: 'wrap',
                        marginBottom: 5
                    }}
                >
                    <div
                        className='div_DadosMunicipio_ljhdviwqadf9'
                    >
                        <CardLimites
                            valor={dadosMunicipio && dadosMunicipio?.limiteLrf !== null &&
                                dadosMunicipio?.limiteLrf !== undefined ?
                                convertMoedaBRDoJSComExtamente2CasasDecimais(dadosMunicipio.limiteLrf) : ''}
                            descricao='Limite LRF'
                            style={{
                                margin: 5,
                            }}
                        />
                    </div>
                    <div className='div_DadosMunicipio_ljhdviwqadf9' >
                        <CardLimites
                            valor={dadosMunicipio && dadosMunicipio?.municipio?.valorComprometido !== null &&
                                dadosMunicipio?.municipio?.valorComprometido !== undefined ?
                                convertMoedaBRDoJSComExtamente2CasasDecimais(dadosMunicipio.municipio.valorComprometido) : ''}
                            descricao='Valor Comprometido DSP'
                            style={{
                                margin: 5
                            }}
                        />
                    </div>
                    <div className='div_DadosMunicipio_ljhdviwqadf9'>
                        <CardLimites
                            valor={dadosMunicipio && dadosMunicipio?.limitePotencial !== null &&
                                dadosMunicipio?.limitePotencial !== undefined ?
                                convertMoedaBRDoJSComExtamente2CasasDecimais(dadosMunicipio.limitePotencial) : ''}
                            descricao='Limite Potencial'
                            style={{
                                margin: 5
                            }}
                        />
                    </div>
                    <div className='div_DadosMunicipio_ljhdviwqadf9'>
                        <CardLimites
                            resultado
                            title={dadosMunicipio && dadosMunicipio?.habilitado ?
                                'Habilitado' : 'Não Habilitado'}
                            descricao='Resultado'
                            corFundo={dadosMunicipio && dadosMunicipio?.habilitado ?
                                '#4AA64E' : '#ef5350'}
                            style={{
                                margin: 5
                            }}
                        />

                    </div>
                </div>
                <div>
                    <TodasInformacoes
                        municipioSelecionado={dadosMunicipio}
                    />
                </div>
            </Loading>
        </>
    );
}
