import React, { useCallback, useEffect, useState, useRef, useContext } from 'react';
import {
    Typography, notification, Button, Table, Row, Col, Divider
} from 'antd';
import { useNavigate } from 'react-router-dom';
import { GlobalContext } from '../../../../contexts/globalContext';
import locale from 'antd/es/locale/pt_BR';
import Icon, {
    AreaChartOutlined
} from '@ant-design/icons';
import { FaDownload, FaCog } from 'react-icons/fa';
import moment from 'moment';

// import { colSelect } from './GridStyle';

// import { getToken } from './../../../../services/LStorage/token';
import { validacaoPermissao, VerificaRota } from './../../../../services/VerificaSePodeAcessar';
import LayoutDashboard from './../../../../layout/LayoutAdmin';
import { urlLimiteOperacoesImportacao, urlLimiteOperacoesImportacaoDownload } from './../../../../services/urls';
import { api } from './../../../../services/apiAxios';
import { EmitirErro } from '../../../../services/EmitirErro';
import { SairDoSistema } from '../../../../services/LStorage/SairSistema';
import { getToken } from './../../../../services/LStorage/token';

import { convertDataUSParaBrasilEHora } from './../../../../services/Conversores';

import { useGetColumnSearchProps } from './../../../../hooks/FilterTable/string';
import { useGetColumnSearchPropsData } from './../../../../hooks/FilterTable/date';

import { Loading } from '../../../../components/Loading';
import { useGetColumnSearchPropsObj } from '../../../../hooks/FilterTable/objString';

const { Text, Title } = Typography;

const IconCogs = (props: any) => (<Icon {...props} component={FaCog} ></Icon>);
const IconDownload = (props: any) => <Icon {...props} component={FaDownload} ></Icon>

// const largura = window.innerWidth;
// const altura = window.innerHeight;

interface interfDadosUpload {
    "id": string,
    "createdAt"?: string | null,
    "updatedAt"?: string | null,
    "arquivo": string | null,
    "nomeArquivo": string | null,
    "usuario": {
        id: string
        nome: string
    }
}
interface DadosCidadesAll {
    "idIbge": number,
    "nome": string,
}

const ArquivosDeImportacao: React.FC = () => {

    const navigate = useNavigate();

    const { theme } = useContext(GlobalContext);

    const refContagemDoFiltro = React.useRef<Array<any>>([]);

    const refDataListCidades = useRef<Array<DadosCidadesAll>>([]);

    const [dadosTabelaState, setDadosTabelaState] = useState<Array<interfDadosUpload>>([]);
    const [loadingPage, setLoadingPage] = useState(false);

    //setando dados das cidades no selected
    const buscaCidades = useCallback(() => {

        const buscaCiaddes = async () => {
            try {
                setLoadingPage(true);
                let result = await api.get(urlLimiteOperacoesImportacao,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        }
                    });

                refDataListCidades.current = result.data
                setDadosTabelaState(result.data);

                setLoadingPage(false);

            } catch (error) {
                let msgErro: any = (error as Error);
                setLoadingPage(false);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'municipios_dkwdfaiweyi')
                }


            }
        }

        buscaCiaddes();

    }, []);

    useEffect(() => {

        let arrayUrl = window.location.pathname.split("/");
        VerificaRota(arrayUrl, navigate, true, validacaoPermissao(['import_files']));

        buscaCidades();


    }, []);

    const clickBtnBaixarXLSX = useCallback((arquivo?: string, nomeArquivo?: string) => {

        const buscaXLSX = async () => {
            try {


                if (arquivo) {
                    let result = await api.get(urlLimiteOperacoesImportacaoDownload + '/' + arquivo,
                        {
                            headers: {
                                'Authorization': 'Bearer ' + getToken()
                            },
                            responseType: 'blob',
                        });

                    const url = window.URL.createObjectURL(new Blob([result.data]));
                    const link = document.createElement('a');
                    link.href = url;

                    link.setAttribute('download', nomeArquivo!);
                    document.body.appendChild(link);
                    link.click();

                }


            } catch (error) {

                let msgErro: any = (error as Error);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'relatorioconsolidado_dkwdfaiweyi')
                }


            }
        }


        buscaXLSX();



    }, []);


    const columns = [
        {
            title: 'Nome Arquivo',
            dataIndex: 'nomeArquivoOriginal',
            ...useGetColumnSearchProps('nomeArquivoOriginal'),
            width: '10%',
            sorter: (a: any, b: any) => a.nomeArquivoOriginal.localeCompare(b.nomeArquivoOriginal),
            showSorterTooltip: false,
        },
        {
            title: 'Usuário',
            dataIndex: 'usuario',
            ...useGetColumnSearchPropsObj('usuario'),
            width: '10%',
            sorter: (a: any, b: any) => a.usuario.nome.localeCompare(b.usuario.nome),
            showSorterTooltip: false,
            render: (usuario: any) => {

                return usuario.nome
            },
        },
        {
            title: 'Criado em',
            dataIndex: 'createdAt',
            ...useGetColumnSearchPropsData('createdAt'),
            width: '2%',
            sorter: (a: any, b: any) => {

                let aMonMen: any = null
                let bMonMen: any = null
                if (a.createdAt) {
                    aMonMen = moment(a.createdAt).unix()
                } else {
                    aMonMen = 0
                }
                if (b.createdAt) {
                    bMonMen = moment(b.createdAt).unix()
                } else {
                    bMonMen = 0
                }

                return aMonMen - bMonMen
            },
            showSorterTooltip: false,
            // sorter: (a: any, b: any) => a.update_at.localeCompare(b.update_at),
            render: (data: any) => {

                return (
                    convertDataUSParaBrasilEHora(data)
                )
            },
        },
        {
            title: '',
            dataIndex: 'arquivo',
            width: '2%',
            showSorterTooltip: false,
            render: (arquivo: any, record: any) => {

                return (
                    <div className='naoElinha'>
                        {
                            arquivo ?
                                <Button
                                    type="primary"
                                    className="botaoNaolinha"
                                    // disabled={!hasSelectedAdd}
                                    onClick={() => { clickBtnBaixarXLSX(arquivo, record.nomeArquivoOriginal) }}
                                >
                                    {'Baixar '}<IconDownload />
                                </Button>
                                :
                                <Text
                                    style={{
                                        fontWeight: 'bold',
                                        color: record.status ? 'red' : 'green'
                                    }}
                                >
                                    {'Não há XLSX!'}
                                </Text>
                        }

                    </div>
                )
            },
        },
    ];

    return (
        <LayoutDashboard>
            <Loading
                loading={loadingPage}
            >
                <Row>
                    <Col span={24}>
                        <Title level={4}>
                            <IconCogs style={{ fontSize: 23, marginRight: 10 }} />
                            {"Arquivos de importação"}
                        </Title>
                        <Divider />
                    </Col>
                </Row>

                <Table
                    style={{
                        // marginTop: 50
                    }}
                    className='marginTopTables'
                    rowKey={(record) => record.id}
                    // loading={loadingTable}
                    // onRow={(record, rowIndex) => {
                    //     return {
                    //         onClick: event => { onclickTable(record, rowIndex) }, // click row
                    //         onDoubleClick: event => { }, // double click row
                    //         onContextMenu: event => { }, // right button click row
                    //         onMouseEnter: event => { }, // mouse enter row
                    //         onMouseLeave: event => { }, // mouse leave row
                    //     };
                    // }}
                    // rowSelection={rowSelection}
                    columns={columns} dataSource={dadosTabelaState}
                    scroll={{ x: 650 }}
                    onChange={
                        (pagination, filters, sorter, extra) => {
                            refContagemDoFiltro.current = extra.currentDataSource
                        }
                    }
                    title={(registros) => {

                        return (
                            <div
                                style={{
                                    backgroundColor: theme === 'light' ? '#FAFAFA' : '#1D1D1D',
                                    padding: 10,
                                    margin: -8,
                                    marginBottom: 0.5
                                }}
                            >
                                <Text>
                                    {/* {`Total: ${dadosTabelaState.length} registros.`} */}
                                    {`Mostrando: ${registros.length} de ${refContagemDoFiltro.current.length === 0 ?
                                        dadosTabelaState.length :
                                        refContagemDoFiltro.current.length} num total de ${dadosTabelaState.length} `}
                                </Text>
                            </div>
                        )
                    }}
                    footer={(registros) => {
                        return (
                            <div
                                style={{
                                    textAlign: 'end'
                                }}
                            >
                                <Text>
                                    {/* {`Total: ${dadosTabelaState.length} registros.`} */}
                                    {`Mostrando: ${registros.length} de ${refContagemDoFiltro.current.length === 0 ?
                                        dadosTabelaState.length :
                                        refContagemDoFiltro.current.length} num total de ${dadosTabelaState.length} `}
                                </Text>
                            </div>
                        )
                    }}
                    size="small"
                    pagination={{
                        locale: { items_per_page: '' },
                        showSizeChanger: true,
                        defaultPageSize: 100,
                        position: ['topRight', 'bottomRight'],
                        // position: [],
                        pageSizeOptions: ['10', '20', '50', '100']
                    }}
                    locale={locale.Table}
                />
            </Loading>
        </LayoutDashboard >
    );
}

export default ArquivosDeImportacao;
