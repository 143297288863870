export const colModalChecks = {
    xs: { span: 24, offset: 0 }, //<576
    sm: { span: 24, offset: 0 }, //>576
    md: { span: 12, offset: 0 }, // >768
    lg: { span: 12, offset: 0 }, // > 992
    xl: { span: 12, offset: 0 }, // > 1200
    xxl: { span: 12, offset: 0 }, // > 1600
};

export const colInputsData = {
    xs: { span: 24, offset: 0 }, //<576
    sm: { span: 24, offset: 0 }, //>576
    md: { span: 12, offset: 0 }, // >768
    lg: { span: 6, offset: 0 }, // > 992
    xl: { span: 6, offset: 0 }, // > 1200
    xxl: { span: 6, offset: 0 }, // > 1600
};
