export const colSelect = {
    xs: { span: 24, offset: 0 }, //<576
    sm: { span: 24, offset: 0 }, //>576
    md: { span: 24, offset: 0 }, // >768
    lg: { span: 20, offset: 0 }, // > 992
    xl: { span: 16, offset: 0 }, // > 1200
    xxl: { span: 16, offset: 0 }, // > 1600
};


export const colGerarPDFButton = {
    xs: { span: 24, offset: 0 }, //<576
    sm: { span: 24, offset: 0 }, //>576
    md: { span: 24, offset: 0 }, // >768
    lg: { span: 4, offset: 0 }, // > 992
    xl: { span: 8, offset: 0 }, // > 1200
    xxl: { span: 8, offset: 0 }, // > 1600
};
