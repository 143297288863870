import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Modal, Tabs, Typography, notification, Button, Table, Upload, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import locale from 'antd/es/locale/pt_BR';
import Icon, {
    CloudUploadOutlined,
    LoadingOutlined,
    PlusOutlined,
    InboxOutlined,
    CloseOutlined
} from '@ant-design/icons';
import { FaDownload } from 'react-icons/fa';
import moment from 'moment';

// import { colSelect } from './GridStyle';

// import { getToken } from './../../services/LStorage/token';
import { validacaoPermissao, VerificaRota } from './../../services/VerificaSePodeAcessar';
import LayoutDashboard from './../../layout/LayoutAdmin';
import { urlMunicipioArquivoUpload, urlMunicipioArquivoUploadSaneamento, urlMunicipioArquivoUploadSeade, urlMunicipioMunicipios } from './../../services/urls';
import { api } from './../../services/apiAxios';
import { EmitirErro } from '../../services/EmitirErro';
import { SairDoSistema } from '../../services/LStorage/SairSistema';
import { getToken } from './../../services/LStorage/token';

import { convertDataUSParaBrasil, convertDataUSParaBrasilEHora } from './../../services/Conversores';

import { useGetColumnSearchProps } from './../../hooks/FilterTable/string';
import { useGetColumnSearchPropsData } from './../../hooks/FilterTable/date';

import {
    removeAcento
} from './../../utils/RemoveAcentos';
import { RcFile } from 'antd/lib/upload';
import { UploadChangeParam } from 'antd/es/upload/interface';
import { UploadFile } from 'antd/lib/upload/interface';
import { Loading } from '../../components/Loading';
import { useGetColumnSearchPropsObj } from '../../hooks/FilterTable/objString';

const { TabPane } = Tabs;
const { Text } = Typography;

const IconDownload = (props: any) => <Icon {...props} component={FaDownload} ></Icon>

// const largura = window.innerWidth;
// const altura = window.innerHeight;

interface interfDadosUpload {
    "id": string,
    "createdAt"?: string | null,
    "updatedAt"?: string | null,
    "arquivo": string | null,
    "nomeArquivo": string | null,
    "tipo": string | null,
    "usuario": {
        id: string
        nome: string
    }
}
interface DadosCidadesAll {
    "idIbge": number,
    "nome": string,
}

const UploadArquivos: React.FC = () => {

    const navigate = useNavigate();

    const refContagemDoFiltro = React.useRef<Array<any>>([]);

    const refDataListCidades = useRef<Array<DadosCidadesAll>>([]);

    const [dadosTabelaState, setDadosTabelaState] = useState<Array<interfDadosUpload>>([]);
    const [visibleModal, setVisibleModal] = useState<boolean>(false);
    // const [imageUrl, setImageUrl] = useState<any>(null);
    const [arquivoObjeto, setArquivoObjeto] = useState<any>(null);
    const [arquivoObjetoSeade, setArquivoObjetoSeade] = useState<any>(null);
    const [arquivoObjetoSaneamento, setArquivoObjetoSaneamento] = useState<any>(null);
    const [activeKeyTabs, setActiveKeyTabs] = useState<any>('1vlrComprometido');
    const [loading, setLoading] = useState(false);
    const [loadingPage, setLoadingPage] = useState(false);

    //setando dados das cidades no selected
    const buscaCidades = useCallback(() => {

        const buscaCiaddes = async () => {
            try {
                setLoadingPage(true);
                let result = await api.get(urlMunicipioArquivoUpload,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        }
                    });

                refDataListCidades.current = result.data
                setDadosTabelaState(result.data);

                setLoadingPage(false);

            } catch (error) {
                let msgErro: any = (error as Error);
                setLoadingPage(false);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'municipios_dkwdfaiweyi')
                }


            }
        }

        buscaCiaddes();

    }, []);

    useEffect(() => {

        let arrayUrl = window.location.pathname.split("/");
        VerificaRota(arrayUrl, navigate, true, validacaoPermissao(['import_files']));

        buscaCidades();


    }, []);

    const beforeUpload = useCallback((file: RcFile) => {
        const isJpgOrPng = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        if (!isJpgOrPng) {
            message.error('Você só pode fazer upload de arquivos XLSX!');
        }

        return isJpgOrPng;
    }, []);

    const beforeUploadCSV = useCallback((file: RcFile) => {

        const isArquiveCSV = (file.type === '.csv' || file.type === 'text/csv');
        if (!isArquiveCSV) {
            message.error('Você só pode fazer upload de arquivos CSV!');
        }

        return isArquiveCSV;
    }, []);

    const handleChange = useCallback((info: UploadChangeParam<UploadFile<any>>) => {

        if (info?.file?.status === 'done') {
            setArquivoObjeto(info)
        }


    }, [arquivoObjeto]);

    const handleChangeArquiveSeade = useCallback((info: UploadChangeParam<UploadFile<any>>) => {

        if (info?.file?.status === 'done') {
            setArquivoObjetoSeade(info)
        }


    }, [arquivoObjetoSeade]);

    const handleChangeArquivoSaneamento = useCallback((info: UploadChangeParam<UploadFile<any>>) => {

        if (info?.file?.status === 'done') {
            setArquivoObjetoSaneamento(info)
        }


    }, [arquivoObjetoSaneamento]);

    const clickBtnBaixarXLSX = useCallback((arquivo?: string, nomeArquivo?: string) => {

        const buscaXLSX = async () => {
            try {


                if (arquivo) {
                    let result = await api.get(urlMunicipioArquivoUpload + '/' + arquivo,
                        {
                            headers: {
                                'Authorization': 'Bearer ' + getToken()
                            },
                            responseType: 'blob',
                        });

                    const url = window.URL.createObjectURL(new Blob([result.data]));
                    const link = document.createElement('a');
                    link.href = url;

                    link.setAttribute('download', nomeArquivo!);
                    document.body.appendChild(link);
                    link.click();

                }


            } catch (error) {

                let msgErro: any = (error as Error);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'relatorioconsolidado_dkwdfaiweyi')
                }


            }
        }


        buscaXLSX();



    }, []);

    const clickCancelModal = useCallback(() => {
        setArquivoObjeto(null);
        setArquivoObjetoSeade(null);
        setArquivoObjetoSaneamento(null);
        setVisibleModal(false);
    }, []);

    const dummyRequest = useCallback(({ file, onSuccess }: any) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    }, []);

    const clickBtnSubmitUploadArquivo = useCallback((arquivo: any) => {

        const buscaXLSX = async () => {
            try {

                setLoading(true)
                const formData = new FormData();

                formData.append("arquivo", arquivo.file.originFileObj);

                await api.post(urlMunicipioArquivoUpload, formData,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken(),
                            "Content-Type": "multipart/form-data",
                        }
                    });

                notification.success({
                    message: 'Sucesso',
                    description: "Arquivo enviado com sucesso!",
                });

                clickCancelModal();
                buscaCidades();
                setLoading(false)

            } catch (error) {
                setLoading(false)
                let msgErro: any = (error as Error);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'relatorioconsolidado_dkwdfaiweyi')
                }


            }
        }


        buscaXLSX();

    }, []);

    const clickBtnSubmitUploadArquivoSeade = useCallback((arquivo: any) => {

        const buscaXLSX = async () => {
            try {

                setLoading(true)
                const formData = new FormData();

                formData.append("arquivo", arquivo.file.originFileObj);

                await api.post(urlMunicipioArquivoUploadSeade, formData,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken(),
                            "Content-Type": "multipart/form-data",
                        }
                    });

                notification.success({
                    message: 'Sucesso',
                    description: "Arquivo enviado com sucesso!",
                });

                clickCancelModal();
                buscaCidades();
                setLoading(false)

            } catch (error) {
                setLoading(false)
                let msgErro: any = (error as Error);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'relatorioconsolidado_dkwdfaiweyi')
                }


            }
        }


        buscaXLSX();

    }, []);

    const clickBtnSubmitUploadArquivoSaneamento = useCallback((arquivo: any) => {

        const buscaXLSX = async () => {
            try {

                setLoading(true)
                const formData = new FormData();

                formData.append("arquivo", arquivo.file.originFileObj);

                await api.post(urlMunicipioArquivoUploadSaneamento, formData,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken(),
                            "Content-Type": "multipart/form-data",
                        }
                    });

                notification.success({
                    message: 'Sucesso',
                    description: "Arquivo enviado com sucesso!",
                });

                clickCancelModal();
                buscaCidades();
                setLoading(false)

            } catch (error) {
                setLoading(false)
                let msgErro: any = (error as Error);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'relatorioconsolidado_dkwdfaiweyi')
                }


            }
        }


        buscaXLSX();

    }, []);


    const columns = [
        {
            title: 'Nome Arquivo',
            dataIndex: 'nomeArquivo',
            ...useGetColumnSearchProps('nomeArquivo'),
            width: '10%',
            sorter: (a: any, b: any) => a.nomeArquivo.localeCompare(b.nomeArquivo),
            showSorterTooltip: false,
        },
        {
            title: 'Tipo de Arquivo',
            dataIndex: 'tipo',
            ...useGetColumnSearchProps('tipo'),
            width: '10%',
            sorter: (a: any, b: any) => a.tipo.localeCompare(b.tipo),
            showSorterTooltip: false,
        },
        {
            title: 'Usuário',
            dataIndex: 'usuario',
            ...useGetColumnSearchPropsObj('usuario'),
            width: '10%',
            sorter: (a: any, b: any) => a.usuario.nome.localeCompare(b.usuario.nome),
            showSorterTooltip: false,
            render: (usuario: any) => {

                return usuario.nome
            },
        },
        // {
        //     title: 'Validade',
        //     dataIndex: 'dataValidade',
        //     ...useGetColumnSearchPropsData('dataValidade'),
        //     width: '2%',
        //     sorter: (a: any, b: any) => {

        //         let aMonMen: any = null
        //         let bMonMen: any = null
        //         if (a.dataValidade) {
        //             aMonMen = moment(a.dataValidade).unix()
        //         } else {
        //             aMonMen = 0
        //         }
        //         if (b.dataValidade) {
        //             bMonMen = moment(b.dataValidade).unix()
        //         } else {
        //             bMonMen = 0
        //         }

        //         return aMonMen - bMonMen
        //     },
        //     showSorterTooltip: false,
        //     // sorter: (a: any, b: any) => a.dataValidade.localeCompare(b.dataValidade),
        //     render: (criado: any, record: any) => {
        //         return (
        //             <div
        //                 style={{
        //                     display: 'flex',
        //                     justifyContent: 'space-between',
        //                     alignItems: 'center'
        //                 }}
        //             >

        //                 <Text>
        //                     {
        //                         criado ? convertDataUSParaBrasil(criado) : '-'
        //                     }
        //                 </Text>

        //                 {/* <img style={{
        //                     width: 20,
        //                     marginLeft: 5
        //                 }} src={record.status ? ImgDownLike : ImgUpLike} /> */}
        //             </div>
        //         )
        //     },
        // },
        {
            title: 'Criado em',
            dataIndex: 'createdAt',
            ...useGetColumnSearchPropsData('createdAt'),
            width: '2%',
            sorter: (a: any, b: any) => {

                let aMonMen: any = null
                let bMonMen: any = null
                if (a.createdAt) {
                    aMonMen = moment(a.createdAt).unix()
                } else {
                    aMonMen = 0
                }
                if (b.createdAt) {
                    bMonMen = moment(b.createdAt).unix()
                } else {
                    bMonMen = 0
                }

                return aMonMen - bMonMen
            },
            showSorterTooltip: false,
            // sorter: (a: any, b: any) => a.update_at.localeCompare(b.update_at),
            render: (data: any) => {

                return (
                    convertDataUSParaBrasilEHora(data)
                )
            },
        },
        {
            title: '',
            dataIndex: 'arquivo',
            width: '2%',
            showSorterTooltip: false,
            render: (arquivo: any, record: any) => {
                return (
                    <div className='naoElinha'>
                        {
                            arquivo ?
                                <Button
                                    type="primary"
                                    className="botaoNaolinha"
                                    // disabled={!hasSelectedAdd}
                                    onClick={() => { clickBtnBaixarXLSX(arquivo, record.nomeArquivo) }}
                                >
                                    {'Baixar '}<IconDownload />
                                </Button>
                                :
                                <Text
                                    style={{
                                        fontWeight: 'bold',
                                        color: record.status ? 'red' : 'green'
                                    }}
                                >
                                    {'Não há XLSX!'}
                                </Text>
                        }

                    </div>
                )
            },
        },
    ];

    return (
        <LayoutDashboard>
            <Loading
                loading={loadingPage}
            >
                <div
                    style={{
                        display: 'flex',
                        // justifyContent: 'flex-end',
                        // justifyContent: 'flex-end'
                        marginBottom: 15
                    }}
                >
                    <Button
                        type="primary"
                        className="botaoVerdeBaixarTodas"
                        style={{ margin: 2 }}
                        // disabled={!hasSelectedAdd}
                        onClick={() => { setVisibleModal(true) }}
                    >
                        Upload Arquivo<CloudUploadOutlined />
                    </Button>
                </div>
                <Table
                    style={{
                        // marginTop: 50
                    }}
                    className='marginTopTables'
                    rowKey={(record) => record.id}
                    // loading={loadingTable}
                    // onRow={(record, rowIndex) => {
                    //     return {
                    //         onClick: event => { onclickTable(record, rowIndex) }, // click row
                    //         onDoubleClick: event => { }, // double click row
                    //         onContextMenu: event => { }, // right button click row
                    //         onMouseEnter: event => { }, // mouse enter row
                    //         onMouseLeave: event => { }, // mouse leave row
                    //     };
                    // }}
                    // rowSelection={rowSelection}
                    columns={columns} dataSource={dadosTabelaState}
                    scroll={{ x: 650 }}
                    onChange={
                        (pagination, filters, sorter, extra) => {
                            refContagemDoFiltro.current = extra.currentDataSource
                        }
                    }
                    // title={(registros) => {

                    //     return (
                    //         <div
                    //             style={{
                    //                 backgroundColor: theme === 'light' ? '#FAFAFA' : '#1D1D1D',
                    //                 padding: 10,
                    //                 margin: -8,
                    //                 marginBottom: 0.5
                    //             }}
                    //         >
                    //             <Text>
                    //                 {/* {`Total: ${dadosTabelaState.length} registros.`} */}
                    //                 {`Mostrando: ${registros.length} de ${refContagemDoFiltro.current.length === 0 ?
                    //                     dadosTabelaState.length :
                    //                     refContagemDoFiltro.current.length} num total de ${dadosTabelaState.length} `}
                    //             </Text>
                    //         </div>
                    //     )
                    // }}
                    // footer={(registros) => {
                    //     return (
                    //         <div
                    //             style={{
                    //                 textAlign: 'end'
                    //             }}
                    //         >
                    //             <Text>
                    //                 {/* {`Total: ${dadosTabelaState.length} registros.`} */}
                    //                 {`Mostrando: ${registros.length} de ${refContagemDoFiltro.current.length === 0 ?
                    //                     dadosTabelaState.length :
                    //                     refContagemDoFiltro.current.length} num total de ${dadosTabelaState.length} `}
                    //             </Text>
                    //         </div>
                    //     )
                    // }}
                    size="small"
                    pagination={{
                        locale: { items_per_page: '' },
                        showSizeChanger: false,
                        defaultPageSize: 100,
                        // position: ['topRight', 'bottomRight'],
                        position: [],
                        pageSizeOptions: ['10', '20', '50', '100']
                    }}
                    locale={locale.Table}
                />
            </Loading>
            <Modal
                title="Upload Arquivo"
                visible={visibleModal}
                onOk={() => clickBtnSubmitUploadArquivo(arquivoObjeto)}
                onCancel={() => clickCancelModal()}
                style={{
                    top: 20
                }}
                // className="modalDadasLimite_kaevdkfaed"
                footer={[
                    <Button
                        key="back"
                        onClick={clickCancelModal}
                        loading={loading}
                    >
                        Cancelar
                    </Button>,
                    activeKeyTabs === '1vlrComprometido' ?
                        <Button
                            key="submit"
                            type="primary"
                            loading={loading}
                            onClick={() => {
                                clickBtnSubmitUploadArquivo(arquivoObjeto)
                            }}
                            disabled={!arquivoObjeto}
                        >
                            Salvar
                        </Button>
                        :
                        activeKeyTabs === '2Seade' ?
                            <Button
                                key="submit"
                                type="primary"
                                loading={loading}
                                onClick={() => {
                                    clickBtnSubmitUploadArquivoSeade(arquivoObjetoSeade)
                                }}
                                disabled={!arquivoObjetoSeade}
                            >
                                Salvar
                            </Button>
                            :
                            activeKeyTabs === '3Saneamento' ?
                                <Button
                                    key="submit"
                                    type="primary"
                                    loading={loading}
                                    onClick={() => {
                                        clickBtnSubmitUploadArquivoSaneamento(arquivoObjetoSaneamento)
                                    }}
                                    disabled={!arquivoObjetoSaneamento}
                                >
                                    Salvar
                                </Button>
                                :
                                <></>
                ]}
                key="modalUploadArquivos"
            >
                <Loading
                    loading={loading}
                >
                    <Tabs
                        type="card"
                        // defaultActiveKey="1"
                        activeKey={activeKeyTabs}
                        style={{
                            marginBottom: 20,
                        }}
                        onChange={(e) => {
                            if (arquivoObjeto || arquivoObjetoSeade) {
                                notification.warning({
                                    message: 'Atenção',
                                    description:
                                        'Não é possivel trocar abas com arquivos pre carregados!',
                                });
                            } else {
                                setActiveKeyTabs(e)
                            }
                        }}
                        className='tab-municipios'
                    >
                        <TabPane
                            tab="Valor comprometido"
                            key="1vlrComprometido"
                        >
                            <Upload.Dragger
                                // name="file"
                                // listType="picture-card"

                                // className="avatar-uploader"
                                style={{
                                    // width: 400,
                                    // height: 128
                                }}
                                // multiple={true}
                                // fileList={arquivoObjeto}
                                showUploadList={false}

                                customRequest={dummyRequest}
                                // action={() => {
                                //     return new Promise((resolve, reject) => {
                                //         reject('done');
                                //     })
                                // }}
                                beforeUpload={beforeUpload}
                                onChange={handleChange}
                            // onPreview={() => {}}
                            // onRemove={(file) => {console.log(file); return (true)}}
                            >
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text" style={{ color: 'red' }}>Envio somente de arquivos .XLSX</p>
                                <p className="ant-upload-text">Clique ou arraste o arquivo para esta área para fazer upload</p>
                            </Upload.Dragger>
                            {arquivoObjeto &&
                                <div style={{
                                    marginTop: 8,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between'
                                }}>
                                    <Text>
                                        {arquivoObjeto?.file?.name}
                                    </Text>
                                    <Button
                                        type='text'
                                        danger
                                        style={{ margin: 0, padding: 0 }}
                                        icon={<CloseOutlined />}
                                        onClick={() => {
                                            setArquivoObjeto(null)
                                        }}
                                    />
                                </div>
                            }
                        </TabPane>
                        <TabPane
                            tab="População SEADE"
                            key="2Seade"
                        >
                            <Upload.Dragger
                                // name="file"
                                // listType="picture-card"

                                // className="avatar-uploader"
                                style={{
                                    // width: 400,
                                    // height: 128
                                }}
                                // multiple={true}
                                // fileList={arquivoObjeto}
                                showUploadList={false}

                                customRequest={dummyRequest}
                                // action={() => {
                                //     return new Promise((resolve, reject) => {
                                //         reject('done');
                                //     })
                                // }}
                                beforeUpload={beforeUploadCSV}
                                onChange={handleChangeArquiveSeade}
                            // onPreview={() => {}}
                            // onRemove={(file) => {console.log(file); return (true)}}
                            >
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text" style={{ color: 'red' }}>Envio somente de arquivos .CSV</p>
                                <p className="ant-upload-text">Clique ou arraste o arquivo para esta área para fazer upload</p>
                            </Upload.Dragger>
                            {arquivoObjetoSeade &&
                                <div style={{
                                    marginTop: 8,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between'
                                }}>
                                    <Text>
                                        {arquivoObjetoSeade?.file?.name}
                                    </Text>
                                    <Button
                                        type='text'
                                        danger
                                        style={{ margin: 0, padding: 0 }}
                                        icon={<CloseOutlined />}
                                        onClick={() => {
                                            setArquivoObjetoSeade(null)
                                        }}
                                    />
                                </div>
                            }
                        </TabPane>

                        <TabPane
                            tab="Saneamento"
                            key="3Saneamento"
                        >
                            <Upload.Dragger
                                // name="file"
                                // listType="picture-card"

                                // className="avatar-uploader"
                                style={{
                                    // width: 400,
                                    // height: 128
                                }}
                                // multiple={true}
                                // fileList={arquivoObjeto}
                                showUploadList={false}

                                customRequest={dummyRequest}
                                // action={() => {
                                //     return new Promise((resolve, reject) => {
                                //         reject('done');
                                //     })
                                // }}
                                beforeUpload={beforeUploadCSV}
                                onChange={handleChangeArquivoSaneamento}
                            // onPreview={() => {}}
                            // onRemove={(file) => {console.log(file); return (true)}}
                            >
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text" style={{ color: 'red' }}>Envio somente de arquivos .CSV</p>
                                <p className="ant-upload-text">Clique ou arraste o arquivo para esta área para fazer upload</p>
                            </Upload.Dragger>
                            {arquivoObjetoSaneamento &&
                                <div style={{
                                    marginTop: 8,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between'
                                }}>
                                    <Text>
                                        {arquivoObjetoSaneamento?.file?.name}
                                    </Text>
                                    <Button
                                        type='text'
                                        danger
                                        style={{ margin: 0, padding: 0 }}
                                        icon={<CloseOutlined />}
                                        onClick={() => {
                                            setArquivoObjetoSaneamento(null)
                                        }}
                                    />
                                </div>
                            }
                        </TabPane>

                    </Tabs>
                </Loading>
            </Modal>
        </LayoutDashboard >
    );
}

export default UploadArquivos;
