import React from 'react';
// import Route from './Route';
import TodasRotas from './TodasRotas';
// import * as GlobalActions from '../store/modules/global/actions';


// import { ThemeProvider } from 'styled-components';
// import { useDispatch, useSelector } from 'react-redux';

// import GlobalTheme from "./../globalscss";

// import SignIn from '../pages/Signin';//primeira pagina
// import SignUp from '../pages/Signup';

// import Dashboard from '../pages/Dashboard';
// import Profile from '../pages/Profile';//page perfil

const LightTheme = React.lazy(() => import('./../css/Light'));
const DarkTheme = React.lazy(() => import('./../css/Dark'));

//STEP 2:
//create a parent component that will load the components conditionally using React.Suspense
const ThemeSelector = ({ children }: any) => {
    // const dispatch = useDispatch();

    // useEffect(() => {
    //     const localTheme = localStorage.getItem("@DesenvolveSP:theme");
    //     // localTheme && dispatch(GlobalActions.updateTheme(localTheme));
    // }, []);

    let dataLSTheme: string | null = localStorage.getItem('@DesenvolveSP:theme');
    let localUserData: any = null;
    if (typeof dataLSTheme == 'string') {
        localUserData = JSON.parse(dataLSTheme);
    }

    const CHOSEN_THEME = localUserData || "light";

    return (
        <>
            <React.Suspense fallback={<></>}>
                {(CHOSEN_THEME === "light") && <LightTheme />}
                {(CHOSEN_THEME === "dark") && <DarkTheme />}
            </React.Suspense>
            {children}
        </>
    )
}

const Routes: React.FC = () => {

    return (
        <>
            <ThemeSelector>
                <TodasRotas />
            </ThemeSelector>
        </>
    )
}

export default Routes;
