import React, { useCallback, useEffect, useState, useRef, useContext } from 'react';
import { Typography, notification, Button, Table, Col, Modal, Card, Row } from 'antd';
import { Chart } from '@antv/g2';
import { useNavigate } from 'react-router-dom';
import locale from 'antd/es/locale/pt_BR';
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import ptLocale from "date-fns/locale/pt-BR";
import DateFnsUtils from '@date-io/date-fns';
import { useVT } from 'virtualizedtableforantd4';
// import moment from 'moment';


// import { getToken } from './../../services/LStorage/token';
import { VerificaRota } from './../../../services/VerificaSePodeAcessar';
import LayoutDashboard from './../../../layout/LayoutAdmin';
import { urlLimiteOperacoesRelatorio } from './../../../services/urls';
import { api } from './../../../services/apiAxios';
import { EmitirErro } from '../../../services/EmitirErro';
import { SairDoSistema } from '../../../services/LStorage/SairSistema';
import { getToken } from './../../../services/LStorage/token';

import { abreviarNumero, convertDataUSParaBrasil, convertDataUSParaBrasilEHoraSegundos, convertMoedaBRDoJSComExtamente2CasasDecimais } from './../../../services/Conversores';

import { Loading } from '../../../components/Loading';
import { GlobalContext } from '../../../contexts/globalContext';
import { addMonths, isAfter, isBefore, isEqual } from 'date-fns';
import { convertDataUSParaBRReturnMesEAnoEscrito, convertDataUSParaBRReturnMesEAno } from './components/Conversores';

import './style.css';
import { colInputsData } from './GridStyle';
import { DividerStyled } from '../../../components/DividerStyled';
import { FormModal } from './components/FormModal';
const { Text } = Typography;


const CronogramaProjecaoDeLimites: React.FC = () => {

    // const { innerWidth: widthScreen } = window;

    const navigate = useNavigate();

    const dataRequisicao = useRef<any>(null);

    const refNewGraficoScreen = useRef<any>();
    const refDestaqueDoFiltro = useRef<string>('todos');
    const refGraficoNaTela = useRef<boolean>(false);
    const refColunasDinamic = useRef<Array<string>>([]);
    const refDataTodosTotais = useRef<any>();
    const refLimite75PorcentoColor = useRef<any>();
    const refDataTotaisDestaqueAndCadip = useRef<any>();
    const refDadosGrafico = useRef<Array<{
        month: string,
        nomeP: string,
        valor?: any
    }>>([]);


    const refValueArquivosSelect = useRef<any>(null);
    const refContagemDoFiltro = React.useRef<Array<any>>([]);

    const { theme } = useContext(GlobalContext);

    const [dadosTabelaState, setDadosTabelaState] = useState<Array<any>>([]);
    const [columnsState, setColumnsState] = useState<Array<any>>([]);
    const [loadingPage, setLoadingPage] = useState(false);
    const [loadingModalFiltros, setLoadingModalFiltros] = useState(false);
    const [graficoOrTable, setGraficoOrTable] = useState(false);
    const [graficoOrTableUPDATE, setGraficoOrTableUPDATE] = useState(true);

    const [dateInicioSeachGrafi, setDateInicioSeachGrafi] = useState<any>(null);
    const [dateFimSeachGrafi, setDateFimSeachGrafi] = useState<any>(null);


    const [visibleModalFiltros, setVisibleModalFiltros] = useState<boolean>(false);
    const [handleSubmitModal, setHandleSubmitModal] = useState<any>(null);

    const [valueArquivoSelect, setValueArquivoSelect] = useState<string>();
    // const [arquivos, setArquivos] = useState<Array<{ id: string, nomeArquivoOriginal: string, createdAt: string }>>([]);

    const [vt, set_components] = useVT(() => ({ scroll: { y: 300 } }), []);

    // pesquisa "reset filter table antd"
    // Reset filters table example
    // https://github.com/ant-design/ant-design/blob/master/components/table/demo/reset-filter.md
    // or ou
    // https://programming.vip/docs/antd-how-to-clear-the-filter-items-after-the-table-component-data-is-re-rendered.html
    const colunasDinamicas: any = [
        {
            title: 'Município',
            key: 'municipio',
            dataIndex: 'municipio',
            // ...useGetColumnSearchProps('municipio'),
            // ...useGetColumnSearchPropsObj('municipio'),
            width: 150,
            fixed: 'left',
            // width: '6%',
            // sorter: (a: any, b: any) => a.municipio.nome.localeCompare(b.municipio.nome),
            showSorterTooltip: false,
            render: (valor: any, record: any) => {
                return (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                    >
                        <Text>
                            {
                                valor ? valor : '-'
                            }
                        </Text>
                    </div>
                )
            },
        },
        {
            title: 'Data Contrato',
            key: 'data_contrato',
            dataIndex: 'data_contrato',
            width: 100,
            // width: '2%',
            showSorterTooltip: false,
            // sorter: (a: any, b: any) => a.dataValidade.localeCompare(b.dataValidade),
            render: (data: any, record: any) => {
                return (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                    >
                        <Text>
                            {
                                data ? convertDataUSParaBrasil(data) : '-'
                            }
                        </Text>
                    </div>
                )
            },
        },
        {
            title: 'Posição',
            key: 'posicao',
            dataIndex: 'posicao',
            // ...useGetColumnSearchProps('municipio'),
            // ...useGetColumnSearchPropsObj('municipio'),
            width: 100,
            // width: '6%',
            // sorter: (a: any, b: any) => a.municipio.nome.localeCompare(b.municipio.nome),
            showSorterTooltip: false,
            render: (valor: any, record: any) => {
                return (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                    >
                        <Text>
                            {
                                valor ? valor : '-'
                            }
                        </Text>
                    </div>
                )
            },
        },
        {
            title: 'Status',
            key: 'status',
            dataIndex: 'status',
            width: 120,
            showSorterTooltip: false,
            // sorter: (a: any, b: any) => a.dataValidade.localeCompare(b.dataValidade),
            render: (valor: any, record: any) => {
                return (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                    >
                        <Text>
                            {
                                valor ? valor : '-'
                            }
                        </Text>
                    </div>
                )
            },
        },
        {
            title: 'Objeto',
            key: 'objeto',
            dataIndex: 'objeto',
            width: 200,
            showSorterTooltip: false,
            // sorter: (a: any, b: any) => a.dataValidade.localeCompare(b.dataValidade),
            render: (valor: any, record: any) => {
                return (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                    >
                        <Text>
                            {
                                valor ? valor : '-'
                            }
                        </Text>
                    </div>
                )
            },
        },
        {
            title: 'Produto',
            key: 'produto',
            dataIndex: 'produto',
            width: 100,
            showSorterTooltip: false,
            // sorter: (a: any, b: any) => a.dataValidade.localeCompare(b.dataValidade),
            render: (valor: any, record: any) => {
                return (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                    >
                        <Text>
                            {
                                valor ? valor : '-'
                            }
                        </Text>
                    </div>
                )
            },
        },
        {
            title: 'Valor a liberar Cronograma',
            key: 'valor_a_liberar_cronograma',
            dataIndex: 'valor_a_liberar_cronograma',
            width: 200,
            showSorterTooltip: false,
            // sorter: (a: any, b: any) => a.dataValidade.localeCompare(b.dataValidade),
            render: (valor: any, record: any) => {
                return (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                    >
                        <Text>
                            {
                                valor ? 'R$ ' + convertMoedaBRDoJSComExtamente2CasasDecimais(valor, true) : '-'
                            }
                        </Text>
                    </div>
                )
            },
        },
        {
            title: 'Destaque',
            key: 'destaque',
            dataIndex: 'destaque',
            width: 100,
            showSorterTooltip: false,
            // sorter: (a: any, b: any) => a.dataValidade.localeCompare(b.dataValidade),
            render: (valor: any) => {
                return (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                    >
                        <Text>
                            {
                                valor ? valor : '-'
                            }
                        </Text>
                    </div>
                )
            },
        }
    ]

    //setando dados das cidades no selected
    const buscaDadosESetDadosNaTabela = useCallback((
        isFiltroGrafico = false,
        dataOtherReq: any = false,
        destaqueFiltro = 'todos'
    ) => {

        const buscaCiaddes = async () => {
            try {
                setLoadingPage(true);

                let result: { data: any } = { data: null }

                if (!isFiltroGrafico) {
                    if (dataOtherReq && typeof dataOtherReq === 'object') {
                        result = dataOtherReq
                    } else {

                        // se estiver iniciando fazer requisição
                        result = await api.post(urlLimiteOperacoesRelatorio, {},
                            {
                                headers: {
                                    'Authorization': 'Bearer ' + getToken()
                                }
                            });
                    }

                    result.data.operacoes.forEach((element: any, index: number) => {
                        result.data.operacoes[index] = {
                            ...result.data.operacoes[index],
                            ...result.data.operacoes[index].meses
                        }
                    });

                    dataRequisicao.current = result.data
                }

                if (isFiltroGrafico && dataRequisicao.current != null) {

                    if (
                        dateInicioSeachGrafi != null &&
                        dateFimSeachGrafi != null
                    ) {
                        const dataFiltroInicioComper = new Date(`${dateInicioSeachGrafi.getFullYear()}-${(dateInicioSeachGrafi.getMonth() + 1)}`)
                        const dataFiltroFimComper = new Date(`${dateFimSeachGrafi.getFullYear()}-${(dateFimSeachGrafi.getMonth() + 1)}`)
                        if (
                            isEqual(dataFiltroInicioComper, dataFiltroFimComper) ||
                            isAfter(dataFiltroInicioComper, dataFiltroFimComper)
                        ) {
                            setLoadingPage(false);
                            notification.error({
                                message: 'Erro',
                                description:
                                    'Data de inicio não pode ser maior do que a de fim!',
                            });
                            return
                        }
                    }
                    result = { data: dataRequisicao.current }
                }

                // const posicaoFinal = (requisicaoCronograma.data.length)

                // const objReqAnoEMesInicio = requisicaoCronograma.data[0]
                // const objReqAnoEMesFinal = requisicaoCronograma.data[posicaoFinal - 1]

                const dataAgora = new Date()
                const dataNow = new Date(`${result.data.primeiro_ano}-${(result.data.primeiro_mes + 1)}-${dataAgora.getDate()}`)
                // const dataNow = new Date(`${objReqAnoEMesInicio.ano}-${objReqAnoEMesInicio.mes}-${dataAgora.getDate()}`)
                // const dataNext = new Date(`${objReqAnoEMesFinal.ano}-${objReqAnoEMesFinal.mes}-${dataNow.getDate()}`)

                const diferencaMonth = result.data.limite
                // const diferencaMonth = differenceInCalendarMonths(dataNext, dataNow)

                let objColunasTabela: Array<any> = [...colunasDinamicas];
                let arrayKeys: Array<string> = [];

                let graficoArrayMonth: Array<{
                    month: string,
                }> = []


                // { month: 'Jan/23', nomeP: 'Tokyo', valor: 7 }

                for (let index = 0; index <= diferencaMonth; index++) {

                    let dataAtual = addMonths(dataNow, index)

                    //para filtro do grafico

                    if (isFiltroGrafico) {

                        let podeDarPushInGraficoInicio = false
                        let podeDarPushInGraficoFim = false

                        const dataAtuComper = new Date(`${dataAtual.getFullYear()}-${(dataAtual.getMonth() + 1)}`)
                        if (dateInicioSeachGrafi != null) {
                            const dataFiltroInicioComper = new Date(`${dateInicioSeachGrafi.getFullYear()}-${(dateInicioSeachGrafi.getMonth() + 1)}`)

                            if (isEqual(dataAtuComper, dataFiltroInicioComper) || isAfter(dataAtuComper, dataFiltroInicioComper)) {
                                podeDarPushInGraficoInicio = true
                            } else {
                                podeDarPushInGraficoInicio = false
                            }

                        } else {
                            podeDarPushInGraficoInicio = true
                        }


                        if (dateFimSeachGrafi != null) {

                            const dataFiltroFimComper = new Date(`${dateFimSeachGrafi.getFullYear()}-${(dateFimSeachGrafi.getMonth() + 1)}`)

                            if (isEqual(dataAtuComper, dataFiltroFimComper) || isBefore(dataAtuComper, dataFiltroFimComper)) {
                                podeDarPushInGraficoFim = true
                            } else {
                                podeDarPushInGraficoFim = false
                            }

                        } else {
                            podeDarPushInGraficoFim = true
                        }


                        if (podeDarPushInGraficoInicio && podeDarPushInGraficoFim) {
                            arrayKeys.push(convertDataUSParaBRReturnMesEAno(dataAtual))

                            graficoArrayMonth.push({ month: convertDataUSParaBRReturnMesEAnoEscrito(dataAtual) })
                        }

                    }

                    if (!isFiltroGrafico) {
                        arrayKeys.push(convertDataUSParaBRReturnMesEAno(dataAtual))

                        graficoArrayMonth.push({ month: convertDataUSParaBRReturnMesEAnoEscrito(dataAtual) })
                    }

                    //fim filtro grafico

                    objColunasTabela.push({
                        title: `${convertDataUSParaBRReturnMesEAnoEscrito(dataAtual)}`,
                        key: `${convertDataUSParaBRReturnMesEAno(dataAtual)}`,
                        dataIndex: `${convertDataUSParaBRReturnMesEAno(dataAtual)}`,
                        width: 190,
                        // sorter: (a: any, b: any) => a.municipio.nome.localeCompare(b.municipio.nome),
                        // showSorterTooltip: false,
                        render: (valor: any, record: any) => {
                            return (
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center'
                                    }}
                                >
                                    <Text>
                                        {
                                            valor ?
                                                'R$ ' + convertMoedaBRDoJSComExtamente2CasasDecimais(valor, true)
                                                :
                                                '-'
                                        }
                                    </Text>
                                </div>
                            )
                        },
                    })

                }

                let graficoArrayKeys: Array<{
                    month: string,
                    nomeP: string,
                    valor?: any
                }> = []

                let valorPorcentagem75Total = 0
                let valorPorcentagem75Restante = 0
                let valorPorcentagem75TotalVermelho = 0
                let valorPorcentagem75RestanteVermelho = 0

                // gerando dados grafico
                arrayKeys.forEach((elemnt, index) => {

                    if (index === 0) {
                        valorPorcentagem75Total = result.data?.restante?.meses[elemnt] * 0.2//AMARELO
                        valorPorcentagem75TotalVermelho = result.data?.restante?.meses[elemnt] * 0.1

                        if (destaqueFiltro === 'todos') {

                            refDataTotaisDestaqueAndCadip.current = result.data?.total_destaque_e_cadip
                            valorPorcentagem75Restante = result.data?.total_destaque_e_cadip?.meses[elemnt] * 0.2
                            valorPorcentagem75RestanteVermelho = result.data?.total_destaque_e_cadip?.meses[elemnt] * 0.1
                        } else if (destaqueFiltro === 'S') {

                            refDataTotaisDestaqueAndCadip.current = result.data?.total_destaque
                            valorPorcentagem75Restante = result.data?.total_destaque?.meses[elemnt] * 0.2
                            valorPorcentagem75RestanteVermelho = result.data?.total_destaque?.meses[elemnt] * 0.1
                        } else if (destaqueFiltro === 'N') {

                            refDataTotaisDestaqueAndCadip.current = result.data?.total_cadip
                            valorPorcentagem75Restante = result.data?.total_cadip?.meses[elemnt] * 0.2
                            valorPorcentagem75RestanteVermelho = result.data?.total_cadip?.meses[elemnt] * 0.1
                        } else {

                            refDataTotaisDestaqueAndCadip.current = result.data?.total_destaque_e_cadip
                            valorPorcentagem75Restante = result.data?.total_destaque_e_cadip?.meses[elemnt] * 0.2
                            valorPorcentagem75RestanteVermelho = result.data?.total_destaque_e_cadip?.meses[elemnt] * 0.1
                        }

                    }
                    // // gerando do total total
                    // graficoArrayKeys.push({
                    //     month: graficoArrayMonth[index].month,
                    //     nomeP: result.data?.total_total?.descricao ? result.data?.total_total?.descricao : '',
                    //     valor: result.data?.total_total?.meses[elemnt] ? result.data?.total_total?.meses[elemnt] : undefined
                    // })
                    // gerando do total Restante / Saldo a utilizar
                    graficoArrayKeys.push({
                        month: graficoArrayMonth[index].month,
                        nomeP: result.data?.restante?.descricao ? result.data?.restante?.descricao : '',
                        valor: result.data?.restante?.meses[elemnt] ? result.data?.restante?.meses[elemnt] : undefined
                    })

                    // gerando do despesas todal / Saldo Contábil Total
                    // graficoArrayKeys.push({
                    //     month: graficoArrayMonth[index].month,
                    //     nomeP: result.data?.total_despesa_dsp?.descricao ? result.data?.total_despesa_dsp?.descricao : '',
                    //     valor: result.data?.total_despesa_dsp?.meses[elemnt] ? result.data?.total_despesa_dsp?.meses[elemnt] : undefined
                    // })

                })

                refLimite75PorcentoColor.current = {
                    total_total: valorPorcentagem75Total,
                    restante: valorPorcentagem75Restante,
                    total_total_RED: valorPorcentagem75TotalVermelho,
                    restante_RED: valorPorcentagem75RestanteVermelho,
                }

                refDadosGrafico.current = graficoArrayKeys
                refDataTodosTotais.current = result.data
                refColunasDinamic.current = arrayKeys

                setDadosTabelaState(result.data.operacoes)

                setColumnsState(objColunasTabela)


                if (refGraficoNaTela.current) {
                    handleGraficoNaTela(true);
                } else {

                    setLoadingPage(false);
                }

                return true

            } catch (error) {
                let msgErro: any = (error as Error);
                setLoadingPage(false);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'municipios_dkwdfaiweyi')
                }

                return false

            }
        }

        return buscaCiaddes();

    }, [dateInicioSeachGrafi, dateFimSeachGrafi]);

    useEffect(() => {

        let arrayUrl = window.location.pathname.split("/");
        VerificaRota(arrayUrl, navigate);

        // buscarArquivosESeleciona();
        buscaDadosESetDadosNaTabela();


    }, []);



    const funCriarGrafico = useCallback(() => {
        refNewGraficoScreen.current = new Chart({
            container: 'container',
            autoFit: true,
            height: 350
        });

        refNewGraficoScreen.current.data(refDadosGrafico.current);


        refNewGraficoScreen.current.option('scrollbar', {
            type: 'horizontal',
        });
        refNewGraficoScreen.current.scale({
            month: {
                range: [0, 1],
            },
            valor: {
                nice: true,
            },
        });

        refNewGraficoScreen.current.tooltip({
            showCrosshairs: true,
            shared: true
        });

        refNewGraficoScreen.current.axis('valor', {
            label: {
                formatter: (val: any) => {
                    return abreviarNumero(Number(val));
                },
            },
        });
        // #598AEF
        // #57D1A0
        // #1bbd19
        // #fa513a
        refNewGraficoScreen.current
            .line()
            .position('month*valor')
            // .color('nomeP', ['#57D1A0', '#598AEF'])
            .color('nomeP*valor', (nomeP: any, valor: any) => {
                if (nomeP === 'Restante') {
                    if (valor <= 0) {
                        return '#57D1A0'
                        return '#fa513a'
                    }
                    return '#57D1A0'
                }
                return '#598AEF'
            })
            .shape('smooth')
            .tooltip('nomeP*valor', (name: any, value: any) => {
                return {
                    name: name,
                    value: 'R$ ' + convertMoedaBRDoJSComExtamente2CasasDecimais(value)
                };
            });

        refNewGraficoScreen.current
            .point()
            .position('month*valor')
            // .color('nomeP', ['#57D1A0', '#598AEF'])
            .color('nomeP*valor', (nomeP: any, valor: any) => {
                // console.log(nomeP)
                // console.log(t)
                // ['#57D1A0', '#598AEF']
                if (nomeP === 'Restante') {
                    if (valor <= 0) {
                        return '#57D1A0'
                        return '#fa513a'
                    }
                    return '#57D1A0'
                }
                return '#598AEF'
            })
            .tooltip(false)
            .shape('circle');


        //removendo click da legenda
        refNewGraficoScreen.current.interaction('legend-filter', {
            start: [
            ],
        });
        // refNewGraficoScreen.current.legend(false);

        refNewGraficoScreen.current.render();

    }, [])

    const handleGraficoNaTela = useCallback((isUpdate: boolean) => {
        const buscaCiaddes = async () => {
            try {

                if (isUpdate) {

                    setLoadingPage(true);
                    setGraficoOrTableUPDATE(false)

                    // refNewGraficoScreen.current.changeVisible(false);
                    // // refNewGraficoScreen.current.clear();
                    // refNewGraficoScreen.current.changeData(refDadosGrafico.current)
                    // // refNewGraficoScreen.current.data(refDadosGrafico.current);

                }

                if (isUpdate) {
                    setTimeout(() => {
                        setGraficoOrTableUPDATE(true)
                        setTimeout(() => {
                            funCriarGrafico();
                            setLoadingPage(false);
                        }, 200);
                    }, 200);
                } else {

                    funCriarGrafico();
                    setLoadingPage(false);
                }





            } catch (error) {
                let msgErro: any = (error as Error);
                setLoadingPage(false);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'municipios_dkwdfaiweyi')
                }


            }
        }

        setLoadingPage(true);
        setTimeout(() => {
            buscaCiaddes();
        }, 500);
    }, [])

    useEffect(() => {

        if (graficoOrTable) {
            handleGraficoNaTela(false);
        }

    }, [graficoOrTable]);

    const handleClickSelectArquivo = useCallback((selectedIndex: any) => {
        refValueArquivosSelect.current = selectedIndex;
        setValueArquivoSelect(selectedIndex)

        buscaDadosESetDadosNaTabela();

    }, []);

    const clickCancelModalFiltros = useCallback(() => {
        setHandleSubmitModal(null);
        setVisibleModalFiltros(false);
    }, []);

    const handleClickSalvarModalFiltros = useCallback((form: any): void => {

        const setDados = async (values: any) => {
            try {
                const destaqueFiltros = values.destaque
                refDestaqueDoFiltro.current = values.destaque
                if (values.destaque === 'todos') values.destaque = null


                setLoadingModalFiltros(true);

                let results = await api.post(urlLimiteOperacoesRelatorio, values,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        }
                    });

                await buscaDadosESetDadosNaTabela(false, results, destaqueFiltros)

                setLoadingModalFiltros(false);
                clickCancelModalFiltros();

            } catch (error) {
                let msgErro: any = (error as Error);
                setLoadingModalFiltros(false);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'municipios_dkwdfaiweyi')
                }


            }
        }

        form?.validateFields()
            .then((values: any) => {

                setDados(values)

            })
            .catch(() => {
                // .catch((errorInfo: any) => {

                notification.error({
                    message: 'Erro',
                    description:
                        'Preencha os campos obrigatorios!',
                });
            });

    }, []);

    const renderModalTabela = useCallback(() => {
        return (
            <Modal
                title="Filtros da tabela"
                visible={visibleModalFiltros}
                // visible={true}
                // onOk={() => clickBtnSubmitUploadArquivo(arquivoObjeto)}
                // onCancel={() => clickCancelModalFiltros()}
                style={{
                    top: 20
                }}
                className="modalFiltrosCronograma_jkbqerwoygfoerl"
                footer={[
                    <Button
                        key="back"
                        onClick={clickCancelModalFiltros}
                        loading={loadingModalFiltros}
                    >
                        Cancelar
                    </Button>,
                    <Button
                        key="submit"
                        type="primary"
                        loading={loadingModalFiltros}
                        onClick={() => {
                            setDateInicioSeachGrafi(null)
                            setDateFimSeachGrafi(null)
                            setHandleSubmitModal(Math.round(new Date().getTime() / 1000))
                        }}
                    >
                        Filtrar
                    </Button>
                ]}
                key="modalListagemMunicipiosFiltros"
            >
                <Loading
                    loading={
                        loadingModalFiltros
                    }
                >
                    <FormModal
                        submitModal={handleSubmitModal}
                        clickSalvarModalFiltros={handleClickSalvarModalFiltros}
                    />
                </Loading>
            </Modal>
        )

    }, [visibleModalFiltros, handleSubmitModal, loadingModalFiltros]);

    return (
        <LayoutDashboard>
            <Loading
                loading={
                    (loadingPage && !loadingModalFiltros)
                }
            >
                <div
                    className='Cronograma_TextosStatusEDatas_kuyjfvcdtkgc'
                >
                    <div
                        style={{
                            display: 'flex',
                            margin: 7
                        }}
                    >

                        <Text>Arquivo:</Text>
                        {
                            refDataTodosTotais.current?.arquivo &&
                            <Text style={{ marginLeft: 3 }} strong>{refDataTodosTotais.current?.arquivo}</Text>
                        }
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            margin: 7
                        }}
                    >

                        <Text>Data:</Text>
                        {
                            refDataTodosTotais.current?.data_processamento &&
                            <Text style={{ marginLeft: 3 }} strong>{convertDataUSParaBrasilEHoraSegundos(refDataTodosTotais.current?.data_processamento)}</Text>
                        }
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            margin: 7
                        }}
                    >

                        <Text>Usuário:</Text>
                        {
                            refDataTodosTotais.current?.usuario_nome &&
                            <Text style={{ marginLeft: 3 }} strong>{refDataTodosTotais.current?.usuario_nome}</Text>
                        }
                    </div>
                </div>
                {/*<div
                    style={{ marginBottom: 5 }}
                >
                    <Text style={{ marginRight: 5 }} >{'Selecione o arquivo:'}</Text>
                </div>
                 <div
                    style={{
                        display: 'flex'
                    }}
                >
                    <div
                        style={{
                            marginRight: 5,
                            marginBottom: 5
                        }}
                    >
                        <Select
                            showSearch
                            optionFilterProp="children"
                            onChange={handleClickSelectArquivo}
                            value={valueArquivoSelect}
                            // style={{
                            //     width: '100%',
                            //     marginBottom: 5,
                            //     marginRight: 5,
                            // }}
                            filterOption={(input: any, option: any) => {
                                let textDigit = removeAcento(input)
                                let listCidade = removeAcento(option?.children);
                                return listCidade.indexOf(textDigit) >= 0
                            }}
                        >
                            {
                                arquivos.map((element, index) => (
                                    <Select.Option
                                        key={index}
                                        value={element.id}
                                    >
                                        {element.nomeArquivoOriginal + ' - ' + element.createdAt}
                                    </Select.Option>
                                ))
                            }
                        </Select>
                    </div>
                </div> */}

                <div
                    style={{
                        display: 'flex',
                        // justifyContent: 'flex-end',
                        // justifyContent: 'flex-end'
                    }}
                // className="botoesGerarXlsxEoutros_ksjhvfgh"
                >
                    <div
                        style={{
                            display: 'flex',
                        }}
                    >
                        <Button
                            type="primary"
                            // danger
                            // className="botaoVerdeBaixarTodas"
                            style={{ marginBottom: 2, marginRight: 4 }}
                            // disabled={!hasSelectedAdd}
                            onClick={() => {
                                setGraficoOrTable((value) => {
                                    refGraficoNaTela.current = !value
                                    return !value
                                })
                            }}
                        >
                            {graficoOrTable ? 'Listagem' : 'Gráfico'}
                        </Button>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                        }}
                        hidden={graficoOrTable}
                    >
                        <Button
                            type="primary"
                            // className="botaoVerdeBaixarTodas"
                            style={{ marginBottom: 2, marginRight: 4 }}
                            // disabled={!hasSelectedAdd}
                            onClick={() => {
                                setVisibleModalFiltros(true)
                            }}
                        >
                            Filtros
                        </Button>
                    </div>
                    {/* <div
                        style={{
                            display: 'flex',
                            // justifyContent: 'flex-end',
                            // justifyContent: 'flex-end'
                            // marginBottom: 15
                        }}
                    >
                        <Button
                            type="primary"
                            danger
                            // className="botaoVerdeBaixarTodas"
                            style={{ marginBottom: 2 }}
                            // disabled={!hasSelectedAdd}
                            onClick={() => { window.location.reload(); }}
                        >
                            Resetar Listagem
                        </Button>
                    </div> */}
                </div>
                <DividerStyled
                    style={{
                        marginBottom: 15,
                        backgroundColor: '#2D9CDB',
                        marginTop: 5,
                        // height: 2,
                        width: '100%'
                    }}
                />
                <Table
                    style={{
                        // marginTop: 50
                        display: graficoOrTable ? 'none' : 'block'
                    }}
                    className='marginTopTables'
                    rowKey={(record) => record?.prospecao?.toString()}
                    // loading={loadingTable}
                    // onRow={(record, rowIndex) => {
                    //     return {
                    //         onClick: event => { onclickTable(record, rowIndex) }, // click row
                    //         onDoubleClick: event => { }, // double click row
                    //         onContextMenu: event => { }, // right button click row
                    //         onMouseEnter: event => { }, // mouse enter row
                    //         onMouseLeave: event => { }, // mouse leave row
                    //     };
                    // }}
                    // rowSelection={rowSelection}
                    columns={columnsState} dataSource={dadosTabelaState}
                    scroll={{ x: true, y: 300 }}
                    onChange={
                        (pagination, filters, sorter, extra) => {
                            refContagemDoFiltro.current = extra.currentDataSource
                        }
                    }
                    components={vt}
                    title={(registros) => {

                        return (
                            <div
                                style={{
                                    backgroundColor: theme === 'light' ? '#FAFAFA' : '#1D1D1D',
                                    padding: 10,
                                    margin: -8,
                                    marginBottom: 0.5
                                }}
                            >
                                <Text>
                                    {/* {`Total: ${dadosTabelaState.length} registros.`} */}
                                    {`Mostrando: ${registros.length} de ${refContagemDoFiltro.current.length === 0 ?
                                        dadosTabelaState.length :
                                        refContagemDoFiltro.current.length} num total de ${dadosTabelaState.length} `}
                                </Text>
                            </div>
                        )
                    }}
                    footer={(registros) => {
                        return (
                            <div
                                className='footerTable_lhjfvwee'
                            >
                                <div
                                    style={{
                                        textAlign: 'start',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        marginBottom: 10
                                    }}
                                >
                                    <Text>
                                        {`Valores de Saldo: 20% a 10% ficam em `}<span style={{ color: '#F2C521' }}>Amarelo</span>.
                                    </Text>
                                    <Text>
                                        {`Valores de Saldo: abaixo de 10% ficam em `}<span style={{ color: '#f00' }}>Vermelho</span>.
                                    </Text>
                                </div>
                                <div
                                    style={{
                                        textAlign: 'end'
                                    }}
                                >
                                    <Text>
                                        {/* {`Total: ${dadosTabelaState.length} registros.`} */}
                                        {`Mostrando: ${registros.length} de ${refContagemDoFiltro.current.length === 0 ?
                                            dadosTabelaState.length :
                                            refContagemDoFiltro.current.length} num total de ${dadosTabelaState.length} `}
                                    </Text>
                                </div>
                            </div>
                        )
                    }}
                    // sticky
                    summary={() => {
                        return (
                            <Table.Summary fixed={'bottom'}>
                                <Table.Summary.Row>
                                    <Table.Summary.Cell
                                        index={0}
                                    // colSpan={7}
                                    >
                                        <Text
                                            strong
                                        >
                                            {
                                                refDataTodosTotais.current?.total_despesa_dsp?.descricao
                                            }
                                        </Text>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={1} colSpan={7}>
                                    </Table.Summary.Cell>
                                    {
                                        refColunasDinamic.current.map((elemnt: string, index: number) => {

                                            const valor = refDataTodosTotais.current?.total_despesa_dsp?.meses[elemnt]
                                            return (
                                                <React.Fragment key={index}>
                                                    {
                                                        valor ?
                                                            <Table.Summary.Cell index={(index + 7)}>
                                                                <Text
                                                                    strong
                                                                    style={{
                                                                        color: valor && valor < 0 ? '#f00' : undefined
                                                                    }}
                                                                >
                                                                    {
                                                                        valor ?
                                                                            'R$ ' + convertMoedaBRDoJSComExtamente2CasasDecimais(valor, true)
                                                                            :
                                                                            '-'
                                                                    }
                                                                </Text>
                                                            </Table.Summary.Cell>
                                                            :
                                                            <Table.Summary.Cell index={(index + 7)}>
                                                                -
                                                            </Table.Summary.Cell>
                                                    }
                                                </React.Fragment>
                                            )
                                        })
                                    }
                                </Table.Summary.Row>
                                <Table.Summary.Row>
                                    <Table.Summary.Cell
                                        index={0}
                                    // colSpan={7}
                                    >
                                        <Text
                                            strong
                                        >
                                            {
                                                // valor * 0.75 >= amarelo
                                                refDataTotaisDestaqueAndCadip.current?.descricao
                                            }
                                        </Text>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={1} colSpan={7}>
                                    </Table.Summary.Cell>
                                    {
                                        refColunasDinamic.current.map((elemnt: string, index: number) => {

                                            const valor = refDataTotaisDestaqueAndCadip.current?.meses[elemnt]

                                            return (
                                                <React.Fragment key={index}>
                                                    {
                                                        valor ?
                                                            <Table.Summary.Cell index={(index + 7)}>
                                                                <Text
                                                                    strong
                                                                >
                                                                    {
                                                                        valor ?
                                                                            'R$ ' + convertMoedaBRDoJSComExtamente2CasasDecimais(valor, true)
                                                                            :
                                                                            '-'
                                                                    }
                                                                </Text>
                                                            </Table.Summary.Cell>
                                                            :
                                                            <Table.Summary.Cell index={(index + 7)}>
                                                                -
                                                            </Table.Summary.Cell>
                                                    }
                                                </React.Fragment>
                                            )
                                        })
                                    }
                                </Table.Summary.Row>
                                <Table.Summary.Row>
                                    <Table.Summary.Cell
                                        index={0}
                                    // colSpan={7}
                                    >
                                        <Text
                                            strong
                                        >
                                            {
                                                refDataTodosTotais.current?.restante?.descricao
                                            }
                                        </Text>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={1} colSpan={7}>
                                    </Table.Summary.Cell>
                                    {
                                        refColunasDinamic.current.map((elemnt: string, index: number) => {

                                            const valor = refDataTodosTotais.current?.restante?.meses[elemnt]
                                            return (
                                                <React.Fragment key={index}>
                                                    {
                                                        valor ?
                                                            <Table.Summary.Cell index={(index + 7)}>
                                                                <Text
                                                                    strong
                                                                    style={{
                                                                        color:
                                                                            // valor && valor < 0
                                                                            valor <= refLimite75PorcentoColor.current?.restante_RED
                                                                                ?
                                                                                '#f00'
                                                                                :
                                                                                valor <= refLimite75PorcentoColor.current?.restante ?
                                                                                    '#F2C521'
                                                                                    :
                                                                                    undefined
                                                                    }}
                                                                >
                                                                    {
                                                                        valor ?
                                                                            'R$ ' + convertMoedaBRDoJSComExtamente2CasasDecimais(valor, true) : '-'
                                                                    }
                                                                </Text>
                                                            </Table.Summary.Cell>
                                                            :
                                                            <Table.Summary.Cell index={(index + 7)}>
                                                                -
                                                            </Table.Summary.Cell>
                                                    }
                                                </React.Fragment>
                                            )
                                        })
                                    }
                                </Table.Summary.Row>
                            </Table.Summary>
                        )
                    }}
                    size="small"
                    pagination={{
                        locale: { items_per_page: '' },
                        showSizeChanger: true,
                        defaultPageSize: 645,
                        // position: ['topRight', 'bottomRight'],
                        position: [],
                        pageSizeOptions: ['10', '20', '50', '100', '645']
                    }}
                    locale={locale.Table}
                />
                {
                    (graficoOrTable && graficoOrTableUPDATE) &&
                    <Col span={24} >
                        <Row>
                            <Col {...colInputsData}
                                style={{ margin: 5 }}
                            >
                                <Text>Data inicio:</Text>
                                <MuiPickersUtilsProvider locale={ptLocale} utils={DateFnsUtils} >
                                    <DatePicker
                                        value={dateInicioSeachGrafi}
                                        onChange={setDateInicioSeachGrafi}
                                        required={false}
                                        views={["year", "month"]}
                                        style={{
                                            width: '100%'
                                        }}
                                        okLabel="Ok"
                                        clearLabel="Limpar"
                                        cancelLabel="Cancelar"
                                        clearable
                                        format="MM/yyyy"
                                    />
                                </MuiPickersUtilsProvider>
                            </Col>
                            <Col {...colInputsData}
                                style={{ margin: 5 }}
                            >
                                <Text>Data fim:</Text>
                                <MuiPickersUtilsProvider locale={ptLocale} utils={DateFnsUtils} >
                                    <DatePicker
                                        value={dateFimSeachGrafi}
                                        onChange={setDateFimSeachGrafi}
                                        required={false}
                                        style={{
                                            width: '100%'
                                        }}
                                        views={["year", "month"]}
                                        okLabel="Ok"
                                        clearLabel="Limpar"
                                        cancelLabel="Cancelar"
                                        clearable
                                        format="MM/yyyy"
                                    />
                                </MuiPickersUtilsProvider>
                            </Col>
                        </Row>
                        <Row>
                            <Button
                                type="primary"
                                // danger
                                // className="botaoVerdeBaixarTodas"
                                style={{ marginLeft: 5 }}
                                // disabled={!hasSelectedAdd}
                                onClick={() => {
                                    buscaDadosESetDadosNaTabela(true, false, refDestaqueDoFiltro.current);
                                }}
                            >
                                {'Filtrar'}
                            </Button>
                        </Row>
                        <Card
                            title="Limite do Setor Público" bordered={false}
                        >
                            <div
                                className="container" id="container"
                            />
                        </Card>
                    </Col>
                }
                {renderModalTabela()}
            </Loading>
        </LayoutDashboard >
    );
}

export default CronogramaProjecaoDeLimites;
