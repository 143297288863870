import { ReactElement, ReactNode } from 'react';
import { Col, Typography, Row } from 'antd';

// import { useNavigate } from 'react-router-dom';

// import { getToken } from './../../services/LStorage/token';
// import { VerificaRota } from './../../../../../services/VerificaSePodeAcessar';
// import { urlUsuariosRegra } from './../../../../../services/urls';
// import { api } from './../../../../../services/apiAxios';
// import { EmitirErro } from '../../../../../services/EmitirErro';
// import { SairDoSistema } from '../../../../../services/LStorage/SairSistema';
// import { getToken } from './../../../../../services/LStorage/token';
import './style.css';

// import {
//     removeAcento
// } from './../../../../../utils/RemoveAcentos';
// import { CardLimites } from './CardLimites';
// import { TodasInformacoes } from './TodasInformacoes';

const { Text } = Typography;

interface intProps {
    urlImg?: string;
    nome?: string;
    cargo?: string;
    statusCard?: string | null;
    render?: () => ReactNode;
}

export const CardPrefeito = ({
    urlImg = '',
    nome = '',
    cargo = '',
    statusCard = 'Em mandato',
    render = () => <></>,
}: intProps) => {

    // const navigate = useNavigate();

    return (
        <div className='Municipios_DivPrincipalPreifeito_fdijkbsi'>
            {
                urlImg && urlImg !== '' &&
                <img
                    className='Municipios_ImgPrefeito_oiufbgwefo'
                    style={{
                        // width: 130,
                        // margin: 10,
                        marginRight: 12,
                        filter: statusCard !== 'Em mandato' ? 'opacity(0.4) grayscale(1)' : undefined,
                        // height
                        // padding: '0px 4px',
                        // line- height: 1.42857143;
                        // border: '1px solid #ddd',
                        borderRadius: 4,
                    }}
                    // src={urlImg}
                    src={
                        process.env.NODE_ENV === 'development' ?
                            "https://divulgacandcontas.tse.jus.br/candidaturas/oficial/2022/BR/BR/544/candidatos/893498/foto.jpg"
                            :
                            urlImg
                    }
                />
            }
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                }}
                className='divtextsCards_CardPrefeito_fewohbfwo'
            >
                <Text style={{ fontWeight: 'bold', fontSize: '1.2rem' }} type={statusCard === 'Em mandato' ? undefined : 'secondary'} >
                    <Text style={{ fontSize: '1.1rem', fontWeight: 'normal' }}>{cargo + ': '}</Text>
                    {nome}
                </Text>
                {render()}
                <Text style={{ fontWeight: 'bold', fontSize: '1.2rem' }} type={statusCard === 'Em mandato' ? undefined : 'secondary'} >
                    <Text style={{ fontSize: '1.1rem', fontWeight: 'normal' }}>{'Status: '}</Text>
                    {statusCard}
                </Text>

            </div>
        </div>
    );
}
