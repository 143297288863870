import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Login from './../pages/Login';
// import Dashboard from './../pages/Dashboard';
import Municipios from './../pages/Municipios';
import RelatorioConsolidado from './../pages/RelatorioConsolidado';
import Usuarios from './../pages/Usuarios';
import UsuariosAddOrUpdate from './../pages/Usuarios/components/AddOrUpdate';
import PrimeiroAcesso from './../pages/PrimeiroAcesso';
import RecuperarSenha from './../pages/RecuperarSenha';
import RecuperarSenhaNovaSenha from './../pages/RecuperarSenha/components/NovaSenha';
import PerfilUsuario from './../pages/PerfilUsuario';
import UploadArquivos from './../pages/UploadArquivos';
import ListagemMunicipios from './../pages/ListagemMunicipios';
import Indexador from './../pages/LimitesDeOperacao/Configuracoes/Indexador';
import IndexadorAddOrUpdate from './../pages/LimitesDeOperacao/Configuracoes/Indexador/AddOrUpdate';
import Produto from './../pages/LimitesDeOperacao/Configuracoes/Produto';
import ProdutoAddOrUpdate from './../pages/LimitesDeOperacao/Configuracoes/Produto/AddOrUpdate';
import PosicaoDeOperacao from './../pages/LimitesDeOperacao/Configuracoes/PosicaoDeOperacao';
import PosicaoDeOperacaoAddOrUpdate from './../pages/LimitesDeOperacao/Configuracoes/PosicaoDeOperacao/AddOrUpdate';
import Status from './../pages/LimitesDeOperacao/Configuracoes/Status';
import StatusAddOrUpdate from './../pages/LimitesDeOperacao/Configuracoes/Status/AddOrUpdate';
import Objeto from './../pages/LimitesDeOperacao/Configuracoes/Objeto';
import ObjetoAddOrUpdate from './../pages/LimitesDeOperacao/Configuracoes/Objeto/AddOrUpdate';
import LimiteConfig from './../pages/LimitesDeOperacao/Configuracoes/LimiteDeOperacaoConfig';
import LimiteConfigAddOrUpdate from './../pages/LimitesDeOperacao/Configuracoes/LimiteDeOperacaoConfig/AddOrUpdate';
import CronogramaProjecaoDeLimites from '../pages/LimitesDeOperacao/Cronograma';
import ImportacaoDePlanilha from '../pages/LimitesDeOperacao/ImportacaoPlanilha';
import ArquivosDeImportacao from '../pages/LimitesDeOperacao/Configuracoes/ArquivosDeImportacao';

const TodasRotas: React.FC = () => {

    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Login />} />
                    {/* <Route path="/dashboard" element={<Dashboard />} /> */}
                    <Route path="/municipios" element={<Municipios />} />
                    <Route path="/relatorioConsolidado" element={<RelatorioConsolidado />} />
                    <Route path="/usuarios" element={<Usuarios />} />
                    <Route path="/usuarios/:id" element={<UsuariosAddOrUpdate />} />
                    <Route path="/primeiro-acesso" element={<PrimeiroAcesso />} />
                    <Route path="/recuperar-senha" element={<RecuperarSenha />} />
                    <Route path="/recuperar-senha/:codigo" element={<RecuperarSenhaNovaSenha />} />
                    <Route path="/perfil/:id" element={<PerfilUsuario />} />
                    <Route path="/upload" element={<UploadArquivos />} />
                    <Route path="/listagemMunicipios" element={<ListagemMunicipios />} />
                    <Route path="/configuracoes/projecao-limites" element={<CronogramaProjecaoDeLimites />} />
                    <Route path="/configuracoes/indexador" element={<Indexador />} />
                    <Route path="/configuracoes/indexador/:id" element={<IndexadorAddOrUpdate />} />
                    <Route path="/configuracoes/produto" element={<Produto />} />
                    <Route path="/configuracoes/produto/:id" element={<ProdutoAddOrUpdate />} />
                    <Route path="/configuracoes/posicao-operacao" element={<PosicaoDeOperacao />} />
                    <Route path="/configuracoes/posicao-operacao/:id" element={<PosicaoDeOperacaoAddOrUpdate />} />
                    <Route path="/configuracoes/status" element={<Status />} />
                    <Route path="/configuracoes/status/:id" element={<StatusAddOrUpdate />} />
                    <Route path="/configuracoes/objeto" element={<Objeto />} />
                    <Route path="/configuracoes/objeto/:id" element={<ObjetoAddOrUpdate />} />
                    <Route path="/configuracoes/limite" element={<LimiteConfig />} />
                    <Route path="/configuracoes/limite/:id" element={<LimiteConfigAddOrUpdate />} />
                    <Route path="/limites-operacao/importacao-planilha" element={<ImportacaoDePlanilha />} />
                    <Route path="/configuracoes/arquivos-importacao" element={<ArquivosDeImportacao />} />
                    {/* <Route path="/*" element={<h1>404</h1>} /> */}

                    {/* <Route path="/perfil" component={Login} />
                    <Route path="/usuarios" exact component={Usuarios} /> */}
                </Routes>
            </BrowserRouter>
        </>
    )
}

export default TodasRotas;
