import React, { useContext, useEffect, useState } from 'react';
import { Typography, Button, Col, Row } from 'antd';
import {
    EyeOutlined,
    EyeInvisibleOutlined
} from '@ant-design/icons';
import { GlobalContext } from './../../../../../contexts/globalContext';
import { colTodasInformacoes, colTodasInformacoesEsquerda } from './GridStyle';
import { funcRetornaNomeParadataComTempo } from '../../../../../services/Conversores';
import { HomologRREOandRGFDataModal } from './HomologRREOandRGFDataModal';
import {
    DividerStyled
} from '../../../../../components/DividerStyled';

const { Text, Title } = Typography;

interface interfDadosMunicipioSelecionado {
    municipioSelecionado?: {
        "limiteLrf"?: number | null,
        "cdp": boolean,
        "cdpAtualizadoEm": string | null,
        "despesaPessoalLegislativo"?: number | null,
        "despesaPessoalLegislativoAtualizadoEm": string | null,
        "despesaPessoalLegislativoPeriodo": string | null,
        "despesaPessoalExecutivo"?: number | null,
        "despesaPessoalExecutivoAtualizadoEm": string | null,
        "despesaPessoalExecutivoPeriodo": string | null,
        "pendentesRegularizacaoConsultaJuridica": boolean,
        "pendentesRegularizacaoConsultaJuridicaAtualizadoEm": string | null,
        "retificacaoRgfEm": string | null,
        "retificacaoRreoEm": string | null,
        "homologacaoRgf": boolean,
        "homologacaoRgfPeriodo": string | null,
        "homologacaoRgfEm": string | null,
        "homologacaoRgfAtualizadoEm": string | null,
        "homologacaoRreoAtualizadoEm": string | null,
        "homologacaoRreoEm": string | null,
        "homologacaoRreoPeriodo": string | null,
        "homologacaoRreo": boolean,
        "municipioIdIbge": number,
        "municipio": {
            "idIbge": number,
            "nome"?: string | null,
            "ddd"?: string | null,
            "populacao"?: number | null,
            "valorComprometido"?: number | null,
            "municipioAtendido": boolean,
            "municipioAtendidoAtualizadoEm": string | null,
            "regiaoAdministrativa"?: {
                "id"?: string | null,
                "createdAt": string,
                "updatedAt": string,
                "nome"?: string | null
            },
            "createdAt": string,
            "updatedAt": string
        },
        "artigo167aGeradoEm": string | null,
        "artigo167aPeriodo": string | null,
        "artigo167aAnteriorGeradoEm": string | null,
        "artigo167aAnteriorPeriodo": string | null,
        "artigo167aStatus": boolean,
        "artigo167aAtualizadoEm": string | null,
        "artigo167aResultado": number | null,
        "artigo167aAnteriorResultado": number | null,
        "limitePotencial"?: number | null,
        "habilitado": boolean
    }
}

export const TodasInformacoes = ({
    municipioSelecionado
}: interfDadosMunicipioSelecionado) => {
    // export const TodasInformacoes = ({
    //     valor = '',
    //     descricao = '',
    //     title = '',
    //     style = {}
    // }: intProps) => {

    const { theme } = useContext(GlobalContext);

    const [isVisible, setIsVisible] = useState<boolean>(false);

    useEffect(() => {

        if (municipioSelecionado && !municipioSelecionado?.habilitado) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }

    }, [municipioSelecionado])

    return (
        <>
            <Button
                style={{
                    padding: 0,
                    color: theme === 'light' ?
                        isVisible ? '#535353' : '#bbbbbb'
                        :
                        isVisible ? '#929292' : '#636363',
                    marginBottom: 15
                }}
                className='akqtedbuttonVerTodosOsDados'
                type="link"
                onClick={() => { setIsVisible(!isVisible) }}
            >
                {isVisible ? <EyeOutlined /> : <EyeInvisibleOutlined />}Visualizar todos os dados
            </Button>
            {isVisible &&
                <Row>
                    <Col {...colTodasInformacoes} style={{ marginBottom: 5, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div
                                style={{
                                    // width: '50%',
                                    // backgroundColor: 'red',
                                    marginRight: 5
                                }}
                            >
                                <Text
                                    style={{ fontSize: '1.1rem' }}
                                // strong
                                >{'DDD do Município:'}</Text>
                            </div>
                            <Text
                                style={{
                                    fontSize: '1.1rem',
                                    // color: '#2D9CDB',
                                    fontWeight: 'bold'
                                }}
                            // strong
                            >
                                {municipioSelecionado && municipioSelecionado.municipio?.ddd ?
                                    municipioSelecionado.municipio?.ddd : ''}
                            </Text>
                        </div>
                        <DividerStyled
                            style={{
                                marginTop: 10,
                                marginBottom: 10
                            }}
                        />
                    </Col>
                    <Col {...colTodasInformacoesEsquerda} style={{ marginBottom: 5, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div
                                style={{
                                    // width: '50%',
                                    // backgroundColor: 'red',
                                    marginRight: 5
                                }}
                            >
                                <Text style={{ fontSize: '1.1rem' }} >{'Região Administrativa:'}</Text>
                            </div>
                            <Text style={{ fontSize: '1.1rem', fontWeight: 'bold' }} >
                                {municipioSelecionado &&
                                    municipioSelecionado.municipio?.regiaoAdministrativa &&
                                    municipioSelecionado.municipio?.regiaoAdministrativa?.nome
                                    ?
                                    municipioSelecionado.municipio.regiaoAdministrativa.nome
                                    : ''}

                            </Text>
                        </div>
                        <DividerStyled
                            style={{
                                marginTop: 10,
                                marginBottom: 10
                            }}
                        />
                    </Col>

                    <Col {...colTodasInformacoes} style={{ marginBottom: 5, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                        <div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div
                                    style={{
                                        // width: '50%',
                                        // backgroundColor: 'red',
                                        marginRight: 5
                                    }}
                                >
                                    <Text style={{ fontSize: '1.1rem' }} >{'CDP:'}</Text>
                                </div>
                                <Text style={{
                                    fontSize: '1.1rem',
                                    fontWeight: 'bold',
                                    color: municipioSelecionado && municipioSelecionado.cdp ? 'green' : '#f00'
                                }} >

                                    {municipioSelecionado &&
                                        municipioSelecionado.cdp ?
                                        'Regular'
                                        : 'Irregular'}
                                </Text>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Text type='secondary' style={{ fontSize: '0.8rem' }}>
                                    {municipioSelecionado &&
                                        municipioSelecionado?.cdpAtualizadoEm ? "Dados atualizados em: " + funcRetornaNomeParadataComTempo(municipioSelecionado.cdpAtualizadoEm, true) : ''}
                                </Text>
                                {/* <Text type='secondary' style={{ fontSize: '0.8rem' }}>
                                    {'6.00%'}
                                </Text> */}
                            </div>
                        </div>

                        <DividerStyled
                            style={{
                                marginTop: 10,
                                marginBottom: 10
                            }}
                        />
                    </Col>

                    <Col {...colTodasInformacoesEsquerda} style={{ marginBottom: 5, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                        <div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div
                                    style={{
                                        // width: '50%',
                                        // backgroundColor: 'red',
                                        marginRight: 5
                                    }}
                                >
                                    <Text style={{ fontSize: '1.1rem' }}>
                                        {'Pendentes de regularização / Em consulta jurídica:'}
                                    </Text>
                                </div>
                                <Text
                                    style={{
                                        fontSize: '1.1rem',
                                        fontWeight: 'bold',
                                        color: municipioSelecionado && !municipioSelecionado.pendentesRegularizacaoConsultaJuridica ? 'green' : '#f00'
                                    }}
                                >
                                    {municipioSelecionado &&
                                        municipioSelecionado.pendentesRegularizacaoConsultaJuridica ?
                                        'SIM'
                                        : 'NÃO'}
                                </Text>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Text type='secondary' style={{ fontSize: '0.8rem' }}>
                                    {municipioSelecionado &&
                                        municipioSelecionado?.pendentesRegularizacaoConsultaJuridicaAtualizadoEm ?
                                        "Dados atualizados em: " + funcRetornaNomeParadataComTempo(municipioSelecionado.pendentesRegularizacaoConsultaJuridicaAtualizadoEm, true) : ''}
                                </Text>
                                {/* <Text type='secondary' style={{ fontSize: '0.8rem' }}>
                                {municipioSelecionado &&
                                    municipioSelecionado?.despesaPessoalLegislativoPeriodo ? municipioSelecionado.despesaPessoalLegislativoPeriodo : ''}
                            </Text> */}
                            </div>
                        </div>
                        <DividerStyled
                            style={{
                                marginTop: 10,
                                marginBottom: 10
                            }}
                        />
                    </Col>

                    <Col {...colTodasInformacoes} style={{ marginBottom: 5, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                        <div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div
                                    style={{
                                        // width: '50%',
                                        // backgroundColor: 'red',
                                        marginRight: 5
                                    }}
                                >
                                    <Text style={{ fontSize: '1.1rem' }}>
                                        {'Despesa Pessoal Legislativo (% RCL Ajustada):'}
                                    </Text>
                                </div>
                                <Text
                                    style={{
                                        fontSize: '1.1rem',
                                        fontWeight: 'bold',
                                        color: municipioSelecionado && municipioSelecionado?.despesaPessoalLegislativo &&
                                            municipioSelecionado.despesaPessoalLegislativo <= 6 ?
                                            'green' :
                                            '#f00'
                                    }}
                                >
                                    {municipioSelecionado &&
                                        municipioSelecionado.despesaPessoalLegislativo ?
                                        municipioSelecionado.despesaPessoalLegislativo + '%'
                                        : 'Não informado'}
                                </Text>

                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Text style={{ fontSize: '0.8rem' }}>
                                    {'Limite legal (alínea "a" do inciso III do art. 20 da LRF)'}
                                </Text>
                                <Text style={{ fontSize: '0.8rem', paddingLeft: 6 }}>
                                    {'6.00%'}
                                </Text>
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Text type='secondary' style={{ fontSize: '0.8rem' }}>
                                    {municipioSelecionado &&
                                        municipioSelecionado?.despesaPessoalLegislativoAtualizadoEm ?
                                        "Dados atualizados em: " + funcRetornaNomeParadataComTempo(municipioSelecionado.despesaPessoalLegislativoAtualizadoEm, true) : ''}
                                </Text>
                                <Text type='secondary' style={{ fontSize: '0.8rem', paddingLeft: 6 }}>
                                    {municipioSelecionado &&
                                        municipioSelecionado?.despesaPessoalLegislativoPeriodo ? municipioSelecionado.despesaPessoalLegislativoPeriodo : ''}
                                </Text>
                            </div>
                        </div>

                        <DividerStyled
                            style={{
                                marginTop: 10,
                                marginBottom: 10
                            }}
                        />
                    </Col>
                    <Col {...colTodasInformacoesEsquerda}>
                        <div style={{ marginBottom: 5, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                            <div>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div
                                        style={{
                                            // width: '50%',
                                            // backgroundColor: 'red',
                                            marginRight: 5
                                        }}
                                    >
                                        <Text style={{ fontSize: '1.1rem' }}>
                                            {'Despesa Pessoal Executivo (% RCL Ajustada):'}
                                        </Text>
                                    </div>
                                    <Text
                                        style={{
                                            fontSize: '1.1rem',
                                            fontWeight: 'bold',
                                            color: municipioSelecionado && municipioSelecionado?.despesaPessoalExecutivo &&
                                                municipioSelecionado.despesaPessoalExecutivo <= 54 ?
                                                'green' :
                                                '#f00'
                                        }}
                                    >
                                        {municipioSelecionado &&
                                            municipioSelecionado.despesaPessoalExecutivo ?
                                            municipioSelecionado.despesaPessoalExecutivo + '%'
                                            : 'Não informado'}
                                    </Text>
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Text style={{ fontSize: '0.8rem' }}>
                                        {'Limite legal (alínea "b" do inciso III do art. 20 da LRF)'}
                                    </Text>
                                    <Text style={{ fontSize: '0.8rem', paddingLeft: 6 }}>
                                        {'54.00%'}
                                    </Text>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Text type='secondary' style={{ fontSize: '0.8rem' }}>
                                        {municipioSelecionado &&
                                            municipioSelecionado?.despesaPessoalExecutivoAtualizadoEm ?
                                            "Dados atualizados em: " + funcRetornaNomeParadataComTempo(municipioSelecionado.despesaPessoalExecutivoAtualizadoEm, true) : ''}
                                    </Text>
                                    <Text type='secondary' style={{ fontSize: '0.8rem', paddingLeft: 6 }}>
                                        {municipioSelecionado &&
                                            municipioSelecionado?.despesaPessoalExecutivoPeriodo ? municipioSelecionado.despesaPessoalExecutivoPeriodo : ''}
                                    </Text>
                                </div>
                            </div>

                            <DividerStyled
                                style={{
                                    marginTop: 10,
                                    marginBottom: 10
                                }}
                            />
                        </div>
                    </Col>

                    <HomologRREOandRGFDataModal municipioSelecionado={municipioSelecionado} />

                    <Col {...colTodasInformacoes} style={{ marginBottom: 5, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                        <div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div
                                    style={{
                                        // width: '50%',
                                        // backgroundColor: 'red',
                                        marginRight: 5
                                    }}
                                >
                                    <Text style={{ fontSize: '1.1rem' }}>
                                        {'Artigo 167-A CF'}
                                    </Text>
                                </div>
                            </div>
                            {/* Atual */}
                            <div style={{
                                display: 'flex', justifyContent: 'space-between',
                                marginTop: 15
                            }}>
                                <div
                                    style={{
                                        // width: '50%',
                                        // backgroundColor: 'red',
                                        marginRight: 5
                                    }}
                                >
                                    <Text style={{ fontSize: '1.1rem' }}>
                                        {municipioSelecionado && municipioSelecionado?.artigo167aPeriodo ?
                                            'Período: ' + municipioSelecionado.artigo167aPeriodo : 'Período:'}
                                    </Text>
                                </div>
                                <Text
                                    style={{
                                        fontSize: '1.1rem',
                                        fontWeight: 'bold',
                                        color: municipioSelecionado && municipioSelecionado?.artigo167aResultado &&
                                            municipioSelecionado.artigo167aResultado <= 95 ?
                                            'green' :
                                            '#f00'
                                    }}
                                >
                                    {municipioSelecionado &&
                                        municipioSelecionado.artigo167aResultado ?
                                        municipioSelecionado.artigo167aResultado + '%'
                                        : 'Não informado'}
                                </Text>

                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Text style={{ fontSize: '0.8rem' }}>
                                    {'Limite máximo'}
                                </Text>
                                <Text style={{ fontSize: '0.8rem', paddingLeft: 6 }}>
                                    {'95.00%'}
                                </Text>
                            </div>

                            <div style={{ display: 'flex' }}>
                                <Text type='secondary' style={{ fontSize: '0.8rem' }}>
                                    {"Gerado em: "}
                                </Text>
                                <Text type='secondary' style={{ fontSize: '0.8rem', paddingLeft: 6 }}>
                                    {municipioSelecionado &&
                                        municipioSelecionado?.artigo167aGeradoEm ?
                                        funcRetornaNomeParadataComTempo(municipioSelecionado.artigo167aGeradoEm, true) : ''}
                                </Text>
                            </div>

                            {/* Anterior */}

                            <div style={{
                                display: 'flex', justifyContent: 'space-between',
                                marginTop: 15
                            }}>
                                <div
                                    style={{
                                        // width: '50%',
                                        // backgroundColor: 'red',
                                        marginRight: 5
                                    }}
                                >
                                    <Text style={{ fontSize: '1.1rem' }}>
                                        {municipioSelecionado && municipioSelecionado?.artigo167aAnteriorPeriodo ?
                                            'Período: ' + municipioSelecionado.artigo167aAnteriorPeriodo : 'Período:'}
                                    </Text>
                                </div>
                                <Text
                                    style={{
                                        fontSize: '1.1rem',
                                        fontWeight: 'bold',
                                        color: municipioSelecionado && municipioSelecionado?.artigo167aAnteriorResultado &&
                                            municipioSelecionado.artigo167aAnteriorResultado <= 95 ?
                                            'green' :
                                            '#f00'
                                    }}
                                >
                                    {municipioSelecionado &&
                                        municipioSelecionado.artigo167aAnteriorResultado ?
                                        municipioSelecionado.artigo167aAnteriorResultado + '%'
                                        : 'Não informado'}
                                </Text>

                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Text style={{ fontSize: '0.8rem' }}>
                                    {'Limite máximo'}
                                </Text>
                                <Text style={{ fontSize: '0.8rem', paddingLeft: 6 }}>
                                    {'95.00%'}
                                </Text>
                            </div>

                            <div style={{ display: 'flex' }}>
                                <Text type='secondary' style={{ fontSize: '0.8rem' }}>
                                    {"Gerado em: "}
                                </Text>
                                <Text type='secondary' style={{ fontSize: '0.8rem', paddingLeft: 6 }}>
                                    {municipioSelecionado &&
                                        municipioSelecionado?.artigo167aAnteriorGeradoEm ?
                                        funcRetornaNomeParadataComTempo(municipioSelecionado.artigo167aAnteriorGeradoEm, true) : ''}
                                </Text>
                            </div>

                            <div style={{ display: 'flex', marginTop: 15 }}>
                                <Text type='secondary' style={{ fontSize: '0.8rem' }}>
                                    {"Dados atualizados em: "}
                                </Text>
                                <Text type='secondary' style={{ fontSize: '0.8rem', paddingLeft: 6 }}>
                                    {municipioSelecionado &&
                                        municipioSelecionado?.artigo167aAtualizadoEm ?
                                        funcRetornaNomeParadataComTempo(municipioSelecionado.artigo167aAtualizadoEm, true) : ''}
                                </Text>
                            </div>
                        </div>

                        <DividerStyled
                            style={{
                                marginTop: 10,
                                marginBottom: 10
                            }}
                        />
                    </Col>
                    <Col {...colTodasInformacoesEsquerda} style={{ marginBottom: 5, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                        <div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div
                                    style={{
                                        // width: '50%',
                                        // backgroundColor: 'red',
                                        marginRight: 5
                                    }}
                                >
                                    <Text style={{ fontSize: '1.1rem' }}>{'Município Atendido:'}</Text>
                                </div>
                                <Text style={{ fontSize: '1.1rem', fontWeight: 'bold' }}>
                                    {municipioSelecionado &&
                                        municipioSelecionado.municipio?.municipioAtendido ?
                                        'SIM'
                                        : 'NÃO'}
                                </Text>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Text type='secondary' style={{ fontSize: '0.8rem' }}>
                                    {municipioSelecionado &&
                                        municipioSelecionado?.municipio?.municipioAtendidoAtualizadoEm ?
                                        "Dados atualizados em: " + funcRetornaNomeParadataComTempo(municipioSelecionado.municipio.municipioAtendidoAtualizadoEm, true) : ''}
                                </Text>
                                {/* <Text type='secondary' style={{ fontSize: '0.8rem' }}>
                                {municipioSelecionado &&
                                    municipioSelecionado?.despesaPessoalLegislativoPeriodo ? municipioSelecionado.despesaPessoalLegislativoPeriodo : ''}
                            </Text> */}
                            </div>
                        </div>
                        <DividerStyled
                            style={{
                                marginTop: 10,
                                marginBottom: 10
                            }}
                        />
                    </Col>

                </Row>
            }
        </>
    );
}
