import { Col, Form, Input, Row, Select } from "antd";
import { useCallback, useEffect, useRef, useState } from "react";
import { validaDigitaçãoEFormataDinheiroBR } from "../../../../services/Conversores";
import { removeAcento } from "../../../../utils/RemoveAcentos";
import { colModalInputs } from "./GridStyle";

interface IntefProps {
    submitModal: any;
    clickSalvarModalFiltros: (valorForm: any) => void;
}

export const FormModal = (
    {
        submitModal,
        clickSalvarModalFiltros
    }: IntefProps
) => {

    const [valorInputLegislativoInicial, setValorInputLegislativoInicial] = useState<any>('');
    const [valorInputLegislativoFinal, setValorInputLegislativoFinal] = useState<any>('');
    const [valorInputExecutivoInicial, setValorInputExecutivoInicial] = useState<any>('');
    const [valorInputExecutivoFinal, setValorInputExecutivoFinal] = useState<any>('');
    const formRefFiltros = useRef<any>(null);

    useEffect(() => {
        if (submitModal != null && submitModal != undefined) {

            clickSalvarModalFiltros(formRefFiltros.current)
        }
    }, [submitModal])

    const onChangeInputNumberLegislativoInicial = useCallback((e: any) => {
        const originalValue = e.target.value;//+''
        const regex = /^[0-9\.]*$/
        // const regex = /^-?[0-9][0-9\.]+$/

        if (regex.test(originalValue)) {
            setValorInputLegislativoInicial(originalValue)
            formRefFiltros.current.setFieldsValue(
                { legislativoInicial: originalValue }
            );
        }
        else {
            formRefFiltros.current.setFieldsValue(
                { legislativoInicial: valorInputLegislativoInicial }
            );
        }
    }, [valorInputLegislativoInicial]);

    const onChangeInputNumberLegislativoFinal = useCallback((e: any) => {
        const originalValue = e.target.value;//+''
        const regex = /^[0-9\.]*$/
        // const regex = /^-?[0-9][0-9\.]+$/

        if (regex.test(originalValue)) {
            setValorInputLegislativoFinal(originalValue)
            formRefFiltros.current.setFieldsValue(
                { legislativoFinal: originalValue }
            );
        }
        else {
            formRefFiltros.current.setFieldsValue(
                { legislativoFinal: valorInputLegislativoFinal }
            );
        }
    }, [valorInputLegislativoFinal]);

    const onChangeInputNumberExecutivoInicial = useCallback((e: any) => {
        const originalValue = e.target.value;//+''
        const regex = /^[0-9\.]*$/
        // const regex = /^-?[0-9][0-9\.]+$/

        if (regex.test(originalValue)) {
            setValorInputExecutivoInicial(originalValue)
            formRefFiltros.current.setFieldsValue(
                { executivoInicial: originalValue }
            );
        }
        else {
            formRefFiltros.current.setFieldsValue(
                { executivoInicial: valorInputExecutivoInicial }
            );
        }
    }, [valorInputExecutivoInicial]);

    const onChangeInputNumberExecutivoFinal = useCallback((e: any) => {
        const originalValue = e.target.value;//+''
        const regex = /^[0-9\.]*$/
        // const regex = /^-?[0-9][0-9\.]+$/

        if (regex.test(originalValue)) {
            setValorInputExecutivoFinal(originalValue)
            formRefFiltros.current.setFieldsValue(
                { executivoFinal: originalValue }
            );
        }
        else {
            formRefFiltros.current.setFieldsValue(
                { executivoFinal: valorInputExecutivoFinal }
            );
        }
    }, [valorInputExecutivoFinal]);

    const onChangeInputNumberLimiteLRFInicial = useCallback((e: any) => {
        formRefFiltros.current.setFieldsValue(
            { limiteLRFInicial: validaDigitaçãoEFormataDinheiroBR(e.target.value) }
        );
    }, []);

    const onChangeInputNumberLimiteLRFFinal = useCallback((e: any) => {

        formRefFiltros.current.setFieldsValue(
            { limiteLRFFinal: validaDigitaçãoEFormataDinheiroBR(e.target.value) }
        );

    }, []);

    return (
        <Form
            name="formColunasTableFiltros"
            layout="vertical"
            ref={formRefFiltros}
            // initialValues={{
            //     remember: true,
            // }}
            style={{
                width: '100%'
            }}

        // onFinish={onSubmitFiltros}
        // onFinishFailed={onFinishFailed}
        >
            <Row>
                <Col
                    // {...colModalInputs}
                    span={24}
                >
                    <Form.Item
                        name="populacao"
                        label="População"
                        initialValue={'todos'}
                        style={{
                            margin: 5
                        }}
                        rules={[
                            {
                                required: false,
                                message: 'Por favor selecione o perfil!',
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            optionFilterProp="children"
                            placeholder="Selecione..."
                            filterOption={(input: any, option: any) => {
                                let textDigit = removeAcento(input)
                                let listCidade = removeAcento(option?.children);
                                return listCidade.indexOf(textDigit) >= 0
                            }}
                        >
                            <Select.Option
                                value={'todos'}
                            >
                                {'Todos'}
                            </Select.Option>
                            <Select.Option
                                value={'municipiosAteVinteMil'}
                            >
                                {'Municípios até 20 mil habitantes'}
                            </Select.Option>
                            <Select.Option
                                value={'municipiosEntreVinteECinquenta'}
                            >
                                {'Municípios entre 20 mil e 50 mil habitantes'}
                            </Select.Option>
                            <Select.Option
                                value={'municipiosEntreCinquentaECem'}
                            >
                                {'Municípios entre 50 mil e 100 mil habitantes'}
                            </Select.Option>
                            <Select.Option
                                value={'municipiosAcimaDeCem'}
                            >
                                {'Municípios acima de 100 mil habitantes'}
                            </Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col {...colModalInputs}>

                    <Form.Item
                        style={{
                            margin: 5,
                        }}
                        name="legislativoInicial"
                        label="Despesa Pessoal Legislativo (% Inicial)"
                    >
                        <Input
                            // autoComplete="off"
                            placeholder="00.00"
                            onChange={(e) => onChangeInputNumberLegislativoInicial(e)}

                        />
                    </Form.Item>
                </Col>
                <Col {...colModalInputs}>
                    <Form.Item
                        style={{
                            margin: 5,
                        }}
                        name="legislativoFinal"
                        label="Despesa Pessoal Legislativo (% Final)"
                    >
                        <Input
                            // autoComplete="off"
                            placeholder="00.00"
                            onChange={(e) => onChangeInputNumberLegislativoFinal(e)}

                        />
                    </Form.Item>
                </Col>
                <Col {...colModalInputs}>

                    <Form.Item
                        style={{
                            margin: 5,
                        }}
                        name="executivoInicial"
                        label="Despesa Pessoal Executivo (% Inicial)"
                    >
                        <Input
                            // autoComplete="off"
                            placeholder="00.00"
                            onChange={(e) => onChangeInputNumberExecutivoInicial(e)}

                        />
                    </Form.Item>
                </Col>
                <Col {...colModalInputs}>
                    <Form.Item
                        style={{
                            margin: 5,
                        }}
                        name="executivoFinal"
                        label="Despesa Pessoal Executivo (% Final)"
                    >
                        <Input
                            // autoComplete="off"
                            placeholder="00.00"
                            onChange={(e) => onChangeInputNumberExecutivoFinal(e)}

                        />
                    </Form.Item>
                </Col>
                <Col {...colModalInputs}>

                    <Form.Item
                        style={{
                            margin: 5,
                        }}
                        name="limiteLRFInicial"
                        label="Limite LRF (R$ Inicial)"
                    >
                        <Input
                            // autoComplete="off"
                            placeholder="0,00"
                            onChange={(e) => onChangeInputNumberLimiteLRFInicial(e)}

                        />
                    </Form.Item>
                </Col>
                <Col {...colModalInputs}>
                    <Form.Item
                        style={{
                            margin: 5,
                        }}
                        name="limiteLRFFinal"
                        label="Limite LRF (R$ Final)"
                    >
                        <Input
                            // autoComplete="off"
                            placeholder="0,00"
                            onChange={(e) => onChangeInputNumberLimiteLRFFinal(e)}

                        />
                    </Form.Item>
                </Col>
            </Row>
        </Form>

    )
}
