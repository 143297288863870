import React from 'react';
import { Typography } from 'antd';
import './style.css';

const { Text, Title } = Typography;

interface intProps {
    descricao?: string;
    title?: string;
    corFundo?: string;
    style?: React.CSSProperties;
    resultado?: boolean;
}

export const CardSituacao = ({
    title = '',
    descricao = '',
    corFundo = '#4CA750',
    style = {},
}: intProps) => {

    let arr: any = {
        ...{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            // alignItems: 'baseline',
            justifyContent: 'space-between',
            padding: "0.25rem 0.75rem",
            // marginBottom: "0",
            // borderBottom: "none",
            background: corFundo,
            // zIndex: "3 !important",
            // margin: "0 15px",
            // borderRadius: "calc(.25rem - 1px) calc(.25rem - 1px) 0 0",
            borderRadius: "3px",
            // fontSize: "36px",
            // lineHeight: "56px",
            // textAlign: "center",
            // width: "36px",
            // height: "36px",
            // margin: "10px 10px 4px",

        }, ...style
    }

    return (
        <>
            {
                title !== '' &&
                <div
                    style={arr}
                    className='divCard_CardSituacao_ohjgvbferwfyt'
                >
                    <div
                        style={{
                            display: 'flex',
                        }}
                    >
                        <Title level={4} style={{
                            color: '#fff',
                            margin: 0,
                            // fontWeight: 'bold'
                        }}>{title}</Title>
                    </div>

                    <Text style={{
                        color: '#fff',
                        margin: 0,
                        // fontWeight: 'bold'
                    }}>{descricao}</Text>
                </div>
            }
        </>
    );
}
