import React, { useCallback, useEffect, useState, useRef } from 'react';
import {
    Modal, Tabs, Typography, notification, Button, Table, Upload, message, Row, Col,
    Input, Popconfirm, Form
} from 'antd';
import { useNavigate } from 'react-router-dom';
import { io } from 'socket.io-client';
import locale from 'antd/es/locale/pt_BR';
import Icon, {
    CloudUploadOutlined,
    InboxOutlined,
    CloseOutlined
} from '@ant-design/icons';
import { FaDownload, FaPen } from 'react-icons/fa';
import moment from 'moment';
import './style.css';

// import { colSelect } from './GridStyle';

// import { getToken } from './../../../services/LStorage/token';
import { validacaoPermissao, VerificaRota } from './../../../services/VerificaSePodeAcessar';
import LayoutDashboard from './../../../layout/LayoutAdmin';
import {
    urlHttpGovSocketIo,
    urlLimiteOperacoesImportacao,
    urlLimiteOperacoesImportacaoReprocessa,
    urlLimiteOperacoesImportacaoUltima, urlLimiteOperacoesIndexador,
    urlLimiteOperacoesLimite, urlLimiteOperacoesObjeto, urlLimiteOperacoesProduto,
    urlLimiteOperacoesPosicao,
    urlLimiteOperacoesStatus
} from './../../../services/urls';
import { api } from './../../../services/apiAxios';
import { EmitirErro } from '../../../services/EmitirErro';
import { SairDoSistema } from '../../../services/LStorage/SairSistema';
import { getToken } from './../../../services/LStorage/token';

import { convertDataUSParaBrasilEHora, convertMoedaBRDoJSComExtamente2CasasDecimais } from './../../../services/Conversores';

import { useGetColumnSearchProps } from './../../../hooks/FilterTable/string';
import { useGetColumnSearchPropsData } from './../../../hooks/FilterTable/date';

import { RcFile } from 'antd/lib/upload';
import { UploadChangeParam } from 'antd/es/upload/interface';
import { UploadFile } from 'antd/lib/upload/interface';
import { Loading } from '../../../components/Loading';
import { useGetColumnSearchPropsObj } from '../../../hooks/FilterTable/objString';
import { DividerStyled } from '../../../components/DividerStyled';

const { TabPane } = Tabs;
const { Text, Title } = Typography;

const IconDownload = (props: any) => <Icon {...props} component={FaDownload} ></Icon>
const IconPen = (props: any) => <Icon {...props} component={FaPen} ></Icon>

// const largura = window.innerWidth;
// const altura = window.innerHeight;

interface interfDadosUpload {
    "id": string,
    "createdAt"?: string | null,
    "updatedAt"?: string | null,
    "arquivo": string | null,
    "nomeArquivo": string | null,
    "usuario": {
        id: string
        nome: string
    }
}
interface DadosCidadesAll {
    "idIbge": number,
    "nome": string,
}

interface interfDataTableIndexador {
    "id": string,
    "createdAt": string | null,
    "updatedAt": string | null,
    "nome": string | null,
    "taxa": number | null
}
interface interfDataTableProduto {
    "id": string,
    "createdAt": string | null,
    "updatedAt": string | null,
    "nome": string | null,
    "indexadorId": string,
    "meses_carencia": number | null,
    "meses_prazo": number | null,
    "taxa_juros": number | null,
}

interface interfDataTableStatusOperacao {
    "id": string,
    "createdAt": string | null,
    "updatedAt": string | null,
    "nome": string | null,
    "prazo_contratacao": number | null,
    "inicio_liberacao": number | null,
    "prazo_liberacao": number | null
}
interface interfDataTableObjeto {
    "id": string,
    "createdAt": string | null,
    "updatedAt": string | null,
    "nome": string | null
}

interface interfDataTableStatus {
    "id": string,
    "createdAt": string | null,
    "updatedAt": string | null,
    "nome": string | null
}

interface interfDataTableLimiteOperacoes {
    "id": string,
    "createdAt": string | null,
    "updatedAt": string | null,
    "nome": string | null,
    "valor": number | null
}

const ImportacaoDePlanilha: React.FC = () => {

    const navigate = useNavigate();

    const refContagemDoFiltro = React.useRef<Array<any>>([]);
    const refsocket = React.useRef<any>(null);

    const refForm = useRef<any>(null);

    const refDataListCidades = useRef<Array<DadosCidadesAll>>([]);

    const [dadosTabelaState, setDadosTabelaState] = useState<Array<interfDadosUpload>>([]);

    const [dataTableStateIndexador, setDataTableStateIndexador] = useState<Array<interfDataTableIndexador>>([]);
    const [loadingIndexador, setLoadingIndexador] = useState(false);

    const [dataTableStateProduto, setDataTableStateProduto] = useState<Array<interfDataTableProduto>>([]);
    const [loadingProduto, setLoadingProduto] = useState(false);

    const [dataTableStatePosicaoOperacao, setDataTableStatePosicaoOperacao] = useState<Array<interfDataTableStatusOperacao>>([]);
    const [loadingPosicaoOperacao, setLoadingPosicaoOperacao] = useState(false);

    const [dataTableStateStatus, setDataTableStateStatus] = useState<Array<interfDataTableStatus>>([]);
    const [loadingStatus, setLoadingStatus] = useState(false);

    const [dataTableStateObjeto, setDataTableStateObjeto] = useState<Array<interfDataTableObjeto>>([]);
    const [loadingObjeto, setLoadingObjeto] = useState(false);

    const [dataTableStateLimiteOperacoes, setDataTableStateLimiteOperacoes] = useState<Array<interfDataTableLimiteOperacoes>>([]);
    const [loadingLimiteOperacoes, setLoadingLimiteOperacoes] = useState(false);

    const [visibleModal, setVisibleModal] = useState<boolean>(false);
    // const [imageUrl, setImageUrl] = useState<any>(null);
    const [arquivoObjeto, setArquivoObjeto] = useState<any>(null);
    const [loading, setLoading] = useState(false);
    const [loadingPage, setLoadingPage] = useState(false);

    useEffect(() => {
        return () => {
            desconectarSocker();
        }
    }, []);

    const desconectarSocker = useCallback((): void => {
        //desconectar socket
        if (refsocket.current) {

            refsocket.current.close();
            refsocket.current = null

        }
    }, [])

    const socketConnection = useCallback((idOperacao: any): void => {

        const socket = io(urlHttpGovSocketIo);


        refsocket.current = socket
        socket.on('connect', () => {
            socket.emit('limite_de_operacao', { limite_de_operacao: idOperacao })

            socket.on('limite_de_operacao', data => {

                if (typeof data === 'object') {

                    if (data.statusProcessamento) {
                        desconectarSocker();

                        navigate('/configuracoes/projecao-limites')
                    }

                    // setDadosTabelaState(data)
                }

                // if (typeof data === 'string') {

                //     desconectarSocker();

                //     // buscaCongelamentoPelaCidade(refIdIbge.current)
                // }

            })

        });

        // socket.on('disconnect', () => {

        //     console.log('Fechoou :D')

        // });

    }, []);

    //setando dados das cidades no selected
    const buscaUltimaOperacaoFeita = useCallback(() => {

        const buscaCiaddes = async () => {
            try {
                setLoadingPage(true);
                let result = await api.get(urlLimiteOperacoesImportacaoUltima,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        }
                    });

                if (result.data?.statusProcessamento === false) {
                    setLoadingPage(true);
                    socketConnection(result.data.id)
                }

                setLoadingPage(false);

            } catch (error) {
                let msgErro: any = (error as Error);
                setLoadingPage(false);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'municipios_dkwdfaiweyi')
                }


            }
        }

        buscaCiaddes();

    }, []);

    const buscaCidades = useCallback(() => {

        const buscaCiaddes = async () => {
            try {
                setLoadingPage(true);
                let result = await api.get(urlLimiteOperacoesImportacao,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        }
                    });

                refDataListCidades.current = result.data
                setDadosTabelaState(result.data);

                setLoadingPage(false);

            } catch (error) {
                let msgErro: any = (error as Error);
                setLoadingPage(false);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'municipios_dkwdfaiweyi')
                }


            }
        }

        buscaCiaddes();

    }, []);

    //busca de dados nas tabelas
    const reqTableIndexador = useCallback(() => {


        const funcBusca = async () => {
            try {
                setLoadingIndexador(true);
                let result = await api.get(urlLimiteOperacoesIndexador,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        }
                    });

                setDataTableStateIndexador(result.data);

                setLoadingIndexador(false);

            } catch (error) {
                setLoadingIndexador(false);
                let msgErro: any = (error as Error);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'indexador_dkwdfaiweyi')
                }


            }
        }

        funcBusca();

    }, []);

    const reqTableProduto = useCallback(() => {


        const funcBusca = async () => {
            try {
                setLoadingProduto(true);
                let result = await api.get(urlLimiteOperacoesProduto,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        }
                    });

                setDataTableStateProduto(result.data);

                setLoadingProduto(false);

            } catch (error) {
                setLoadingProduto(false);
                let msgErro: any = (error as Error);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'produto_dkwdfaiweyi')
                }


            }
        }

        funcBusca();

    }, []);

    const reqTablePosicaoOperacao = useCallback(() => {


        const funcBusca = async () => {
            try {
                setLoadingPosicaoOperacao(true);
                let result = await api.get(urlLimiteOperacoesPosicao,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        }
                    });

                setDataTableStatePosicaoOperacao(result.data);

                setLoadingPosicaoOperacao(false);

            } catch (error) {
                setLoadingPosicaoOperacao(false);
                let msgErro: any = (error as Error);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'produto_dkwdfaiweyi')
                }


            }
        }

        funcBusca();

    }, []);

    const reqTableStatus = useCallback(() => {


        const funcBusca = async () => {
            try {
                setLoadingStatus(true);
                let result = await api.get(urlLimiteOperacoesStatus,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        }
                    });

                setDataTableStateStatus(result.data);

                setLoadingStatus(false);

            } catch (error) {
                setLoadingStatus(false);
                let msgErro: any = (error as Error);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'produto_dkwdfaiweyi')
                }


            }
        }

        funcBusca();

    }, []);

    const reqTableObjeto = useCallback(() => {

        const funcBusca = async () => {
            try {
                setLoadingObjeto(true);
                let result = await api.get(urlLimiteOperacoesObjeto,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        }
                    });

                setDataTableStateObjeto(result.data);

                setLoadingObjeto(false);

            } catch (error) {
                setLoadingObjeto(false);
                let msgErro: any = (error as Error);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'produto_dkwdfaiweyi')
                }


            }
        }

        funcBusca();

    }, []);

    const reqTableLimiteOperacoes = useCallback(() => {

        const funcBusca = async () => {
            try {
                setLoadingLimiteOperacoes(true);
                let result = await api.get(urlLimiteOperacoesLimite,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        }
                    });

                setDataTableStateLimiteOperacoes(result.data);

                setLoadingLimiteOperacoes(false);

            } catch (error) {
                setLoadingLimiteOperacoes(false);
                let msgErro: any = (error as Error);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'produto_dkwdfaiweyi')
                }


            }
        }

        funcBusca();

    }, []);

    useEffect(() => {

        let arrayUrl = window.location.pathname.split("/");
        VerificaRota(arrayUrl, navigate, true, validacaoPermissao(['import_files']));

        // buscaCidades();
        buscaUltimaOperacaoFeita();
        reqTableIndexador();
        reqTableProduto();
        reqTablePosicaoOperacao();
        reqTableStatus();
        reqTableObjeto();
        reqTableLimiteOperacoes();


    }, []);

    const beforeUpload = useCallback((file: RcFile) => {
        const isJpgOrPng = (
            file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
            file.type === 'application/wps-office.xlsx'
        );
        if (!isJpgOrPng) {
            message.error('Você só pode fazer upload de arquivos XLSX!');
        }

        return isJpgOrPng;
    }, []);

    const handleChange = useCallback((info: UploadChangeParam<UploadFile<any>>) => {

        if (info?.file?.status === 'done') {
            setArquivoObjeto(info)
        }


    }, [arquivoObjeto]);

    // const clickBtnBaixarXLSX = useCallback((arquivo?: string, nomeArquivo?: string) => {

    //     const buscaXLSX = async () => {
    //         try {


    //             if (arquivo) {
    //                 let result = await api.get(urlLimiteOperacoesImportacao + '/' + arquivo,
    //                     {
    //                         headers: {
    //                             'Authorization': 'Bearer ' + getToken()
    //                         },
    //                         responseType: 'blob',
    //                     });

    //                 const url = window.URL.createObjectURL(new Blob([result.data]));
    //                 const link = document.createElement('a');
    //                 link.href = url;

    //                 link.setAttribute('download', nomeArquivo!);
    //                 document.body.appendChild(link);
    //                 link.click();

    //             }


    //         } catch (error) {

    //             let msgErro: any = (error as Error);

    //             if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
    //                 SairDoSistema();
    //                 notification.error({
    //                     message: 'Sessão expirada',
    //                     description:
    //                         'Sua sessão expirou, realize o login novamente!',
    //                 });
    //                 navigate('/');
    //             } else {
    //                 EmitirErro(error, true, 'relatorioconsolidado_dkwdfaiweyi')
    //             }


    //         }
    //     }


    //     buscaXLSX();



    // }, []);

    const clickCancelModal = useCallback(() => {
        setLoading(false)
        setArquivoObjeto(null);
        setVisibleModal(false);
    }, []);

    const dummyRequest = useCallback(({ file, onSuccess }: any) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    }, []);

    const clickBtnSubmitUploadArquivo = useCallback((arquivo: any) => {

        const buscaXLSX = async (values: any) => {
            try {

                setLoading(true)
                const formData = new FormData();

                formData.append("arquivo", arquivo.file.originFileObj);
                formData.append("coluna_inicio_cronograma", values.coluna_inicio_cronograma.toUpperCase());
                formData.append("coluna_fim_cronograma", values.coluna_fim_cronograma.toUpperCase());

                let result = await api.post(urlLimiteOperacoesImportacao, formData,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken(),
                            "Content-Type": "multipart/form-data",
                        }
                    });

                notification.success({
                    message: 'Sucesso',
                    description: "Arquivo enviado com sucesso!",
                });

                clickCancelModal();

                if (result.data.statusProcessamento === false) {
                    setLoadingPage(true);
                    socketConnection(result.data.id)
                }


                // navigate('/configuracoes/projecao-limites')
                // buscaCidades();
                // setLoading(false)

            } catch (error) {
                setLoading(false)
                let msgErro: any = (error as Error);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'relatorioconsolidado_dkwdfaiweyi')
                }


            }
        }

        refForm.current?.validateFields()
            .then((values: any) => {
                buscaXLSX(values);

            })
            .catch((errorInfo: any) => {
                notification.error({
                    message: 'Erro',
                    description:
                        'Preencha os campos obrigatorios!',
                });
            });

    }, []);

    const clickBtnSubmitRecalcularPlanilha = useCallback(() => {

        const recalcularPlanilhaReq = async () => {
            try {

                setLoading(true)

                let result = await api.get(urlLimiteOperacoesImportacaoReprocessa,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken(),
                        }
                    });

                // notification.success({
                //     message: 'Sucesso',
                //     description: "Dados recalculados com sucesso!",
                // });

                notification.success({
                    message: 'Sucesso',
                    description: "Recalculando os dados!",
                });

                if (result.data.statusProcessamento === false) {
                    setLoadingPage(true);
                    socketConnection(result.data.id)
                }

                // navigate('/configuracoes/projecao-limites')
                // buscaCidades();
                // setLoading(false)

            } catch (error) {
                setLoading(false)
                let msgErro: any = (error as Error);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'relatorioconsolidado_dkwdfaiweyi')
                }


            }
        }


        recalcularPlanilhaReq();

    }, []);

    const columns = [
        {
            title: 'Nome Arquivo',
            dataIndex: 'nomeArquivoOriginal',
            ...useGetColumnSearchProps('nomeArquivoOriginal'),
            width: '10%',
            sorter: (a: any, b: any) => a.nomeArquivoOriginal.localeCompare(b.nomeArquivoOriginal),
            showSorterTooltip: false,
        },
        {
            title: 'Usuário',
            dataIndex: 'usuario',
            ...useGetColumnSearchPropsObj('usuario'),
            width: '10%',
            sorter: (a: any, b: any) => a.usuario.nome.localeCompare(b.usuario.nome),
            showSorterTooltip: false,
            render: (usuario: any) => {

                return usuario.nome
            },
        },
        // {
        //     title: 'Validade',
        //     dataIndex: 'dataValidade',
        //     ...useGetColumnSearchPropsData('dataValidade'),
        //     width: '2%',
        //     sorter: (a: any, b: any) => {

        //         let aMonMen: any = null
        //         let bMonMen: any = null
        //         if (a.dataValidade) {
        //             aMonMen = moment(a.dataValidade).unix()
        //         } else {
        //             aMonMen = 0
        //         }
        //         if (b.dataValidade) {
        //             bMonMen = moment(b.dataValidade).unix()
        //         } else {
        //             bMonMen = 0
        //         }

        //         return aMonMen - bMonMen
        //     },
        //     showSorterTooltip: false,
        //     // sorter: (a: any, b: any) => a.dataValidade.localeCompare(b.dataValidade),
        //     render: (criado: any, record: any) => {
        //         return (
        //             <div
        //                 style={{
        //                     display: 'flex',
        //                     justifyContent: 'space-between',
        //                     alignItems: 'center'
        //                 }}
        //             >

        //                 <Text>
        //                     {
        //                         criado ? convertDataUSParaBrasil(criado) : '-'
        //                     }
        //                 </Text>

        //                 {/* <img style={{
        //                     width: 20,
        //                     marginLeft: 5
        //                 }} src={record.status ? ImgDownLike : ImgUpLike} /> */}
        //             </div>
        //         )
        //     },
        // },
        {
            title: 'Criado em',
            dataIndex: 'createdAt',
            ...useGetColumnSearchPropsData('createdAt'),
            width: '2%',
            sorter: (a: any, b: any) => {

                let aMonMen: any = null
                let bMonMen: any = null
                if (a.createdAt) {
                    aMonMen = moment(a.createdAt).unix()
                } else {
                    aMonMen = 0
                }
                if (b.createdAt) {
                    bMonMen = moment(b.createdAt).unix()
                } else {
                    bMonMen = 0
                }

                return aMonMen - bMonMen
            },
            showSorterTooltip: false,
            // sorter: (a: any, b: any) => a.update_at.localeCompare(b.update_at),
            render: (data: any) => {

                return (
                    convertDataUSParaBrasilEHora(data)
                )
            },
        },
        // {
        //     title: '',
        //     dataIndex: 'arquivo',
        //     width: '2%',
        //     showSorterTooltip: false,
        //     render: (arquivo: any, record: any) => {
        //         return (
        //             <div className='naoElinha'>
        //                 {
        //                     arquivo ?
        //                         <Button
        //                             type="primary"
        //                             className="botaoNaolinha"
        //                             // disabled={!hasSelectedAdd}
        //                             onClick={() => { clickBtnBaixarXLSX(arquivo, record.nomeArquivo) }}
        //                         >
        //                             {'Baixar '}<IconDownload />
        //                         </Button>
        //                         :
        //                         <Text
        //                             style={{
        //                                 fontWeight: 'bold',
        //                                 color: record.status ? 'red' : 'green'
        //                             }}
        //                         >
        //                             {'Não há XLSX!'}
        //                         </Text>
        //                 }

        //             </div>
        //         )
        //     },
        // },
    ];

    const columnsTableInDexador = [
        {
            title: 'Nome',
            dataIndex: 'nome',
            ...useGetColumnSearchProps('nome'),
            width: '8%',
            sorter: (a: any, b: any) => a.nome.localeCompare(b.nome),
            showSorterTooltip: false,
        },
        {
            title: 'Taxa (%)',
            dataIndex: 'taxa',
            // ...useGetColumnSearchProps('email'),
            width: '6%',
            // sorter: (a: any, b: any) => a.email.localeCompare(b.email),
            showSorterTooltip: false,
            render: (taxa: any) => (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    {
                        taxa &&
                        <Text>
                            {
                                taxa
                            }
                        </Text>
                    }
                </div>
            ),
        },
        {
            title: 'Período',
            dataIndex: 'periodo',
            // ...useGetColumnSearchProps('email'),
            width: '6%',
            // sorter: (a: any, b: any) => a.email.localeCompare(b.email),
            showSorterTooltip: false,
            render: (periodo: any) => (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    {
                        periodo &&
                        <Text>
                            {
                                periodo
                            }
                        </Text>
                    }
                </div>
            ),
        },
        {
            title: 'Atualizado em',
            dataIndex: 'updatedAt',
            ...useGetColumnSearchPropsData('updatedAt'),
            width: '3%',
            sorter: (a: any, b: any) => {

                let aMonMen: any = null
                let bMonMen: any = null
                if (a.updatedAt) {
                    aMonMen = moment(a.updatedAt).unix()
                } else {
                    aMonMen = 0
                }
                if (b.updatedAt) {
                    bMonMen = moment(b.updatedAt).unix()
                } else {
                    bMonMen = 0
                }

                return aMonMen - bMonMen
            },
            showSorterTooltip: false,
            // sorter: (a: any, b: any) => a.updatedAt.localeCompare(b.updatedAt),
            render: (atualizado: any) => (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <Text>
                        {
                            atualizado ? convertDataUSParaBrasilEHora(atualizado) : '-'
                        }
                    </Text>
                </div>
            ),
        },
    ];

    const columnsTableProduto = [
        {
            title: 'Nome',
            dataIndex: 'nome',
            ...useGetColumnSearchProps('nome'),
            width: '10%',
            sorter: (a: any, b: any) => a.nome.localeCompare(b.nome),
            showSorterTooltip: false,
        },
        {
            title: 'Taxa de juros (%)',
            dataIndex: 'taxa_juros',
            // ...useGetColumnSearchProps('email'),
            width: '3%',
            // sorter: (a: any, b: any) => a.email.localeCompare(b.email),
            showSorterTooltip: false,
            render: (taxa_juros: any) => (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    {
                        taxa_juros &&
                        <Text>
                            {
                                taxa_juros
                            }
                        </Text>
                    }
                </div>
            ),
        },
        {
            title: 'Meses de carência',
            dataIndex: 'meses_carencia',
            // ...useGetColumnSearchProps('email'),
            width: '3%',
            // sorter: (a: any, b: any) => a.email.localeCompare(b.email),
            showSorterTooltip: false,
            render: (meses_carencia: any) => (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    {
                        meses_carencia &&
                        <Text>
                            {
                                meses_carencia
                            }
                        </Text>
                    }
                </div>
            ),
        },
        {
            title: 'Meses de prazo',
            dataIndex: 'meses_prazo',
            // ...useGetColumnSearchProps('email'),
            width: '3%',
            // sorter: (a: any, b: any) => a.email.localeCompare(b.email),
            showSorterTooltip: false,
            render: (meses_prazo: any) => (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    {
                        meses_prazo &&
                        <Text>
                            {
                                meses_prazo
                            }
                        </Text>
                    }
                </div>
            ),
        },
        {
            title: 'Atualizado em',
            dataIndex: 'updatedAt',
            ...useGetColumnSearchPropsData('updatedAt'),
            width: '3%',
            sorter: (a: any, b: any) => {

                let aMonMen: any = null
                let bMonMen: any = null
                if (a.updatedAt) {
                    aMonMen = moment(a.updatedAt).unix()
                } else {
                    aMonMen = 0
                }
                if (b.updatedAt) {
                    bMonMen = moment(b.updatedAt).unix()
                } else {
                    bMonMen = 0
                }

                return aMonMen - bMonMen
            },
            showSorterTooltip: false,
            // sorter: (a: any, b: any) => a.updatedAt.localeCompare(b.updatedAt),
            render: (atualizado: any) => (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <Text>
                        {
                            atualizado ? convertDataUSParaBrasilEHora(atualizado) : '-'
                        }
                    </Text>
                </div>
            ),
        },
    ];

    const columnsTablePosicaoOperacao = [
        {
            title: 'Nome',
            dataIndex: 'nome',
            ...useGetColumnSearchProps('nome'),
            width: '10%',
            sorter: (a: any, b: any) => a.nome.localeCompare(b.nome),
            showSorterTooltip: false,
        },
        // {
        //     title: 'Prazo de contratação',
        //     dataIndex: 'prazo_contratacao',
        //     // ...useGetColumnSearchProps('email'),
        //     width: '2%',
        //     // sorter: (a: any, b: any) => a.email.localeCompare(b.email),
        //     showSorterTooltip: false,
        //     render: (prazo_contratacao: any) => (
        //         <div
        //             style={{
        //                 display: 'flex',
        //                 alignItems: 'center'
        //             }}
        //         >
        //             {
        //                 prazo_contratacao &&
        //                 <Text>
        //                     {
        //                         prazo_contratacao
        //                     }
        //                 </Text>
        //             }
        //         </div>
        //     ),
        // },
        // {
        //     title: 'Inicio de liberação',
        //     dataIndex: 'inicio_liberacao',
        //     // ...useGetColumnSearchProps('email'),
        //     width: '2%',
        //     // sorter: (a: any, b: any) => a.email.localeCompare(b.email),
        //     showSorterTooltip: false,
        //     render: (inicio_liberacao: any) => (
        //         <div
        //             style={{
        //                 display: 'flex',
        //                 alignItems: 'center'
        //             }}
        //         >
        //             {
        //                 inicio_liberacao &&
        //                 <Text>
        //                     {
        //                         inicio_liberacao
        //                     }
        //                 </Text>
        //             }
        //         </div>
        //     ),
        // },
        // {
        //     title: 'Prazo de liberação',
        //     dataIndex: 'prazo_liberacao',
        //     // ...useGetColumnSearchProps('email'),
        //     width: '2%',
        //     // sorter: (a: any, b: any) => a.email.localeCompare(b.email),
        //     showSorterTooltip: false,
        //     render: (prazo_liberacao: any) => (
        //         <div
        //             style={{
        //                 display: 'flex',
        //                 alignItems: 'center'
        //             }}
        //         >
        //             {
        //                 prazo_liberacao &&
        //                 <Text>
        //                     {
        //                         prazo_liberacao
        //                     }
        //                 </Text>
        //             }
        //         </div>
        //     ),
        // },
        {
            title: 'Atualizado em',
            dataIndex: 'updatedAt',
            ...useGetColumnSearchPropsData('updatedAt'),
            width: '3%',
            sorter: (a: any, b: any) => {

                let aMonMen: any = null
                let bMonMen: any = null
                if (a.updatedAt) {
                    aMonMen = moment(a.updatedAt).unix()
                } else {
                    aMonMen = 0
                }
                if (b.updatedAt) {
                    bMonMen = moment(b.updatedAt).unix()
                } else {
                    bMonMen = 0
                }

                return aMonMen - bMonMen
            },
            showSorterTooltip: false,
            // sorter: (a: any, b: any) => a.updatedAt.localeCompare(b.updatedAt),
            render: (atualizado: any) => (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <Text>
                        {
                            atualizado ? convertDataUSParaBrasilEHora(atualizado) : '-'
                        }
                    </Text>
                </div>
            ),
        },
    ];

    const columnsTableStatus = [
        {
            title: 'Nome',
            dataIndex: 'nome',
            ...useGetColumnSearchProps('nome'),
            width: '10%',
            sorter: (a: any, b: any) => a.nome.localeCompare(b.nome),
            showSorterTooltip: false,
        },
        {
            title: 'Atualizado em',
            dataIndex: 'updatedAt',
            ...useGetColumnSearchPropsData('updatedAt'),
            width: '3%',
            sorter: (a: any, b: any) => {

                let aMonMen: any = null
                let bMonMen: any = null
                if (a.updatedAt) {
                    aMonMen = moment(a.updatedAt).unix()
                } else {
                    aMonMen = 0
                }
                if (b.updatedAt) {
                    bMonMen = moment(b.updatedAt).unix()
                } else {
                    bMonMen = 0
                }

                return aMonMen - bMonMen
            },
            showSorterTooltip: false,
            // sorter: (a: any, b: any) => a.updatedAt.localeCompare(b.updatedAt),
            render: (atualizado: any) => (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <Text>
                        {
                            atualizado ? convertDataUSParaBrasilEHora(atualizado) : '-'
                        }
                    </Text>
                </div>
            ),
        },
    ];

    const columnsTableObjeto = [
        {
            title: 'Nome',
            dataIndex: 'nome',
            ...useGetColumnSearchProps('nome'),
            width: '10%',
            sorter: (a: any, b: any) => a.nome.localeCompare(b.nome),
            showSorterTooltip: false,
        },
        {
            title: 'Atualizado em',
            dataIndex: 'updatedAt',
            ...useGetColumnSearchPropsData('updatedAt'),
            width: '3%',
            sorter: (a: any, b: any) => {

                let aMonMen: any = null
                let bMonMen: any = null
                if (a.updatedAt) {
                    aMonMen = moment(a.updatedAt).unix()
                } else {
                    aMonMen = 0
                }
                if (b.updatedAt) {
                    bMonMen = moment(b.updatedAt).unix()
                } else {
                    bMonMen = 0
                }

                return aMonMen - bMonMen
            },
            showSorterTooltip: false,
            // sorter: (a: any, b: any) => a.updatedAt.localeCompare(b.updatedAt),
            render: (atualizado: any) => (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <Text>
                        {
                            atualizado ? convertDataUSParaBrasilEHora(atualizado) : '-'
                        }
                    </Text>
                </div>
            ),
        },
    ];

    const columnsTableLimiteOperacoes = [
        {
            title: 'Nome',
            dataIndex: 'nome',
            ...useGetColumnSearchProps('nome'),
            width: '10%',
            sorter: (a: any, b: any) => a.nome.localeCompare(b.nome),
            showSorterTooltip: false,
        },
        {
            title: 'Valor (R$)',
            dataIndex: 'valor',
            // ...useGetColumnSearchProps('email'),
            width: '6%',
            // sorter: (a: any, b: any) => a.email.localeCompare(b.email),
            showSorterTooltip: false,
            render: (valor: any) => (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    {
                        valor &&
                        <Text>
                            {
                                convertMoedaBRDoJSComExtamente2CasasDecimais(valor, true)
                            }
                        </Text>
                    }
                </div>
            ),
        },
        {
            title: 'Atualizado em',
            dataIndex: 'updatedAt',
            ...useGetColumnSearchPropsData('updatedAt'),
            width: '3%',
            sorter: (a: any, b: any) => {

                let aMonMen: any = null
                let bMonMen: any = null
                if (a.updatedAt) {
                    aMonMen = moment(a.updatedAt).unix()
                } else {
                    aMonMen = 0
                }
                if (b.updatedAt) {
                    bMonMen = moment(b.updatedAt).unix()
                } else {
                    bMonMen = 0
                }

                return aMonMen - bMonMen
            },
            showSorterTooltip: false,
            // sorter: (a: any, b: any) => a.updatedAt.localeCompare(b.updatedAt),
            render: (atualizado: any) => (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <Text>
                        {
                            atualizado ? convertDataUSParaBrasilEHora(atualizado) : '-'
                        }
                    </Text>
                </div>
            ),
        },
    ];

    return (
        <LayoutDashboard>
            <Loading
                loading={loadingPage}
            >

                <Row>
                    <Col span={24}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            marginBottom: 15
                        }}
                    >
                        <Text
                            style={{
                                fontWeight: 'bold',
                                fontSize: '1rem'
                            }}
                        >
                            {'Verifique se os dados (Indexador, Produtos, Posição, Status, Objetos e Limites de Operação) estão de acordo com a planilha a ser importada. Em caso de diferença, faça as alterações antes de importar.'}
                        </Text>
                    </Col>
                    <Col span={24}
                        style={{
                            display: 'flex',
                            flexDirection: 'row'
                        }}
                    >
                        <Title level={4}
                            style={{ marginRight: 5 }}
                        >
                            {"Indexadores"}
                        </Title>
                        <Button
                            type="primary"
                            className='ImportacaoPlanilha_BotaoAmarelo_fshbcwefb'
                            // style={{ margin: 2 }}
                            // disabled={!hasSelectedAdd}
                            onClick={() => {
                                navigate('/configuracoes/indexador')
                            }}
                        >
                            <IconPen />
                        </Button>
                    </Col>
                </Row>
                <Table
                    className='marginTopTables'
                    rowKey={(record) => record.id}
                    loading={loadingIndexador}
                    columns={columnsTableInDexador}
                    dataSource={dataTableStateIndexador}
                    scroll={{ x: 850, y: 150 }}
                    size="small"
                    pagination={false}
                    locale={locale.Table}
                />

                <DividerStyled
                    style={{
                        marginTop: 20,
                        marginBottom: 20,
                        backgroundColor: '#1890FF'
                    }}
                />

                <Row>
                    <Col span={24}
                        style={{
                            display: 'flex',
                            flexDirection: 'row'
                        }}
                    >
                        <Title level={4}
                            style={{ marginRight: 5 }}
                        >
                            {"Produtos"}
                        </Title>
                        <Button
                            type="primary"
                            className='ImportacaoPlanilha_BotaoAmarelo_fshbcwefb'
                            // style={{ margin: 2 }}
                            // disabled={!hasSelectedAdd}
                            onClick={() => {
                                navigate('/configuracoes/produto')
                            }}
                        >
                            <IconPen />
                        </Button>
                    </Col>
                </Row>
                <Table
                    className='marginTopTables'
                    rowKey={(record) => record.id}
                    loading={loadingProduto}
                    columns={columnsTableProduto}
                    dataSource={dataTableStateProduto}
                    scroll={{ x: 850, y: 150 }}
                    size="small"
                    pagination={false}
                    locale={locale.Table}
                />

                <DividerStyled
                    style={{
                        marginTop: 20,
                        marginBottom: 20,
                        backgroundColor: '#1890FF'
                    }}
                />
                <Row>
                    <Col span={24}
                        style={{
                            display: 'flex',
                            flexDirection: 'row'
                        }}
                    >
                        <Title level={4}
                            style={{ marginRight: 5 }}
                        >
                            {"Posição"}
                        </Title>
                        <Button
                            type="primary"
                            className='ImportacaoPlanilha_BotaoAmarelo_fshbcwefb'
                            // style={{ margin: 2 }}
                            // disabled={!hasSelectedAdd}
                            onClick={() => {
                                navigate('/configuracoes/posicao-operacao')
                            }}
                        >
                            <IconPen />
                        </Button>
                    </Col>
                </Row>
                <Table
                    className='marginTopTables'
                    rowKey={(record) => record.id}
                    loading={loadingPosicaoOperacao}
                    columns={columnsTablePosicaoOperacao}
                    dataSource={dataTableStatePosicaoOperacao}
                    scroll={{ x: 850, y: 150 }}
                    size="small"
                    pagination={false}
                    locale={locale.Table}
                />

                <DividerStyled
                    style={{
                        marginTop: 20,
                        marginBottom: 20,
                        backgroundColor: '#1890FF'
                    }}
                />
                <Row>
                    <Col span={24}
                        style={{
                            display: 'flex',
                            flexDirection: 'row'
                        }}
                    >
                        <Title level={4}
                            style={{ marginRight: 5 }}
                        >
                            {"Status"}
                        </Title>
                        <Button
                            type="primary"
                            className='ImportacaoPlanilha_BotaoAmarelo_fshbcwefb'
                            // style={{ margin: 2 }}
                            // disabled={!hasSelectedAdd}
                            onClick={() => {
                                navigate('/configuracoes/status')
                            }}
                        >
                            <IconPen />
                        </Button>
                    </Col>
                </Row>
                <Table
                    className='marginTopTables'
                    rowKey={(record) => record.id}
                    loading={loadingStatus}
                    columns={columnsTableStatus}
                    dataSource={dataTableStateStatus}
                    scroll={{ x: 850, y: 150 }}
                    size="small"
                    pagination={false}
                    locale={locale.Table}
                />

                <DividerStyled
                    style={{
                        marginTop: 20,
                        marginBottom: 20,
                        backgroundColor: '#1890FF'
                    }}
                />
                <Row>
                    <Col span={24}
                        style={{
                            display: 'flex',
                            flexDirection: 'row'
                        }}
                    >
                        <Title level={4}
                            style={{ marginRight: 5 }}
                        >
                            {"Objetos"}
                        </Title>
                        <Button
                            type="primary"
                            className='ImportacaoPlanilha_BotaoAmarelo_fshbcwefb'
                            // style={{ margin: 2 }}
                            // disabled={!hasSelectedAdd}
                            onClick={() => {
                                navigate('/configuracoes/objeto')
                            }}
                        >
                            <IconPen />
                        </Button>
                    </Col>
                </Row>
                <Table
                    className='marginTopTables'
                    rowKey={(record) => record.id}
                    loading={loadingObjeto}
                    columns={columnsTableObjeto}
                    dataSource={dataTableStateObjeto}
                    scroll={{ x: 850, y: 150 }}
                    size="small"
                    pagination={false}
                    locale={locale.Table}
                />

                <DividerStyled
                    style={{
                        marginTop: 20,
                        marginBottom: 20,
                        backgroundColor: '#1890FF'
                    }}
                />
                <Row>
                    <Col span={24}
                        style={{
                            display: 'flex',
                            flexDirection: 'row'
                        }}
                    >
                        <Title
                            level={4}
                            style={{ marginRight: 5 }}
                        >
                            {"Limites de operação"}
                        </Title>
                        <Button
                            type="primary"
                            className='ImportacaoPlanilha_BotaoAmarelo_fshbcwefb'
                            // style={{ margin: 2 }}
                            // disabled={!hasSelectedAdd}
                            onClick={() => {
                                navigate('/configuracoes/limite')
                            }}
                        >
                            <IconPen />
                        </Button>
                    </Col>
                </Row>
                <Table
                    className='marginTopTables'
                    rowKey={(record) => record.id}
                    loading={loadingLimiteOperacoes}
                    columns={columnsTableLimiteOperacoes}
                    dataSource={dataTableStateLimiteOperacoes}
                    scroll={{ x: 850, y: 150 }}
                    size="small"
                    pagination={false}
                    locale={locale.Table}
                />

                <DividerStyled
                    style={{
                        marginTop: 20,
                        marginBottom: 20,
                        backgroundColor: '#1890FF'
                    }}
                />

                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginBottom: 15
                    }}
                >
                    <Popconfirm
                        key="popRecalcularPlanilha"
                        placement="topRight"
                        title={() => (
                            <div className='popConfirmEditAtendimentos'>
                                <Text>
                                    {`Os cálculos dos saldos contábeis serão refeitos com base nos dados acima, tem certeza que deseja continuar?`}
                                </Text>
                            </div>
                        )}
                        onConfirm={() => {
                            clickBtnSubmitRecalcularPlanilha();
                        }}
                        onCancel={() => { }}
                        okText="Sim"
                        cancelText="Não"
                    >
                        <Button
                            type="primary"
                            className="ImportacaoPlanilha_BotaoAmarelo_fshbcwefb"
                            style={{ margin: 2 }}
                        // disabled={!hasSelectedAdd}
                        // onClick={() => { setVisibleModal(true) }}
                        >
                            Recalcular planilha
                        </Button>
                    </Popconfirm>
                    <Popconfirm
                        key="pop"
                        placement="topRight"
                        title={() => (
                            <div className='popConfirmEditAtendimentos'>
                                <Text>
                                    {`Os cálculos dos saldos contábeis serão feitos com base nos dados acima, tem certeza que deseja continuar?`}
                                </Text>
                            </div>
                        )}
                        onConfirm={() => { setVisibleModal(true) }}
                        onCancel={() => { }}
                        okText="Sim"
                        cancelText="Não"
                    >
                        <Button
                            type="primary"
                            className="botaoVerdeBaixarTodas"
                            style={{ margin: 2 }}
                        // disabled={!hasSelectedAdd}
                        // onClick={() => { setVisibleModal(true) }}
                        >
                            Upload Arquivo<CloudUploadOutlined />
                        </Button>
                    </Popconfirm>
                </div>

                {/* <Table
                    style={{
                        // marginTop: 50
                    }}
                    className='marginTopTables'
                    rowKey={(record) => record.id}
                    // loading={loadingTable}
                    // onRow={(record, rowIndex) => {
                    //     return {
                    //         onClick: event => { onclickTable(record, rowIndex) }, // click row
                    //         onDoubleClick: event => { }, // double click row
                    //         onContextMenu: event => { }, // right button click row
                    //         onMouseEnter: event => { }, // mouse enter row
                    //         onMouseLeave: event => { }, // mouse leave row
                    //     };
                    // }}
                    // rowSelection={rowSelection}
                    columns={columns} dataSource={dadosTabelaState}
                    scroll={{ x: 650 }}
                    onChange={
                        (pagination, filters, sorter, extra) => {
                            refContagemDoFiltro.current = extra.currentDataSource
                        }
                    }
                    size="small"
                    pagination={{
                        locale: { items_per_page: '' },
                        showSizeChanger: false,
                        defaultPageSize: 100,
                        // position: ['topRight', 'bottomRight'],
                        position: [],
                        pageSizeOptions: ['10', '20', '50', '100']
                    }}
                    locale={locale.Table}
                /> */}
            </Loading>
            <Modal
                title="Upload Arquivo"
                visible={visibleModal}
                onOk={() => clickBtnSubmitUploadArquivo(arquivoObjeto)}
                onCancel={() => clickCancelModal()}
                style={{
                    top: 20
                }}
                // className="modalDadasLimite_kaevdkfaed"
                footer={[
                    <Button
                        key="back"
                        onClick={clickCancelModal}
                        loading={loading}
                    >
                        Cancelar
                    </Button>,
                    <Button
                        key="submit"
                        type="primary"
                        loading={loading}
                        onClick={() => clickBtnSubmitUploadArquivo(arquivoObjeto)}
                        disabled={!arquivoObjeto}
                    >
                        Salvar
                    </Button>
                ]}
                key="modalUploadArquivos"
            >
                <Loading
                    loading={loading}
                >
                    <Upload.Dragger
                        // name="file"
                        // listType="picture-card"

                        // className="avatar-uploader"
                        style={{
                            // width: 400,
                            // height: 128
                        }}
                        // multiple={true}
                        // fileList={arquivoObjeto}
                        showUploadList={false}

                        customRequest={dummyRequest}
                        // action={() => {
                        //     return new Promise((resolve, reject) => {
                        //         reject('done');
                        //     })
                        // }}
                        beforeUpload={beforeUpload}
                        onChange={handleChange}
                    // onPreview={() => {}}
                    // onRemove={(file) => {console.log(file); return (true)}}
                    >
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text" style={{ color: 'red' }}>Envio somente de arquivos .XLSX</p>
                        <p className="ant-upload-text">Clique ou arraste o arquivo para esta área para fazer upload</p>
                    </Upload.Dragger>
                    {arquivoObjeto &&
                        <div style={{
                            marginTop: 8,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                        }}>
                            <Text>
                                {arquivoObjeto?.file?.name}
                            </Text>
                            <Button
                                type='text'
                                danger
                                style={{ margin: 0, padding: 0 }}
                                icon={<CloseOutlined />}
                                onClick={() => {
                                    setArquivoObjeto(null)
                                }}
                            />
                        </div>
                    }
                    {arquivoObjeto &&
                        <DividerStyled
                        />
                    }
                    <Form
                        ref={refForm}
                        name="adicionarUsuario"
                        layout="vertical"
                        // onFieldsChange={onFieldsChange}
                        initialValues={{
                            remember: true,
                        }}
                        // onFinish={onFinish}
                        // onFinishFailed={() => { }}
                        autoComplete="off"
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row'
                            }}
                        >
                            <div
                                style={{
                                    width: '100%'
                                }}
                            >
                                <Form.Item
                                    style={{
                                        margin: 5
                                    }}
                                    name="coluna_inicio_cronograma"
                                    label="Coluna inicio do cronograma"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Por favor digite no max duas letras!',
                                            max: 2,
                                            pattern: /^[A-Za-z]+$/,
                                        },
                                    ]}
                                >
                                    <Input
                                        autoComplete="off"
                                        placeholder=""
                                        maxLength={2}
                                        style={{
                                            width: '100%',
                                            textTransform: 'uppercase'
                                        }}
                                    />
                                </Form.Item>
                            </div>
                            <div
                                style={{
                                    width: '100%'
                                }}
                            >
                                <Form.Item
                                    style={{
                                        margin: 5
                                    }}
                                    name="coluna_fim_cronograma"
                                    label="Coluna fim do cronograma"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Por favor digite no max duas letras!',
                                            max: 2,
                                            pattern: /^[A-Za-z]+$/
                                        },
                                    ]}
                                >
                                    <Input
                                        autoComplete="off"
                                        placeholder=""
                                        maxLength={2}
                                        style={{
                                            width: '100%',
                                            textTransform: 'uppercase'
                                        }}
                                    />
                                </Form.Item>
                            </div>
                        </div>
                    </Form>
                </Loading>
            </Modal>
        </LayoutDashboard >
    );
}

export default ImportacaoDePlanilha;
