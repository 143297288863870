import { useCallback, useState } from "react";
import { Button, Input, Space } from "antd";
import Icon, { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { FaSearch } from 'react-icons/fa';
import {
    removeAcento
} from '../../utils/RemoveAcentos';

const IconSerach = (props: any) => <Icon {...props} component={FaSearch} ></Icon>

//Search da tabela de listagem
export const useGetColumnSearchProps = (dataIndex: any) => {

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');

    const handleSearch = useCallback((selectedKeys: any, confirm: any, dataIndex: any): void => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    }, [searchText]);

    const handleReset = useCallback((clearFilters: any): void => {
        clearFilters();
        setSearchText('');
    }, []);

    let searchInput: any = '';
    return ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => {
            return (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={node => {
                            searchInput = node;
                        }}
                        placeholder={`Digite...`}
                        value={selectedKeys[0]}
                        onChange={(e: any) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : [])
                        }
                        }
                        onPressEnter={(e) => { e.stopPropagation(); handleSearch(selectedKeys, confirm, dataIndex); }}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => {
                                handleSearch(selectedKeys, confirm, dataIndex)
                            }}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Pesquisar
                        </Button>
                        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                            Limpar
                        </Button>
                    </Space>
                </div>
            )
        },
        filterIcon: (filtered: any) => filtered ? <IconSerach style={{ color: filtered ? '#1890ff' : undefined, fontSize: filtered ? 14 : undefined }} /> : <SearchOutlined />,
        onFilter: (value: any, record: any) => {

            let trecord = removeAcento(record[dataIndex]?.toString());

            return record[dataIndex] ? trecord.includes(removeAcento(value)) : false

        },
        onFilterDropdownVisibleChange: (visible: any) => {
            if (visible) {
                setTimeout(() => searchInput.select());
            }
        },
        render: (text: any) => {

            return (
                <>
                    {searchedColumn === dataIndex ? (
                        <Highlighter
                            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                            searchWords={[searchText]}
                            autoEscape
                            textToHighlight={text ? text.toString() : ''}
                        />
                    ) : (
                        text
                    )}
                </>
            )
        }
    })
};
