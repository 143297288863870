import { Col, Form, Input, Row, Select, Spin, Button, Divider } from "antd";
import { useCallback, useEffect, useRef, useState } from "react";
import { removeAcento } from "../../../../../utils/RemoveAcentos";
import { colModalInputs } from "./GridStyle";
import { getToken } from "../../../../../services/LStorage/token";
import { api } from "../../../../../services/apiAxios";
import { EmitirErro } from "../../../../../services/EmitirErro";
import { urlLimiteOperacoesObjeto, urlLimiteOperacoesPosicao, urlLimiteOperacoesProduto } from "../../../../../services/urls";

interface IntefPropsDefault {
    nome: string;
    id: string;
}
interface IntefProps {
    submitModal: any;
    clickSalvarModalFiltros: (valorForm: any) => void;
}

export const FormModal = (
    {
        submitModal,
        clickSalvarModalFiltros
    }: IntefProps
) => {

    const formRefFiltros = useRef<any>(null);

    const [valorInputLegislativoInicial, setValorInputLegislativoInicial] = useState<any>('');

    const refBotaoAddTodos = useRef<any>(null);
    const refSelectListTodos = useRef<any>(null);

    const refLimiteOperacoesPosicao = useRef<Array<IntefPropsDefault>>([]);
    const [limiteOperacoesPosicao, setLimiteOperacoesPosicao] = useState<Array<IntefPropsDefault>>([]);
    const [loadingLimiteOperacoesPosicao, setLoadingLimiteOperacoesPosicao] = useState(false);

    const refLimiteOperacoesObjeto = useRef<Array<IntefPropsDefault>>([]);
    const [limiteOperacoesObjeto, setLimiteOperacoesObjeto] = useState<Array<IntefPropsDefault>>([]);
    const [loadingLimiteOperacoesObjeto, setLoadingLimiteOperacoesObjeto] = useState(false);

    const refLimiteOperacoesProduto = useRef<Array<IntefPropsDefault>>([]);
    const [limiteOperacoesProduto, setLimiteOperacoesProduto] = useState<Array<IntefPropsDefault>>([]);
    const [loadingLimiteOperacoesProduto, setLoadingLimiteOperacoesProduto] = useState(false);
    // posicao - obj - produto - destaque

    //setando dados das cidades no selected
    const buscaDadosLimiteOperacoesPosicao = useCallback(() => {

        const buscaCiaddes = async () => {
            try {
                setLoadingLimiteOperacoesPosicao(true);
                let result = await api.get(urlLimiteOperacoesPosicao,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        }
                    });
                let initValue: Array<any> = [];

                result.data.forEach((obj: IntefPropsDefault) => {
                    initValue.push(obj.nome);
                });

                setTimeout(() => {

                    formRefFiltros.current.setFieldsValue(
                        {
                            posicoes: initValue,
                        }
                    );
                    setLimiteOperacoesPosicao(result.data);
                    refLimiteOperacoesPosicao.current = result.data
                    setLoadingLimiteOperacoesPosicao(false);

                }, 100);

            } catch (error) {
                EmitirErro(error, true, 'addoredituser_dkwdfaiweyi')

                setLoadingLimiteOperacoesPosicao(false);

            }
        }

        buscaCiaddes();

    }, []);

    //setando dados das cidades no selected
    const buscaDadosLimiteOperacoesObjeto = useCallback(() => {

        const buscaCiaddes = async () => {
            try {
                setLoadingLimiteOperacoesObjeto(true);
                let result = await api.get(urlLimiteOperacoesObjeto,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        }
                    });

                let initValue: Array<any> = [];

                result.data.forEach((obj: IntefPropsDefault) => {
                    initValue.push(obj.nome);
                });

                setTimeout(() => {

                    formRefFiltros.current.setFieldsValue(
                        {
                            objetos: initValue,
                        }
                    );

                    setLimiteOperacoesObjeto(result.data);
                    refLimiteOperacoesObjeto.current = result.data
                    setLoadingLimiteOperacoesObjeto(false);

                }, 100);





            } catch (error) {
                EmitirErro(error, true, 'addoredituser_dkwdfaiweyi')

                setLoadingLimiteOperacoesObjeto(false);

            }
        }

        buscaCiaddes();

    }, []);

    //setando dados das cidades no selected
    const buscaDadosLimiteOperacoesProduto = useCallback(() => {

        const buscaCiaddes = async () => {
            try {
                setLoadingLimiteOperacoesProduto(true);
                let result = await api.get(urlLimiteOperacoesProduto,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        }
                    });
                let initValue: Array<any> = [];

                result.data.forEach((obj: IntefPropsDefault) => {
                    initValue.push(obj.nome);
                });

                setTimeout(() => {

                    formRefFiltros.current.setFieldsValue(
                        {
                            produtos: initValue,
                            // produtosState: result.data,
                        }
                    );
                    setLimiteOperacoesProduto(result.data);
                    refLimiteOperacoesProduto.current = result.data
                    setLoadingLimiteOperacoesProduto(false);
                }, 100)


            } catch (error) {
                EmitirErro(error, true, 'addoredituser_dkwdfaiweyi')

                setLoadingLimiteOperacoesProduto(false);

            }
        }

        buscaCiaddes();

    }, []);

    useEffect(() => {
        buscaDadosLimiteOperacoesPosicao();
        buscaDadosLimiteOperacoesObjeto();
        buscaDadosLimiteOperacoesProduto();
    }, [])

    useEffect(() => {
        if (submitModal != null && submitModal != undefined) {

            clickSalvarModalFiltros(formRefFiltros.current)
        }
    }, [submitModal])

    //Adicionar todos posicoes no Campo
    const onClickAddTodosPosicao = useCallback(() => {

        if (formRefFiltros.current.getFieldValue('posicoes')?.length != refLimiteOperacoesPosicao.current.length) {
            let arrayAll: any = [];
            refLimiteOperacoesPosicao.current.forEach(val => {
                arrayAll.push(val.nome);
            });
            formRefFiltros.current.setFieldsValue(
                { posicoes: arrayAll }
            );

            refBotaoAddTodos.current?.blur();
            refSelectListTodos.current?.blur();

        }
    }, []);

    //Adicionar todos objetos no Campo
    const onClickAddTodosObjetos = useCallback(() => {

        if (formRefFiltros.current.getFieldValue('objetos')?.length != refLimiteOperacoesObjeto.current.length) {
            let arrayAll: any = [];
            refLimiteOperacoesObjeto.current.forEach(val => {
                arrayAll.push(val.nome);
            });
            formRefFiltros.current.setFieldsValue(
                { objetos: arrayAll }
            );

            refBotaoAddTodos.current?.blur();
            refSelectListTodos.current?.blur();

        }
    }, []);

    //Adicionar todos produtos no Campo
    const onClickAddTodosProdutos = useCallback(() => {

        if (formRefFiltros.current.getFieldValue('produtos')?.length != refLimiteOperacoesProduto.current.length) {
            let arrayAll: any = [];
            refLimiteOperacoesProduto.current.forEach(val => {
                arrayAll.push(val.nome);
            });
            formRefFiltros.current.setFieldsValue(
                { produtos: arrayAll }
            );

            refBotaoAddTodos.current?.blur();
            refSelectListTodos.current?.blur();

        }
    }, []);

    return (
        <Form
            name="formColunasTableFiltros"
            layout="vertical"
            ref={formRefFiltros}
            // initialValues={{
            //     produtosState: refLimiteOperacoesProduto.current,
            // }}
            style={{
                width: '100%'
            }}
        >
            <Row>
                <Col
                    {...colModalInputs}
                // span={24}
                >
                    <Form.Item
                        name="destaque"
                        label="Destaque"
                        initialValue={'todos'}
                        style={{
                            margin: 5
                        }}
                        rules={[
                            {
                                required: false,
                                message: 'Por favor selecione o destaque!',
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            optionFilterProp="children"
                            placeholder="Selecione..."
                            filterOption={(input: any, option: any) => {
                                let textDigit = removeAcento(input)
                                let listCidade = removeAcento(option?.children);
                                return listCidade.indexOf(textDigit) >= 0
                            }}
                        >
                            <Select.Option
                                value={'todos'}
                            >
                                {'Todos'}
                            </Select.Option>
                            <Select.Option
                                value={'S'}
                            >
                                {'Sim'}
                            </Select.Option>
                            <Select.Option
                                value={'N'}
                            >
                                {'Não'}
                            </Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col {...colModalInputs}>
                    <Form.Item
                        name="posicoes"
                        label="Posição"
                        style={{ margin: 5 }}
                        rules={[
                            {
                                required: false,
                                message: 'Por favor selecione o posição!',
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            allowClear
                            optionFilterProp="children"
                            placeholder="Selecione..."
                            loading={loadingLimiteOperacoesPosicao}
                            mode='multiple'
                            notFoundContent={loadingLimiteOperacoesPosicao ? <Spin size="small" /> : null}
                            filterOption={(input: any, option: any) => {
                                let textDigit = removeAcento(input)
                                let listCidade = removeAcento(option?.children);
                                return listCidade.indexOf(textDigit) >= 0
                            }}
                            ref={refSelectListTodos}
                            dropdownRender={menu => (
                                <div>

                                    <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                        <Button
                                            ref={refBotaoAddTodos}
                                            block
                                            onClick={onClickAddTodosPosicao}
                                            type="dashed"
                                        >Todos</Button>
                                    </div>
                                    <Divider style={{ margin: '4px 0' }} />
                                    {menu}
                                </div>

                            )}
                        >
                            {
                                limiteOperacoesPosicao.map((item) => {
                                    return (
                                        <Select.Option
                                            value={item.nome}
                                            key={item.id}
                                        >
                                            {item.nome}
                                        </Select.Option>
                                    )
                                })
                            }
                        </Select>
                    </Form.Item>
                </Col>
                <Col {...colModalInputs}>
                    <Form.Item
                        name="objetos"
                        label="Objeto"
                        style={{ margin: 5 }}
                        rules={[
                            {
                                required: false,
                                message: 'Por favor selecione o objeto!',
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            allowClear
                            optionFilterProp="children"
                            placeholder="Selecione..."
                            loading={loadingLimiteOperacoesObjeto}
                            mode='multiple'
                            notFoundContent={loadingLimiteOperacoesObjeto ? <Spin size="small" /> : null}
                            filterOption={(input: any, option: any) => {
                                let textDigit = removeAcento(input)
                                let listCidade = removeAcento(option?.children);
                                return listCidade.indexOf(textDigit) >= 0
                            }}
                            ref={refSelectListTodos}
                            dropdownRender={menu => (
                                <div>

                                    <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                        <Button
                                            ref={refBotaoAddTodos}
                                            block
                                            onClick={onClickAddTodosObjetos}
                                            type="dashed"
                                        >Todos</Button>
                                    </div>
                                    <Divider style={{ margin: '4px 0' }} />
                                    {menu}
                                </div>

                            )}
                        >
                            {
                                limiteOperacoesObjeto.map((item) => {
                                    return (
                                        <Select.Option
                                            value={item.nome}
                                            key={item.id}
                                        >
                                            {item.nome}
                                        </Select.Option>
                                    )
                                })
                            }
                        </Select>
                    </Form.Item>
                </Col>
                <Col {...colModalInputs}>
                    <Form.Item
                        name="produtos"
                        label="Produto"
                        style={{ margin: 5 }}
                        rules={[
                            {
                                required: false,
                                message: 'Por favor selecione o produto!',
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            allowClear
                            optionFilterProp="children"
                            placeholder="Selecione..."
                            loading={loadingLimiteOperacoesProduto}
                            mode='multiple'
                            notFoundContent={loadingLimiteOperacoesProduto ? <Spin size="small" /> : null}
                            filterOption={(input: any, option: any) => {
                                let textDigit = removeAcento(input)
                                let listCidade = removeAcento(option?.children);
                                return listCidade.indexOf(textDigit) >= 0
                            }}
                            ref={refSelectListTodos}
                            dropdownRender={menu => (
                                <div>

                                    <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                        <Button
                                            ref={refBotaoAddTodos}
                                            block
                                            onClick={onClickAddTodosProdutos}
                                            type="dashed"
                                        >Todos</Button>
                                    </div>
                                    <Divider style={{ margin: '4px 0' }} />
                                    {menu}
                                </div>

                            )}
                        >
                            {
                                limiteOperacoesProduto.map((item) => {
                                    return (
                                        <Select.Option
                                            value={item.nome}
                                            key={item.id}
                                        >
                                            {item.nome}
                                        </Select.Option>
                                    )
                                })
                            }
                        </Select>
                    </Form.Item>
                </Col>
                {/* <Col {...colModalInputs}>
                    <Form.Item
                        name="produtosState"
                        label="Produto"
                        hidden
                        style={{ margin: 5 }}
                        rules={[
                            {
                                required: false,
                                message: 'Por favor selecione o produto!',
                            },
                        ]}
                    ></Form.Item>
                </Col> */}
            </Row>
        </Form>

    )
}
