import { useCallback, useEffect, useState } from 'react';
import { Table, Typography, notification } from 'antd';
import Icon from '@ant-design/icons';
import { FaCheckCircle, FaExclamationCircle, FaBan } from 'react-icons/fa';
import moment from 'moment';

import { convertDataUSParaBrasil, convertDataUSParaBrasilEHora, funcRetornaNomeParadataComTempo } from '../../../../services/Conversores';
import { useNavigate } from 'react-router-dom';
import locale from 'antd/es/locale/pt_BR';
import { urlMunicipioSaneamentoRespostas } from './../../../../services/urls';
import { EmitirErro } from '../../../../services/EmitirErro';
import { SairDoSistema } from '../../../../services/LStorage/SairSistema';
import { api } from '../../../../services/apiAxios';
import { getToken } from './../../../../services/LStorage/token';
import { useGetColumnSearchProps } from './../../../../hooks/FilterTable/string';
import { useGetColumnSearchPropsData } from './../../../../hooks/FilterTable/date';

const IconFaCheckCircle = (props: any) => <Icon {...props} component={FaCheckCircle} ></Icon>
const IconFaExclamationCircle = (props: any) => <Icon {...props} component={FaExclamationCircle} ></Icon>
const IconFaBan = (props: any) => <Icon {...props} component={FaBan} ></Icon>

const { Text, Title } = Typography;


interface IReqSaneamentoIndicadoresRespostas {
    "numero_quesito"?: string | null,
    "resposta"?: string | null,
    "quesito"?: string | null,
    "topico"?: string | null,
    "_id": string,
}

interface IReqSaneamentoIndicadores {
    "indicador"?: string | null,
    "respostas": Array<IReqSaneamentoIndicadoresRespostas>,
    "_id": string
}

interface IReqSaneamento {
    "_id": string,
    "idIbge": number,
    "data_importacao_arquivo"?: string | null,
    "ano_base"?: string | null,
    "indicadores": Array<IReqSaneamentoIndicadores>
}

const reqSimulacao = {
    "_id": "661438c94bf8da8981eb2c2d",
    "idIbge": 3500105,
    "data_importacao_arquivo": "2024-04-08T18:34:55.144Z",
    "indicadores": [
        {
            "indicador": "Abastecimento de água",
            "respostas": [
                {
                    "numero_quesito": "3",
                    "resposta": "Sim",
                    "quesito": "O município dispõe de serviço de abastecimento e distribuição de água?",
                    "topico": "Água Potável",
                    "_id": "661438c74bf8da8981eafc15"
                }
            ],
            "_id": "661438c74bf8da8981eafc14"
        },
        {
            "indicador": "Limpeza urbana e manejo de resíduos sólidos",
            "respostas": [
                {
                    "numero_quesito": "13.0",
                    "resposta": "Sim",
                    "quesito": "Antes de aterrar o lixo, o município realiza algum tipo de processamento de resíduos?",
                    "topico": "Aterros Municipais",
                    "_id": "661438c74bf8da8981eafc17"
                },
                {
                    "numero_quesito": "13.1",
                    "resposta": "Compostagem",
                    "quesito": "Assinale qual a forma realizada de processamento de resíduos:",
                    "topico": "Aterros Municipais",
                    "_id": "661438c74bf8da8981eafc18"
                },
                {
                    "numero_quesito": "13.1",
                    "resposta": "Reciclagem",
                    "quesito": "Assinale qual a forma realizada de processamento de resíduos:",
                    "topico": "Aterros Municipais",
                    "_id": "661438c74bf8da8981eafc19"
                },
                {
                    "numero_quesito": "13.1",
                    "resposta": "Reutilização",
                    "quesito": "Assinale qual a forma realizada de processamento de resíduos:",
                    "topico": "Aterros Municipais",
                    "_id": "661438c74bf8da8981eafc1a"
                },
                {
                    "numero_quesito": "14.0",
                    "resposta": "Sim",
                    "quesito": "Existe aterro para os resíduos sólidos urbanos (lixo doméstico e limpeza urbana) no município?",
                    "topico": "Aterros Municipais",
                    "_id": "661438c74bf8da8981eafc1b"
                },
            ]
        }
    ]
}

interface interfDadosMunicipioSelecionado {
    municipioSelecionado?: {
        "idIbge": number,
        "nome": string,
    }
}

export const Saneamento = ({
    municipioSelecionado
}: interfDadosMunicipioSelecionado) => {

    const navigate = useNavigate();

    const [dadosTabelaState, setDadosTabelaState] = useState<IReqSaneamento>();

    const [loading, setLoading] = useState(false);

    //setando dados das cidades no selected
    const buscaCidades = useCallback((idIbge: number) => {


        const buscaCiaddes = async () => {
            try {
                setLoading(true);

                let result = await api.get(urlMunicipioSaneamentoRespostas + '/' + idIbge,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        }
                    });


                setDadosTabelaState(result.data);


                setLoading(false);

            } catch (error) {

                let msgErro: any = (error as Error);
                setLoading(false);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'municipios_dkwdfaiweyi')
                }


            }
        }

        buscaCiaddes();

    }, []);


    useEffect(() => {

        if (municipioSelecionado) buscaCidades(municipioSelecionado.idIbge);

    }, [municipioSelecionado]);

    const columns = [
        {
            title: 'Tópico',
            dataIndex: 'topico',
            ...useGetColumnSearchProps('topico'),
            width: '6%',
            sorter: (a: any, b: any) => a.topico.localeCompare(b.topico),
            showSorterTooltip: false,
        },
        {
            title: 'Nº Quesito',
            dataIndex: 'numero_quesito',
            ...useGetColumnSearchProps('numero_quesito'),
            width: '2%',
            sorter: (a: any, b: any) => a.numero_quesito.localeCompare(b.numero_quesito),
            showSorterTooltip: false,
        },
        {
            title: 'Quesito',
            dataIndex: 'quesito',
            ...useGetColumnSearchProps('quesito'),
            width: '12%',
            sorter: (a: any, b: any) => a.quesito.localeCompare(b.quesito),
            showSorterTooltip: false,
        },
        {
            title: 'Resposta',
            dataIndex: 'resposta',
            ...useGetColumnSearchProps('resposta'),
            width: '12%',
            sorter: (a: any, b: any) => a.resposta.localeCompare(b.resposta),
            showSorterTooltip: false,
        },
        {
            title: 'Ano Base',
            dataIndex: 'periodo',
            ...useGetColumnSearchProps('periodo'),
            width: '2%',
            sorter: (a: any, b: any) => a.periodo.localeCompare(b.periodo),
            showSorterTooltip: false,
        },
    ];


    return (
        <>
            {dadosTabelaState?.ano_base &&
                < div
                    style={{
                        display: 'flex',
                        // justifyContent: 'flex-end',
                        // justifyContent: 'flex-end'
                        marginBottom: 15
                    }}
                >
                    <Text type='secondary' style={{ fontSize: '0.9rem' }}>
                        {
                            "Ano base dos dados: " + dadosTabelaState?.ano_base
                        }
                    </Text>
                </div>
            }
            {dadosTabelaState?.data_importacao_arquivo &&
                < div
                    style={{
                        display: 'flex',
                        // justifyContent: 'flex-end',
                        // justifyContent: 'flex-end'
                        marginBottom: 15
                    }}
                >
                    <Text type='secondary' style={{ fontSize: '0.9rem' }}>
                        {
                            "Data de importação: " + convertDataUSParaBrasilEHora(dadosTabelaState?.data_importacao_arquivo)
                        }
                    </Text>
                </div>
            }
            {
                dadosTabelaState &&
                    dadosTabelaState?.indicadores &&
                    dadosTabelaState?.indicadores.length > 0 ?
                    dadosTabelaState?.indicadores.map((reqCauc, index) => {

                        return (
                            <div
                                key={'divCauc' + reqCauc._id}
                            >
                                <Title
                                    style={{
                                        fontSize: '0.9rem',
                                        marginTop: 25,
                                        marginBottom: 10,
                                    }}
                                >
                                    {
                                        reqCauc?.indicador ? reqCauc.indicador : ''
                                    }
                                </Title>
                                <Table
                                    style={{
                                        // marginTop: 50
                                    }}
                                    key={'tableCauc' + reqCauc._id}
                                    className='marginTopTables'
                                    rowKey={(record) => record._id}
                                    columns={columns} dataSource={reqCauc?.respostas ? reqCauc.respostas : []}
                                    scroll={{ x: 1000 }}
                                    size="small"
                                    pagination={{
                                        locale: { items_per_page: '' },
                                        showSizeChanger: false,
                                        defaultPageSize: 100,
                                        // position: ['topRight', 'bottomRight'],
                                        position: [],
                                        pageSizeOptions: ['10', '20', '50', '100']
                                    }}
                                    locale={locale.Table}
                                />
                                <div

                                    style={{
                                        marginTop: 30,
                                        marginBottom: 30,
                                    }}
                                />
                            </div>
                        )
                    })
                    :
                    <></>
            }
        </>
    );
}
