import { useCallback, useEffect, useState, useRef } from 'react';
import {
    Row, Col, Typography, Button, notification, Divider,
    Form, Input
} from 'antd';
import Icon, { AreaChartOutlined } from '@ant-design/icons';
import { FaCog } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom';

import { colInputs, colFull } from './GridStyle';

// import { getToken } from './../../services/LStorage/token';
import { VerificaRota } from './../../../../../services/VerificaSePodeAcessar';
import LayoutDashboard from './../../../../../layout/LayoutAdmin';
import { urlLimiteOperacoesPosicao } from './../../../../../services/urls';
import { api } from './../../../../../services/apiAxios';
import { EmitirErro } from '../../../../../services/EmitirErro';
import { SairDoSistema } from '../../../../../services/LStorage/SairSistema';
import { getToken } from './../../../../../services/LStorage/token';
// import './style.css';

import { Loading } from '../../../../../components/Loading';


const { Text, Title, Link } = Typography;

const IconCogs = (props: any) => (<Icon {...props} component={FaCog} ></Icon>);
// const largura = window.innerWidth;
// const altura = window.innerHeight;

const StatusAddOrUpdate = () => {

    const navigate = useNavigate();
    let { id } = useParams();

    const refForm = useRef<any>(null);

    const [estaEditando, setEstaEditando] = useState(false);

    const [valorNumeroPrazoContratacao, setValorNumeroPrazoContratacao] = useState('');
    const [valorNumeroInicioLiberacao, setValorNumeroInicioLiberacao] = useState('');
    const [valorNumeroPrazoLiberacao, setValorNumeroPrazoLiberacao] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => () => {

        let dadosLocalStorage: string | null = localStorage.getItem('@DesenvolveSP:configuracoesEditar');
        let localUserData: any = null;
        if (typeof dadosLocalStorage == 'string') {
            localUserData = JSON.parse(dadosLocalStorage);
        }

        if (localUserData) {
            localStorage.removeItem('@DesenvolveSP:configuracoesEditar');
        }

    }, []);

    //setando dados das cidades no selected
    const buscaDadosUsuario = useCallback((id: string) => {

        const buscaCiaddes = async () => {
            try {
                //pega dados do localStorage se existir
                let dadosLocalStorage: string | null = localStorage.getItem('@DesenvolveSP:configuracoesEditar');
                let localUserData: any = null;
                if (typeof dadosLocalStorage == 'string') {
                    localUserData = JSON.parse(dadosLocalStorage);
                }
                // localUserData?.step4.horarioDasNotificacoes? localUserData?.step4.horarioDasNotificacoes: undefined,

                if (localUserData) {

                    refForm.current?.setFieldsValue(
                        {
                            nome: localUserData.nome ? localUserData.nome : undefined,
                            prazo_contratacao: localUserData.prazo_contratacao ? localUserData.prazo_contratacao : undefined,
                            inicio_liberacao: localUserData.inicio_liberacao ? localUserData.inicio_liberacao : undefined,
                            prazo_liberacao: localUserData.prazo_liberacao ? localUserData.prazo_liberacao : undefined,
                        }
                    );
                }

            } catch (error) {
                let msgErro: any = (error as Error);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'addoredituser_dkwdfaiweyi')
                }


            }
        }

        buscaCiaddes();

    }, []);

    useEffect(() => {

        let arrayUrl = window.location.pathname.split("/");
        // VerificaRota(arrayUrl, navigate, true, validacaoPermissao(['manage_user']));
        VerificaRota(arrayUrl, navigate, true);

        if (typeof id === 'string') {
            if (id === 'criar') {
                setEstaEditando(false)
            } else {
                setEstaEditando(true)
                buscaDadosUsuario(id);
            }
        }


    }, []);

    const submitBotaoSalvar = useCallback(() => {


        const salvarDadosCriar = async (obj: any) => {
            try {
                setLoading(true);
                let resultUser = await api.post(urlLimiteOperacoesPosicao, obj,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        }
                    });

                notification.success({
                    message: 'Sucesso',
                    description:
                        'Seus dados foram criados com sucesso!',
                });

                setLoading(false);

                navigate('/configuracoes/posicao-operacao')

            } catch (error) {
                setLoading(false);
                let msgErro: any = (error as Error);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'addoredituser_dkwdfaiweyi')
                }


            }
        }

        refForm.current?.validateFields()
            .then((values: any) => {

                salvarDadosCriar(values);

            })
            .catch((errorInfo: any) => {
                notification.error({
                    message: 'Erro',
                    description:
                        'Preencha os campos obrigatorios!',
                });
            });


    }, []);

    const submitBotaoEditar = useCallback((idUser: string) => {


        const salvarDadosEditar = async (obj: any) => {
            try {
                setLoading(true);

                let resultUser = await api.put(urlLimiteOperacoesPosicao + '/' + idUser, obj,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        }
                    });

                notification.success({
                    message: 'Sucesso',
                    description:
                        'Seus dados foram editados com sucesso!',
                });

                // setDataListCidades(resultCidade.data);

                setLoading(false);
                navigate('/configuracoes/posicao-operacao');

            } catch (error) {
                setLoading(false);
                let msgErro: any = (error as Error);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'addoredituser_dkwdfaiweyi')
                }


            }
        }

        refForm.current?.validateFields()
            .then((values: any) => {

                salvarDadosEditar(values);

            })
            .catch((errorInfo: any) => {
                notification.error({
                    message: 'Erro',
                    description:
                        'Preencha os campos obrigatorios!',
                });
            });


    }, []);

    const onChangeInputNumberPrazoContratacao = useCallback((e: any) => {
        const originalValue = e.target.value;//+''
        const regex = /^[0-9]*$/

        if (regex.test(originalValue)) {
            setValorNumeroPrazoContratacao(originalValue)
            refForm.current.setFieldsValue(
                { prazo_contratacao: originalValue }
            );
        }
        else {
            refForm.current.setFieldsValue(
                { prazo_contratacao: valorNumeroPrazoContratacao }
            );
        }
    }, [valorNumeroPrazoContratacao]);

    const onChangeInputNumberInicioLiberacao = useCallback((e: any) => {
        const originalValue = e.target.value;//+''
        const regex = /^[0-9]*$/

        if (regex.test(originalValue)) {
            setValorNumeroInicioLiberacao(originalValue)
            refForm.current.setFieldsValue(
                { inicio_liberacao: originalValue }
            );
        }
        else {
            refForm.current.setFieldsValue(
                { inicio_liberacao: valorNumeroInicioLiberacao }
            );
        }
    }, [valorNumeroInicioLiberacao]);

    const onChangeInputNumberPrazoLiberacao = useCallback((e: any) => {
        const originalValue = e.target.value;//+''
        const regex = /^[0-9]*$/

        if (regex.test(originalValue)) {
            setValorNumeroPrazoLiberacao(originalValue)
            refForm.current.setFieldsValue(
                { prazo_liberacao: originalValue }
            );
        }
        else {
            refForm.current.setFieldsValue(
                { prazo_liberacao: valorNumeroPrazoLiberacao }
            );
        }
    }, [valorNumeroPrazoLiberacao]);

    return (
        <LayoutDashboard>
            <Loading

                loading={loading}
            >
                <Row>
                    <Col {...colFull}>
                        <Title level={4}>
                            <IconCogs style={{ fontSize: 23, marginRight: 10 }} />
                            {`${estaEditando ? 'Editar' : 'Adicionar'} Posição`}
                        </Title>
                        <div
                            style={{
                                display: 'flex'
                            }}
                        >
                            <Link
                                // href="#"
                                onClick={() => [
                                    navigate('/configuracoes/posicao-operacao')
                                ]}
                                style={{
                                    marginRight: 5
                                }}
                            // target="_blank"
                            >
                                <Text
                                    type='secondary' underline
                                >Posição</Text>
                            </Link>

                            <Text
                                style={{
                                    marginRight: 5
                                }} type='secondary'
                            >/</Text>

                            <Text style={{
                                marginRight: 5
                            }}>{`${estaEditando ? 'Editar' : 'Adicionar'}`}</Text>
                        </div>
                        <Divider />
                    </Col>
                </Row>

                <Form
                    ref={refForm}
                    name="adicionarUsuario"
                    layout="vertical"
                    // onFieldsChange={onFieldsChange}
                    initialValues={{
                        remember: true,
                    }}
                    // onFinish={onFinish}
                    // onFinishFailed={() => { }}
                    autoComplete="off"
                >
                    <Row>
                        <Col {...colInputs}>
                            <Form.Item
                                style={{
                                    margin: 5
                                }}
                                name="nome"
                                label="Nome"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Por favor digite seu nome!',
                                    },
                                ]}
                            >
                                <Input autoComplete="off" placeholder="Nome" />
                            </Form.Item>
                        </Col>
                        {/* <Col {...colInputs}>
                            <Form.Item
                                style={{
                                    margin: 5
                                }}
                                name="prazo_contratacao"
                                label="Prazo de contratação"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Por favor digite o prazo!',
                                    },
                                ]}
                            >
                                <Input
                                    autoComplete="off"
                                    placeholder=""
                                    onChange={onChangeInputNumberPrazoContratacao}
                                />
                            </Form.Item>
                        </Col>
                        <Col {...colInputs}>
                            <Form.Item
                                style={{
                                    margin: 5
                                }}
                                name="inicio_liberacao"
                                label="Inicio de liberação"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Por favor preencha este campo!',
                                    },
                                ]}
                            >
                                <Input
                                    autoComplete="off"
                                    placeholder=""
                                    onChange={onChangeInputNumberInicioLiberacao}
                                />
                            </Form.Item>
                        </Col>
                        <Col {...colInputs}>
                            <Form.Item
                                style={{
                                    margin: 5
                                }}
                                name="prazo_liberacao"
                                label="Prazo de liberação"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Por favor digite o prazo!',
                                    },
                                ]}
                            >
                                <Input
                                    autoComplete="off"
                                    placeholder=""
                                    onChange={onChangeInputNumberPrazoLiberacao}
                                />
                            </Form.Item>
                        </Col> */}
                        <Col {...colFull}>
                            <div className="botaoSalvarStep" style={{
                                flexDirection: 'row',
                                display: 'flex',
                                justifyContent: 'flex-end',
                                // background: 'red'
                            }}>
                                <Button
                                    key="volatrForm"
                                    // type="primary"
                                    style={{
                                        marginTop: 20
                                    }}
                                    onClick={() => {
                                        navigate('/configuracoes/posicao-operacao')
                                    }}
                                >
                                    {"Voltar"}
                                </Button>
                                <Button
                                    key="submit"
                                    type="primary"
                                    style={{
                                        marginLeft: 5,
                                        marginTop: 20,
                                    }}
                                    onClick={() => {
                                        estaEditando ?
                                            submitBotaoEditar(id ? id : 'criar')
                                            :
                                            submitBotaoSalvar()
                                    }}
                                >
                                    {"Salvar"}
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Loading>

        </LayoutDashboard >
    );
}

export default StatusAddOrUpdate;
