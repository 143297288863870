import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Typography, notification, Col, Row } from 'antd';

import { useNavigate } from 'react-router-dom';
import { GlobalContext } from '../../../../../../contexts/globalContext';
import { urlPoliticosProcesso } from './../../../../../../services/urls';
import { EmitirErro } from '../../../../../../services/EmitirErro';
import { SairDoSistema } from '../../../../../../services/LStorage/SairSistema';
import { api } from '../../../../../../services/apiAxios';
import { getToken } from './../../../../../../services/LStorage/token';
import { colListT } from './GridStyle';
import { Loading } from '../../../../../../components/Loading';
import { DividerStyled } from '../../../../../../components/DividerStyled';
import { convertDataUSParaBrasil } from '../../../../../../services/Conversores';

const { Text } = Typography;

// const largura = window.innerWidth;
// const altura = window.innerHeight;

interface interfProcessosTJSP {
    assunto: string | null
    classe: string | null
    cpf: string
    foro: string
    link: string | null
    nome: string
    numero_process: string | null
    parte: string | null
    recebido_em: string | null
    vara: string | null
    _id: string
}


interface interfDadosMunicipioSelecionado {
    dadosPoliticoSelecionado?: {
        cpf: string,
        nome: string,
        parceiro: string,
        cargo: string,
        status: string,
        img: string,
    }
}

export const ProcessosTJSP = ({
    dadosPoliticoSelecionado
}: interfDadosMunicipioSelecionado) => {

    const navigate = useNavigate();
    const { theme } = useContext(GlobalContext);

    const [dadosProcessosTJSPReq, setDadosProcessosTJSPReq] = useState<Array<interfProcessosTJSP>>([]);
    const [arrDeForos, setArrDeForos] = useState<Array<string>>([]);
    const [loading, setLoading] = useState(false);

    //setando dados das cidades no selected
    const buscaCidades = useCallback((cpfPolitico: string) => {

        const buscaCiaddes = async () => {
            try {
                setLoading(true);
                let result = await api.get(urlPoliticosProcesso + '/' + cpfPolitico,
                    // let result = await api.get(urlPoliticosProcesso + '/' + '312321321312',
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        }
                    });

                // const result = {
                //     data: [
                //         {
                //             "_id": "62faacefvfe88fe653fe4574e7a008",
                //             "cpf": "312321321312", "numero_process": "1002388-83.2020.8.26.0024",
                //             "nome": "Izael Antonio Fernandes", "classe": "Execução de Título Extrajudicial",
                //             "assunto": "Cheque",
                //             "foro": "Foro de Andradina", "parte": "Exectdo",
                //             "recebido_em": "2020-08-05T03:00:00.000Z", "vara": "1º Vara",
                //             "link": "https://esaj.tjsp.jus.br/cpopg/show.do?processo.codigo=0O00050F00000&processo.foro=24&conversationId=&cbPesquisa=NMPARTE&dadosConsulta.valorConsulta=IZAEL+Ant%C3%B4nio+fernandes&chNmCompleto=true&cdForo=-1&paginaConsulta=1"
                //         },
                //         {
                //             "_id": "5498789798erthrtw",
                //             "cpf": "312321321312", "numero_process": "1002388-83.2020.8.26.0024",
                //             "nome": "Izael Antonio Fernandes", "classe": "Execução de Título Extrajudicial",
                //             "assunto": "Cheque",
                //             "foro": "Foro de Cafezal do sul", "parte": "Exectdo",
                //             "recebido_em": "2020-08-05T03:00:00.000Z", "vara": "1º Vara",
                //             "link": "https://esaj.tjsp.jus.br/cpopg/show.do?processo.codigo=0O00050F00000&processo.foro=24&conversationId=&cbPesquisa=NMPARTE&dadosConsulta.valorConsulta=IZAEL+Ant%C3%B4nio+fernandes&chNmCompleto=true&cdForo=-1&paginaConsulta=1"
                //         },
                //         {
                //             "_id": "62faa88fe653fe4574e7a008897",
                //             "cpf": "312321321312", "numero_process": "1002388-83.2020.8.26.0024",
                //             "nome": "Izael Antonio Fernandes", "classe": "Execução de Título Extrajudicial",
                //             "assunto": "Cheque",
                //             "foro": "Foro de Andradina", "parte": "Exectdo",
                //             "recebido_em": "2020-08-05T03:00:00.000Z", "vara": "1º Vara",
                //             "link": "https://esaj.tjsp.jus.br/cpopg/show.do?processo.codigo=0O00050F00000&processo.foro=24&conversationId=&cbPesquisa=NMPARTE&dadosConsulta.valorConsulta=IZAEL+Ant%C3%B4nio+fernandes&chNmCompleto=true&cdForo=-1&paginaConsulta=1"
                //         },
                //         {
                //             "_id": "62faa88fe653fe4574e7a008897897wded",
                //             "cpf": "312321321312", "numero_process": "1002388-83.2020.8.26.0024",
                //             "nome": "Izael Antonio Fernandes", "classe": "Execução de Título Extrajudicial",
                //             "assunto": "Cheque",
                //             "foro": "Foro de Andradina", "parte": "Exectdo",
                //             "recebido_em": "2020-08-05T03:00:00.000Z", "vara": "1º Vara",
                //             "link": "https://esaj.tjsp.jus.br/cpopg/show.do?processo.codigo=0O00050F00000&processo.foro=24&conversationId=&cbPesquisa=NMPARTE&dadosConsulta.valorConsulta=IZAEL+Ant%C3%B4nio+fernandes&chNmCompleto=true&cdForo=-1&paginaConsulta=1"
                //         },
                //         {
                //             "_id": "62faa88fe653feragaergt74e7a008",
                //             "cpf": "312321321312", "numero_process": "1002388-83.2020.8.26.0024",
                //             "nome": "Izael Antonio Fernandes", "classe": "Execução de Título Extrajudicial",
                //             "assunto": "Cheque",
                //             "foro": "Foro de Jose Bonifacio", "parte": "Exectdo",
                //             "recebido_em": "2020-08-05T03:00:00.000Z", "vara": "1º Vara",
                //             "link": "https://esaj.tjsp.jus.br/cpopg/show.do?processo.codigo=0O00050F00000&processo.foro=24&conversationId=&cbPesquisa=NMPARTE&dadosConsulta.valorConsulta=IZAEL+Ant%C3%B4nio+fernandes&chNmCompleto=true&cdForo=-1&paginaConsulta=1"
                //         },
                //         {
                //             "_id": "62faa88fe653feragaergt74edswfvawsf7a008",
                //             "cpf": "312321321312", "numero_process": "1002388-83.2020.8.26.0024",
                //             "nome": "Izael Antonio Fernandes", "classe": "Execução de Título Extrajudicial",
                //             "assunto": "Cheque",
                //             "foro": "Foro de Jose Brasilandia", "parte": "Exectdo",
                //             "recebido_em": "2020-08-05T03:00:00.000Z", "vara": "1º Vara",
                //             "link": "https://esaj.tjsp.jus.br/cpopg/show.do?processo.codigo=0O00050F00000&processo.foro=24&conversationId=&cbPesquisa=NMPARTE&dadosConsulta.valorConsulta=IZAEL+Ant%C3%B4nio+fernandes&chNmCompleto=true&cdForo=-1&paginaConsulta=1"
                //         },
                //         {
                //             "_id": "aregyteaqrg5re89gh74987",
                //             "cpf": "312321321312", "numero_process": "1002388-83.2020.8.26.0024",
                //             "nome": "Izael Antonio Fernandes", "classe": "Execução de Título Extrajudicial",
                //             "assunto": "Cheque",
                //             "foro": "Foro de Jose Bonifacio", "parte": "Exectdo",
                //             "recebido_em": "2020-08-05T03:00:00.000Z", "vara": "1º Vara",
                //             "link": "https://esaj.tjsp.jus.br/cpopg/show.do?processo.codigo=0O00050F00000&processo.foro=24&conversationId=&cbPesquisa=NMPARTE&dadosConsulta.valorConsulta=IZAEL+Ant%C3%B4nio+fernandes&chNmCompleto=true&cdForo=-1&paginaConsulta=1"
                //         },

                //     ]
                // }

                let arr: Array<any> = []
                result.data.forEach((element: any, index: any) => {
                    if (index === 0) {
                        arr.push(element.foro)
                    } else {
                        let isIgual = false
                        arr.forEach((elementArr: string, jindexx) => {
                            if (elementArr === element.foro) {
                                isIgual = true
                            }
                        });
                        if (!isIgual) {
                            arr.push(element.foro)
                        }
                    }
                });

                setArrDeForos(arr)
                setDadosProcessosTJSPReq(result.data);


                setLoading(false);

            } catch (error) {

                let msgErro: any = (error as Error);
                setLoading(false);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {

                    EmitirErro(error, true, 'municipios_dkwdfaiweyi')
                }


            }
        }

        buscaCiaddes();

    }, []);


    useEffect(() => {

        if (dadosPoliticoSelecionado) buscaCidades(dadosPoliticoSelecionado.cpf);

    }, [dadosPoliticoSelecionado]);



    return (
        <>
            <Loading
                loading={loading}
            >
                <div style={{ marginBottom: dadosProcessosTJSPReq.length === 0 ? 0 : 20 }}>
                    {
                        <Text style={{ fontSize: '1.1rem' }}>
                            {`${dadosProcessosTJSPReq.length} Processo(s) Encontrado(s)`}
                        </Text>
                    }
                </div>
                <Row>
                    {
                        arrDeForos.map((element, index) => {
                            return (
                                <Col key={'coltj' + index} span={24} style={{ marginBottom: 15 }}>
                                    <Text style={{ fontSize: '1.1rem' }}>
                                        {element}
                                    </Text>
                                    <>
                                        {
                                            dadosProcessosTJSPReq.map((elementData, indexData) => {

                                                if (element === elementData.foro) {
                                                    return (
                                                        <React.Fragment key={elementData._id}>
                                                            <div
                                                                style={{
                                                                    backgroundColor: theme === 'light' ? '#EDEDED' : '#1F1F1F',
                                                                    // backgroundColor: '#',
                                                                    margin: 1,
                                                                    padding: 5
                                                                }}
                                                            >
                                                                {/* <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}> */}
                                                                <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
                                                                    <Col {...colListT} style={{ display: 'flex', flexDirection: 'row' }}>
                                                                        <div
                                                                            style={{
                                                                                margin: 10,
                                                                                overflow: 'hidden',
                                                                            }}
                                                                        >
                                                                            <a
                                                                                href={elementData?.link ?
                                                                                    elementData.link :
                                                                                    ''}
                                                                                className='ant-btn ant-btn-link'
                                                                                target="_blank"
                                                                                rel="noopener noreferrer"
                                                                                style={{
                                                                                    marginLeft: 10,
                                                                                    padding: 0, margin: 0,
                                                                                    // fontWeight: 'bold',
                                                                                    fontSize: '1.1rem'
                                                                                }}
                                                                            >
                                                                                {elementData.numero_process}
                                                                            </a>
                                                                        </div>
                                                                    </Col>
                                                                    <Col {...colListT} style={{ display: 'flex', flexDirection: 'row' }}>
                                                                        <div
                                                                            style={{
                                                                                margin: 10,
                                                                                display: 'flex',
                                                                                flexDirection: 'column'
                                                                            }}
                                                                        >
                                                                            <Text type='secondary' style={{ fontSize: '1.1rem' }}>
                                                                                {elementData.parte}
                                                                            </Text>
                                                                            <Text style={{ fontSize: '1.1rem' }}>
                                                                                {elementData.nome}
                                                                            </Text>
                                                                        </div>
                                                                    </Col>
                                                                    <Col {...colListT} style={{ display: 'flex', flexDirection: 'row' }}>

                                                                        <div
                                                                            style={{
                                                                                margin: 10
                                                                            }}
                                                                        >
                                                                            <Text style={{ fontSize: '1.1rem' }}>
                                                                                {elementData.classe}
                                                                            </Text>
                                                                        </div>
                                                                    </Col>
                                                                    <Col {...colListT} style={{ display: 'flex', flexDirection: 'row' }}>
                                                                        <div
                                                                            style={{
                                                                                margin: 10,
                                                                                display: 'flex',
                                                                                flexDirection: 'column'
                                                                            }}
                                                                        >
                                                                            <Text type='secondary' style={{ fontSize: '1.1rem' }}>
                                                                                {'Recebido em:'}
                                                                            </Text>
                                                                            <Text style={{ fontSize: '1.1rem' }}>
                                                                                {convertDataUSParaBrasil(elementData.recebido_em) + ' - ' + elementData.vara}
                                                                            </Text>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                {/* </div> */}
                                                            </div>
                                                            <DividerStyled style={{
                                                                marginTop: 4,
                                                                marginBottom: 4
                                                            }} />
                                                        </React.Fragment>
                                                    )
                                                }

                                            })
                                        }
                                    </>
                                </Col>
                            )
                        })
                    }
                </Row>
            </Loading>
        </>
    );
}
