import { CSSProperties, useContext } from "react";
import { GlobalContext } from './../../contexts/globalContext';
interface Props {
    style?: CSSProperties
}
export const DividerStyled = (
    {
        style = {}
    }: Props
) => {

    const { theme } = useContext(GlobalContext);

    let arr: any = {
        ...{
            backgroundColor: theme === 'light' ? '#000' : '#434343',
            height: 0.5
        }, ...style
    }

    return (
        <>
            <div
                style={arr}
            />
        </>
    )
}
