export const cardLayout = {
    xs: { span: 24, offset: 0 }, //<576
    sm: { span: 12, offset: 0 }, //>576
    md: { span: 24, offset: 0 }, // >768
    lg: { span: 12, offset: 0 }, // > 992
    xl: { span: 12, offset: 0 }, // > 1200
    xxl: { span: 12, offset: 0 }, // > 1600
};

export const cardImag = {
    xs: { span: 24, offset: 0 }, //<576
    sm: { span: 24, offset: 0 }, //>576
    md: { span: 24, offset: 0 }, // >768
    lg: { span: 12, offset: 6 }, // > 992
    xl: { span: 12, offset: 6 }, // > 1200
    xxl: { span: 12, offset: 7 }, // > 1600
};

export const colSelect = {
    xs: { span: 24, offset: 0 }, //<576
    sm: { span: 18, offset: 0 }, //>576
    md: { span: 13, offset: 0 }, // >768
    lg: { span: 12, offset: 0 }, // > 992
    xl: { span: 12, offset: 0 }, // > 1200
    xxl: { span: 14, offset: 0 }, // > 1600
};
