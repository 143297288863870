import React, { useCallback, useEffect, useState, useContext } from 'react';
import {
    Row, Col, Typography, Button, notification,
    Table, Divider
} from 'antd';
import { UserOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import locale from 'antd/es/locale/pt_BR';

import { colFull } from './GridStyle';

import { GlobalContext } from '../../contexts/globalContext';
import { convertDataUSParaBrasil, convertDataUSParaBrasilEHora } from '../../services/Conversores';
// import { getToken } from './../../services/LStorage/token';
import { validacaoPermissao, VerificaRota } from './../../services/VerificaSePodeAcessar';
import LayoutDashboard from './../../layout/LayoutAdmin';
import { urlUsuariosUsuarioAtivos } from './../../services/urls';
import { api } from './../../services/apiAxios';
import { EmitirErro } from '../../services/EmitirErro';
import { SairDoSistema } from '../../services/LStorage/SairSistema';
import { getToken } from './../../services/LStorage/token';
import { useGetColumnSearchProps } from './../../hooks/FilterTable/string';
import { useGetColumnSearchPropsData } from './../../hooks/FilterTable/date';

import './style.css';

const { Text, Title } = Typography;

// const largura = window.innerWidth;
// const altura = window.innerHeight;

interface IntefListUsersAtivosPermissao {
    "id": string,
    "createdAt": string,
    "updatedAt": string,
    "nome": string,
    "descricao": string
}
interface IntefListUsersAtivosRegra {
    "id": string,
    "createdAt": string,
    "updatedAt": string,
    "nome": string,
    "descricao": string,
    "permissoes": Array<IntefListUsersAtivosPermissao>
}
interface IntefListUsersAtivos {
    "id": string,
    "createdAt": string,
    "updatedAt": string,
    "ativo": boolean,
    "nome": string,
    "email": string,
    "senha": string,
    "lastAccess": string | null,
    "regras": Array<IntefListUsersAtivosRegra>,
    "permissoes": Array<IntefListUsersAtivosPermissao>,
    "deletedAt": null | string
}

const Usuarios: React.FC = () => {

    const navigate = useNavigate();

    const { theme } = useContext(GlobalContext);

    const refContagemDoFiltro = React.useRef<Array<any>>([]);

    const [dadosTabelaState, setDadosTabelaState] = useState<Array<IntefListUsersAtivos>>([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState<Array<string | number>>([]);
    const [hasSelectedEdit, setHasSelectedEdit] = useState(false);

    const [loadingScreen, setLoadingScreen] = useState(false);

    //setando dados das cidades no selected
    const buscaUsuariosAtivos = useCallback(() => {


        const funcBusca = async () => {
            try {
                setLoadingScreen(true);
                let result = await api.get(urlUsuariosUsuarioAtivos,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        }
                    });

                setDadosTabelaState(result.data);

                setLoadingScreen(false);

            } catch (error) {
                setLoadingScreen(false);
                let msgErro: any = (error as Error);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'usuarios_dkwdfaiweyi')
                }


            }
        }

        funcBusca();

    }, []);

    useEffect(() => {

        let arrayUrl = window.location.pathname.split("/");
        VerificaRota(arrayUrl, navigate, true, validacaoPermissao(['manage_user']));

        buscaUsuariosAtivos();


    }, []);

    // click na linha da tabela de listagem de usuario
    const onclickTable = useCallback((record: any, row: any): void => {

        if (selectedRowKeys.includes(record.id)) {
            let filter = selectedRowKeys.filter((val) => {
                return val != record.id
            })
            if (filter.length === 1) {
                setSelectedRowKeys(filter);
                setHasSelectedEdit(true);
            } else if (filter.length === 0) {
                setSelectedRowKeys(filter);
                setHasSelectedEdit(false);
            } else {
                setSelectedRowKeys(filter);
            }

        } else {
            if (selectedRowKeys.length == 0) {
                setSelectedRowKeys([record.id]);
                setHasSelectedEdit(true);
            } else {
                let teste = selectedRowKeys.concat(record.id);
                setSelectedRowKeys(teste);
                setHasSelectedEdit(false);
            }
        }
    }, [selectedRowKeys]);

    //checkbox da tabela de listagem
    // const onSelectChange = useCallback((selectedRowKeys: any): void => {
    const onSelectChange = useCallback((selectedRowKeys: Array<string | number>, selectedRows: Array<any>): void => {
        if (selectedRowKeys.length > 1) {
            setSelectedRowKeys(selectedRowKeys);
            setHasSelectedEdit(false);
        } else if (selectedRowKeys.length === 0) {
            setSelectedRowKeys(selectedRowKeys);
            setHasSelectedEdit(false);
        } else {
            setSelectedRowKeys(selectedRowKeys);
            setHasSelectedEdit(true);
        }
    }, [selectedRowKeys]);

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        columnWidth: '1%'
    };

    const columns = [
        {
            title: 'Nome',
            dataIndex: 'nome',
            ...useGetColumnSearchProps('nome'),
            width: '10%',
            sorter: (a: any, b: any) => a.nome.localeCompare(b.nome),
            showSorterTooltip: false,
        },
        {
            title: 'Perfil',
            dataIndex: 'regras',
            // ...useGetColumnSearchProps('email'),
            width: '6%',
            // sorter: (a: any, b: any) => a.email.localeCompare(b.email),
            showSorterTooltip: false,
            render: (regras: any) => (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <Text>
                        {
                            regras[0].descricao
                        }
                    </Text>
                </div>
            ),
        },
        {
            title: 'Email',
            dataIndex: 'email',
            ...useGetColumnSearchProps('email'),
            width: '10%',
            sorter: (a: any, b: any) => a.email.localeCompare(b.email),
            showSorterTooltip: false,
        },
        {
            title: 'Último acesso',
            dataIndex: 'lastAccess',
            ...useGetColumnSearchPropsData('lastAccess'),
            width: '5%',
            sorter: (a: any, b: any) => {

                let aMonMen: any = null
                let bMonMen: any = null
                if (a.lastAccess) {
                    aMonMen = moment(a.lastAccess).unix()
                } else {
                    aMonMen = 0
                }
                if (b.lastAccess) {
                    bMonMen = moment(b.lastAccess).unix()
                } else {
                    bMonMen = 0
                }

                return aMonMen - bMonMen
            },
            showSorterTooltip: false,
            // sorter: (a: any, b: any) => a.createdAt.localeCompare(b.createdAt),
            render: (criado: any) => (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <Text>
                        {
                            criado ? convertDataUSParaBrasilEHora(criado) : '-'
                        }
                    </Text>
                </div>
            ),
        },
        {
            title: 'Criado em',
            dataIndex: 'createdAt',
            ...useGetColumnSearchPropsData('createdAt'),
            width: '3%',
            sorter: (a: any, b: any) => {

                let aMonMen: any = null
                let bMonMen: any = null
                if (a.createdAt) {
                    aMonMen = moment(a.createdAt).unix()
                } else {
                    aMonMen = 0
                }
                if (b.createdAt) {
                    bMonMen = moment(b.createdAt).unix()
                } else {
                    bMonMen = 0
                }

                return aMonMen - bMonMen
            },
            showSorterTooltip: false,
            // sorter: (a: any, b: any) => a.createdAt.localeCompare(b.createdAt),
            render: (criado: any) => (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <Text>
                        {
                            criado ? convertDataUSParaBrasilEHora(criado) : '-'
                        }
                    </Text>
                </div>
            ),
        },
        // {
        //     title: 'Data de Atualização',
        //     dataIndex: 'updated_at',
        //     ...getColumnSearchPropsData('updated_at'),
        //     width: '2%',
        //     sorter: (a: any, b: any) => {

        //         let aMonMen: any = null
        //         let bMonMen: any = null
        //         if (a.updated_at) {
        //             aMonMen = moment(a.updated_at).unix()
        //         } else {
        //             aMonMen = 0
        //         }
        //         if (b.update_at) {
        //             bMonMen = moment(b.updated_at).unix()
        //         } else {
        //             bMonMen = 0
        //         }

        //         return aMonMen - bMonMen
        //     },
        //     showSorterTooltip: false,
        //     // sorter: (a: any, b: any) => a.update_at.localeCompare(b.update_at),
        //     render: (criado: any) => (
        //         convertDataUSParaBrasil(criado).format('DD/MM/YYYY HH:mm')
        //     ),
        // }
    ];

    const clickBotaoEditarUsuario = useCallback((): void => {
        if (selectedRowKeys.length == 1) {

            let objSelecionado: any = {}
            dadosTabelaState.forEach((dados): void => {
                if (dados.id === selectedRowKeys[0]) {
                    objSelecionado = dados
                }
            })

            localStorage.setItem('@DesenvolveSP:userEdit', JSON.stringify(objSelecionado));

            navigate(`/usuarios/${selectedRowKeys[0]}`)


        }
    }, [selectedRowKeys, dadosTabelaState]);

    return (
        <LayoutDashboard>
            <Row>
                <Col {...colFull}>
                    <Title level={4}>
                        <UserOutlined style={{ fontSize: 25, marginRight: 10 }} />
                        {"Usuarios Ativos"}
                    </Title>
                    <Divider />
                </Col>
            </Row>
            <div
                style={{
                    display: 'flex',
                    // justifyContent: 'flex-end'
                    // marginBottom: 5
                }}
            >
                <Button
                    type="primary"
                    className="Usuarios_BotaoAddUser_nlfewigvoaeu"
                    style={{ margin: 2 }}
                    // disabled={!hasSelectedAdd}
                    onClick={() => {
                        let dadosLocalStorage: string | null = localStorage.getItem('@DesenvolveSP:userEdit');
                        let localUserData: any = null;
                        if (typeof dadosLocalStorage == 'string') {
                            localUserData = JSON.parse(dadosLocalStorage);
                        }

                        if (localUserData) {
                            localStorage.removeItem('@DesenvolveSP:userEdit');
                        }

                        navigate('/usuarios/criar')
                    }}
                >
                    Adicionar
                </Button>
                <Button
                    type="default"
                    style={{ margin: 2 }}
                    className="Usuarios_BotaoEditarUser_blhuewe"
                    onClick={clickBotaoEditarUsuario}
                    disabled={!hasSelectedEdit}
                >
                    Editar
                </Button>
            </div>
            <Table
                style={{
                    // marginTop: 50
                }}
                className='marginTopTables'
                rowKey={(record) => record.id}
                loading={loadingScreen}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: event => { onclickTable(record, rowIndex) }, // click row
                        onDoubleClick: event => { }, // double click row
                        onContextMenu: event => { }, // right button click row
                        onMouseEnter: event => { }, // mouse enter row
                        onMouseLeave: event => { }, // mouse leave row
                    };
                }}
                rowSelection={rowSelection}
                columns={columns} dataSource={dadosTabelaState}
                scroll={{ x: 850 }}
                onChange={
                    (pagination, filters, sorter, extra) => {
                        refContagemDoFiltro.current = extra.currentDataSource
                    }
                }
                title={(registros) => {

                    return (
                        <div
                            style={{
                                backgroundColor: theme === 'light' ? '#FAFAFA' : '#1D1D1D',
                                padding: 10,
                                margin: -8,
                                marginBottom: 0.5
                            }}
                        >
                            <Text>
                                {/* {`Total: ${dadosTabelaState.length} registros.`} */}
                                {`Mostrando: ${registros.length} de ${refContagemDoFiltro.current.length === 0 ?
                                    dadosTabelaState.length :
                                    refContagemDoFiltro.current.length} num total de ${dadosTabelaState.length} `}
                            </Text>
                        </div>
                    )
                }}
                footer={(registros) => {
                    return (
                        <div
                            style={{
                                textAlign: 'end'
                            }}
                        >
                            <Text>
                                {/* {`Total: ${dadosTabelaState.length} registros.`} */}
                                {`Mostrando: ${registros.length} de ${refContagemDoFiltro.current.length === 0 ?
                                    dadosTabelaState.length :
                                    refContagemDoFiltro.current.length} num total de ${dadosTabelaState.length} `}
                            </Text>
                        </div>
                    )
                }}
                size="small"
                pagination={{
                    locale: { items_per_page: '' },
                    showSizeChanger: true,
                    defaultPageSize: 100,
                    position: ['topRight', 'bottomRight'],
                    pageSizeOptions: ['10', '20', '50', '100']
                }}
                locale={locale.Table}
            />

        </LayoutDashboard >
    );
}

export default Usuarios;
