import React, { useCallback, useEffect, useState } from 'react';
import { Table, Typography, notification } from 'antd';
import Icon from '@ant-design/icons';
import { FaCheckCircle, FaExclamationCircle, FaBan } from 'react-icons/fa';
import moment from 'moment';

import { convertDataUSParaBrasil, funcRetornaNomeParadataComTempo } from '../../../../services/Conversores';
import { useNavigate } from 'react-router-dom';
import locale from 'antd/es/locale/pt_BR';
import { urlFileServerGovFacilApp_FontesCauc, urlMunicipioObrigacoesTransparencia } from './../../../../services/urls';
import { EmitirErro } from '../../../../services/EmitirErro';
import { SairDoSistema } from '../../../../services/LStorage/SairSistema';
import { api } from '../../../../services/apiAxios';
import { getToken } from './../../../../services/LStorage/token';
import { useGetColumnSearchProps } from './../../../../hooks/FilterTable/string';
import { useGetColumnSearchPropsData } from './../../../../hooks/FilterTable/date';

const IconFaCheckCircle = (props: any) => <Icon {...props} component={FaCheckCircle} ></Icon>
const IconFaExclamationCircle = (props: any) => <Icon {...props} component={FaExclamationCircle} ></Icon>
const IconFaBan = (props: any) => <Icon {...props} component={FaBan} ></Icon>

const { Text } = Typography;

// const largura = window.innerWidth;
// const altura = window.innerHeight;

interface DadosReqCauc {
    "_id": string,
    "id_ibge": number,
    "atualizado_em": string,
    "itens": Array<
        {
            "numero": string,
            "descricao": string,
            "fonte": string,
            "fonte_imagem_url": string,
            "validade": string,
            "situacao": string
        }
    >
}

interface interfDadosMunicipioSelecionado {
    municipioSelecionado?: {
        "idIbge": number,
        "nome": string,
    }
}

export const CAUCItem3 = ({
    municipioSelecionado
}: interfDadosMunicipioSelecionado) => {

    const navigate = useNavigate();

    const refContagemDoFiltro = React.useRef<Array<any>>([]);

    const [dadosTabelaState, setDadosTabelaState] = useState<DadosReqCauc>();

    const [loading, setLoading] = useState(false);

    //setando dados das cidades no selected
    const buscaCidades = useCallback((idIbge: number) => {


        const buscaCiaddes = async () => {
            try {
                setLoading(true);
                // let result = await api.get(urlMunicipioObrigacoesTransparencia + '/' + '3500105',
                let result = await api.get(urlMunicipioObrigacoesTransparencia + '/' + idIbge,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        }
                    });

                setDadosTabelaState(result.data);


                setLoading(false);

            } catch (error) {

                let msgErro: any = (error as Error);
                setLoading(false);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'municipios_dkwdfaiweyi')
                }


            }
        }

        buscaCiaddes();

    }, []);


    useEffect(() => {

        if (municipioSelecionado) buscaCidades(municipioSelecionado.idIbge);

    }, [municipioSelecionado]);

    // click na linha da tabela de listagem de usuario
    // const onclickTable = useCallback((record: any, row: any): void => {

    //     if (selectedRowKeys.includes(record.uuid)) {
    //         let filter = selectedRowKeys.filter((val) => {
    //             return val != record.uuid
    //         })
    //         if (filter.length === 1) {
    //             setSelectedRowKeys(filter);
    //             setHasSelectedEdit(true);
    //         } else if (filter.length === 0) {
    //             setSelectedRowKeys(filter);
    //             setHasSelectedEdit(false);
    //         } else {
    //             setSelectedRowKeys(filter);
    //         }

    //     } else {
    //         if (selectedRowKeys.length == 0) {
    //             setSelectedRowKeys([record.uuid]);
    //             setHasSelectedEdit(true);
    //         } else {
    //             let teste = selectedRowKeys.concat(record.uuid);
    //             setSelectedRowKeys(teste);
    //             setHasSelectedEdit(false);
    //         }
    //     }
    // }, [selectedRowKeys]);

    //checkbox da tabela de listagem
    // const onSelectChange = useCallback((selectedRowKeys: any): void => {
    // const onSelectChange = useCallback((selectedRowKeys: Array<string | number>, selectedRows: Array<any>): void => {
    //     if (selectedRowKeys.length > 1) {
    //         setSelectedRowKeys(selectedRowKeys);
    //         setHasSelectedEdit(false);
    //     } else if (selectedRowKeys.length === 0) {
    //         setSelectedRowKeys(selectedRowKeys);
    //         setHasSelectedEdit(false);
    //     } else {
    //         setSelectedRowKeys(selectedRowKeys);
    //         setHasSelectedEdit(true);
    //     }
    // }, [selectedRowKeys]);

    // const rowSelection = {
    //     selectedRowKeys,
    //     onChange: onSelectChange,
    //     columnWidth: '1%'
    // };

    const clickBtnGerarPdf = useCallback((arquivo: string) => {

        const buscaPdf = async () => {
            try {
                setLoading(true)
                let result = await api.get(arquivo,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        }
                    });
                console.log(result.data)
                // fileSaver.saveAs(result.data, `example.zip`);
                // console.log('uiui')

                // não funciona no safari
                // window.open(result.data.filename, '_blank');
                // data:application/zip;base64,

                // const data = ``
                // const filename = 'certidoaes.zip';
                // const aTag = document.createElement('a');

                // aTag.href = data;
                // aTag.download = filename;
                // aTag.click();


                //----------------------------------------------------

                // let anchor = window.document.createElement('a');
                // anchor.href = urlMunicipioCertidaoPdfs + '/' + municipioSelec?.idIbge;
                // anchor.target = "_blank";
                // anchor.click();


                setLoading(false)

            } catch (error) {
                setLoading(false)
                let msgErro: any = (error as Error);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'relatorioconsolidado_dkwdfaiweyi')
                }


            }
        }


        buscaPdf();



    }, []);

    const columns = [
        {
            title: 'Item',
            dataIndex: 'numero',
            ...useGetColumnSearchProps('numero'),
            width: '2%',
            sorter: (a: any, b: any) => a.numero.localeCompare(b.numero),
            showSorterTooltip: false,
        },
        {
            title: 'Descrição',
            dataIndex: 'descricao',
            ...useGetColumnSearchProps('descricao'),
            width: '26%',
            sorter: (a: any, b: any) => a.descricao.localeCompare(b.descricao),
            showSorterTooltip: false,
        },
        {
            title: 'Situação',
            dataIndex: 'situacao',
            ...useGetColumnSearchProps('situacao'),
            width: '8%',
            sorter: (a: any, b: any) => a.situacao.localeCompare(b.situacao),
            showSorterTooltip: false,
            render: (criado: any) => {
                return (
                    <div
                        style={{
                            display: 'flex',
                            // justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                    >
                        {
                            criado === 'Comprovado' ?
                                <IconFaCheckCircle style={{ color: '#4CA750', fontSize: 26, marginRight: 10, padding: 0, backgroundColor: '#fff', borderRadius: '100%' }} />
                                : criado === 'A comprovar' ?
                                    <IconFaExclamationCircle style={{ color: '#df9e36', fontSize: 26, marginRight: 10, padding: 0, backgroundColor: '#fff', borderRadius: '100%' }} />
                                    : criado === 'Desativado' ?
                                        <IconFaBan style={{ color: '#ccc', fontSize: 26, marginRight: 10, padding: 0 }} />
                                        :
                                        <IconFaCheckCircle style={{ color: '#4CA750', fontSize: 26, marginRight: 10, padding: 0 }} />
                        }
                        <Text>
                            {
                                criado
                            }
                        </Text>
                    </div>
                )
            },
        },
        {
            title: 'Fonte',
            dataIndex: 'fonte',
            ...useGetColumnSearchProps('fonte'),
            width: '6%',
            sorter: (a: any, b: any) => a.descricao.localeCompare(b.descricao),
            showSorterTooltip: false,
            render: (criado: any, record: any) => {
                return (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                    >
                        <img style={{
                            width: 60,
                            backgroundColor: '#fff',
                            borderRadius: 6
                        }} src={urlFileServerGovFacilApp_FontesCauc + '/' + record.fonte_imagem_url} />
                    </div>
                )
            },
        },
        {
            title: 'Validade',
            dataIndex: 'validade',
            ...useGetColumnSearchPropsData('validade'),
            width: '2%',
            sorter: (a: any, b: any) => {

                let aMonMen: any = null
                let bMonMen: any = null
                if (a.validade) {
                    aMonMen = moment(a.validade).unix()
                } else {
                    aMonMen = 0
                }
                if (b.validade) {
                    bMonMen = moment(b.validade).unix()
                } else {
                    bMonMen = 0
                }

                return aMonMen - bMonMen
            },
            showSorterTooltip: false,
            // sorter: (a: any, b: any) => a.validade.localeCompare(b.validade),
            render: (criado: any) => {
                return (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                    >
                        <Text>
                            {
                                criado ? convertDataUSParaBrasil(criado) : '-'
                            }
                        </Text>
                    </div>
                )
            },
        }
    ];


    return (
        <>
            {dadosTabelaState?.atualizado_em &&
                < div
                    style={{
                        display: 'flex',
                        // justifyContent: 'flex-end',
                        // justifyContent: 'flex-end'
                        marginBottom: 15
                    }}
                >
                    <Text type='secondary' style={{ fontSize: '0.9rem' }}>
                        {
                            "Atualizado em: " + funcRetornaNomeParadataComTempo(dadosTabelaState?.atualizado_em, true)
                        }
                    </Text>
                </div>
            }
            <Table
                style={{
                    // marginTop: 50
                }}
                className='marginTopTables'
                rowKey={(record) => record.numero}
                // loading={loadingTable}
                // onRow={(record, rowIndex) => {
                //     return {
                //         onClick: event => { onclickTable(record, rowIndex) }, // click row
                //         onDoubleClick: event => { }, // double click row
                //         onContextMenu: event => { }, // right button click row
                //         onMouseEnter: event => { }, // mouse enter row
                //         onMouseLeave: event => { }, // mouse leave row
                //     };
                // }}
                // rowSelection={rowSelection}
                columns={columns} dataSource={dadosTabelaState?.itens ? dadosTabelaState.itens : []}
                scroll={{ x: 650 }}
                onChange={
                    (pagination, filters, sorter, extra) => {
                        refContagemDoFiltro.current = extra.currentDataSource
                    }
                }
                // title={(registros) => {

                //     return (
                //         <div
                //             style={{
                //                 backgroundColor: theme === 'light' ? '#FAFAFA' : '#1D1D1D',
                //                 padding: 10,
                //                 margin: -8,
                //                 marginBottom: 0.5
                //             }}
                //         >
                //             <Text>
                //                 {/* {`Total: ${dadosTabelaState.length} registros.`} */}
                //                 {`Mostrando: ${registros.length} de ${refContagemDoFiltro.current.length === 0 ?
                //                     dadosTabelaState.length :
                //                     refContagemDoFiltro.current.length} num total de ${dadosTabelaState.length} `}
                //             </Text>
                //         </div>
                //     )
                // }}
                // footer={(registros) => {
                //     return (
                //         <div
                //             style={{
                //                 textAlign: 'end'
                //             }}
                //         >
                //             <Text>
                //                 {/* {`Total: ${dadosTabelaState.length} registros.`} */}
                //                 {`Mostrando: ${registros.length} de ${refContagemDoFiltro.current.length === 0 ?
                //                     dadosTabelaState.length :
                //                     refContagemDoFiltro.current.length} num total de ${dadosTabelaState.length} `}
                //             </Text>
                //         </div>
                //     )
                // }}
                size="small"
                pagination={{
                    locale: { items_per_page: '' },
                    showSizeChanger: false,
                    defaultPageSize: 100,
                    // position: ['topRight', 'bottomRight'],
                    position: [],
                    pageSizeOptions: ['10', '20', '50', '100']
                }}
                locale={locale.Table}
            />
        </>
    );
}
