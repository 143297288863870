import React, { useCallback, useEffect, useState, useRef } from 'react';
import {
    Row, Col, Select, Typography, Button, notification, Divider,
    Form, Input, Spin
} from 'antd';
import { UserAddOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

import { colInputs, colFull } from './GridStyle';

// import { getToken } from './../../services/LStorage/token';
import { validacaoPermissao, VerificaRota } from './../../../../services/VerificaSePodeAcessar';
import LayoutDashboard from './../../../../layout/LayoutAdmin';
import { urlUsuariosPermissao, urlUsuariosRegra, urlUsuariosUsuario, urlUsuariosUsuarioEmail, urlUsuariosUsuarioPerfil } from './../../../../services/urls';
import { api } from './../../../../services/apiAxios';
import { EmitirErro } from '../../../../services/EmitirErro';
import { SairDoSistema } from '../../../../services/LStorage/SairSistema';
import { getToken } from './../../../../services/LStorage/token';
// import './style.css';

import {
    removeAcento
} from './../../../../utils/RemoveAcentos';
import { getIdUsuario } from '../../../../services/LStorage/UsuarioERefresh';
import { Loading } from '../../../../components/Loading';


const { Text, Title, Link } = Typography;

// const largura = window.innerWidth;
// const altura = window.innerHeight;

interface dadosRegraPerfilPermissoes {
    "id": string,
    "createdAt": string,
    "updatedAt": string,
    "nome": string,
    "descricao": string
}
interface dadosRegraPerfil {
    "id": string,
    "createdAt": string,
    "updatedAt": string,
    "nome": string,
    "descricao": string,
    "permissoes": Array<dadosRegraPerfilPermissoes>
}

const AddOrUpdateUsuarios = () => {

    const navigate = useNavigate();
    let { id } = useParams();

    const refForm = useRef<any>(null);

    const [estaEditando, setEstaEditando] = useState(false);

    const refDadosRegraPerfil = useRef<Array<dadosRegraPerfil>>([]);
    const [dadosRegraPerfil, setDadosRegraPerfil] = useState<Array<dadosRegraPerfil>>([]);
    const [loadingDadosRegraPerfil, setLoadingDadosRegraPerfil] = useState(false);

    const [dadosPermissoes, setDadosPermissoes] = useState<Array<dadosRegraPerfilPermissoes>>([]);
    const [loadingDadosPermissoes, setLoadingDadosPermissoes] = useState(false);

    const [loading, setLoading] = useState(false);

    useEffect(() => () => {

        let dadosLocalStorage: string | null = localStorage.getItem('@DesenvolveSP:userEdit');
        let localUserData: any = null;
        if (typeof dadosLocalStorage == 'string') {
            localUserData = JSON.parse(dadosLocalStorage);
        }

        if (localUserData) {
            localStorage.removeItem('@DesenvolveSP:userEdit');
        }

    }, []);

    //setando dados das cidades no selected
    const buscaDadosUsuario = useCallback((id: string) => {

        const buscaCiaddes = async () => {
            try {
                //pega dados do localStorage se existir
                let dadosLocalStorage: string | null = localStorage.getItem('@DesenvolveSP:userEdit');
                let localUserData: any = null;
                if (typeof dadosLocalStorage == 'string') {
                    localUserData = JSON.parse(dadosLocalStorage);
                }
                // localUserData?.step4.horarioDasNotificacoes? localUserData?.step4.horarioDasNotificacoes: undefined,

                if (localUserData) {

                    refForm.current?.setFieldsValue(
                        {
                            email: localUserData.email ? localUserData.email : undefined,
                            nome: localUserData.nome ? localUserData.nome : undefined,
                            regras: localUserData.regras.length > 0 ? localUserData.regras[0].id : undefined,
                        }
                    );

                    if (localUserData?.permissoes.length > 0) {
                        // permissoes:
                        let arrStr: Array<string> = []

                        localUserData.permissoes.forEach((element: any) => {
                            arrStr.push(element.id)
                        });

                        refForm.current?.setFieldsValue(
                            {
                                permissoes: arrStr
                            }
                        );
                    }
                }

            } catch (error) {
                let msgErro: any = (error as Error);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'addoredituser_dkwdfaiweyi')
                }


            }
        }

        buscaCiaddes();

    }, []);

    //setando dados das cidades no selected
    const buscaDadosRegraDeUsuariosPerfil = useCallback(() => {


        const buscaCiaddes = async () => {
            try {
                setLoadingDadosRegraPerfil(true);
                let result = await api.get(urlUsuariosRegra,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        }
                    });

                setDadosRegraPerfil(result.data);
                refDadosRegraPerfil.current = result.data
                setLoadingDadosRegraPerfil(false);

                setLoadingDadosPermissoes(true);
                let resultPermissao = await api.get(urlUsuariosPermissao,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        }
                    });

                setDadosPermissoes(resultPermissao.data);

                setLoadingDadosPermissoes(false);

            } catch (error) {
                let msgErro: any = (error as Error);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'addoredituser_dkwdfaiweyi')
                }

                setLoadingDadosRegraPerfil(false);
                setLoadingDadosPermissoes(false);


            }
        }

        buscaCiaddes();

    }, []);

    useEffect(() => {

        let arrayUrl = window.location.pathname.split("/");
        VerificaRota(arrayUrl, navigate, true, validacaoPermissao(['manage_user']));

        buscaDadosRegraDeUsuariosPerfil();
        if (typeof id === 'string') {
            if (id === 'criar') {
                setEstaEditando(false)
            } else {
                setEstaEditando(true)
                buscaDadosUsuario(id);
            }
        }


    }, []);


    const validaEmail = useCallback((email: string): boolean => {
        let er = new RegExp(/^[A-Za-z0-9_\-\.]+@[A-Za-z0-9_\-\.]{2,}\.[A-Za-z0-9]{2,}(\.[A-Za-z0-9])?/);

        if (email == '' || !er.test(email)) { return false; }
        return true;

    }, []);

    const buscarEmailSeJaExiste = useCallback((email: string): Promise<boolean> => {

        const buscaDadosTipoDeContrato = async (): Promise<boolean> => {
            try {
                let result = await axios.get(urlUsuariosUsuarioEmail + '/' + email,
                    { headers: { 'Authorization': 'Bearer ' + getToken() } });
                if (typeof result.data?.usuarioId === 'string') {
                    if (result.data.usuarioId === id) {
                        return false;
                    }
                    return true;
                } else {
                    return false;
                }

            } catch (error) {
                notification.error({
                    message: 'Erro',
                    description:
                        'Não foi possivel consultar email!',
                });
                return false
            }
        }

        return buscaDadosTipoDeContrato();

    }, []);

    /**
     * Retorna arr de string[id] para setar direto no form permissoes
     */
    const encontrarObjPermissaoPeloIDPerfil = useCallback((id?: number | string | null) => {
        let resultadoPoder = refDadosRegraPerfil.current.filter((perfil) => perfil.id == id)
        if (resultadoPoder.length > 0) {
            let arrStr: Array<string> = []

            resultadoPoder[0].permissoes.forEach((element) => {
                arrStr.push(element.id)
            });

            return arrStr
        }
        return []
    }, []);

    const onchangeSelectPerfil = useCallback((val: any) => {
        let objSelecionado = encontrarObjPermissaoPeloIDPerfil(val)

        if (objSelecionado.length >= 0) {

            refForm.current?.setFieldsValue(
                {
                    permissoes: objSelecionado
                }
            );
        }
    }, []);

    const submitBotaoSalvar = useCallback(() => {


        const salvarDadosCriar = async (obj: any) => {
            try {
                setLoading(true);
                let resultUser = await api.post(urlUsuariosUsuario, obj,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        }
                    });

                notification.success({
                    message: 'Sucesso',
                    description:
                        'Seus dados foram criados com sucesso!',
                });

                setLoading(false);

                navigate('/usuarios')

            } catch (error) {
                setLoading(false);
                let msgErro: any = (error as Error);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'addoredituser_dkwdfaiweyi')
                }


            }
        }

        refForm.current?.validateFields()
            .then((values: any) => {

                values.regras = [values.regras]

                salvarDadosCriar(values);

            })
            .catch((errorInfo: any) => {
                notification.error({
                    message: 'Erro',
                    description:
                        'Preencha os campos obrigatorios!',
                });
            });


    }, []);

    const submitBotaoEditar = useCallback((idUser: string) => {


        const salvarDadosEditar = async (obj: any) => {
            try {
                setLoading(true);

                let resultUser = await api.put(urlUsuariosUsuario + '/' + idUser, obj,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        }
                    });

                notification.success({
                    message: 'Sucesso',
                    description:
                        'Seus dados foram editados com sucesso!',
                });

                // setDataListCidades(resultCidade.data);

                setLoading(false);
                navigate('/usuarios');

            } catch (error) {
                setLoading(false);
                let msgErro: any = (error as Error);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'addoredituser_dkwdfaiweyi')
                }


            }
        }

        refForm.current?.validateFields()
            .then((values: any) => {

                values.regras = [values.regras];

                salvarDadosEditar(values);

            })
            .catch((errorInfo: any) => {
                notification.error({
                    message: 'Erro',
                    description:
                        'Preencha os campos obrigatorios!',
                });
            });


    }, []);

    return (
        <LayoutDashboard>
            <Loading

                loading={loading}
            >
                <Row>
                    <Col {...colFull}>
                        <Title level={4}>
                            <UserAddOutlined style={{ fontSize: 25, marginRight: 10 }} />
                            {`${estaEditando ? 'Editar' : 'Adicionar'} Usuario`}
                        </Title>
                        <div
                            style={{
                                display: 'flex'
                            }}
                        >
                            <Link
                                // href="#"
                                onClick={() => [
                                    navigate('/usuarios')
                                ]}
                                style={{
                                    marginRight: 5
                                }}
                            // target="_blank"
                            >
                                <Text
                                    type='secondary' underline
                                >Usuários</Text>
                            </Link>

                            <Text
                                style={{
                                    marginRight: 5
                                }} type='secondary'
                            >/</Text>

                            <Text style={{
                                marginRight: 5
                            }}>{`${estaEditando ? 'Editar' : 'Adicionar'}`}</Text>
                        </div>
                        <Divider />
                    </Col>
                </Row>

                <Form
                    ref={refForm}
                    name="adicionarUsuario"
                    layout="vertical"
                    // onFieldsChange={onFieldsChange}
                    initialValues={{
                        remember: true,
                    }}
                    // onFinish={onFinish}
                    // onFinishFailed={() => { }}
                    autoComplete="off"
                >
                    <Row>
                        <Col {...colInputs}>
                            <Form.Item
                                style={{
                                    margin: 5
                                }}
                                name="nome"
                                label="Nome"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Por favor digite seu nome!',
                                    },
                                ]}
                            >
                                <Input autoComplete="off" placeholder="Nome" />
                            </Form.Item>
                        </Col>
                        <Col {...colInputs}>
                            <Form.Item
                                style={{
                                    margin: 5
                                }}
                                name="email"
                                label="Email (Login)"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Por favor digite seu email!',
                                    },
                                    () => ({
                                        validator(rule, value) {
                                            return new Promise((resolve: (value?: any) => void, reject) => {
                                                //CUIDADO AO EDITAR PARA PODER ACEITAR MESMO EMAIL
                                                if (value != '' && value != null) {
                                                    if (validaEmail(value)) {
                                                        buscarEmailSeJaExiste(value).then(valorPromessa => {
                                                            if (valorPromessa) {
                                                                reject("Email já existe!");
                                                            } else {
                                                                resolve();
                                                            }
                                                        }).catch(error => {
                                                            resolve();
                                                        })
                                                    } else {
                                                        reject("Email inválido");
                                                    }
                                                } else {
                                                    reject("Email é obrigatório");
                                                    // if (propsState.nome == "painel" || propsState.nome == "todos") {
                                                    //     reject('');
                                                    // } else {
                                                    //     resolve();
                                                    // }

                                                }
                                            });
                                        },
                                    }),
                                ]}
                            >
                                <Input autoComplete="off" placeholder="Digite seu email!" />
                            </Form.Item>
                        </Col>
                        {/* {
                        estaEditando &&
                        <>
                            <Col {...colInputs}>
                                <Form.Item
                                    style={{
                                        margin: 5
                                    }}
                                    name="senha"
                                    label="Senha Atual"
                                    rules={[
                                        {
                                            required: estaEditando === true ? false : true,
                                            message: 'Por favor digite sua senha!',
                                        },
                                        () => ({
                                            validator(rule, value) {
                                                if (value?.length >= 1) {
                                                    setObrigatorioConfirm(true);
                                                }
                                                if (value?.length == 0) {
                                                    setObrigatorioConfirm(false);
                                                }

                                                return Promise.resolve();
                                            },
                                        }),
                                    ]}
                                >
                                    <Input.Password autoComplete="off" placeholder="Senha" />
                                </Form.Item>
                            </Col>
                            <Col {...colInputs}>
                                <Form.Item
                                    style={{
                                        margin: 5
                                    }}
                                    name="nova_senha"
                                    label="Nova Senha"
                                    rules={[
                                        {
                                            required: obrigatorioConfirm,
                                            message: 'Por favor digite sua nova senha!',
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(rule, value) {
                                                if (!value || getFieldValue('senha') !== value) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject('Senha está igual a anterior!');
                                            },
                                        }),
                                    ]}
                                >
                                    <Input.Password autoComplete="off" placeholder="Por favor digite nova senha!" />
                                </Form.Item>
                            </Col>
                        </>
                    } */}
                        <Col {...colInputs}>
                            <Form.Item
                                name="regras"
                                label="Perfil do usuário"
                                style={{ margin: 5 }}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Por favor selecione o perfil!',
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    placeholder="Selecione..."
                                    loading={loadingDadosRegraPerfil}
                                    onChange={((value: any, option: any) => onchangeSelectPerfil(value))}
                                    notFoundContent={loadingDadosRegraPerfil ? <Spin size="small" /> : null}
                                    filterOption={(input: any, option: any) => {
                                        let textDigit = removeAcento(input)
                                        let listCidade = removeAcento(option?.children);
                                        return listCidade.indexOf(textDigit) >= 0
                                    }}
                                >
                                    {
                                        dadosRegraPerfil.map((item) => {
                                            return (
                                                <Select.Option
                                                    value={item.id}
                                                    key={item.id}
                                                >
                                                    {item.descricao}
                                                </Select.Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col {...colInputs}>
                            <Form.Item
                                name="permissoes"
                                label="Permissões"
                                style={{ margin: 5 }}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Por favor selecione uma permissão!',
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    allowClear
                                    optionFilterProp="children"
                                    placeholder="Selecione..."
                                    loading={loadingDadosPermissoes}
                                    mode='multiple'
                                    notFoundContent={loadingDadosPermissoes ? <Spin size="small" /> : null}
                                    filterOption={(input: any, option: any) => {
                                        let textDigit = removeAcento(input)
                                        let listCidade = removeAcento(option?.children);
                                        return listCidade.indexOf(textDigit) >= 0
                                    }}
                                >
                                    {
                                        dadosPermissoes.map((item) => {
                                            return (
                                                <Select.Option
                                                    value={item.id}
                                                    key={item.id}
                                                >
                                                    {item.descricao}
                                                </Select.Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col {...colFull}>
                            <div className="botaoSalvarStep" style={{
                                flexDirection: 'row',
                                display: 'flex',
                                justifyContent: 'flex-end',
                                // background: 'red'
                            }}>
                                <Button
                                    key="submit"
                                    type="primary"
                                    style={{
                                        marginTop: 20
                                    }}
                                    onClick={() => {
                                        estaEditando ?
                                            submitBotaoEditar(id ? id : 'criar')
                                            :
                                            submitBotaoSalvar()
                                    }}
                                >
                                    {"Salvar"}
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Loading>

        </LayoutDashboard >
    );
}

export default AddOrUpdateUsuarios;
