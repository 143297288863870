import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Row, Col, Select, Typography, Button, notification } from 'antd';
import { useNavigate } from 'react-router-dom';

import { colSelect, colGerarPDFButton } from './GridStyle';

// import { getToken } from './../../services/LStorage/token';
import { validacaoPermissao, VerificaRota } from './../../services/VerificaSePodeAcessar';
import LayoutDashboard from './../../layout/LayoutAdmin';
import { urlMunicipioStatusConsolidado, urlMunicipioStatusConsolidadoDataFiltro, urlMunicipioStatusConsolidadoPdf, urlMunicipioStatusConsolidadoPDFMunicipios } from './../../services/urls';
import { api } from './../../services/apiAxios';
import { EmitirErro } from '../../services/EmitirErro';
import { SairDoSistema } from '../../services/LStorage/SairSistema';
import { getToken } from './../../services/LStorage/token';
import './style.css';

import {
    removeAcento
} from './../../utils/RemoveAcentos';
import { convertMoedaBRDoJSComExtamente2CasasDecimais } from '../../services/Conversores';
import { Loading } from '../../components/Loading';

const { Text } = Typography;


// const largura = window.innerWidth;
// const altura = window.innerHeight;

interface DadosRelatorio {
    "ateVinteMil"?: {
        "aptos"?: number,
        "inaptos"?: number,
        "total"?: number,
        "atendidos"?: number
    },
    "entreVinteECinquenta"?: {
        "aptos"?: number,
        "inaptos"?: number,
        "total"?: number,
        "atendidos"?: number
    },
    "entreCinquentaECem"?: {
        "aptos"?: number,
        "inaptos"?: number,
        "total"?: number,
        "atendidos"?: number
    },
    "acimaDeCem"?: {
        "aptos"?: number,
        "inaptos"?: number,
        "total"?: number,
        "atendidos"?: number
    },
    "anoPopulacao"?: string | null,
    "total"?: {
        "aptos"?: number,
        "inaptos"?: number,
        "total"?: number,
        "atendidos"?: number
    },
    "porcentagemAptos"?: number,
    "porcentagemInaptos"?: number,
    "porcentagemTotal"?: number,
    "limiteLrfAptos"?: number,
    "limiteLrfInaptos"?: number,
    "limiteLrfTotal"?: number,
    "limitePotencialAptos"?: number,
    "limitePotencialInaptos"?: number,
    "limitePotencialTotal"?: number
}

const RelatorioConsolidado: React.FC = () => {

    const navigate = useNavigate();

    const refAno = useRef<any>();
    const refMes = useRef<any>();

    const mesesString = [
        {
            mes: 0,
            nome: 'Janeiro'
        },
        {
            mes: 1,
            nome: 'Fevereiro'
        },
        {
            mes: 2,
            nome: 'Março'
        },
        {
            mes: 3,
            nome: 'Abril'
        },
        {
            mes: 4,
            nome: 'Maio'
        },
        {
            mes: 5,
            nome: 'Junho'
        },
        {
            mes: 6,
            nome: 'Julho'
        },
        {
            mes: 7,
            nome: 'Agosto'
        },
        {
            mes: 8,
            nome: 'Setembro'
        },
        {
            mes: 9,
            nome: 'Outubro'
        },
        {
            mes: 10,
            nome: 'Novembro'
        },
        {
            mes: 11,
            nome: 'Dezembro'
        }
    ]

    const [dataRelatorioConsolidado, setDataRelatorioConsolidado] = useState<DadosRelatorio>();
    const [valSelectMesFiltros, setValSelectMesFiltros] = useState<number>();
    const [valSelectAnoFiltros, setValSelectAnoFiltros] = useState<number>();
    const [haveData, setHaveData] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const [meses, setMeses] = useState<Array<{ mes: number, nome: string }>>([]);
    const [anos, setAnos] = useState<Array<{ ano: number, meses: Array<{ mes: number }> }>>([]);
    // (
    //     () => {
    //         let veses = new Date().getFullYear() - 2017;// ten qye ser 2000 para iniciar de 2001
    //         let anoAtual = new Date().getFullYear();
    //         let arrayDeAnos = []
    //         for (let index = 0; index < veses; index++) {

    //             if (index < 1) {
    //                 arrayDeAnos.push(anoAtual - index)
    //             }
    //         }

    //         refAno.current = arrayDeAnos[0]

    //         setValSelectAnoFiltros(arrayDeAnos[0])

    //         return arrayDeAnos
    //     }
    // );


    //setando dados das cidades no selected
    const buscarDadosRelatorioTable = useCallback(() => {

        const buscaCiaddes = async () => {
            try {
                setLoading(true)
                let resultCidade = await api.get(urlMunicipioStatusConsolidado + '/' + refAno.current + '/' + refMes.current,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        }
                    });

                setHaveData(true)
                setDataRelatorioConsolidado(resultCidade.data);
                setLoading(false)

            } catch (error) {
                setLoading(false)
                let msgErro: any = (error as Error);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'relatorioconsolidado_dkwdfaiweyi')

                    // Expecifico desta tela !!!!!!
                    if (msgErro?.response?.data?.message === 'Dados não encontrados!') {
                        setHaveData(false)

                    }
                }


            }
        }

        if (refAno.current !== null && refAno.current !== undefined &&
            refMes.current !== null && refMes.current !== undefined) {

            buscaCiaddes();
        }

    }, []);

    //setando dados das cidades no selected
    const buscarDadosAnoEMes = useCallback(() => {

        const buscaCiaddes = async () => {
            try {
                setLoading(true)
                let result = await api.get(urlMunicipioStatusConsolidadoDataFiltro,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        }
                    });


                const arrayDeAnos = result.data.anos.sort((a: any, b: any) => {
                    return b.ano - a.ano;
                });

                setAnos(arrayDeAnos);

                refAno.current = arrayDeAnos[0].ano

                setValSelectAnoFiltros(arrayDeAnos[0].ano)

                const arrayDeMeses: Array<{ mes: number }> = arrayDeAnos[0].meses.sort((a: any, b: any) => {
                    return a.ano - b.ano;
                });

                let mesesObjCompleto: Array<{ mes: number, nome: string }> = []

                mesesString.forEach((element, index) => {

                    let isEqual = false
                    arrayDeMeses.forEach((elementMesReq, index) => {
                        if (element.mes === elementMesReq.mes) {
                            isEqual = true
                        }
                    })

                    if (isEqual) {
                        mesesObjCompleto.push(element)
                    }
                })

                setMeses(mesesObjCompleto)
                setValSelectMesFiltros(mesesObjCompleto[(mesesObjCompleto.length - 1)].mes)
                refMes.current = mesesObjCompleto[(mesesObjCompleto.length - 1)].mes


                buscarDadosRelatorioTable();

                setLoading(false)

            } catch (error) {
                setLoading(false)
                let msgErro: any = (error as Error);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'relatorioconsolidado_dkwdfaiweyi')
                }


            }
        }

        buscaCiaddes();


    }, []);

    useEffect(() => {

        let arrayUrl = window.location.pathname.split("/");
        VerificaRota(arrayUrl, navigate, true, validacaoPermissao(['view_consolidate_report']));

        buscarDadosAnoEMes();


    }, []);

    const onChangeSelectCidades = useCallback((value: any, obj: any): void => {

        // let keyNumber = Number(obj.key)

        refMes.current = value
        setValSelectMesFiltros(value)

        buscarDadosRelatorioTable();

    }, []);

    const clickAnoFiltros = useCallback((selectedIndex: any, obj: any) => {
        let keyNumber = Number(obj.key)
        refAno.current = selectedIndex;
        setValSelectAnoFiltros(selectedIndex)

        let mesesObjCompleto: Array<{ mes: number, nome: string }> = []

        mesesString.forEach((element, index) => {

            let isEqual = false
            anos[keyNumber].meses.forEach((elementMesReq, index) => {
                if (element.mes === elementMesReq.mes) {
                    isEqual = true
                }
            })

            if (isEqual) {
                mesesObjCompleto.push(element)
            }
        })
        setMeses(mesesObjCompleto)
        setValSelectMesFiltros(mesesObjCompleto[(mesesObjCompleto.length - 1)].mes)
        refMes.current = mesesObjCompleto[(mesesObjCompleto.length - 1)].mes

        buscarDadosRelatorioTable();

    }, [anos]);

    const clickBtnGerarPdf = useCallback((mes: number | undefined, ano: number | undefined) => {

        const buscaPdf = async () => {
            try {
                setLoading(true)
                let result = await api.get(urlMunicipioStatusConsolidadoPdf + '/' + ano + '/' + mes,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        }
                    });

                // não funciona no safari
                // window.open(result.data.filename, '_blank');

                var anchor = window.document.createElement('a');
                anchor.href = result.data.filename;
                anchor.target = "_blank";
                anchor.click();


                setLoading(false)

            } catch (error) {
                setLoading(false)
                let msgErro: any = (error as Error);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'relatorioconsolidado_dkwdfaiweyi')
                }


            }
        }

        if (ano !== null && ano !== undefined &&
            mes !== null && mes !== undefined) {

            buscaPdf();
        }

    }, []);

    const clickBtnGerarPdfWithMunicipios = useCallback((mes: number | undefined, ano: number | undefined) => {

        const buscaPdf = async () => {
            try {
                setLoading(true)
                let result = await api.get(urlMunicipioStatusConsolidadoPDFMunicipios + '/' + ano + '/' + mes,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        }
                    });

                // não funciona no safari
                // window.open(result.data.filename, '_blank');

                var anchor = window.document.createElement('a');
                anchor.href = result.data.filename;
                anchor.target = "_blank";
                anchor.click();


                setLoading(false)

            } catch (error) {
                setLoading(false)
                let msgErro: any = (error as Error);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'relatorioconsolidado_dkwdfaiweyi')
                }


            }
        }

        if (ano !== null && ano !== undefined &&
            mes !== null && mes !== undefined) {

            buscaPdf();
        }

    }, []);

    const selectNOMEMesPorId = useCallback((id: number | null | undefined): { mes: number, nome: string } => {

        let objMes = { mes: 0, nome: '' };
        if (id !== null && id !== undefined) {

            mesesString.forEach(val => {
                if (val.mes === id) {
                    objMes = val
                }
            });
            return objMes;
        }

        return objMes

    }, []);

    return (
        <LayoutDashboard>
            <Loading
                loading={loading}
            >
                <Row>
                    <Col
                        {...colSelect}
                    >
                        <div
                            className='RelatorioConsolidado_Botoes_dewgfywefv'
                        >
                            <div
                                style={{ marginBottom: 5 }}
                            >
                                <Text style={{ marginRight: 5 }} >{'Selecione o ano e o mês:'}</Text>
                            </div>
                            <div
                                style={{
                                    display: 'flex'
                                }}
                            >
                                <div
                                    style={{
                                        marginRight: 5,
                                        marginBottom: 5
                                    }}
                                >
                                    <Select
                                        showSearch
                                        optionFilterProp="children"
                                        onChange={clickAnoFiltros}
                                        value={valSelectAnoFiltros}
                                        // style={{
                                        //     width: '100%',
                                        //     marginBottom: 5,
                                        //     marginRight: 5,
                                        // }}
                                        filterOption={(input: any, option: any) => {
                                            let textDigit = removeAcento(input)
                                            let listCidade = removeAcento(option?.children);
                                            return listCidade.indexOf(textDigit) >= 0
                                        }}
                                    >
                                        {
                                            anos.map((element, index) => (
                                                <Select.Option
                                                    key={index}
                                                    value={element.ano}
                                                >
                                                    {element.ano}
                                                </Select.Option>
                                            ))
                                        }
                                    </Select>
                                </div>

                                <div
                                    style={{
                                        marginRight: 5,
                                        marginBottom: 5
                                    }}
                                >
                                    <Select
                                        showSearch
                                        placeholder="Selecione o mês"
                                        optionFilterProp="children"
                                        // className="botaoListVendedorUser"
                                        onChange={onChangeSelectCidades}
                                        value={valSelectMesFiltros}
                                        // disabled={!hasSelectedAdd}
                                        // defaultValue='Usuários'
                                        style={{
                                            width: '130%'
                                        }}
                                        filterOption={(input: any, option: any) => {
                                            let textDigit = removeAcento(input)
                                            let listCidade = removeAcento(option?.children);
                                            return listCidade.indexOf(textDigit) >= 0
                                        }}
                                    >
                                        {
                                            meses.map((element: any, index: number) => {
                                                return (
                                                    <Select.Option
                                                        key={index}
                                                        value={element.mes}
                                                    >
                                                        {element.nome}
                                                    </Select.Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </div>
                            </div>

                        </div>
                    </Col>
                    {
                        haveData &&
                        <>
                            <Col
                                {...colGerarPDFButton}
                                className="RelatorioConsolidado_botaoGerarPDF_djbewofuygvb"
                            >
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        clickBtnGerarPdf(valSelectMesFiltros, valSelectAnoFiltros)
                                    }}
                                // disabled={!hasSelectedView}
                                >
                                    Gerar PDF
                                </Button>
                                <Button
                                    type="primary"
                                    style={{
                                        marginLeft: 5
                                    }}
                                    onClick={() => {
                                        clickBtnGerarPdfWithMunicipios(valSelectMesFiltros, valSelectAnoFiltros)
                                    }}
                                // disabled={!hasSelectedView}
                                >
                                    Gerar PDF com Municípios
                                </Button>
                            </Col>
                        </>
                    }
                </Row>
                {!haveData && <div style={{ height: '300px' }} />}
                {
                    haveData &&

                    <div style={{ overflowX: "auto" }}>

                        <table
                            className='RelatorioConsolidado_Table_dnlowefo'
                        >
                            <thead>
                                <tr>
                                    <th
                                        style={{
                                            textAlign: 'center'
                                        }}
                                        colSpan={5}
                                    >
                                        Resultado Consolidado{`${valSelectMesFiltros !== undefined ? ' - ' +
                                            selectNOMEMesPorId(valSelectMesFiltros)?.nome + ' de ' + valSelectAnoFiltros : ''}`}
                                    </th>
                                </tr>
                                <tr>
                                    <th
                                        style={{
                                            minWidth: 180
                                        }}
                                    >
                                        {`População SEADE/${dataRelatorioConsolidado?.anoPopulacao ?
                                            dataRelatorioConsolidado?.anoPopulacao :
                                            ''
                                            }`}
                                    </th>
                                    <th
                                        style={{
                                            minWidth: 200,
                                            color: 'green'
                                        }}
                                    >
                                        Habilitados
                                    </th>
                                    <th
                                        style={{
                                            minWidth: 200,
                                            color: 'red'
                                        }}
                                    >
                                        Não Habilitados
                                    </th>
                                    <th
                                        style={{
                                            minWidth: 200
                                        }}
                                    >
                                        Total
                                    </th>
                                    <th
                                        style={{
                                            minWidth: 130
                                        }}
                                    >
                                        Qtde. Atendidos
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    haveData ?
                                        <>
                                            <tr>
                                                <td>Até 20.000</td>
                                                <td
                                                    style={{
                                                        color: 'green'
                                                    }}
                                                >
                                                    {
                                                        dataRelatorioConsolidado?.ateVinteMil && dataRelatorioConsolidado.ateVinteMil?.aptos ?
                                                            dataRelatorioConsolidado.ateVinteMil.aptos
                                                            : ''
                                                    }
                                                </td>
                                                <td
                                                    style={{
                                                        color: 'red'
                                                    }}
                                                >
                                                    {
                                                        dataRelatorioConsolidado?.ateVinteMil && dataRelatorioConsolidado.ateVinteMil?.inaptos ?
                                                            dataRelatorioConsolidado.ateVinteMil.inaptos
                                                            : ''
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        dataRelatorioConsolidado?.ateVinteMil && dataRelatorioConsolidado.ateVinteMil?.total ?
                                                            dataRelatorioConsolidado.ateVinteMil.total
                                                            : ''
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        dataRelatorioConsolidado?.ateVinteMil && dataRelatorioConsolidado.ateVinteMil?.atendidos ?
                                                            dataRelatorioConsolidado.ateVinteMil.atendidos
                                                            : ''
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Entre 20.000 e 50.000</td>
                                                <td
                                                    style={{
                                                        color: 'green'
                                                    }}
                                                >
                                                    {
                                                        dataRelatorioConsolidado?.entreVinteECinquenta && dataRelatorioConsolidado.entreVinteECinquenta?.aptos ?
                                                            dataRelatorioConsolidado.entreVinteECinquenta.aptos
                                                            : ''
                                                    }
                                                </td>
                                                <td
                                                    style={{
                                                        color: 'red'
                                                    }}
                                                >
                                                    {
                                                        dataRelatorioConsolidado?.entreVinteECinquenta && dataRelatorioConsolidado.entreVinteECinquenta?.inaptos ?
                                                            dataRelatorioConsolidado.entreVinteECinquenta.inaptos
                                                            : ''
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        dataRelatorioConsolidado?.entreVinteECinquenta && dataRelatorioConsolidado.entreVinteECinquenta?.total ?
                                                            dataRelatorioConsolidado.entreVinteECinquenta.total
                                                            : ''
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        dataRelatorioConsolidado?.entreVinteECinquenta && dataRelatorioConsolidado.entreVinteECinquenta?.atendidos ?
                                                            dataRelatorioConsolidado.entreVinteECinquenta.atendidos
                                                            : ''
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Entre 50.000 e 100.000</td>
                                                <td
                                                    style={{
                                                        color: 'green'
                                                    }}
                                                >
                                                    {
                                                        dataRelatorioConsolidado?.entreCinquentaECem && dataRelatorioConsolidado.entreCinquentaECem?.aptos ?
                                                            dataRelatorioConsolidado.entreCinquentaECem.aptos
                                                            : ''
                                                    }
                                                </td>
                                                <td
                                                    style={{
                                                        color: 'red'
                                                    }}
                                                >
                                                    {
                                                        dataRelatorioConsolidado?.entreCinquentaECem && dataRelatorioConsolidado.entreCinquentaECem?.inaptos ?
                                                            dataRelatorioConsolidado.entreCinquentaECem.inaptos
                                                            : ''
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        dataRelatorioConsolidado?.entreCinquentaECem && dataRelatorioConsolidado.entreCinquentaECem?.total ?
                                                            dataRelatorioConsolidado.entreCinquentaECem.total
                                                            : ''
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        dataRelatorioConsolidado?.entreCinquentaECem && dataRelatorioConsolidado.entreCinquentaECem?.atendidos ?
                                                            dataRelatorioConsolidado.entreCinquentaECem.atendidos
                                                            : ''
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Acima de 100.000</td>
                                                <td
                                                    style={{
                                                        color: 'green'
                                                    }}
                                                >
                                                    {
                                                        dataRelatorioConsolidado?.acimaDeCem && dataRelatorioConsolidado.acimaDeCem?.aptos ?
                                                            dataRelatorioConsolidado.acimaDeCem.aptos
                                                            : ''
                                                    }
                                                </td>
                                                <td
                                                    style={{
                                                        color: 'red'
                                                    }}
                                                >
                                                    {
                                                        dataRelatorioConsolidado?.acimaDeCem && dataRelatorioConsolidado.acimaDeCem?.inaptos ?
                                                            dataRelatorioConsolidado.acimaDeCem.inaptos
                                                            : ''
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        dataRelatorioConsolidado?.acimaDeCem && dataRelatorioConsolidado.acimaDeCem?.total ?
                                                            dataRelatorioConsolidado.acimaDeCem.total
                                                            : ''
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        dataRelatorioConsolidado?.acimaDeCem && dataRelatorioConsolidado.acimaDeCem?.atendidos ?
                                                            dataRelatorioConsolidado.acimaDeCem.atendidos
                                                            : ''
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Total</th>
                                                <th
                                                    style={{
                                                        color: 'green'
                                                    }}
                                                >
                                                    {
                                                        dataRelatorioConsolidado?.total && dataRelatorioConsolidado.total?.aptos ?
                                                            dataRelatorioConsolidado.total.aptos
                                                            : ''
                                                    }
                                                </th>
                                                <th
                                                    style={{
                                                        color: 'red'
                                                    }}
                                                >
                                                    {
                                                        dataRelatorioConsolidado?.total && dataRelatorioConsolidado.total?.inaptos ?
                                                            dataRelatorioConsolidado.total.inaptos
                                                            : ''
                                                    }
                                                </th>
                                                <th>
                                                    {
                                                        dataRelatorioConsolidado?.total && dataRelatorioConsolidado.total?.total ?
                                                            dataRelatorioConsolidado.total.total
                                                            : ''
                                                    }
                                                </th>
                                                <th>
                                                    {
                                                        dataRelatorioConsolidado?.total && dataRelatorioConsolidado.total?.atendidos ?
                                                            dataRelatorioConsolidado.total.atendidos
                                                            : ''
                                                    }
                                                </th>
                                            </tr>

                                            <tr>
                                                <th>%</th>
                                                <th
                                                    style={{
                                                        color: 'green'
                                                    }}
                                                >
                                                    {
                                                        dataRelatorioConsolidado?.porcentagemAptos ?
                                                            convertMoedaBRDoJSComExtamente2CasasDecimais(dataRelatorioConsolidado.porcentagemAptos) + '%'
                                                            : ''
                                                    }
                                                </th>
                                                <th
                                                    style={{
                                                        color: 'red'
                                                    }}
                                                >
                                                    {
                                                        dataRelatorioConsolidado?.porcentagemInaptos ?
                                                            convertMoedaBRDoJSComExtamente2CasasDecimais(dataRelatorioConsolidado.porcentagemInaptos) + '%'
                                                            : ''
                                                    }
                                                </th>
                                                <th>
                                                    {
                                                        dataRelatorioConsolidado?.porcentagemTotal ?
                                                            convertMoedaBRDoJSComExtamente2CasasDecimais(dataRelatorioConsolidado.porcentagemTotal) + '%'
                                                            : ''
                                                    }
                                                </th>
                                                <th>
                                                    {''}
                                                </th>
                                            </tr>

                                            <tr>
                                                <th>Limite LRF</th>
                                                <th
                                                    style={{
                                                        color: 'green'
                                                    }}
                                                >
                                                    {
                                                        dataRelatorioConsolidado?.limiteLrfAptos ?
                                                            'R$ ' + convertMoedaBRDoJSComExtamente2CasasDecimais(dataRelatorioConsolidado.limiteLrfAptos)
                                                            : ''
                                                    }
                                                </th>
                                                <th
                                                    style={{
                                                        color: 'red'
                                                    }}
                                                >
                                                    {
                                                        dataRelatorioConsolidado?.limiteLrfInaptos ?
                                                            'R$ ' + convertMoedaBRDoJSComExtamente2CasasDecimais(dataRelatorioConsolidado.limiteLrfInaptos)
                                                            : ''
                                                    }
                                                </th>
                                                <th>
                                                    {
                                                        dataRelatorioConsolidado?.limiteLrfTotal ?
                                                            'R$ ' + convertMoedaBRDoJSComExtamente2CasasDecimais(dataRelatorioConsolidado.limiteLrfTotal)
                                                            : ''
                                                    }
                                                </th>
                                                <th>
                                                    {''}
                                                </th>
                                            </tr>

                                            <tr>
                                                <th>Limite Potencial DSP</th>
                                                <th
                                                    style={{
                                                        color: 'green'
                                                    }}
                                                >
                                                    {
                                                        dataRelatorioConsolidado?.limitePotencialAptos ?
                                                            'R$ ' + convertMoedaBRDoJSComExtamente2CasasDecimais(dataRelatorioConsolidado.limitePotencialAptos)
                                                            : ''
                                                    }
                                                </th>
                                                <th
                                                    style={{
                                                        color: 'red'
                                                    }}
                                                >
                                                    {
                                                        dataRelatorioConsolidado?.limitePotencialInaptos ?
                                                            'R$ ' + convertMoedaBRDoJSComExtamente2CasasDecimais(dataRelatorioConsolidado.limitePotencialInaptos)
                                                            : ''
                                                    }
                                                </th>
                                                <th>
                                                    {
                                                        dataRelatorioConsolidado?.limitePotencialTotal ?
                                                            'R$ ' + convertMoedaBRDoJSComExtamente2CasasDecimais(dataRelatorioConsolidado.limitePotencialTotal)
                                                            : ''
                                                    }
                                                </th>
                                                <th>
                                                    {''}
                                                </th>
                                            </tr>
                                        </>
                                        :
                                        <tr>
                                            <th colSpan={5}
                                                style={{
                                                    textAlign: 'center'
                                                }}
                                            >
                                                {'Sem dados !'}
                                            </th>
                                        </tr>
                                }
                            </tbody>
                        </table>
                    </div>

                }
            </Loading>

        </LayoutDashboard >
    );
}

export default RelatorioConsolidado;
