import { useCallback, useState, useRef, useContext, useEffect } from 'react';
import { Form, Input, Button, Row, Col, Spin, notification } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { api } from '../../services/apiAxios';

// @ts-ignore
import ImagemLogo from './../../assets/imagens/logo.png';

import { urlUsuariosUsuarioRecuperacao } from './../../services/urls';
import { GlobalContext } from './../../contexts/globalContext';
import { EmitirErro } from './../../services/EmitirErro';
import { VerificaRota } from '../../services/VerificaSePodeAcessar';
import './style.css';
import { Loading } from '../../components/Loading';

const RecuperarSenha = () => {
    const navigate = useNavigate();
    const formRef = useRef<any>(null);

    const { theme } = useContext(GlobalContext);

    const [loading, setLoading] = useState(false);

    useEffect(() => {

        let arrayUrl = window.location.pathname.split("/");
        if (VerificaRota(arrayUrl, navigate, false)) {
            navigate('/municipios');
        }

    }, []);

    const onSubmit = useCallback((): void => {
        const buscaDeDados = async (dados: any) => {
            try {

                setLoading(true);

                let result = await api.get(urlUsuariosUsuarioRecuperacao + '/' + dados.email);

                formRef.current?.resetFields();

                notification.success({
                    message: 'Sucesso',
                    description:
                        result.data.msg,
                    duration: 6,
                });
                navigate('/?email=true');


            } catch (error) {
                setLoading(false);

                EmitirErro(error, true, 'recoverpass_dkwdfaiweyi')
            }
        }

        formRef.current?.validateFields()
            .then((values: any) => {
                buscaDeDados(values);
            })
            .catch(() => {
                // .catch((errorInfo: any) => {

                notification.error({
                    message: 'Erro',
                    description:
                        'Preencha os campos obrigatorios!',
                });
            });


    }, []);

    return (
        <>
            <Loading
                loading={loading}
            />
            <Row gutter={24} style={{ maxHeight: "100vh", margin: 0 }} align="middle">

                <Col
                    className='fotoFundoRecuperarSenha'
                    style={{
                        filter: theme === 'light' ? 'grayscale(0%)' : 'grayscale(100%)',

                    }} flex={3}
                />

                <Col flex={1} style={{
                    height: '100vh',
                    // backgroundColor: '#243463',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>

                    <Form
                        name="formRecuperarSenha"
                        ref={formRef}
                        initialValues={{
                            remember: true,
                        }}
                        style={{
                            width: '100%'
                        }}

                        onFinish={onSubmit}
                    // onFinishFailed={onFinishFailed}
                    >
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <img style={{
                                width: '290px',
                                // margin: '20px'
                            }} src={ImagemLogo}></img>
                            <div
                                style={{
                                    marginTop: 10,
                                    marginBottom: -15
                                }}
                            >
                                <p>Por favor digite seu email!</p>
                            </div>
                        </div>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            // alignItems: 'center',
                            // justifyContent: 'center'
                            margin: 30
                        }}>

                            <Form.Item
                                name="email"
                                style={{
                                    marginBottom: 15
                                }}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Por favor digite seu email!',
                                    },
                                ]}
                            >
                                <Input placeholder="Email" />
                            </Form.Item>

                            {/* <a
                                style={{
                                    // marginLeft: 7
                                    marginBottom: 20
                                }}
                                className="login-form-forgot"
                            >
                                Esqueceu a Senha?
                            </a> */}

                            <Button
                                type="primary"
                                style={{ width: "100%" }}
                                htmlType='submit'
                            // onKeyDown={onPreesTecla}
                            // onClick={onSubmitClick}
                            >
                                Enviar
                            </Button>
                            <Button
                                type="link"
                                style={{ width: "100%", marginTop: 10 }}
                                htmlType='button'
                                // onKeyDown={onPreesTecla}
                                onClick={() => {
                                    navigate('/')
                                }}
                            >
                                {`Voltar`}
                            </Button>
                        </div>
                    </Form>
                </Col>
            </Row>
        </>
    );
};

export default RecuperarSenha;
