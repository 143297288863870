import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Table, Typography, notification, Button, Modal, Row, Col } from 'antd';
import Icon, { InfoCircleOutlined } from '@ant-design/icons';
import moment from 'moment';

import { convertDataUSParaBrasil, convertDataUSParaBrasilEHora } from '../../../../services/Conversores';
import { useNavigate } from 'react-router-dom';
import locale from 'antd/es/locale/pt_BR';
import { FaDownload } from 'react-icons/fa';
import { urlCertidoes, urlMunicipioCertidao, urlMunicipioCertidaoPdfs, urlMunicipioCertidaoPdfsLiberacao } from './../../../../services/urls';
import { EmitirErro } from '../../../../services/EmitirErro';
import { SairDoSistema } from '../../../../services/LStorage/SairSistema';
import { api } from '../../../../services/apiAxios';
import { getToken } from './../../../../services/LStorage/token';
import { useGetColumnSearchProps } from './../../../../hooks/FilterTable/string';
import { useGetColumnSearchPropsData } from './../../../../hooks/FilterTable/date';
import { GlobalContext } from '../../../../contexts/globalContext';
import './style.css';

// import { colSelect } from './GridStyle';

// @ts-ignore
import ImgUpLike from './../../../../assets/imagens/upLike.png';
// @ts-ignore
import ImgDownLike from './../../../../assets/imagens/downLike.png';

const IconDownload = (props: any) => <Icon {...props} component={FaDownload} ></Icon>

const { Text } = Typography;

// const largura = window.innerWidth;
// const altura = window.innerHeight;

interface DadosCertidoesAll {
    arquivo: string,
    atualizadoEm: string,
    createdAt: string,
    dataValidade: null | string,
    descricao: string,
    id: string,
    idItem: number,
    municipioIdIbge: number,
    status: boolean,
    updatedAt: string,
}

interface interfDadosMunicipioSelecionado {
    municipioSelecionado?: {
        "idIbge": number,
        "nome": string,
    }
}

export const Certidoes = ({
    municipioSelecionado
}: interfDadosMunicipioSelecionado) => {

    const { theme } = useContext(GlobalContext);
    const navigate = useNavigate();

    const refContagemDoFiltro = React.useRef<Array<any>>([]);

    const [dadosTabelaState, setDadosTabelaState] = useState<Array<DadosCertidoesAll>>([]);

    const [modalViewRegrasCertidoes, setModalViewRegrasCertidoes] = useState(false);
    const [loading, setLoading] = useState(false);

    //setando dados das cidades no selected
    const buscaCidades = useCallback((idIbge: number) => {


        const buscaCiaddes = async () => {
            try {
                setLoading(true);
                let result = await api.get(urlMunicipioCertidao + '/' + idIbge,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        }
                    });

                setDadosTabelaState(result.data);


                setLoading(false);

            } catch (error) {

                let msgErro: any = (error as Error);
                setLoading(false);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'municipios_dkwdfaiweyi')
                }


            }
        }

        buscaCiaddes();

    }, []);


    useEffect(() => {

        if (municipioSelecionado) buscaCidades(municipioSelecionado.idIbge);

    }, [municipioSelecionado]);

    // click na linha da tabela de listagem de usuario
    // const onclickTable = useCallback((record: any, row: any): void => {

    //     if (selectedRowKeys.includes(record.uuid)) {
    //         let filter = selectedRowKeys.filter((val) => {
    //             return val != record.uuid
    //         })
    //         if (filter.length === 1) {
    //             setSelectedRowKeys(filter);
    //             setHasSelectedEdit(true);
    //         } else if (filter.length === 0) {
    //             setSelectedRowKeys(filter);
    //             setHasSelectedEdit(false);
    //         } else {
    //             setSelectedRowKeys(filter);
    //         }

    //     } else {
    //         if (selectedRowKeys.length == 0) {
    //             setSelectedRowKeys([record.uuid]);
    //             setHasSelectedEdit(true);
    //         } else {
    //             let teste = selectedRowKeys.concat(record.uuid);
    //             setSelectedRowKeys(teste);
    //             setHasSelectedEdit(false);
    //         }
    //     }
    // }, [selectedRowKeys]);

    //checkbox da tabela de listagem
    // const onSelectChange = useCallback((selectedRowKeys: any): void => {
    // const onSelectChange = useCallback((selectedRowKeys: Array<string | number>, selectedRows: Array<any>): void => {
    //     if (selectedRowKeys.length > 1) {
    //         setSelectedRowKeys(selectedRowKeys);
    //         setHasSelectedEdit(false);
    //     } else if (selectedRowKeys.length === 0) {
    //         setSelectedRowKeys(selectedRowKeys);
    //         setHasSelectedEdit(false);
    //     } else {
    //         setSelectedRowKeys(selectedRowKeys);
    //         setHasSelectedEdit(true);
    //     }
    // }, [selectedRowKeys]);

    // const rowSelection = {
    //     selectedRowKeys,
    //     onChange: onSelectChange,
    //     columnWidth: '1%'
    // };

    const clickBtnGerarPdf = useCallback((arquivo: string) => {

        const buscaPdf = async () => {
            try {
                setLoading(true)
                let result = await api.get(arquivo,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        }
                    });
                console.log(result.data)
                // fileSaver.saveAs(result.data, `example.zip`);
                // console.log('uiui')

                // não funciona no safari
                // window.open(result.data.filename, '_blank');
                // data:application/zip;base64,

                // const data = ``
                // const filename = 'certidoaes.zip';
                // const aTag = document.createElement('a');

                // aTag.href = data;
                // aTag.download = filename;
                // aTag.click();


                //----------------------------------------------------

                // let anchor = window.document.createElement('a');
                // anchor.href = urlMunicipioCertidaoPdfs + '/' + municipioSelec?.idIbge;
                // anchor.target = "_blank";
                // anchor.click();


                setLoading(false)

            } catch (error) {
                setLoading(false)
                let msgErro: any = (error as Error);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'relatorioconsolidado_dkwdfaiweyi')
                }


            }
        }


        buscaPdf();



    }, []);

    const columns = [
        {
            title: 'Certidões',
            dataIndex: 'descricao',
            ...useGetColumnSearchProps('descricao'),
            width: '10%',
            sorter: (a: any, b: any) => a.descricao.localeCompare(b.descricao),
            showSorterTooltip: false,
        },
        {
            title: 'Validade',
            dataIndex: 'dataValidade',
            ...useGetColumnSearchPropsData('dataValidade'),
            width: '2%',
            sorter: (a: any, b: any) => {

                let aMonMen: any = null
                let bMonMen: any = null
                if (a.dataValidade) {
                    aMonMen = moment(a.dataValidade).unix()
                } else {
                    aMonMen = 0
                }
                if (b.dataValidade) {
                    bMonMen = moment(b.dataValidade).unix()
                } else {
                    bMonMen = 0
                }

                return aMonMen - bMonMen
            },
            showSorterTooltip: false,
            // sorter: (a: any, b: any) => a.dataValidade.localeCompare(b.dataValidade),
            render: (criado: any, record: any) => {
                return (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                    >

                        <Text>
                            {
                                criado ? convertDataUSParaBrasil(criado) : '-'
                            }
                        </Text>

                        <img style={{
                            width: 20,
                            marginLeft: 5
                        }} src={record.status ? ImgDownLike : ImgUpLike} />
                    </div>
                )
            },
        },
        {
            title: 'Data de Atualização',
            dataIndex: 'atualizadoEm',
            ...useGetColumnSearchPropsData('atualizadoEm'),
            width: '2%',
            sorter: (a: any, b: any) => {

                let aMonMen: any = null
                let bMonMen: any = null
                if (a.atualizadoEm) {
                    aMonMen = moment(a.atualizadoEm).unix()
                } else {
                    aMonMen = 0
                }
                if (b.atualizadoEm) {
                    bMonMen = moment(b.atualizadoEm).unix()
                } else {
                    bMonMen = 0
                }

                return aMonMen - bMonMen
            },
            showSorterTooltip: false,
            // sorter: (a: any, b: any) => a.update_at.localeCompare(b.update_at),
            render: (data: any) => {

                return (
                    convertDataUSParaBrasilEHora(data)
                )
            },
        },
        {
            title: 'Baixar',
            dataIndex: 'arquivo',
            width: '2%',
            showSorterTooltip: false,
            render: (arquivo: any, record: any) => {
                return (
                    <div className='naoElinha'>
                        {
                            arquivo ?
                                <a href={urlCertidoes + '/' + arquivo} className='ant-btn ant-btn-primary botaoNaolinha' target="_blank" rel="noopener noreferrer">
                                    {'Baixar '}<IconDownload />
                                </a>
                                // <Button
                                //     type="primary"
                                //     className="botaoNaolinha"
                                //     // disabled={!hasSelectedAdd}
                                //     onClick={() => { clickBtnGerarPdf(arquivo) }}
                                // >
                                //     {'Baixar '}<IconDownload />
                                // </Button>
                                :
                                <Text
                                    style={{
                                        fontWeight: 'bold',
                                        color: record.status ? 'red' : 'green'
                                    }}
                                >
                                    {'Não há PDF'}
                                </Text>
                        }

                    </div>
                )
            },
        },
    ];

    const clickBtnGerarZip = useCallback((municipioSelec?: {
        "idIbge": number,
        "nome": string,
    }) => {

        const buscaPdf = async () => {
            try {
                setLoading(true)
                let result = await api.get(urlMunicipioCertidaoPdfs + '/' + municipioSelec?.idIbge,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        },
                        responseType: 'blob',
                    });


                const url = window.URL.createObjectURL(new Blob([result.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'certidoes.zip');
                document.body.appendChild(link);
                link.click();


                setLoading(false)

            } catch (error) {
                setLoading(false)
                let msgErro: any = (error as Error);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'relatorioconsolidado_dkwdfaiweyi')
                }


            }
        }

        if (municipioSelec) {

            buscaPdf();
        }


    }, []);


    const clickBtnZipCertLiberacao = useCallback((municipioSelec?: {
        "idIbge": number,
        "nome": string,
    }) => {

        const buscaPdf = async () => {
            try {
                setLoading(true)
                let result = await api.get(urlMunicipioCertidaoPdfsLiberacao + '/' + municipioSelec?.idIbge,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        },
                        responseType: 'blob',
                    });


                const url = window.URL.createObjectURL(new Blob([result.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'certidoes_liberacao.zip');
                document.body.appendChild(link);
                link.click();


                setLoading(false)

            } catch (error) {
                setLoading(false)
                let msgErro: any = (error as Error);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'relatorioconsolidado_dkwdfaiweyi')
                }


            }
        }

        if (municipioSelec) {

            buscaPdf();
        }


    }, []);


    return (
        <>
            <div
                className='divBotaoRegraEBaixarTodas_certidao_xcekfghvewiqf'
            >
                <div
                    style={{
                        display: 'flex',
                        // justifyContent: 'flex-end',
                        // justifyContent: 'flex-end',
                        alignItems: 'center',
                        marginBottom: 15
                    }}
                >
                    <Text style={{ marginRight: 5 }}>{'Regras de Atualização'}</Text>
                    <Button
                        type="link"
                        // className="BotaoLi"
                        style={{ margin: 2, padding: 0, fontSize: 16 }}
                        // disabled={!hasSelectedAdd}
                        onClick={() => { setModalViewRegrasCertidoes(true) }}
                    >
                        <InfoCircleOutlined style={{ color: theme === 'light' ? '#262626' : '#fff' }} />
                    </Button>
                </div>
                <div
                    style={{
                        display: 'flex',
                        // justifyContent: 'flex-end',
                        // justifyContent: 'flex-end'
                        marginBottom: 15
                    }}
                    className='divbotoesDownload_certidao_ohufverwiqyutv'
                >
                    <Button
                        type="primary"
                        // className="botaoVerdeBaixarTodas"
                        style={{ margin: 2 }}
                        // disabled={!hasSelectedAdd}
                        onClick={() => { clickBtnZipCertLiberacao(municipioSelecionado) }}
                    >
                        Cert. Liberação<IconDownload />
                    </Button>
                    <Button
                        type="primary"
                        className="botaoVerdeBaixarTodas"
                        style={{ margin: 2 }}
                        // disabled={!hasSelectedAdd}
                        onClick={() => { clickBtnGerarZip(municipioSelecionado) }}
                    >
                        Baixar Todas<IconDownload />
                    </Button>
                    {/* <Button
                    className="botaoViewUsuarioUser"
                    type="primary"
                    style={{ margin: 2 }}
                // onClick={start}
                // disabled={!hasSelectedView}
                >
                    Documento SICONFI
                </Button> */}
                </div>
            </div>
            <Table
                style={{
                    // marginTop: 50
                }}
                className='marginTopTables'
                rowKey={(record) => record.id}
                // loading={loadingTable}
                // onRow={(record, rowIndex) => {
                //     return {
                //         onClick: event => { onclickTable(record, rowIndex) }, // click row
                //         onDoubleClick: event => { }, // double click row
                //         onContextMenu: event => { }, // right button click row
                //         onMouseEnter: event => { }, // mouse enter row
                //         onMouseLeave: event => { }, // mouse leave row
                //     };
                // }}
                // rowSelection={rowSelection}
                columns={columns} dataSource={dadosTabelaState}
                scroll={{ x: 650 }}
                onChange={
                    (pagination, filters, sorter, extra) => {
                        refContagemDoFiltro.current = extra.currentDataSource
                    }
                }
                // title={(registros) => {

                //     return (
                //         <div
                //             style={{
                //                 backgroundColor: theme === 'light' ? '#FAFAFA' : '#1D1D1D',
                //                 padding: 10,
                //                 margin: -8,
                //                 marginBottom: 0.5
                //             }}
                //         >
                //             <Text>
                //                 {/* {`Total: ${dadosTabelaState.length} registros.`} */}
                //                 {`Mostrando: ${registros.length} de ${refContagemDoFiltro.current.length === 0 ?
                //                     dadosTabelaState.length :
                //                     refContagemDoFiltro.current.length} num total de ${dadosTabelaState.length} `}
                //             </Text>
                //         </div>
                //     )
                // }}
                // footer={(registros) => {
                //     return (
                //         <div
                //             style={{
                //                 textAlign: 'end'
                //             }}
                //         >
                //             <Text>
                //                 {/* {`Total: ${dadosTabelaState.length} registros.`} */}
                //                 {`Mostrando: ${registros.length} de ${refContagemDoFiltro.current.length === 0 ?
                //                     dadosTabelaState.length :
                //                     refContagemDoFiltro.current.length} num total de ${dadosTabelaState.length} `}
                //             </Text>
                //         </div>
                //     )
                // }}
                size="small"
                pagination={{
                    locale: { items_per_page: '' },
                    showSizeChanger: false,
                    defaultPageSize: 100,
                    // position: ['topRight', 'bottomRight'],
                    position: [],
                    pageSizeOptions: ['10', '20', '50', '100']
                }}
                locale={locale.Table}
            />
            <Modal
                title="Regras de Atualização"
                visible={modalViewRegrasCertidoes}
                // className="classmodalDadosContatoInatendimento"
                onOk={() => setModalViewRegrasCertidoes(false)}
                onCancel={() => setModalViewRegrasCertidoes(false)}
                footer={[
                    <Button key="back" onClick={() => setModalViewRegrasCertidoes(false)}>
                        Fechar
                    </Button>,
                ]}
            >
                <Row>
                    <Col span={24} style={{ marginBottom: 5 }}>
                        <Text
                            strong={false}
                        >
                            <Text strong style={{ marginRight: 5 }}>CADIN: </Text>
                            {'Atualiza diariamente.'}
                        </Text>
                    </Col>
                </Row>
                <Row>
                    <Col span={24} style={{ marginBottom: 5 }}>
                        <Text
                            strong={false}
                        >
                            <Text strong style={{ marginRight: 5 }}>CAUC - Item 3: </Text>
                            {'Atualiza diariamente a partir das 09:00h (Antes desse horário é provável que os dados apresentem inconsistências no sistema do STN).'}
                        </Text>
                    </Col>
                </Row>
                <Row>
                    <Col span={24} style={{ marginBottom: 5 }}>
                        <Text
                            strong={false}
                        >
                            <Text strong style={{ marginRight: 5 }}>Certificado de Regularidade Previdenciária (CRP): </Text>
                            {'Atualiza no vencimento.'}
                        </Text>
                    </Col>
                </Row>
                <Row>
                    <Col span={24} style={{ marginBottom: 5 }}>
                        <Text
                            strong={false}
                        >
                            <Text strong style={{ marginRight: 5 }}>FGTS: </Text>
                            {'Atualiza no vencimento.'}
                        </Text>
                    </Col>
                </Row>
                <Row>
                    <Col span={24} style={{ marginBottom: 5 }}>
                        <Text
                            strong={false}
                        >
                            <Text strong style={{ marginRight: 5 }}>Procuradoria Geral do Estado - Dívida Ativa: </Text>
                            {'Atualiza no vencimento.'}
                        </Text>
                    </Col>
                </Row>
                <Row>
                    <Col span={24} style={{ marginBottom: 5 }}>
                        <Text
                            strong={false}
                        >
                            <Text strong style={{ marginRight: 5 }}>Receita Estadual - Tributos: </Text>
                            {'Atualiza no vencimento.'}
                        </Text>
                    </Col>
                </Row>
                <Row>
                    <Col span={24} style={{ marginBottom: 5 }}>
                        <Text
                            strong={false}
                        >
                            <Text strong style={{ marginRight: 5 }}>Receita Federal – (Financiamento e Fundo Perdido): </Text>
                            {'Atualiza no vencimento.'}
                        </Text>
                    </Col>
                </Row>
                <Row>
                    <Col span={24} style={{ marginBottom: 5 }}>
                        <Text
                            strong={false}
                        >
                            <Text strong style={{ marginRight: 5 }}>SAHEM: </Text>
                            {'Atualiza diariamente, porém em dias de semana o site só permite consultas das 10:00h até as 23:59h. Finais de semana e feriados o site não permite consultas.'}
                        </Text>
                    </Col>
                </Row>
                <Row>
                    <Col span={24} style={{ marginBottom: 5 }}>
                        <Text
                            strong={false}
                        >
                            <Text strong style={{ marginRight: 5 }}>Trabalho Análogo a de Escravo: </Text>
                            {'Atualiza diariamente.'}
                        </Text>
                    </Col>
                </Row>
                <Row>
                    <Col span={24} style={{ marginBottom: 5 }}>
                        <Text
                            strong={false}
                        >
                            <Text strong style={{ marginRight: 5 }}>Tribunal Superior do Trabalho - TST: </Text>
                            {'Atualiza no vencimento.'}
                        </Text>
                    </Col>
                </Row>
            </Modal>
        </>
    );
}
