import React from 'react';
import { Typography } from 'antd';
import { title } from 'process';

const { Text, Title } = Typography;

interface intProps {
    valor?: string;
    descricao?: string;
    title?: string;
    corFundo?: string;
    style?: React.CSSProperties;
    resultado?: boolean;
}

export const CardLimites = ({
    valor = '',
    descricao = '',
    title = '',
    corFundo = '#40a9ff',
    style = {},
    resultado = false
}: intProps) => {

    let arr: any = {
        ...{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            // alignItems: 'baseline',
            justifyContent: 'space-between',
            padding: !resultado ? "0.25rem 0.75rem" : "0.25rem 0.75rem" ,
            // marginBottom: "0",
            // borderBottom: "none",
            background: corFundo,
            // zIndex: "3 !important",
            // margin: "0 15px",
            // borderRadius: "calc(.25rem - 1px) calc(.25rem - 1px) 0 0",
            borderRadius: "3px",

            // fontSize: "36px",
            // lineHeight: "56px",
            // textAlign: "center",
            // width: "36px",
            // height: "36px",
            // margin: "10px 10px 4px",

        }, ...style
    }

    return (
        <>
            {
                !resultado ?
                    <>
                        {
                            valor !== '' &&
                            <div
                                style={arr}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                    }}
                                >
                                    <Title level={4} style={{
                                        color: '#fff',
                                        margin: 0,
                                        marginRight: 5
                                        // fontWeight: 'bold'
                                    }}>{'R$'}</Title>
                                    <Title level={4} style={{
                                        color: '#fff',
                                        margin: 0,
                                        // fontWeight: 'bold'
                                    }}>{valor}</Title>
                                </div>

                                <Text style={{
                                    color: '#fff',
                                    margin: 0,
                                    // fontWeight: 'bold'
                                }}>{descricao}</Text>
                            </div>
                        }
                    </>
                    :
                    <div
                        style={arr}
                    >
                        <div
                            style={{
                                display: 'flex',
                            }}
                        >
                            <Title level={4} style={{
                                color: '#fff',
                                margin: 0,
                                marginRight: 5
                                // fontWeight: 'bold'
                            }}>{title}</Title>
                        </div>

                        <Text style={{
                            color: '#fff',
                            // fontSize: '1.1rem',
                            margin: 0,
                            // fontWeight: 'bold'
                        }}>{descricao}</Text>
                    </div>
            }
        </>
    );
}
