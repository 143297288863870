import React, { useCallback, useEffect, useState } from 'react';
import { Table, Typography, notification, Tag } from 'antd';
import Icon from '@ant-design/icons';

import { convertMoedaBRDoJSComExtamente2CasasDecimais } from '../../../../../../services/Conversores';
import { useNavigate } from 'react-router-dom';
import locale from 'antd/es/locale/pt_BR';
import { FaDownload } from 'react-icons/fa';
import { urlCertidoes, urlPoliticosEleicao } from './../../../../../../services/urls';
import { EmitirErro } from '../../../../../../services/EmitirErro';
import { SairDoSistema } from '../../../../../../services/LStorage/SairSistema';
import { api } from '../../../../../../services/apiAxios';
import { getToken } from './../../../../../../services/LStorage/token';
import { useGetColumnSearchProps } from './../../../../../../hooks/FilterTable/string';
import { useGetColumnSearchPropsData } from './../../../../../../hooks/FilterTable/date';
import './style.css';

// import { colSelect } from './GridStyle';

// @ts-ignore
import ImgUpLike from './../../../../../../assets/imagens/upLike.png';
// @ts-ignore
import ImgDownLike from './../../../../../../assets/imagens/downLike.png';

const IconDownload = (props: any) => <Icon {...props} component={FaDownload} ></Icon>

const { Text } = Typography;

// const largura = window.innerWidth;
// const altura = window.innerHeight;

interface DadosEleicoesAll {
    _id: string,
    cpf: string,
    eleicoes: Array<{
        ano: number,
        cargo: string,
        id_candidato: number,
        bens_declarados: number,
        local: string,
        partido: string,
        resultado: string
    }>
}

interface interfDadosMunicipioSelecionado {
    dadosPoliticoSelecionado?: {
        cpf: string,
        nome: string,
        parceiro: string,
        cargo: string,
        status: string,
        img: string,
    }
}

export const Eleicoes = ({
    dadosPoliticoSelecionado
}: interfDadosMunicipioSelecionado) => {

    const navigate = useNavigate();

    const refContagemDoFiltro = React.useRef<Array<any>>([]);

    const [dadosTabelaState, setDadosTabelaState] = useState<DadosEleicoesAll>();

    const [loading, setLoading] = useState(false);

    //setando dados das cidades no selected
    const buscaCidades = useCallback((cpfPolitico: string) => {


        const buscaCiaddes = async () => {
            try {
                setLoading(true);
                let result = await api.get(urlPoliticosEleicao + '/' + cpfPolitico,
                    // let result = await api.get(urlPoliticosEleicao + '/' + '312321321312',
                    // let result = await api.get(urlPoliticosEleicao + '/' + '03990043862',
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        }
                    });

                setDadosTabelaState(result.data);


                setLoading(false);

            } catch (error) {

                let msgErro: any = (error as Error);
                setLoading(false);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {

                    if (msgErro?.response.data.message === 'Eleições não encontradas') {
                        setDadosTabelaState(undefined);
                    }
                    EmitirErro(error, true, 'municipios_dkwdfaiweyi')
                }


            }
        }

        buscaCiaddes();

    }, []);


    useEffect(() => {

        if (dadosPoliticoSelecionado) buscaCidades(dadosPoliticoSelecionado.cpf);

    }, [dadosPoliticoSelecionado]);

    const columns = [
        {
            title: 'Ano',
            dataIndex: 'ano',
            ...useGetColumnSearchProps('ano'),
            width: '4%',
            sorter: (a: any, b: any) => a.ano - b.ano,
            showSorterTooltip: false,
            render: (resultado: any) => {
                return (
                    <div style={{
                        padding: 8
                    }}>
                        <Text>
                            {
                                resultado
                            }
                        </Text>
                    </div>

                )
            },
        },
        {
            title: 'Cargo',
            dataIndex: 'cargo',
            ...useGetColumnSearchProps('cargo'),
            width: '6%',
            sorter: (a: any, b: any) => a.cargo.localeCompare(b.cargo),
            showSorterTooltip: false,
            render: (resultado: any) => {
                return (
                    <div style={{
                        padding: 8
                    }}>
                        <Text>
                            {
                                resultado
                            }
                        </Text>
                    </div>

                )
            },
        },
        {
            title: 'Bens Declarados',
            dataIndex: 'bens_declarados',
            // ...useGetColumnSearchProps('cdp'),
            width: '10%',
            sorter: (a: any, b: any) => a.bens_declarados - b.bens_declarados,
            showSorterTooltip: false,
            render: (bens_declarados: any) => {

                return (
                    <div style={{
                        display: 'flex',
                        padding: 8
                    }}>
                        <Text
                        >
                            {
                                'R$ '+convertMoedaBRDoJSComExtamente2CasasDecimais(bens_declarados, true)
                            }
                        </Text>
                    </div>

                )
            },
        },
        {
            title: 'Local',
            dataIndex: 'local',
            ...useGetColumnSearchProps('local'),
            width: '12%',
            sorter: (a: any, b: any) => a.local.localeCompare(b.local),
            showSorterTooltip: false,
            render: (resultado: any) => {
                return (
                    <div style={{
                        padding: 8
                    }}>
                        <Text>
                            {
                                resultado
                            }
                        </Text>
                    </div>

                )
            },
        },
        {
            title: 'Partido',
            dataIndex: 'partido',
            ...useGetColumnSearchProps('partido'),
            width: '6%',
            sorter: (a: any, b: any) => a.partido.localeCompare(b.partido),
            showSorterTooltip: false,
            render: (resultado: any) => {
                return (
                    <div style={{
                        padding: 8
                    }}>
                        <Text>
                            {
                                resultado
                            }
                        </Text>
                    </div>

                )
            },
        },
        {
            title: 'Resultado',
            dataIndex: 'resultado',
            ...useGetColumnSearchProps('resultado'),
            width: '10%',
            sorter: (a: any, b: any) => a.resultado.localeCompare(b.resultado),
            showSorterTooltip: false,
            render: (resultado: any) => {

                return (
                    <div style={{
                        display: 'flex',
                        // justifyContent: 'center',
                        // backgroundColor:
                        //     resultado === null ? undefined :
                        //         resultado.toLowerCase() === 'eleito' || resultado.toLowerCase() === 'eleito por qp' ||
                        //             resultado.toLowerCase() === 'eleito por média'
                        //             ? 'green'
                        //             : '#a09f9f',
                        padding: 8
                        // justifyContent: 'center'
                    }}>
                        <Tag
                            color={
                                resultado === null ? undefined :
                                    resultado.toLowerCase() === 'eleito' || resultado.toLowerCase() === 'eleito por qp' ||
                                        resultado.toLowerCase() === 'eleito por média' ||
                                        resultado.toLowerCase() === 'média'
                                        ? '#00aa00'
                                        : '#a09f9f'
                            }
                            style={{
                                fontWeight: 'bold',
                                fontSize: '0.9rem',
                                paddingLeft: 12,
                                paddingRight: 12,
                                paddingTop: 4,
                                paddingBottom: 4,
                            }}
                        >
                            {
                                resultado
                            }
                        </Tag>
                    </div>

                )
            },
        },
    ];


    return (
        <>
            <Table
                style={{
                    // marginTop: 50
                }}
                className='marginTopTablesEleicoes'
                rowKey={(record) => record.id_candidato.toString()}
                loading={loading}
                // onRow={(record, rowIndex) => {
                //     return {
                //         onClick: event => { onclickTable(record, rowIndex) }, // click row
                //         onDoubleClick: event => { }, // double click row
                //         onContextMenu: event => { }, // right button click row
                //         onMouseEnter: event => { }, // mouse enter row
                //         onMouseLeave: event => { }, // mouse leave row
                //     };
                // }}
                // rowSelection={rowSelection}
                columns={columns}
                dataSource={dadosTabelaState?.eleicoes ? dadosTabelaState.eleicoes : []}
                scroll={{ x: 850 }}
                onChange={
                    (pagination, filters, sorter, extra) => {
                        refContagemDoFiltro.current = extra.currentDataSource
                    }
                }
                // title={(registros) => {

                //     return (
                //         <div
                //             style={{
                //                 backgroundColor: theme === 'light' ? '#FAFAFA' : '#1D1D1D',
                //                 padding: 10,
                //                 margin: -8,
                //                 marginBottom: 0.5
                //             }}
                //         >
                //             <Text>
                //                 {/* {`Total: ${dadosTabelaState.length} registros.`} */}
                //                 {`Mostrando: ${registros.length} de ${refContagemDoFiltro.current.length === 0 ?
                //                     dadosTabelaState.length :
                //                     refContagemDoFiltro.current.length} num total de ${dadosTabelaState.length} `}
                //             </Text>
                //         </div>
                //     )
                // }}
                // footer={(registros) => {
                //     return (
                //         <div
                //             style={{
                //                 textAlign: 'end'
                //             }}
                //         >
                //             <Text>
                //                 {/* {`Total: ${dadosTabelaState.length} registros.`} */}
                //                 {`Mostrando: ${registros.length} de ${refContagemDoFiltro.current.length === 0 ?
                //                     dadosTabelaState.length :
                //                     refContagemDoFiltro.current.length} num total de ${dadosTabelaState.length} `}
                //             </Text>
                //         </div>
                //     )
                // }}
                size="small"
                pagination={{
                    locale: { items_per_page: '' },
                    showSizeChanger: false,
                    defaultPageSize: 100,
                    // position: ['topRight', 'bottomRight'],
                    position: [],
                    pageSizeOptions: ['10', '20', '50', '100']
                }}
                locale={locale.Table}
            />
        </>
    );
}
