export const colTodasInformacoes = {
    xs: { span: 24, offset: 0 }, //<576
    sm: { span: 24, offset: 0 }, //>576
    md: { span: 12, offset: 0 }, // >768
    lg: { span: 12, offset: 0 }, // > 992
    xl: { span: 12, offset: 0 }, // > 1200
    xxl: { span: 12, offset: 0 }, // > 1600
};
export const colTodasInformacoesEsquerda = {
    xs: { span: 24, offset: 0 }, //<576
    sm: { span: 24, offset: 0 }, //>576
    md: { span: 11, offset: 1 }, // >768
    lg: { span: 11, offset: 1 }, // > 992
    xl: { span: 11, offset: 1 }, // > 1200
    xxl: { span: 11, offset: 1 }, // > 1600
};
