import { ReactNode, useContext } from "react";
import { Typography } from 'antd';
import { GlobalContext } from "../../../../../../../../contexts/globalContext";

const { Text } = Typography;

interface intProps {
    sites: Array<string>;
    description?: string;
    renderIcon?: () => ReactNode;
}

export const CardSitesDadosPessoais = ({
    sites,
    description = '',
    renderIcon = () => <></>,
}: intProps) => {
    const { theme } = useContext(GlobalContext);

    return (
        <div style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            borderRadius: 8,
            backgroundColor: theme === 'light' ? '#fff' : '#141414',
            boxShadow: '4px 4px 4px 4px rgba(0, 0, 0, 0.2)',
            padding: 10,
        }}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                }}
            >
                <div
                    style={{
                        padding: 8,
                        backgroundColor: '#1890ff',
                        borderRadius: '100%',
                        width: 38,
                        height: 38,
                        margin: 5,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    {renderIcon()}
                </div>
                <div
                    style={{
                        marginLeft: 5,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start'
                    }}
                >
                    <>
                        {
                            sites.map((elemento, index) => {
                                return (
                                    <a
                                        key={'sites' + index}
                                        href={elemento}
                                        className='ant-btn ant-btn-link'
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{
                                            marginLeft: 0,
                                            padding: 0, margin: 0,
                                            // fontWeight: 'bold',
                                            fontSize: '1rem'
                                        }}
                                    >
                                        {elemento}
                                    </a>
                                )
                            })
                        }
                    </>
                    <Text strong type='secondary' style={{
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: '0.9rem'
                    }}>
                        {description}
                    </Text>
                </div>
            </div>
        </div>
    )
}
