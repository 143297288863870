import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Typography, notification, Button, Col, Row } from 'antd';

import { useNavigate } from 'react-router-dom';
import { urlPoliticosPoliticoNoticia, urlPoliticosPoliticoObservacao, urlPoliticosPoliticoSegmento } from './../../../../../../services/urls';
import { EmitirErro } from '../../../../../../services/EmitirErro';
import { SairDoSistema } from '../../../../../../services/LStorage/SairSistema';
import { api } from '../../../../../../services/apiAxios';
import { getToken } from './../../../../../../services/LStorage/token';
import { colInputsTextarea } from './GridStyle';
import { Loading } from '../../../../../../components/Loading';

const { Text } = Typography;

// const largura = window.innerWidth;
// const altura = window.innerHeight;

interface interfNoticia {
    "segmento_atuacao"?: string | null
    "observacao"?: string | null
}

interface interfNoticiaReq {
    "noticia"?: interfNoticia,
    "_id": string
}
interface interfDadosPolitico {
    cpf: string,
    nome: string,
    parceiro: string,
    cargo: string,
    status: string,
    img: string,
}

interface interfDadosMunicipioSelecionado {
    dadosPoliticoSelecionado?: {
        cpf: string,
        nome: string,
        parceiro: string,
        cargo: string,
        status: string,
        img: string,
    }
}

export const Noticias = ({
    dadosPoliticoSelecionado
}: interfDadosMunicipioSelecionado) => {

    const navigate = useNavigate();

    const refInputObservacao = useRef<any>();
    const refInputObservacaoValue = useRef<any>();
    const refInputSegmentoAtuacao = useRef<any>();
    const refInputSegmentoAtuacaoValue = useRef<any>();

    const [dadosNoticiaReq, setDadosNoticiaReq] = useState<interfNoticiaReq>();
    const [podeSalvarObs, setPodeSalvarObs] = useState(false);
    const [podeSalvarSegAtuacao, setPodeSalvarSegAtuacao] = useState(false);
    const [loading, setLoading] = useState(false);

    //setando dados das cidades no selected
    const buscaCidades = useCallback((cpfPolitico: string) => {


        const buscaCiaddes = async () => {
            try {
                setLoading(true);
                let result = await api.get(urlPoliticosPoliticoNoticia + '/' + cpfPolitico,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        }
                    });

                const { noticia }: interfNoticiaReq = result.data

                setDadosNoticiaReq(result.data);

                if (noticia) {
                    refInputObservacao.current.value = noticia?.observacao !== null &&
                        noticia?.observacao !== undefined ?
                        noticia.observacao : ''

                    refInputSegmentoAtuacao.current.value = noticia?.segmento_atuacao !== null &&
                        noticia?.segmento_atuacao !== undefined ?
                        noticia.segmento_atuacao : ''
                } else {
                    refInputObservacao.current.value = ''
                    refInputSegmentoAtuacao.current.value = ''
                }

                setLoading(false);

            } catch (error) {

                let msgErro: any = (error as Error);
                setLoading(false);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {

                    EmitirErro(error, true, 'municipios_dkwdfaiweyi')
                }


            }
        }

        buscaCiaddes();

    }, []);


    useEffect(() => {

        if (dadosPoliticoSelecionado) buscaCidades(dadosPoliticoSelecionado.cpf);

    }, [dadosPoliticoSelecionado]);


    const onChangeInputObservacao = useCallback((e: any) => {

        refInputObservacaoValue.current = e.target.value;

        const obsev = dadosNoticiaReq?.noticia?.observacao !== null && dadosNoticiaReq?.noticia?.observacao !== undefined
            ? dadosNoticiaReq.noticia.observacao : ''

        if (obsev === e.target.value) {
            setPodeSalvarObs(false)
        } else {
            setPodeSalvarObs(true)
        }

    }, [dadosNoticiaReq])

    const onClickSalvarObservacao = useCallback((eleicao?: interfDadosPolitico) => {

        const buscaCiaddes = async () => {
            try {

                if (eleicao) {

                    setLoading(true);
                    let result = await api.put(urlPoliticosPoliticoObservacao + '/' + eleicao.cpf,
                        { observacao: refInputObservacaoValue.current },
                        {
                            headers: {
                                'Authorization': 'Bearer ' + getToken()
                            }
                        });

                    notification.success({
                        message: 'Sucesso',
                        description:
                            'Suas observações foram salvas!',
                    });

                    // apenas para não bugar
                    if (result.data.noticia?.observacao) {

                        result.data.noticia.observacao = refInputObservacaoValue.current
                    } else {
                        result.data = {
                            ...result.data,
                            noticia: { observacao: refInputObservacaoValue.current }
                        }
                    }

                    setDadosNoticiaReq(result.data)
                    setPodeSalvarObs(false)
                    setLoading(false);
                }

            } catch (error) {

                let msgErro: any = (error as Error);
                setLoading(false);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'municipios_dkwdfaiweyi')
                }


            }
        }

        buscaCiaddes();

    }, []);

    const onChangeInputSegmentoAtuacao = useCallback((e: any) => {

        refInputSegmentoAtuacaoValue.current = e.target.value;

        const obsev = dadosNoticiaReq?.noticia?.segmento_atuacao !== null && dadosNoticiaReq?.noticia?.segmento_atuacao !== undefined
            ? dadosNoticiaReq.noticia.segmento_atuacao : ''

        if (obsev === e.target.value) {
            setPodeSalvarSegAtuacao(false)
        } else {
            setPodeSalvarSegAtuacao(true)
        }

    }, [dadosNoticiaReq])

    const onClickSalvarSegmentoAtuacao = useCallback((eleicao?: interfDadosPolitico) => {

        const buscaCiaddes = async () => {
            try {

                if (eleicao) {

                    setLoading(true);
                    let result = await api.put(urlPoliticosPoliticoSegmento + '/' + eleicao.cpf,
                        { segmento_atuacao: refInputSegmentoAtuacaoValue.current },
                        {
                            headers: {
                                'Authorization': 'Bearer ' + getToken()
                            }
                        });

                    notification.success({
                        message: 'Sucesso',
                        description:
                            'Suas alteraçoes foram salvas!',
                    });

                    // apenas para não bugar
                    if (result.data.noticia?.segmento_atuacao) {

                        result.data.noticia.segmento_atuacao = refInputSegmentoAtuacaoValue.current
                    } else {
                        result.data = {
                            ...result.data,
                            noticia: { segmento_atuacao: refInputSegmentoAtuacaoValue.current }
                        }
                    }


                    setDadosNoticiaReq(result.data)
                    setPodeSalvarSegAtuacao(false)
                    setLoading(false);
                }

            } catch (error) {

                let msgErro: any = (error as Error);
                setLoading(false);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'municipios_dkwdfaiweyi')
                }


            }
        }

        buscaCiaddes();

    }, []);

    return (
        <>
            <Loading
                loading={loading}
            >
                <Row>
                    <Col
                        span={24}
                        style={{
                            padding: 5
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                marginBottom: 5,
                                alignItems: 'center'
                            }}
                        >
                            <Text style={{ fontSize: '1rem', marginRight: 5 }} >{'Busca Google: '}</Text>
                            <a
                                href={`https://www.google.com.br/search?q=${dadosPoliticoSelecionado?.nome ?
                                    dadosPoliticoSelecionado.nome
                                    :
                                    ''
                                    }`
                                }
                                className='ant-btn ant-btn-link'
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                    marginLeft: 10,
                                    padding: 0, margin: 0,
                                    // fontWeight: 'bold',
                                    fontSize: '1.2rem'
                                }}
                            >
                                {dadosPoliticoSelecionado?.nome ? dadosPoliticoSelecionado.nome : ''}
                            </a>
                        </div>
                    </Col>
                    <Col
                        {...colInputsTextarea}
                        style={{
                            padding: 5
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                marginBottom: 5,
                                alignItems: 'center'
                            }}
                        >
                            <Text style={{ fontSize: '1rem' }} >{'Segmento de Atuação:'}</Text>
                            <Button
                                type="primary"
                                style={{ marginLeft: 10 }}
                                onClick={() => onClickSalvarSegmentoAtuacao(dadosPoliticoSelecionado)}
                                disabled={!podeSalvarSegAtuacao}
                            >
                                Salvar
                            </Button>
                        </div>

                        <textarea
                            className="ant-input"
                            ref={refInputSegmentoAtuacao}
                            style={{ maxHeight: 200, height: 100 }}
                            onChange={(e) => {
                                onChangeInputSegmentoAtuacao(e)
                            }}
                        />
                    </Col>
                    <Col
                        {...colInputsTextarea}
                        style={{
                            padding: 5
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                marginBottom: 5,
                                alignItems: 'center'
                            }}
                        >
                            <Text style={{ fontSize: '1rem' }} >{'Observações:'}</Text>
                            <Button
                                type="primary"
                                style={{ marginLeft: 10 }}
                                onClick={() => onClickSalvarObservacao(dadosPoliticoSelecionado)}
                                disabled={!podeSalvarObs}
                            >
                                Salvar
                            </Button>
                        </div>

                        <textarea
                            className="ant-input"
                            ref={refInputObservacao}
                            style={{ maxHeight: 200, height: 100 }}
                            onChange={(e) => {
                                onChangeInputObservacao(e)
                            }}
                        />
                    </Col>
                </Row>
            </Loading>
        </>
    );
}
