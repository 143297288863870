import { useCallback, useState, useRef, useContext, useEffect } from 'react';
import { Form, Input, Button, Row, Col, Spin, notification, Alert } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { api } from '../../services/apiAxios';

// @ts-ignore
import ImagemLogo from './../../assets/imagens/logo.png';

import { urlUsuarioSession } from './../../services/urls';
import { GlobalContext } from './../../contexts/globalContext';
import { EmitirErro } from './../../services/EmitirErro';
import { VerificaRota } from '../../services/VerificaSePodeAcessar';
import './style.css';
import { Loading } from '../../components/Loading';

const Login = () => {

    const [params] = useSearchParams();
    const isParamEmail = params.get("email");

    const navigate = useNavigate();
    const formRef = useRef<any>(null);

    const { theme } = useContext(GlobalContext);

    const [loading, setLoading] = useState(false);
    const [msgEmail, setMsgEmail] = useState(false);

    useEffect(() => {

        let arrayUrl = window.location.pathname.split("/");
        if (VerificaRota(arrayUrl, navigate, false)) {
            navigate('/municipios');
        }


        if (isParamEmail) {

            const emailParam = String(isParamEmail);

            if (emailParam === 'true') {

                setMsgEmail(true)
            } else {
                setMsgEmail(false)
            }

        }

    }, [])

    const onSubmit = useCallback((): void => {
        const buscaDeDados = async (dados: any) => {
            try {
                setLoading(true);
                let result = await api.post(urlUsuarioSession, dados);

                localStorage.setItem('@DesenvolveSP:token', JSON.stringify(
                    { token: result.data.token }
                ));

                localStorage.setItem('@DesenvolveSP:rftoken', JSON.stringify(
                    result.data
                ));

                localStorage.setItem('@DesenvolveSP:usuario', JSON.stringify(
                    { usuario: result.data.usuario }
                ));

                formRef.current?.resetFields();

                if (result.data.usuario?.primeiro_acesso) {

                    notification.warning({
                        message: `Redefina sua senha!`,
                    });

                    navigate('/primeiro-acesso');

                } else {

                    navigate('/municipios');

                }

            } catch (error) {
                setLoading(false);

                EmitirErro(error, true, 'login_dkwdfaiweyi')
            }
        }

        formRef.current?.validateFields()
            .then((values: any) => {

                buscaDeDados(values);

            })
            .catch(() => {
                // .catch((errorInfo: any) => {

                notification.error({
                    message: 'Erro',
                    description:
                        'Preencha os campos obrigatorios!',
                });
            });


    }, []);

    return (
        <>
            <Loading
                loading={loading}
            />
            <Row gutter={24} style={{ maxHeight: "100vh", margin: 0 }} align="middle">

                <Col
                    className='fotoFundoLogin'
                    style={{
                        filter: theme === 'light' ? 'grayscale(0%)' : 'grayscale(100%)',
                        // display: 'flex',
                        // alignItems: 'center',
                        // justifyContent: 'center',


                        // borderTopWidth: 0,
                        // borderBottomWidth: 0,
                        // borderRightWidth: 4,
                        // borderLeftWidth: 0,
                        // borderStyle: 'solid',
                        // borderColor: '#3B5AA3'
                    }} flex={3}
                >
                    {/* <img style={{
                        width: '400px'
                    }} src={ImagemLogo}></img> */}
                </Col>
                <Col flex={1} style={{
                    height: '100vh',
                    // backgroundColor: '#243463',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    <Form
                        name="formLogin"
                        ref={formRef}
                        initialValues={{
                            remember: true,
                        }}
                        style={{
                            width: '100%'
                        }}

                        onFinish={onSubmit}
                    // onFinishFailed={onFinishFailed}
                    >
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <img style={{
                                width: '290px',
                                // margin: '20px'
                            }} src={ImagemLogo}></img>

                            {
                                msgEmail &&
                                // <div
                                //     style={{
                                //         width: '100%'
                                //     }}
                                // >
                                <div
                                    style={{
                                        float: 'left',
                                        marginLeft: 30,
                                        marginRight: 30
                                    }}
                                >

                                    <Alert
                                        message="Verifique seu email."
                                        type="success"
                                        style={{
                                            paddingLeft: 5,
                                            paddingRight: 5,
                                            paddingTop: 2,
                                            paddingBottom: 2,
                                            marginTop: 10,
                                            marginBottom: -15,
                                        }}
                                    />
                                </div>
                                //  </div>
                            }
                        </div>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            // alignItems: 'center',
                            // justifyContent: 'center'
                            margin: 30
                        }}>

                            <Form.Item
                                name="email"
                                style={{
                                    marginBottom: 15
                                }}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Por favor digite seu email!',
                                    },
                                ]}
                            >
                                <Input placeholder="Email" />
                            </Form.Item>

                            <Form.Item
                                name="senha"
                                style={{
                                    marginBottom: 15
                                }}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Por favor digite sua senha!',
                                    },
                                ]}
                            >
                                <Input.Password placeholder="Senha" />
                            </Form.Item>

                            <div>
                                <Button
                                    style={{
                                        // marginLeft: 7
                                        marginBottom: 20,
                                        padding: 0
                                    }}
                                    type="link"
                                    onClick={() => {
                                        navigate('/recuperar-senha')
                                    }}
                                // className="login-form-forgot"
                                >
                                    Esqueceu a Senha?
                                </Button>
                            </div>

                            <Button
                                type="primary"
                                style={{ width: "100%" }}
                                htmlType='submit'
                            // onKeyDown={onPreesTecla}
                            // onClick={onSubmitClick}
                            >
                                Entrar
                            </Button>
                        </div>
                        {/* <Row>
                            <Col {...Layout1_2}>
                                <Form.Item
                                    name="email"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Por favor digite seu nome!',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Nome" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col {...Layout1_2}>
                                <Form.Item
                                    name="senha"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Por favor digite sua senha!',
                                        },
                                    ]}
                                >
                                    <Input.Password placeholder="Senha" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col {...esqueceuASenha}>
                                <Form.Item>
                                    <a style={{ marginLeft: 7 }} className="login-form-forgot">
                                        Esqueceu a Senha?
                                    </a>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col {...Layout1_2}>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit" style={{ width: "100%" }} >
                                        Entrar
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row> */}
                    </Form>
                </Col>
            </Row>
        </>
    );
};

export default Login;
