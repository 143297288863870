import React, { useContext, useState } from 'react';
import { Typography, Button, Col, Modal } from 'antd';
import {
    InfoCircleOutlined
} from '@ant-design/icons';
import { GlobalContext } from './../../../../../../contexts/globalContext';
import { DividerStyled } from '../../../../../../components/DividerStyled';
import { colTodasInformacoesEsquerda, colTodasInformacoes } from './../GridStyle';
import './style.css';

// @ts-ignore
import rgfimage from './../../../../../../assets/imagens/rgfimage.png';
import { funcRetornaNomeParadataComTempo } from '../../../../../../services/Conversores';

const { Text } = Typography;

interface interfDadosMunicipioSelecionado {
    municipioSelecionado?: {
        "limiteLrf"?: number | null,
        "cdp": boolean,
        "cdpAtualizadoEm": string | null,
        "despesaPessoalLegislativo"?: number | null,
        "despesaPessoalLegislativoAtualizadoEm": string | null,
        "despesaPessoalLegislativoPeriodo": string | null,
        "despesaPessoalExecutivo"?: number | null,
        "despesaPessoalExecutivoAtualizadoEm": string | null,
        "despesaPessoalExecutivoPeriodo": string | null,
        "pendentesRegularizacaoConsultaJuridica": boolean,
        "pendentesRegularizacaoConsultaJuridicaAtualizadoEm": string | null,
        "retificacaoRgfEm": string | null,
        "retificacaoRreoEm": string | null,
        "homologacaoRgf": boolean,
        "homologacaoRgfPeriodo": string | null,
        "homologacaoRgfEm": string | null,
        "homologacaoRgfAtualizadoEm": string | null,
        "homologacaoRreoAtualizadoEm": string | null,
        "homologacaoRreoEm": string | null,
        "homologacaoRreoPeriodo": string | null,
        "homologacaoRreo": boolean,
        "municipioIdIbge": number,
        "municipio": {
            "idIbge": number,
            "nome"?: string | null,
            "ddd"?: string | null,
            "populacao"?: number | null,
            "valorComprometido"?: number | null,
            "municipioAtendido": boolean,
            "municipioAtendidoAtualizadoEm": string | null,
            "regiaoAdministrativa"?: {
                "id"?: string | null,
                "createdAt": string,
                "updatedAt": string,
                "nome"?: string | null
            },
            "createdAt": string,
            "updatedAt": string
        },
        "limitePotencial"?: number | null,
        "habilitado": boolean
    }
}

export const HomologRREOandRGFDataModal = ({
    municipioSelecionado
}: interfDadosMunicipioSelecionado) => {

    const { theme } = useContext(GlobalContext);

    const [visibleModal, setVisibleModal] = useState<boolean>(false);

    return (
        <>
            <Col {...colTodasInformacoes} style={{ marginBottom: 5, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div
                            style={{
                                // width: '50%',
                                // backgroundColor: 'red',
                                marginRight: 5,
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            <Text style={{ fontSize: '1.1rem' }} >{'Homologação RREO'}</Text>
                            <div>
                                <Button
                                    style={{
                                        padding: 0,
                                        marginTop: 3.5,
                                        marginLeft: 6,
                                        border: 0,
                                        height: 0
                                    }}
                                    type="link"
                                    onClick={() => { setVisibleModal(true) }}
                                >
                                    <InfoCircleOutlined style={{ fontSize: '1.1rem', color: theme === 'light' ? '#000000d9' : '#fff' }} />
                                </Button>
                            </div>
                        </div>
                        <Text
                            style={{
                                fontSize: '1.1rem',
                                fontWeight: 'bold',
                                color: municipioSelecionado && municipioSelecionado.homologacaoRreo ? 'green' : '#f00'
                            }}
                        >
                            {municipioSelecionado &&
                                municipioSelecionado.homologacaoRreo ?
                                'SIM'
                                : 'NÃO'}
                        </Text>
                    </div>
                    {municipioSelecionado &&
                        municipioSelecionado?.homologacaoRreoEm &&
                        <div style={{ display: 'flex' }}>
                            <Text style={{ fontSize: '0.8rem' }}>
                                {municipioSelecionado &&
                                    municipioSelecionado?.homologacaoRreoEm ?
                                    "Homologado em: " + funcRetornaNomeParadataComTempo(municipioSelecionado.homologacaoRreoEm, false) : ''
                                }
                            </Text>
                        </div>
                    }
                    {municipioSelecionado &&
                        municipioSelecionado?.retificacaoRreoEm &&
                        <div style={{ display: 'flex' }}>
                            <Text style={{ fontSize: '0.8rem' }}>
                                {municipioSelecionado &&
                                    municipioSelecionado?.retificacaoRreoEm ?
                                    "Retificado em: " + funcRetornaNomeParadataComTempo(municipioSelecionado.retificacaoRreoEm, false) : ''
                                }
                            </Text>
                        </div>
                    }
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Text type='secondary' style={{ fontSize: '0.8rem' }}>
                            {municipioSelecionado &&
                                municipioSelecionado?.homologacaoRreoAtualizadoEm ?
                                "Dados atualizados em: " + funcRetornaNomeParadataComTempo(municipioSelecionado.homologacaoRreoAtualizadoEm, true) : ''
                            }
                        </Text>
                        <Text type='secondary' style={{ fontSize: '0.8rem', paddingLeft: 6 }}>
                            {municipioSelecionado &&
                                municipioSelecionado?.homologacaoRreoPeriodo ? municipioSelecionado.homologacaoRreoPeriodo : ''}
                        </Text>
                    </div>
                </div>
                <DividerStyled
                    style={{
                        marginTop: 10,
                        marginBottom: 10
                    }}
                />
            </Col>
            <Col {...colTodasInformacoesEsquerda} style={{ marginBottom: 5, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div
                            style={{
                                // width: '50%',
                                // backgroundColor: 'red',
                                marginRight: 5,
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            <Text style={{ fontSize: '1.1rem' }} >{'Homologação RGF'}</Text>
                            <div>
                                <Button
                                    style={{
                                        padding: 0,
                                        marginTop: 3.5,
                                        marginLeft: 6,
                                        border: 0,
                                        height: 0
                                    }}
                                    type="link"
                                    onClick={() => { setVisibleModal(true) }}
                                >
                                    <InfoCircleOutlined style={{ fontSize: '1.1rem', color: theme === 'light' ? '#000000d9' : '#fff' }} />
                                </Button>
                            </div>
                        </div>
                        <Text
                            style={{
                                fontSize: '1.1rem',
                                fontWeight: 'bold',
                                color: municipioSelecionado && municipioSelecionado.homologacaoRgf ? 'green' : '#f00'
                            }}
                        >
                            {municipioSelecionado &&
                                municipioSelecionado.homologacaoRgf ?
                                'SIM'
                                : 'NÃO'}
                        </Text>
                    </div>
                    {municipioSelecionado &&
                        municipioSelecionado?.homologacaoRgfEm &&
                        <div style={{ display: 'flex' }}>
                            <Text style={{ fontSize: '0.8rem' }}>
                                {municipioSelecionado &&
                                    municipioSelecionado?.homologacaoRgfEm ?
                                    "Homologado em: " + funcRetornaNomeParadataComTempo(municipioSelecionado.homologacaoRgfEm, false) : ''
                                }
                            </Text>
                        </div>
                    }
                    {municipioSelecionado &&
                        municipioSelecionado?.retificacaoRgfEm &&
                        <div style={{ display: 'flex' }}>
                            <Text style={{ fontSize: '0.8rem' }}>
                                {municipioSelecionado &&
                                    municipioSelecionado?.retificacaoRgfEm ?
                                    "Retificado em: " + funcRetornaNomeParadataComTempo(municipioSelecionado.retificacaoRgfEm, false) : ''
                                }
                            </Text>
                        </div>
                    }
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Text type='secondary' style={{ fontSize: '0.8rem' }}>
                            {municipioSelecionado &&
                                municipioSelecionado?.homologacaoRgfAtualizadoEm ?
                                "Dados atualizados em: " + funcRetornaNomeParadataComTempo(municipioSelecionado.homologacaoRgfAtualizadoEm, true) : ''
                            }
                        </Text>
                        <Text type='secondary' style={{ fontSize: '0.8rem', paddingLeft: 6 }}>
                            {municipioSelecionado &&
                                municipioSelecionado?.homologacaoRgfPeriodo ? municipioSelecionado.homologacaoRgfPeriodo : ''}
                        </Text>
                    </div>
                </div>
                <DividerStyled
                    style={{
                        marginTop: 10,
                        marginBottom: 10
                    }}
                />
            </Col>
            <Modal
                title="Datas de limite para Homologação do RREO e RGF"
                visible={visibleModal}
                onOk={() => setVisibleModal(false)}
                onCancel={() => setVisibleModal(false)}
                style={{
                    top: 20
                }}
                className="modalDadasLimite_kaevdkfaed"
                footer={[
                    <Button key="submit" type="primary" onClick={() => setVisibleModal(false)}>
                        OK
                    </Button>,
                ]}
                key="modaldatalimitesnwwdgaiwsdfawi"
            >
                <img style={{
                    width: '100%',
                    height: '40%'
                }} src={rgfimage} />
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <Text  >{'Fonte:'}</Text>
                    <a
                        href={'https://www.tesourotransparente.gov.br/mip'}
                        style={{
                            padding: 0,
                            marginLeft: 5
                        }}
                        className='ant-btn ant-btn-link botaoNaolinha'
                        target="_blank"
                        rel="noopener noreferrer"
                    >

                        {'https://www.tesourotransparente.gov.br/mip'}
                    </a>
                </div>
            </Modal>
        </>
    );
}
