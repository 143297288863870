import React, { useContext, useEffect, useState } from 'react';
import { Typography, Col, Row } from 'antd';
import Icon from '@ant-design/icons';

import { GlobalContext } from '../../../../../../contexts/globalContext';
import { colListT } from './GridStyle';
import { convertDataUSParaBrasil, convertMoedaBRDoJSComExtamente2CasasDecimais } from '../../../../../../services/Conversores';
import {
    FaUser,
    FaCalendar,
    FaVenusMars,
    FaChild,
    // FaCircle,
    FaMap,
    FaGraduationCap,
    // FaBuilding,
    // FaUserGroup,
    FaFolderOpen,
    FaGlobeAmericas,
    FaDollarSign
} from 'react-icons/fa';
import {
    BsBank,
    BsCircle
} from 'react-icons/bs';
import {
    HiUserGroup
} from 'react-icons/hi';
import { CardDadosPessoais } from './components/Card';
import { CardSitesDadosPessoais } from './components/CardSites';
const IconFaUser = (props: any) => <Icon {...props} component={FaUser} ></Icon>
const IconFaCalendar = (props: any) => <Icon {...props} component={FaCalendar} ></Icon>
const IconFaVenusMars = (props: any) => <Icon {...props} component={FaVenusMars} ></Icon>
const IconFaChild = (props: any) => <Icon {...props} component={FaChild} ></Icon>
const IconBsCircle = (props: any) => <Icon {...props} component={BsCircle} ></Icon>
const IconFaMap = (props: any) => <Icon {...props} component={FaMap} ></Icon>
const IconFaGraduationCap = (props: any) => <Icon {...props} component={FaGraduationCap} ></Icon>
const IconBsBank = (props: any) => <Icon {...props} component={BsBank} ></Icon>
const IconHiUserGroup = (props: any) => <Icon {...props} component={HiUserGroup} ></Icon>
const IconFaFolderOpen = (props: any) => <Icon {...props} component={FaFolderOpen} ></Icon>
const IconFaGlobeAmericas = (props: any) => <Icon {...props} component={FaGlobeAmericas} ></Icon>
const IconFaDollarSign = (props: any) => <Icon {...props} component={FaDollarSign} ></Icon>

const { Text } = Typography;

// const largura = window.innerWidth;
// const altura = window.innerHeight;

interface interfDadosPessoais {
    coligacao: string | null,
    composicao_coligacao: string | null,
    cor_raca: string | null,
    cpf: string | null,
    data_nascimento: string | null,
    estado_civil: string | null,
    grau_de_instrucao: string | null,
    nacionalidade_naturalidade: string | null,
    nome: string,
    ocupacao: string | null,
    _id: string,
    genero: string | null,//daqui pra baixo não esta vindo na rota
    sites: Array<string>,
    limite_legal_de_gastos_1t: number | null,
    limite_legal_de_gastos_2t: number | null,
    atualizado_em: string | null,
    dados_atualizado_em: string | null,
}


interface interfDadosMunicipioSelecionado {
    dadosPoliticoSelecionado?: {
        cpf: string,
        nome: string,
        parceiro: string,
        cargo: string,
        status: string,
        img: string,
    },
    dadosInAbaDadosPessoais?: interfDadosPessoais | null
}

export const DadosPessoais = ({
    dadosInAbaDadosPessoais
}: interfDadosMunicipioSelecionado) => {

    const { theme } = useContext(GlobalContext);

    const [dadosDadosPessoaisReq, setDadosDadosPessoaisReq] = useState<interfDadosPessoais | null>(null);

    useEffect(() => {

        // if (dadosPoliticoSelecionado) buscaCidades(dadosPoliticoSelecionado.cpf);
        if (dadosInAbaDadosPessoais) {
            setDadosDadosPessoaisReq(dadosInAbaDadosPessoais);
        } else {
            setDadosDadosPessoaisReq(null);
        }

    }, [dadosInAbaDadosPessoais]);



    return (
        <>
            <Row
                style={{
                    backgroundColor: theme === 'light' ? '#F7F9FC' : '#000',
                    paddingTop: 15,
                    // paddingBottom: 15,
                    paddingLeft: 20
                }}
            >
                {
                    dadosInAbaDadosPessoais?.dados_atualizado_em &&
                    <div
                        style={{
                            marginBottom: 2
                        }}
                        className="DadosPrefeito_divsDataHora_olbrqeoiugfyb"
                    >
                        <Text style={{ marginRight: 5, fontSize: '1rem' }} >{'Última atualização feita pelo TSE em:'}</Text>
                        <Text style={{ fontWeight: 'bold', fontSize: '1rem' }}>
                            {dadosInAbaDadosPessoais?.dados_atualizado_em ? convertDataUSParaBrasil(dadosInAbaDadosPessoais.dados_atualizado_em) : ''}
                        </Text>
                    </div>
                }
            </Row>
            <Row
                style={{
                    backgroundColor: theme === 'light' ? '#F7F9FC' : '#000',
                    padding: 15
                }}
            >
                {dadosDadosPessoaisReq &&
                    <>
                        {dadosDadosPessoaisReq?.nome &&
                            <Col
                                {...colListT}
                                style={{
                                    padding: 5
                                }}
                            >
                                <CardDadosPessoais
                                    renderIcon={() =>
                                        <IconFaUser
                                            style={{ color: '#fff', fontSize: '1.1rem' }}
                                        />
                                    }
                                    title={dadosDadosPessoaisReq.nome}
                                    description={'NOME COMPLETO'}
                                />
                            </Col>
                        }
                        {
                            dadosDadosPessoaisReq?.data_nascimento &&
                            <Col
                                {...colListT}
                                style={{
                                    padding: 5
                                }}
                            >
                                <CardDadosPessoais
                                    renderIcon={() =>
                                        <IconFaCalendar
                                            style={{ color: '#fff', fontSize: '1.1rem' }}
                                        />
                                    }
                                    title={convertDataUSParaBrasil(dadosDadosPessoaisReq.data_nascimento)}
                                    description={'DATA DE NASCIMENTO'}
                                />
                            </Col>
                        }
                        {dadosDadosPessoaisReq?.genero &&
                            <Col
                                {...colListT}
                                style={{
                                    padding: 5
                                }}
                            >
                                <CardDadosPessoais
                                    renderIcon={() =>
                                        <IconFaVenusMars
                                            style={{ color: '#fff', fontSize: '1.1rem' }}
                                        />
                                    }
                                    title={dadosDadosPessoaisReq.genero}
                                    description={'GÊNERO'}
                                />
                            </Col>
                        }
                        {dadosDadosPessoaisReq.cor_raca &&
                            <Col
                                {...colListT}
                                style={{
                                    padding: 5
                                }}
                            >
                                <CardDadosPessoais
                                    renderIcon={() =>
                                        <IconFaChild
                                            style={{ color: '#fff', fontSize: '1.1rem' }}
                                        />
                                    }
                                    title={dadosDadosPessoaisReq.cor_raca}
                                    description={'COR / RAÇA'}
                                />
                            </Col>
                        }
                        {dadosDadosPessoaisReq.estado_civil &&
                            <Col
                                {...colListT}
                                style={{
                                    padding: 5
                                }}
                            >
                                <CardDadosPessoais
                                    renderIcon={() =>
                                        <IconBsCircle
                                            style={{ color: '#fff', fontSize: '1.1rem' }}
                                        />
                                    }
                                    title={dadosDadosPessoaisReq.estado_civil}
                                    description={'ESTADO CIVIL'}
                                />
                            </Col>
                        }
                        {dadosDadosPessoaisReq.nacionalidade_naturalidade &&
                            <Col
                                {...colListT}
                                style={{
                                    padding: 5
                                }}
                            >
                                <CardDadosPessoais
                                    renderIcon={() =>
                                        <IconFaMap
                                            style={{ color: '#fff', fontSize: '1.1rem' }}
                                        />
                                    }
                                    title={dadosDadosPessoaisReq.nacionalidade_naturalidade}
                                    description={'NACIONALIDADE / NATURALIDADE'}
                                />
                            </Col>
                        }
                        {dadosDadosPessoaisReq.grau_de_instrucao &&
                            <Col
                                {...colListT}
                                style={{
                                    padding: 5
                                }}
                            >
                                <CardDadosPessoais
                                    renderIcon={() =>
                                        <IconFaGraduationCap
                                            style={{ color: '#fff', fontSize: '1.1rem' }}
                                        />
                                    }
                                    title={dadosDadosPessoaisReq.grau_de_instrucao}
                                    description={'GRAU DE INSTRUÇÃO'}
                                />
                            </Col>
                        }
                        {dadosDadosPessoaisReq.ocupacao &&
                            <Col
                                {...colListT}
                                style={{
                                    padding: 5
                                }}
                            >
                                <CardDadosPessoais
                                    renderIcon={() =>
                                        <IconBsBank
                                            style={{ color: '#fff', fontSize: '1.1rem' }}
                                        />
                                    }
                                    title={dadosDadosPessoaisReq.ocupacao}
                                    description={'OCUPAÇÃO'}
                                />
                            </Col>
                        }
                        {dadosDadosPessoaisReq.coligacao &&
                            <Col
                                {...colListT}
                                style={{
                                    padding: 5
                                }}
                            >
                                <CardDadosPessoais
                                    renderIcon={() =>
                                        <IconHiUserGroup
                                            style={{ color: '#fff', fontSize: '1.1rem' }}
                                        />
                                    }
                                    title={dadosDadosPessoaisReq.coligacao}
                                    description={'COLIGAÇÃO'}
                                />
                            </Col>
                        }
                        {dadosDadosPessoaisReq.composicao_coligacao &&
                            <Col
                                {...colListT}
                                style={{
                                    padding: 5
                                }}
                            >
                                <CardDadosPessoais
                                    renderIcon={() =>
                                        <IconFaFolderOpen
                                            style={{ color: '#fff', fontSize: '1.1rem' }}
                                        />
                                    }
                                    title={dadosDadosPessoaisReq.composicao_coligacao}
                                    description={'COMPOSIÇÃO DA COLIGAÇÃO'}
                                />
                            </Col>
                        }

                        {dadosDadosPessoaisReq.limite_legal_de_gastos_1t != null ?
                            <Col
                                {...colListT}
                                style={{
                                    padding: 5
                                }}
                            >
                                <CardDadosPessoais
                                    renderIcon={() =>
                                        <IconFaDollarSign
                                            style={{ color: '#fff', fontSize: '1.1rem' }}
                                        />
                                    }
                                    title={convertMoedaBRDoJSComExtamente2CasasDecimais(dadosDadosPessoaisReq.limite_legal_de_gastos_1t, true)}
                                    description={'LIMITE LEGAL DE GASTOS 1º TURNO'}
                                />
                            </Col>
                            :
                            <Col
                                {...colListT}
                                style={{
                                    padding: 5
                                }}
                            >
                                <CardDadosPessoais
                                    renderIcon={() =>
                                        <IconFaDollarSign
                                            style={{ color: '#fff', fontSize: '1.1rem' }}
                                        />
                                    }
                                    title={'Sem limite legal cadastrado no TSE'}
                                    description={'LIMITE LEGAL DE GASTOS 1º TURNO'}
                                />
                            </Col>
                        }
                        {dadosDadosPessoaisReq.limite_legal_de_gastos_2t != null && dadosDadosPessoaisReq.limite_legal_de_gastos_2t != 0 &&
                            <Col
                                {...colListT}
                                style={{
                                    padding: 5
                                }}
                            >
                                <CardDadosPessoais
                                    renderIcon={() =>
                                        <IconFaDollarSign
                                            style={{ color: '#fff', fontSize: '1.1rem' }}
                                        />
                                    }
                                    title={convertMoedaBRDoJSComExtamente2CasasDecimais(dadosDadosPessoaisReq.limite_legal_de_gastos_2t, true)}
                                    description={'LIMITE LEGAL DE GASTOS 2º TURNO'}
                                />
                            </Col>
                        }
                        {dadosDadosPessoaisReq.sites.length === 0 ?
                            <Col
                                {...colListT}
                                style={{
                                    padding: 5
                                }}
                            >
                                <CardDadosPessoais
                                    renderIcon={() =>
                                        <IconFaGlobeAmericas
                                            style={{ color: '#fff', fontSize: '1.1rem' }}
                                        />
                                    }
                                    title={'Nenhum site cadastrado'}
                                    description={'SITE DO CANDIDATO'}
                                />
                            </Col>
                            :
                            <Col
                                {...colListT}
                                style={{
                                    padding: 5
                                }}
                            >
                                <CardSitesDadosPessoais
                                    renderIcon={() =>
                                        <IconFaGlobeAmericas
                                            style={{ color: '#fff', fontSize: '1.1rem' }}
                                        />
                                    }
                                    sites={dadosDadosPessoaisReq.sites}
                                    description={`${dadosDadosPessoaisReq.sites.length === 1 ? 'SITE' : 'SITES'} DO CANDIDATO`}
                                />
                            </Col>
                        }
                    </>
                }
            </Row>
        </>
    );
}
